import React, { useState, useEffect } from 'react';
import './toggle.scss';
import { useCoinContextData } from '../../context/CoinContext';

function Toggle({ toggle = false, setToggle = () => null, coinType }) {
  const { check, setCheck } = useCoinContextData();

  useEffect(() => {
    setToggle(check);
  }, [check]);

  return (
    <div className="togglecomp">
      <p>
        {coinType === 'tracker'
          ? 'Deposits'
          : check
          ? 'With Balances'
          : 'Without Balances'}
      </p>
      <div className="toggle-div">
        <input
          className="tgl1"
          type="checkbox"
          id="switch"
          onClick={(e) => setCheck(e.target.checked)}
          checked={check}
        />
        <label className="tgl2" htmlFor="switch" />
      </div>
    </div>
  );
}

export default Toggle;
