import axios from 'axios';

export const addProductFunction = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/gxb/product/create',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteProductFunction = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/gxb/product/delete',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const statusFilterFunction = async (email, status) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/gxb/product/get?product_created_by=${email}&status=${status}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const clearFilterFunction = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/gxb/product/get?product_created_by=${email}&status=active`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const addCompPlanFunction = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/gxb/product/commission/fees/set',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getCustomerLicense = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/user/license/get?merchant=${email}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};
