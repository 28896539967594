import React, { useRef } from 'react';
import LoadingAnimation from './LoadingAnimation';
import ProfileAvatar from './ProfileAvatar';
import { usdValueFormatter } from '../../../utils/FunctionTools';
import add from '../../../static/images/affiliate/add.png';

const LevelUsers = ({
  userList,
  emptyMessage,
  selectedUser,
  setSelectedUser,
  isLoading,
  setLastSelectedUser,
  onUserSelected,
  level,
  isMoneyViewOpen,
  onPopupOpen,
}) => {
  const scrollRef = useRef();

  const onItemClick = (item) => {
    if (!selectedUser) {
      setSelectedUser(item);
      setLastSelectedUser(item);
      onUserSelected(item.email, level);
    }
  };

  const onScroll = (isRight) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += isRight ? 200 : -200;
    }
  };

  return (
    <div className="level-user-list">
      {isLoading ? (
        <LoadingAnimation />
      ) : userList !== undefined ? (
        userList?.length > 0 ? (
          <div className="user-list-container">
            <div className="scroll-arrow" onClick={() => onScroll(false)}>
              <svg
                width="40"
                height="36"
                viewBox="0 0 40 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.5958 15.1924C37.4321 15.1658 37.2665 15.1536 37.1007 15.1557H8.88155L9.49687 14.8695C10.0983 14.5848 10.6455 14.1974 11.1139 13.7247L19.0273 5.81135C20.0695 4.81645 20.2446 3.21598 19.4422 2.01923C18.5084 0.743948 16.7176 0.467051 15.4423 1.40086C15.3392 1.47635 15.2413 1.55863 15.1493 1.64717L0.839387 15.9571C-0.278931 17.0741 -0.279914 18.8862 0.837151 20.0045C0.837867 20.0052 0.838672 20.0061 0.839387 20.0068L15.1493 34.3167C16.2685 35.4327 18.0806 35.4302 19.1967 34.311C19.2846 34.2229 19.3666 34.1292 19.4422 34.0305C20.2446 32.8337 20.0695 31.2332 19.0273 30.2383L11.1282 22.3107C10.7083 21.8903 10.2255 21.5378 9.69721 21.266L8.83862 20.8797H36.9432C38.4053 20.934 39.688 19.9127 39.9626 18.4756C40.2156 16.9154 39.156 15.4455 37.5958 15.1924Z"
                  fill="#08152D"
                />
              </svg>
            </div>
            <div className="list" ref={scrollRef}>
              {userList?.map((item) => (
                <div
                  key={item.email}
                  className={`user-item  ${
                    selectedUser?.email === item.email ? 'active' : ''
                  } ${selectedUser ? 'disabled' : ''} `}
                >
                  <div
                    className="d-flex flex-fill"
                    onClick={() => onItemClick(item)}
                  >
                    <ProfileAvatar
                      avatar={item.profile_img}
                      name={item?.name}
                      size={40}
                    />
                    <div className="name-container">
                      <div className="name">{item?.name}</div>
                      <div className="email">
                        {isMoneyViewOpen
                          ? usdValueFormatter.format(
                              item?.comData?.total_earnings || 0
                            )
                          : item.email}
                      </div>
                    </div>
                  </div>
                  {isMoneyViewOpen ? (
                    <div
                      className="earnings-button"
                      onClick={() => onPopupOpen(item)}
                    >
                      <img src={add} alt="" className="earnings-icon" />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="scroll-arrow" onClick={() => onScroll(true)}>
              <svg
                width="40"
                height="36"
                viewBox="0 0 40 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.40423 20.8076C2.5679 20.8341 2.73354 20.8464 2.89926 20.8442L31.1185 20.8442L30.5031 21.1304C29.9017 21.4151 29.3545 21.8025 28.8861 22.2752L20.9727 30.1886C19.9305 31.1835 19.7554 32.784 20.5578 33.9807C21.4916 35.256 23.2824 35.5329 24.5577 34.5991C24.6608 34.5236 24.7587 34.4413 24.8507 34.3528L39.1606 20.0429C40.2789 18.9258 40.2799 17.1137 39.1628 15.9954C39.1621 15.9947 39.1613 15.9939 39.1606 15.9932L24.8507 1.68327C23.7315 0.56719 21.9194 0.569694 20.8033 1.68891C20.7154 1.777 20.6334 1.87073 20.5578 1.96947C19.7554 3.16622 19.9305 4.7667 20.9727 5.76159L28.8718 13.6893C29.2917 14.1096 29.7745 14.4621 30.3028 14.7339L31.1614 15.1203L3.05676 15.1203C1.59474 15.066 0.312033 16.0873 0.0373726 17.5243C-0.215644 19.0846 0.844005 20.5544 2.40423 20.8076Z"
                  fill="#08152D"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div className="level-user-empty">{emptyMessage}</div>
        )
      ) : null}
    </div>
  );
};

export default LevelUsers;
