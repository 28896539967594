import axios from 'axios';

export const productLinesFunction = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/promo/farm/product/line/get?email=${email}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const fixedPayoutCampaigns = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/promo/farm/video/campaign/get?email=${email}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const vaultCurrencies = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/service/coins/get',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const createFPCampaignFunction = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/promo/farm/video/campaign/create',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteCampaignFunction = async (body) => {
  try {
    const data = await axios.put(
      'https://comms.globalxchange.io/coin/promo/farm/video/campaign/delete',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};
