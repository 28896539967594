import axios from 'axios';

export const getProfileForBanker = async (banker) => {
  try {
    const data = await axios.get(
      `https://teller2.apimachine.com/admin/allBankers?email=${banker}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};
