import React from 'react';
import './aiindex.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import fulllogo from '../../static/images/aiindex/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import MallProduct from '../../components/MallProduct/MallProduct';

const MallsIndex = () => {
  const {
    aitab,
    setAiTab,
    allOpportunities,
    filteredallOpportunities,
    settFilteredallOpportunities,
    setfiltersearch,
  } = useAppContextDetails();
  const { insideplanBCreate, setinsideplanBCreate } = useContext(planBContext);
  return (
    <Layout active="aiindex" className="aiindex" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="aiindex-navbar">
          <div className="aiindex-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-aiindex">
            {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div
            className="aiindex-listBtn"
            onClick={() => {
              setinsideplanBCreate(!insideplanBCreate);
              // setSelectedDropDown(!selectedDropDown);
            }}
          >
            List Your Product
          </div>
        </div>
        <div style={{ position: 'relative', width: '100%', height: '92%' }}>
          <MallProduct />
        </div>
      </div>
    </Layout>
  );
};

export default MallsIndex;
