import React, { useEffect, useContext } from 'react';
import guest from '../../static/images/guest.jpg';
import uparrow from '../../static/images/affiliate/uparrow.svg';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import moment from 'moment';

const ProductsAffiliateBank = () => {
  const { email, username, name, profileImg } = useContext(BankContext);
  const { productsABData, filteredProductsABData } = useAppContextDetails();

  return filteredProductsABData?.length > 0
    ? filteredProductsABData?.map((eachItem, i) => {
        return (
          <div className="ind-detailss products-details" key={'adfda'}>
            <div className="type1" style={{ paddingRight: '4rem' }}>
              <div className="detailss  product-details">
                <p>
                  {eachItem?.txn?.product_code
                    ? eachItem?.txn?.product_code
                    : ''}
                </p>
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                  See Product Details
                </p>
              </div>
            </div>
            <div className="type1">
              <div className="detailss  product-details">
                <p>
                  {eachItem?.txn?.name ? eachItem?.txn?.name : ''}
                  <span>
                    (
                    {eachItem?.commissions?.ps
                      ? eachItem?.commissions?.ps
                      : '0'}
                    )
                  </span>
                </p>
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                  {eachItem?.txn?.email ? eachItem?.txn?.email : ''}
                </p>
              </div>
            </div>
            <div className="type1">
              <div className="detailss product-details">
                <p>
                  {eachItem?.txn
                    ? eachItem?.txn?.product_price?.toFixed(2)
                    : '0.00'}{' '}
                  &nbsp;
                  {eachItem?.txn ? eachItem?.txn?.amount_coin : ''}
                </p>
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                  {' '}
                  {eachItem?.txn?.billing_method
                    ? eachItem?.txn?.billing_method
                    : ''}
                </p>
              </div>
            </div>
            <div className="type1">
              <div className="detailss  product-details">
                <p>
                  {' '}
                  {eachItem?.txn
                    ? eachItem?.txn?.amount_paid?.toFixed(2)
                    : '0.00'}
                  &nbsp;USD
                </p>
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                  From {eachItem?.txn ? eachItem?.txn?.user_pay_coin : ' '}{' '}
                  Vault
                </p>
              </div>
            </div>
            <div className="dd1">
              {eachItem?.commissions
                ? eachItem?.commissions?.dt_commission?.toFixed(2)
                : '0.00'}
              &nbsp;USD
            </div>
            <div className="dd1">
              {eachItem?.commissions
                ? eachItem?.commissions?.it_commission?.toFixed(2)
                : '0.00' + eachItem?.txn?.amount_coin
                ? eachItem?.txn?.amount_coin
                : ''}
              &nbsp;USD
            </div>
            <div className="dd1">
              {eachItem?.commissions
                ? eachItem?.commissions?.bd_commission?.toFixed(2)
                : '0.00' + eachItem?.txn?.amount_coin
                ? eachItem?.txn?.amount_coin
                : ''}
              &nbsp;USD
            </div>
          </div>
        );
      })
    : '';
};

export default ProductsAffiliateBank;
