import React, { useState, useContext, useEffect } from 'react';
import Layout from '../../Layout/Layout';
import './academy.scss';
import img1 from '../../static/images/malls/ham.svg';
import fulllogo from '../../static/images/sidebar-icons/trainingIcon.svg';
import ddown from '../../static/images/malls/ddown.svg';
import search from '../../static/images/malls/search.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Skeleton from 'react-loading-skeleton';
import Customers from './Customers';
import EducationContainer from '../../components/React_Education';

const AcademyPage = () => {
  const { academytab, setAcademytab } = useAppContextDetails();

  return (
    <Layout active="academy" className="academy" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="academy-navbar">
          {/* <div>
            <img src={img1} alt="" />
          </div> */}
          <div className="academy-icon">
            <img src={fulllogo} alt="" />
            Training
          </div>
          <div className="search-academy">
            {/* <div className="all">
              <p>All Videos</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '40%' }}>
            <div
              className="each-option"
              style={{
                // borderBottom:
                //   academytab === 'customers' ? '2px solid #18191d' : '',
                fontWeight: academytab === 'customers' ? '600' : '',
              }}
              onClick={() => setAcademytab('customers')}
            >
              <div>
                <p>For Users</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                // borderBottom:
                //   academytab === 'affiliates' ? '2px solid #18191d' : '',
                fontWeight: academytab === 'affiliates' ? '600' : '',
              }}
              // onClick={() => setAcademytab('affiliates')}
            >
              <div>
                <p>Affiliate Training</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                // borderBottom: academytab === 'paid' ? '2px solid #18191d' : '',
                fontWeight: academytab === 'paid' ? '600' : '',
              }}
              // onClick={() => setAcademytab('paid')}
            >
              <div>
                <p>Paid Courses</p>
              </div>
            </div>
          </div>
        </div>
        {academytab === 'customers' ? (
          <EducationContainer />
        ) : (
          <div className="csoon">Coming soon</div>
        )}
      </div>
    </Layout>
  );
};

export default AcademyPage;
