import bitcoin from '../../../static/images/vaults/bitcoin.svg';
import bonds from '../../../static/images/login/bond.svg';
import forex from '../../../static/images/vaults/forex.svg';
import moneymarket from '../../../static/images/vaults/moneymarket.svg';

export const vaultTypeDataList = (
  cryptoData,
  fiatData,
  moneyMarketData,
  bondsData,
  vaultTypeData,
  numberOfContracts,
  setCoinType,
  onContractsClick
) => [
  {
    id: 1,
    icon: bitcoin,
    type: 'Crypto',
    subText: 'Liquid Vaults',
    secondColumnData: `${cryptoData?.count ?? 0} Assets`,
    thirdColumnData: cryptoData?.liquid_dc,
    onClick: () => setCoinType('crypto'),
  },
  {
    id: 2,
    icon: forex,
    type: 'Forex',
    subText: 'Liquid Vaults',
    secondColumnData: `${fiatData?.count ?? 0} Assets`,
    thirdColumnData: fiatData?.liquid_dc,
    onClick: () => setCoinType('fiat'),
  },
  {
    id: 3,
    icon: moneymarket,
    type: 'MoneyMarket',
    subText: 'Earning Vaults',
    secondColumnData: `${moneyMarketData?.coins?.length ?? 0} Assets`,
    thirdColumnData: cryptoData?.liquid_dc,
    onClick: () => setCoinType('moneyMarkets'),
  },
  {
    id: 4,
    icon: bonds,
    type: 'Bond',
    subText: 'Contracts',
    secondColumnData: `${numberOfContracts} Contracts`,
    thirdColumnData: vaultTypeData?.bondsNetWorth_dc,
    onClick: onContractsClick,
  },
  {
    id: 5,
    icon: bonds,
    type: 'Bond',
    subText: 'Earning Vaults',
    secondColumnData: `${bondsData?.coins?.length ?? 0} Assets`,
    thirdColumnData: bondsData?.totalBondEarnings_dc,
    onClick: () => setCoinType('bonds'),
  },
];
