import React, { useEffect, useContext, useState } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { useMarketContext } from '../../../context/MarketContext';
import { useHistory } from 'react-router-dom';

const Investments = () => {
  const {
    stakingResponse,
    setStakingResponse,
  } = useAppContextDetails();
  const { email } = useContext(BankContext);
  const {
    contractId,
    setContractId,
  } = useMarketContext();
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    let mailId = email;
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/contract/get?email=${mailId}`
      )
      .then((response) => {
        let result = response?.data?.icedContracts;
        // console.log(result, 'investmentsresult');
        // console.log(result[0], 'setSelectedContractresult');
        // console.log(result?.[0]?._id, 'setContractIdresult');
        setStakingResponse(result);
        setContractId(result?.[0]?._id);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="investment-container" style={{ height: '23rem' }}>
      <div
        className="investment-data"
        style={{
          overflowX: 'scroll',
          overflowY: 'hidden',
          gap: '2rem',
          flexWrap: 'nowrap',
          paddingBottom: '0',
        }}
      >
        {isloading
          ? Array(10)
              .fill(' ')
              .map((item, index) => {
                return (
                  <div
                    className="investment-card"
                    key={index}
                    style={{
                      marginTop: '0',
                      minWidth: '35rem',
                      maxWidth: '35rem',
                    }}
                  >
                    <div className="top-data">
                      <div className="coin-data">
                        <Skeleton width={50} height={50} borderRadius={50} />
                        <Skeleton width={75} height={25} />
                      </div>
                      <div className="coin-value">
                        <div className="text1" style={{ textAlign: 'end' }}>
                          Value Of Contracts
                        </div>
                        <div className="text2" style={{ textAlign: 'end' }}>
                          <Skeleton width={75} height={25} />
                        </div>
                      </div>
                    </div>
                    <div className="bottom-data">
                      <div className="total-value">
                        <div className="text1">Total Staked</div>
                        <Skeleton width={75} height={25} />
                      </div>
                      <div className="total-value">
                        <div className="text1">Earnings Till Date</div>
                        <div className="text2" style={{ textAlign: 'center' }}>
                          <Skeleton width={75} height={25} />
                        </div>
                      </div>
                      <div className="total-value">
                        <div className="text1">Future Earnings</div>
                        <div className="text2" style={{ textAlign: 'end' }}>
                          <Skeleton width={75} height={25} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          : stakingResponse?.map((e, i) => {
              return (
                <div
                  className="investment-card"
                  style={{
                    marginTop: '0',
                    minWidth: '35rem',
                    maxWidth: '35rem',
                    boxShadow: `${
                      contractId === e?._id
                        ? '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px hsla(0, 0%, 100%, 0.05)'
                        : ''
                    }`,
                  }}
                  key={i}
                  onClick={() => {
                    setContractId(e?._id);
                  }}
                >
                  <div className="top-data">
                    <div className="coin-data">
                      <img src={e?.coin_image} alt="" />
                      <div>{e?.coin_name}</div>
                    </div>
                    <div className="coin-value">
                      <div className="text1" style={{ textAlign: 'end' }}>
                        Value Of Contracts
                      </div>
                      <div className="text2" style={{ textAlign: 'end' }}>
                        {e?.currentVoc?.toFixed(3)}
                      </div>
                    </div>
                  </div>
                  <div className="bottom-data">
                    <div className="total-value">
                      <div className="text1">Total Staked</div>
                      <div className="text2">{e?.investment}</div>
                    </div>
                    <div className="total-value">
                      <div className="text1">Earnings Till Date</div>
                      <div className="text2" style={{ textAlign: 'center' }}>
                        {e?.total_interest_earned?.toFixed(3)}
                      </div>
                    </div>
                    <div className="total-value">
                      <div className="text1">Future Earnings</div>
                      <div className="text2" style={{ textAlign: 'end' }}>
                        {e?.remaining_earning_power?.toFixed(3)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Investments;
