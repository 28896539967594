import React, { createContext, useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { BankContext } from './Context';

export const DefiContext = createContext();
function DefiContextProvider({ children }) {
  const { email } = useContext(BankContext);
  const [defiTrustProfileId, setDefiTrustProfileId] = useState('');
  useEffect(() => {
    if (email) {
      Axios.get(
        `https://comms.globalxchange.io/user/details/get?email=${email}`
      ).then(({ data }) => {
        if (data.status) {
          const profileId = data.user.icetray_profile_id;
          setDefiTrustProfileId(profileId);
        }
      });
    }
  }, [email]);
  const [trusstCoinList, setTrusstCoinList] = useState([]);
  useEffect(() => {
    Axios.post('https://comms.globalxchange.io/coin/vault/service/coins/get', {
      app_code: 'icetray',
      profile_id: defiTrustProfileId,
    }).then(({ data }) => {
      if (data.status) {
        const { coins_data } = data;
        setTrusstCoinList(coins_data);
      }
    });
  }, [defiTrustProfileId]);

  const [trustCoinListObject, setTrustCoinListObject] = useState('');
  useEffect(() => {
    let coinObj = {};
    if (trusstCoinList) {
      trusstCoinList.forEach((coin) => {
        coinObj[coin.coinSymbol] = coin;
      });
    }
    setTrustCoinListObject(coinObj);
  }, [trusstCoinList]);

  return (
    <DefiContext.Provider
      value={{
        defiTrustProfileId,
        setDefiTrustProfileId,
        trusstCoinList,
        trustCoinListObject,
      }}
    >
      {children}
    </DefiContext.Provider>
  );
}

export default DefiContextProvider;
