import React from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import info from '../../../static/images/info.svg';
import timebox from '../../../static/images/timebox.svg';
import {
  getDateTime,
  getTime,
  getTimeFull,
  getYear,
  handleDecimal,
} from '../../../utils/FunctionTools';
import picelock from '../../../static/images/picelock.svg';
import {
  ExchangeRangeSkeleton,
  FeesCardSkeleton,
  InputSkeleton,
  Step3Skeleton,
  Step3SkeletonBottom,
  TimeLoadingSkeleton,
} from './Skeletons';
import Skeleton from 'react-loading-skeleton';

export const WithdrawalConfirmation = ({
  handleBreadCrumb,
  confirmationData,
  validateText,
  isLoading,
}) => {
  const {
    addressTerm,
    selectedCoin,
    toTerm,
    checkedOne,
    setCheckedOne,
    checkedTwo,
    setCheckedTwo,
    setSelectedCoin,
    setCoinActionEnabled,
    setAddressTerm,
    setStep,
    fromTerm,
    setInitiateWithdraw,
    setCoinAction,
    setFromTerm,
    setSendVerificationMail,
  } = useCoinContextData();
  const { appCurrencyName, textColor, appColorCode } = useAppContextDetails();

  const handleChangeOne = () => setCheckedOne(!checkedOne);
  const handleChangeTwo = () => setCheckedTwo(!checkedTwo);

  function convertTimestampToTime(timestamp) {
    var date = new Date(timestamp);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    // Add leading zeros to minutes if necessary
    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return hours + ':' + minutes + ' ' + period;
  }

  return isLoading ? (
    <div className="sidebarwithdraw" style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <div
          className="breadcrumb"
          style={{
            display: 'flex',
            padding: '5px 0 5px 1.85rem',
            height: '4%',
            borderTop: '0.5px solid #E7E7E7',
            borderBottom: '0.5px solid #E7E7E7',
            alignItems: 'center',
            width: '100%',
            borderRadius: '0px',
            minHeight: '38px',
            cursor: 'default',
            marginBottom: '0',
            gap: '1rem',
          }}
        >
          <p
            className="breadCrumbText"
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => handleBreadCrumb()}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 1.5rem',
                borderRadius: '35px',
                color: '#18191d',
                fontWeight: '500',
                border: '0.5px solid #e5e5e5',
              }}
            >
              Amount
            </span>
          </p>
          <p
            className="active-crumb"
            style={{ height: '100%', marginTop: '0' }}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 1.5rem',
                borderRadius: '35px',
                color: '#fff',
                fontWeight: '500',
                background: appColorCode,
              }}
            >
              Confirmation
            </span>
          </p>
        </div>
        <div
          className="step-div"
          style={{ padding: '0', width: '100%', height: 'calc(100% - 4%)' }}
        >
          <div className="step-3-scroll-div" style={{ height: '100%' }}>
            <p className="step3-each-card-head1">Debiting App</p>
            <div className="step3-each-card2">
              <Step3Skeleton />
              <Step3SkeletonBottom />
            </div>
            <p className="step3-each-card-head1">Debiting Vault</p>
            <div className="step3-each-card2">
              <Step3Skeleton />
              <Step3SkeletonBottom />
            </div>
            <p className="step3-each-card-head1">Destination</p>
            <div className="step3-each-card2">
              <Step3Skeleton />

              <div className="d-flex flex-column">
                <Skeleton
                  style={{
                    marginLeft: '15px',
                    height: '30px',
                    width: '150px',
                  }}
                />
              </div>
            </div>
            <p className="step3-each-card-head1">Debiting Amount</p>
            <div
              className="from-to-inputs"
              style={{
                margin: '0',
                borderRadius: '0',
                height: 'unset',
                padding: '0',
                borderRight: 'none',
              }}
            >
              <div
                className="div-from-input"
                style={{ height: '6.25rem', padding: '0 1.85rem' }}
              >
                <InputSkeleton />
              </div>
              <div className="div-border"></div>
              <div
                className="div-to-input"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                }}
              >
                <InputSkeleton />
              </div>
            </div>
            <p className="step3-each-card-head1">PriceLock Insurance</p>
            <div className="step3-each-card2" style={{ gap: '15px' }}>
              <div>
                <img className="step3-each-card-img" src={picelock} alt="" />
              </div>
              <p className="step3-each-card-text">Disabled</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 1.85rem',
                height: '5rem',
                alignItems: 'center',
              }}
            >
              <p
                className="step3-subhead"
                style={{ fontWeight: '100', margin: '0' }}
              >
                Fees
              </p>
              <Skeleton
                style={{
                  marginLeft: '25px',
                  height: '30px',
                  width: '50px',
                }}
              />
            </div>
            <div
              className="feescard-1"
              style={{
                borderRadius: '0',
                padding: '0',
                borderRight: 'none',
                borderLeft: 'none',
                borderBottom: 'none',
              }}
            >
              <div
                className="fees-card-content"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                  alignItems: 'center',
                }}
              >
                <p className="fee-text1" style={{ margin: '0' }}>
                  Affiliate
                </p>
                <FeesCardSkeleton />
              </div>
              <div
                className="fees-card-content"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                  alignItems: 'center',
                }}
              >
                <p className="fee-text1" style={{ margin: '0' }}>
                  App{' '}
                  <span>
                    <img src={info} alt="" width={10} />
                  </span>
                </p>
                <FeesCardSkeleton />
              </div>
              <div
                className="fees-card-content"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                  alignItems: 'center',
                }}
              >
                <p className="fee-text1" style={{ margin: '0' }}>
                  Banker
                </p>
                <FeesCardSkeleton />
              </div>
            </div>
            <p className="step3-each-card-head1">Receiving Amount</p>
            <div
              className="from-to-inputs"
              style={{
                margin: '0',
                borderRadius: '0',
                height: 'unset',
                padding: '0',
                borderRight: 'none',
              }}
            >
              <div
                className="div-from-input"
                style={{ height: '6.25rem', padding: '0 1.85rem' }}
              >
                <InputSkeleton />
              </div>
              <div className="div-border"></div>
              <div
                className="div-to-input"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                }}
              >
                <InputSkeleton />
              </div>
            </div>
            <p
              className="step3-each-card-head1"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>Latest Time Of Arrival</div>
              <div>
                <TimeLoadingSkeleton />
              </div>
            </p>
            <div
              className="check-div"
              style={{
                width: '100%',
                padding: '0 1.85rem',
                marginTop: '3.5rem',
              }}
            >
              <label>
                <input
                  className="check-box-withdraw"
                  type="checkbox"
                  key="check-1"
                  id="check-1"
                  checked={checkedOne}
                  onChange={handleChangeOne}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {
                    ' I Consent To All The Aforementioned Details Regarding This Transfer Request.'
                  }
                </p>
              </label>
            </div>
            <div
              className="check-div"
              style={{
                width: '100%',
                padding: '0 1.85rem',
                marginTop: '1rem',
              }}
            >
              <label>
                <input
                  className="check-box-withdraw-2"
                  type="checkbox"
                  key="check-2"
                  id="check-2"
                  checked={checkedTwo}
                  onChange={handleChangeTwo}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {` I Acknowledge That ${
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.appData.app_name
                      : ''
                  } Is Not Able To Recover Funds Once They Have Sent To`}{' '}
                  {addressTerm}
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarwithdraw" style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <div
          className="breadcrumb"
          style={{
            display: 'flex',
            padding: '5px 0 5px 1.85rem',
            height: '4%',
            borderTop: '0.5px solid #E7E7E7',
            borderBottom: '0.5px solid #E7E7E7',
            alignItems: 'center',
            width: '100%',
            borderRadius: '0px',
            minHeight: '38px',
            cursor: 'default',
            marginBottom: '0',
            gap: '1rem',
          }}
        >
          <p
            className="breadCrumbText"
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleBreadCrumb}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 1.5rem',
                borderRadius: '35px',
                color: '#18191d',
                fontWeight: '500',
                border: '0.5px solid #e5e5e5',
              }}
            >
              Amount
            </span>
          </p>
          <p
            className="active-crumb"
            style={{ height: '100%', marginTop: '0' }}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 1.5rem',
                borderRadius: '35px',
                color: '#fff',
                fontWeight: '500',
                background: appColorCode,
              }}
            >
              Confirmation
            </span>
          </p>
        </div>
        <div
          className="step-div"
          style={{ padding: '0', width: '100%', height: 'calc(100% - 4%)' }}
        >
          <div
            className="step-3-scroll-div"
            style={{ height: '100%', position: 'relative' }}
          >
            <p className="step3-each-card-head1">Debiting App</p>
            <div className="step3-each-card2" style={{ gap: '15px' }}>
              <div>
                <img
                  className="step3-each-card-img"
                  src={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.appData.app_icon
                      : ''
                  }
                  alt=""
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
              <p className="step3-each-card-text">
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.appData.app_name
                  : ''}
              </p>
            </div>
            <p className="step3-each-card-head1">Debiting Vault</p>
            <div className="step3-each-card2" style={{ gap: '15px' }}>
              <div>
                <img
                  className="step3-each-card-img"
                  src={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.coinsData.fromCoinData.icon
                      : ''
                  }
                  alt=""
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
              <p className="step3-each-card-text">
                Liquid{' '}
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.coinsData.fromCoinData.name
                  : ''}
              </p>
            </div>
            <p className="step3-each-card-head1">Destination</p>
            <div className="step3-each-card2" style={{ gap: '15px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className="step3-each-card-text2">{addressTerm}</p>
              </div>
            </div>
            <p className="step3-each-card-head1">Debiting Amount</p>
            <div
              className="from-to-inputs"
              style={{
                margin: '0',
                borderRadius: '0',
                height: 'unset',
                padding: '0',
                borderRight: 'none',
              }}
            >
              <div
                className="div-from-input"
                style={{ height: '6.25rem', padding: '0 1.85rem' }}
              >
                <input
                  style={{
                    height: '100%',
                    margin: '0',
                    borderRadius: '0',
                    paddingLeft: '0',
                    fontWeight: '500',
                    fontSize: '1.5rem',
                  }}
                  className="from-input"
                  placeholder="0.00"
                  key="from-2"
                  id="from-2"
                  type="text"
                  value={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.finalFromAmount
                      : ''
                  }
                  disabled
                />
                <div
                  className="from-coin"
                  key="from-coin"
                  style={{
                    alignItems: 'center',
                    border: 'none',
                    width: 'unset',
                    height: 'unset',
                    padding: '0',
                    margin: '0',
                  }}
                >
                  <img
                    style={{ width: '30px', height: '30px' }}
                    className="coinimgsmall"
                    src={
                      Object.keys(confirmationData).length > 0
                        ? confirmationData.coinsData.fromCoinData.icon
                        : ''
                    }
                    alt=""
                  />
                  <p
                    className="coinsymbolsmall"
                    style={{ fontSize: '1.5rem', fontWeight: '500' }}
                  >
                    {Object.keys(confirmationData).length > 0
                      ? confirmationData.coinsData.fromCoinData.coin
                      : ''}
                    &nbsp;
                  </p>
                </div>
              </div>
              <div className="div-border"></div>
            </div>
            <p className="step3-each-card-head1">PriceLock Insurance</p>
            <div className="step3-each-card2" style={{ gap: '15px' }}>
              <div>
                <img className="step3-each-card-img" src={picelock} alt="" />
              </div>
              <p className="step3-each-card-text">Disabled</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 1.85rem',
                height: '5rem',
                alignItems: 'center',
              }}
            >
              <p
                className="step3-subhead"
                style={{ fontWeight: '100', margin: '0' }}
              >
                Fees
              </p>
              <p
                className="step3-subhead"
                style={{ fontWeight: '100', margin: '0' }}
              >
                {Object.keys(confirmationData).length > 0
                  ? handleDecimal(confirmationData.reducedAmount)
                  : ''}{' '}
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.fees_in_coin
                  : ''}{' '}
                (
                {Object.keys(confirmationData).length > 0
                  ? confirmationData.percentage_fee_by_volume.fees_with_broker.toFixed(
                      2
                    )
                  : ''}
                %)
              </p>
            </div>
            <div
              className="feescard-1"
              style={{
                borderRadius: '0',
                padding: '0',
                borderRight: 'none',
                borderLeft: 'none',
                borderBottom: 'none',
              }}
            >
              <div
                className="fees-card-content"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                  alignItems: 'center',
                }}
              >
                <p className="fee-text1" style={{ margin: '0' }}>
                  Affiliate
                </p>
                <p
                  className="fee-text2"
                  style={{ margin: '0', fontWeight: '400' }}
                >
                  {Object.keys(confirmationData).length > 0
                    ? handleDecimal(confirmationData.brokerData.fee)
                    : ''}{' '}
                  (
                  {Object.keys(confirmationData).length > 0
                    ? confirmationData.brokerData.broker_fee_percentage.toFixed(
                        2
                      )
                    : ''}
                  %)
                </p>
              </div>
              <div
                className="fees-card-content"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                  alignItems: 'center',
                }}
              >
                <p className="fee-text1" style={{ margin: '0' }}>
                  App
                </p>
                <p
                  className="fee-text2"
                  style={{ margin: '0', fontWeight: '400' }}
                >
                  {Object.keys(confirmationData).length > 0
                    ? handleDecimal(confirmationData.appData.app_fee)
                    : ''}{' '}
                  (
                  {Object.keys(confirmationData).length > 0
                    ? confirmationData.appData.app_fee_percentage.toFixed(2)
                    : ''}
                  %)
                </p>
              </div>
              <div
                className="fees-card-content"
                style={{
                  height: '6.25rem',
                  padding: '0 1.85rem',
                  alignItems: 'center',
                }}
              >
                <p className="fee-text1" style={{ margin: '0' }}>
                  Banker
                </p>
                <p
                  className="fee-text2"
                  style={{ margin: '0', fontWeight: '400' }}
                >
                  {Object.keys(confirmationData).length > 0
                    ? handleDecimal(
                        confirmationData.bankerData.trade_fee_native
                      )
                    : ''}{' '}
                  (
                  {Object.keys(confirmationData).length > 0
                    ? confirmationData.bankerData.banker_fee_percentage.toFixed(
                        2
                      )
                    : ''}
                  %)
                </p>
              </div>
            </div>
            <p className="step3-each-card-head1">Receiving Amount</p>
            <div
              className="from-to-inputs"
              style={{
                margin: '0',
                borderRadius: '0',
                height: 'unset',
                padding: '0',
                borderRight: 'none',
              }}
            >
              <div
                className="div-from-input"
                style={{ height: '6.25rem', padding: '0 1.85rem' }}
              >
                <input
                  style={{
                    height: '100%',
                    margin: '0',
                    borderRadius: '0',
                    paddingLeft: '0',
                    fontWeight: '500',
                    fontSize: '1.5rem',
                  }}
                  className="from-input"
                  placeholder="0.00"
                  key="from-2"
                  id="from-2"
                  type="text"
                  value={
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.finalToAmount
                      : ''
                  }
                  disabled
                />
                <div
                  className="from-coin"
                  key="from-coin"
                  style={{
                    alignItems: 'center',
                    border: 'none',
                    width: 'unset',
                    height: 'unset',
                    padding: '0',
                    margin: '0',
                  }}
                >
                  <img
                    style={{ width: '30px', height: '30px' }}
                    className="coinimgsmall"
                    src={
                      Object.keys(confirmationData).length > 0
                        ? confirmationData.coinsData.toCoinData.icon
                        : ''
                    }
                    alt=""
                  />
                  <p
                    className="coinsymbolsmall"
                    style={{ fontSize: '1.5rem', fontWeight: '500' }}
                  >
                    {Object.keys(confirmationData).length > 0
                      ? confirmationData.coinsData.toCoinData.coin
                      : ''}
                  </p>
                </div>
              </div>
              <div className="div-border"></div>
            </div>
            <p
              className="step3-each-card-head1"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>Latest Time Of Arrival</div>
              <div>24 Hours</div>
            </p>
            <div className="step3-each-card2">
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  className="step3-each-card-text1"
                  style={{ margin: '0', fontWeight: '500', fontSize: '1rem' }}
                >
                  {Object.keys(confirmationData).length > 0
                    ? convertTimestampToTime(confirmationData.currentTimestamp)
                    : ''}
                </p>
                <p
                  className="step3-each-card-text2"
                  style={{ margin: '0', fontWeight: '500' }}
                >
                  {Object.keys(confirmationData).length > 0
                    ? getYear(confirmationData.currentTimestamp)
                    : ''}
                </p>
              </div>
            </div>
            <div
              className="check-div"
              style={{
                width: '100%',
                padding: '0 1.85rem',
                marginTop: '3.5rem',
              }}
            >
              <label>
                <input
                  className="check-box-withdraw"
                  type="checkbox"
                  key="check-1"
                  id="check-1"
                  checked={checkedOne}
                  onChange={handleChangeOne}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {
                    ' I Consent To All The Aforementioned Details Regarding This Transfer Request.'
                  }
                </p>
              </label>
            </div>
            <div
              className="check-div"
              style={{
                width: '100%',
                padding: '0 1.85rem',
                marginTop: '1rem',
                marginBottom: '45vh',
              }}
            >
              <label>
                <input
                  className="check-box-withdraw-2"
                  type="checkbox"
                  key="check-2"
                  id="check-2"
                  checked={checkedTwo}
                  onChange={handleChangeTwo}
                />
                <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                  {` I Acknowledge That ${
                    Object.keys(confirmationData).length > 0
                      ? confirmationData.appData.app_name
                      : ''
                  } Is Not Able To Recover Funds Once They Have Sent To`}{' '}
                  {addressTerm}
                </p>
              </label>
            </div>

            <div className="w-nextBtn1">
              <div
                style={{
                  opacity: checkedOne && checkedTwo ? 1 : 0.25,
                  cursor: checkedOne && checkedTwo ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (checkedOne && checkedTwo) {
                    setSendVerificationMail(true);
                    setStep(4);
                  }
                }}
              >
                Initiate Withdraw
              </div>
            </div>
            <div className="withdrawFooter1">
              <div className="backclose-btns1">
                <div
                  style={{
                    color: '#18191d',
                    border: '1px solid #E5E5E5',
                  }}
                  onClick={() => {
                    handleBreadCrumb();
                  }}
                >
                  Back
                </div>
                <div
                  style={{
                    background: '#FF5E5E',
                  }}
                  onClick={() => {
                    setCoinAction(['Menu']);
                    setSelectedCoin('');
                    setCoinActionEnabled(false);
                    setAddressTerm('');
                    setFromTerm('');
                    setStep(1);
                  }}
                >
                  Close
                </div>
              </div>
              <div
                className="action-btn1"
                style={{
                  background: appColorCode,
                }}
                onClick={() => {
                  setCoinAction(['Menu']);
                  setAddressTerm('');
                  setFromTerm('');
                  setStep(1);
                }}
              >
                {selectedCoin?.coinSymbol} Action
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
