import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { vaultTypeDataList } from './constants';
import { useMarketContext } from '../../../context/MarketContext';

export const VaultTypes = ({
  vaultTypeResponse,
  searchValue,
  contractResponse,
}) => {
  const history = useHistory();
  const { appCurrencySymbol } = useAppContextDetails();
  const { setCoinType } = useCoinContextData();
  const { setStreamOpen } = useMarketContext();
  const contracts = contractResponse?.data?.icedContracts;
  const numberOfContracts = contracts?.reduce(
    (acc, item) => acc + item?.count,
    0
  );

  const vaultTypeData = vaultTypeResponse?.data;
  const cryptoData = vaultTypeData?.crypto;
  const fiatData = vaultTypeData?.fiat;
  const moneyMarketData = vaultTypeData?.MoneyMarkets_Data;
  const bondsData = vaultTypeData?.Bonds_Data;

  const vaultTypeArray = vaultTypeDataList(
    cryptoData,
    fiatData,
    moneyMarketData,
    bondsData,
    vaultTypeData,
    numberOfContracts,
    setCoinType,
    () => {
      history.push('/market/bonds');
      setStreamOpen(true);
    }
  );

  return (
    <>
      {vaultTypeArray
        .filter((item) => item.type?.toLowerCase()?.includes(searchValue))
        ?.map((each) => {
          return (
            <div className="main-card" key={each.id}>
              <div className="individual">
                <div
                  className="minidiv"
                  style={{ width: '100%', paddingRight: 0 }}
                >
                  <div
                    className="first-column align-items-center"
                    style={{ width: '35%' }}
                  >
                    <img
                      className="coinimage"
                      src={each.icon}
                      alt="CoinImage"
                    />
                    <div className="child-div mt-3 ml-2">
                      <h1 className="cardheading">{each.type}</h1>
                      <p className="cardpara">{each.subText}</p>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{ width: '30%' }}
                  >
                    <div className="d-flex">
                      <div className="d-flex flex-column ml-2">
                        <h1 className="cardheading1 pt-2">
                          {each.secondColumnData}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column justify-content-center mt-3"
                    style={{ width: '30%' }}
                  >
                    <div className="d-flex">
                      <div className="d-flex flex-column ml-2">
                        <h1 className="cardheading1">
                          {appCurrencySymbol}{' '}
                          {each.thirdColumnData?.toFixed(2) ?? '0.00'}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttondiv">
                  <button className="actionbtn" onClick={each.onClick}>
                    {each.subText === 'Contracts' ? 'Contracts' : 'Vaults'}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
