import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { BankContext } from '../../context/Context';
import { LoadingAnimation } from '../../components/LoadingAnimation';

import backshow from './backshow.svg';
import e1 from './e1.svg';
import e2 from './e2.svg';
import e3 from './e3.svg';
import e4 from './e4.svg';
import e5 from './e5.svg';
import closeWhite from './closeWhite.svg';
import suceessAfflilate from './suceessAfflilate.svg';
import exchange from './exchange.svg';
import markert from './markert.svg';
import bond from './bond.svg';
import meta from './meta.svg';
import retriment from './retriment.svg';
import logo from './vv.svg';
import './CustomDrawer.scss';
import './Meta.scss';

function Bonds({ selectedUser, listExchange, setlistExchange }) {
  const { email, token } = useContext(BankContext);
  const [toggle, settoggle] = useState(false);
  const [infoData, setinfoData] = useState([]);
  //   const [selectedUser, setselectedUser] = useState([{
  //     name:"xzc"
  //   }]);
  const [dataLoading, setdataLoading] = useState(true);
  useEffect(() => {
    handledataapi(listExchange);
  }, [email]);

  const [apiresult, setapiresult] = useState('');
  const handledataapi = (e) => {
    if (e === 'CryptoBond') {
      setapiresult('crypto');
      getMetaBrands('crypto');
    } else if (e === 'ForexBond') {
      getMetaBrands('fiat');
      setapiresult('fiat');
    } else {
      //getMetaBrands("fCrypto");
    }
  };

  const getMetaBrands = (e) => {
    setdataLoading(true);

    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/user/get/interest/fees?email=${email}&type=${e}`
      )
      .then((res) => {
        // setmetaData(res.data.apps)
        setinfoData(res?.data?.feesData?.fees);
        setdataLoading(false);
        //  console.log("inmzxczxczxdo", res?.data?.levelsData)
      });
  };

  // console.log('dccv123345345345345345345', infoData);

  // useEffect(() => {
  //     setDataLoading(true)
  //     axios
  //         .get(
  //             `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?select_type=fund&banker_email=${banker
  //                 ? banker.bankerTag
  //                 : localStorage.getItem("bankerTag")}}`
  //         )
  //         .then((res) => {
  //             console.log("cxcxvxcvxcvasdzxczxczxczxczcxzxczxzxzwerwer", res?.data)
  //             setbankInfo(res?.data?.paths)
  //             setDataLoading(false)

  //         });
  // }, [banker])
  const [newPair, setnewPair] = useState('');
  const [Exchangelist, setExchangelist] = useState([]);
  const Exchangefeee = [
    {
      name: 'Forex To Crypto',
      des: 'When Your Customers Trade From Forex To Crypto',
      img: e1,
    },
    {
      name: 'Crypto To Forex',
      des: 'When Your Customers Trade From Crypto To Forex',
      img: e2,
    },
    {
      name: 'Forex To Forex',
      des: 'When Your Customers Trade From Forex To Forex',
      img: e3,
    },
    {
      name: 'Crypto To Crypto',
      des: 'When Your Customers Trade Crypto To Crypto',
      img: e4,
    },
    {
      name: 'Per Customer',
      des: 'Set Custom Exchange Fees For Specific Customers',
      img: e5,
    },
  ];

  //const [listExchange, setlistExchange] = useState("");
  const handledata = (e) => {
    setlistExchange(e.name);
    // setselectedUser([e]);
    settoggle(true);
  };
  const [sublist, setsublist] = useState(listExchange);
  const [coin, setcoin] = useState('');

  const handleforexcrypto = (e) => {
    setcoin(e);
    setsublist('list');
  };
  const [pairdata, setpairdata] = useState({
    fee: '',
    buy: '',
    sell: '',
  });
  const [fessloading, setfessloading] = useState('');

  const drawer = () => {
    switch (fessloading) {
      case 'feeexchange':
        return (
          <div className="sideDrawer">
            <div className="input-fee-section">
              <div className="title-tag-for-fee">Previous Fees</div>
              <input
                value={`${
                  pairdata?.fee == ''
                    ? pairdata?.fee
                    : pairdata?.fee?.toFixed(2)
                }%`}
              />
              <div className="title-tag-for-fee" style={{ marginTop: '2rem' }}>
                New Fees
              </div>
              <input
                type="number"
                value={newPair}
                placeholder="0.00%"
                onChange={(e) => setnewPair(e.target.value)}
              />
            </div>

            <div className="label">
              <label
                className="labelupdateallpair"
                onClick={() => postfess('AllPairs')}
              >
                Update Fees For All Pairs
              </label>
              <label
                className="labelupdateSelectedpair"
                onClick={() => postfess('SeletedPairs')}
              >
                Update Fees For BTC/USD
              </label>
            </div>

            <div className="close-section">
              <img
                onClick={() => setfessloading('')}
                className="close"
                src={closeWhite}
              />
            </div>
          </div>
        );
      case 'loading':
        return (
          <div className="loader-fee">
            <LoadingAnimation icon={logo} width={200} />
          </div>
        );

      case 'sucess':
        return (
          <div className="loader-fee">
            <div className="close-section">
              <img
                onClick={() => window.location.reload()}
                className="close"
                src={closeWhite}
              />
            </div>

            <img src={suceessAfflilate} />
            <div className="updatefee">
              Updating Fees For {pairdata.buy}/{pairdata.sell} To {newPair}%
            </div>
          </div>
        );
    }
  };

  const postfess = (e) => {
    setfessloading('loading');
    axios
      .post(`https://comms.globalxchange.io/coin/trade/user/fees/set`, {
        email: email, // user email
        token: token,
        buy: pairdata.buy,
        sell: pairdata.sell,
        percentage: Number(newPair),
        set_default: e == 'AllPairs' ? true : false,
      })
      .then((res) => {
        if (res.data.status) {
          setfessloading('sucess');
          // setfessloading(false)
          // console.log('nbcgr5r', res.data);
        }
      });
  };

  const forexapp = (desktopDataGrid) => {
    switch (sublist) {
      case 'ForexBond':
      case 'CryptoBond':
        return (
          <>
            <div
              style={{
                fontWeight: 700,
                fontSize: '20px',
                // height: window.innerHeight - 175,
                overflowY: 'scroll',
              }}
            >
              {dataLoading ? (
                loadingSection('listDataGrid', desktopDataGrid)
              ) : (
                <>
                  {selectedUser?.map((item) => {
                    return (
                      <>
                        <div
                          //  onClick={() => handledata(item)}

                          className="listDataGrid"
                          style={{
                            gridTemplateColumns: '1.5fr 1fr 1fr 1fr 0.6fr',
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              onClick={() =>
                                setlistExchange('Staking')
                              }
                              src={backshow}
                              alt=""
                              style={{
                                cursor: 'pointer',
                                height: '40px',
                                width: '40px',
                              }}
                              // className={classNames.icon}
                            />
                            <div style={{ paddingLeft: '15px' }}>
                              <div>
                                <div className="customtitle" style={{fontWeight: '600'}}>
                                  {item.name == 'ForexBond'
                                    ? 'Forex'
                                    : item.name == 'CryptoBond'
                                    ? 'Crypto'
                                    : ''}
                                </div>
                                <div
                                  className=""
                                  style={{
                                    fontSize: '11px ',
                                    fontWeight: '400',
                                    color: '#18191D',
                                  }}
                                >
                                  Apps:{' '}
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '13px',
                                    }}
                                  >
                                    --
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div >
                                                            <div className="customtitle">DD Level {item.level}</div>
                                                            <div className="" style={{
                                                                fontSize: "11px ", fontWeight: "400",
                                                                color: "#18191D"
                                                            }}>Users: <span style={{ fontWeight: "bold", fontSize: "13px" }}>{item?.count}</span></div>
                                                        </div>
                                                    </div> */}

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div>
                                <div className="customtitle">--</div>
                                <div
                                  className=""
                                  style={{
                                    fontSize: '11px ',
                                    fontWeight: '400',
                                    color: '#18191D',
                                  }}
                                >
                                  Customer
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div>
                                <div className="customtitle">--</div>

                                <div
                                  className=""
                                  style={{
                                    fontSize: '11px ',
                                    fontWeight: '400',
                                    color: '#18191D',
                                  }}
                                >
                                  Volume
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <div className="customtitle">--</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              My Revenue
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div>
                                <div className="customtitle">--</div>
                                <div
                                  className=""
                                  style={{
                                    fontSize: '11px ',
                                    fontWeight: '400',
                                    color: '#18191D',
                                    textAlign: 'right',
                                  }}
                                >
                                  Revenue Txn’s
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>

            {/* // sdfsdfsdf */}

            <div className="desktopWrapper" style={{height: 'calc(100% - 90px)'}}>
              <div style={{ width: '100%' }}>
                <div
                  className={'listGrid'}
                  style={{ gridTemplateColumns: desktopDataGrid }}
                >
                  <div>Select Base Pair</div>
                </div>
              </div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  height: 'calc(100% - 45px)',
                  overflowY: 'scroll',
                }}
              >
                {' '}
                <>
                  {infoData?.map((item, i) => {
                    return (
                      <>
                        <div
                          className="listDataGrid"
                          style={{
                            gridTemplateColumns: '1fr',
                          }}
                          // onClick={() => {
                          //   setpairdata({
                          //     fee: c[1],
                          //     sell: c[0],
                          //     buy: coin,
                          //   });
                          //   setfessloading("feeexchange");
                          // }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <div className="customtitle">{item?.coin}</div>
                            </div>
                            <div className="customtitle">
                              {item?.iced?.toFixed(2)}%
                              {/* {c[1]?.toFixed(2)}% */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              </div>
            </div>
          </>
        );

      //
    }
  };

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return <>{forexapp(desktopDataGrid)}</>;
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Name</div>
      </div>
    );
  };

  const headerSectionSelected = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Type</div>
      </div>
    );
  };
  const datadropdownlist = [
    {
      name: 'Exchange Fees',
      img: exchange,
    },

    {
      name: 'MoneyMarkets',
      img: markert,
    },
    {
      name: 'Bonds Interest',
      img: bond.svg,
    },
    {
      name: 'Bond Spread',
      img: bond.svg,
    },
    {
      name: 'MarketsVerse',
      img: meta.svg,
    },
    {
      name: 'Retirement Plans',
      img: retriment.svg,
    },
  ];

  const contentSection = (item, gridClass, gridValues) => {
    // console.log('234234234xvcxvxcv', item);
    return (
      <div
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
        onClick={() => handledata(item)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '22px', marginRight: '10px' }} src={item.img} />
          <div>
            <div className="customtitle">{item.name}</div>
          </div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill('')
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: '100%',
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        datadropdownlist,
        dataLoading,
        toggle
          ? '2fr 1.1fr 1.3fr 1.5fr 1.5fr  0.6fr'
          : '1.5fr 1.5fr 1.5fr  0.5fr', // Desktop view Grid columns
        '200px 200px 250px 200px ' // Mobile view Grid columns
      )}

      {/* {conditionalResposiveView(
                infoData,
                dataLoading,
                "1.5fr 1.5fr 1.5fr  0.5fr", // Desktop view Grid columns
                "200px 200px 250px 200px " // Mobile view Grid columns
            )} */}

      {/* <BankerSubDashboard

                bankid={bankid}
            /> */}
    </>
  );
}

export default Bonds;
