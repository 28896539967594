import React, { useEffect, useState, useContext } from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import userArr from '../../static/images/affiliate/userArr.svg';
import userClose from '../../static/images/affiliate/userClose.svg';
import dropdown from '../../static/images/affiliate/dropdown.svg';
import loader from '../../static/images/affiliate/loader1.svg';
import { BankContext } from '../../context/Context';
import { balForSelectedApp, getAllApps, inviteWithGift } from './api';
import { LoadingAnimation } from '../../components/LoadingAnimation';

const Invite = () => {
  const {
    inviteStep,
    setInviteStep,
    invite,
    setInvite,
    selectedInviteApp,
    setSelectedInviteApp,
    isInviteappselected,
    setIsInviteappselected,
    selectedInviteAsset,
    setSelectedInviteAsset,
    isInviteAssetSelected,
    setIsInviteAssetSelected,
    invitefilteredApps,
    setinvitefilteredApps,
    invitefilteredAsset,
    setinvitefilteredAsset,
  } = useAppContextDetails();
  const { email, token } = useContext(BankContext);
  const [allapps, setAllapps] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const [friendname, setFriendName] = useState('');
  const [friendemail, setFriendEmail] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState('');
  const [expiry, setExpiry] = useState('');
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    getAllApps(email).then((response) => {
      let result = response?.data?.userApps;
      setAllapps(result);
      setinvitefilteredApps(result);
    });
  }, []);

  const assetSelection = () => {
    let obj = {
      app_code: selectedInviteApp?.app_code,
      profile_id: selectedInviteApp?.profile_id,
    };
    balForSelectedApp(obj).then((response) => {
      let result = response?.data?.coins_data;
      setCoinData(result);
      setinvitefilteredAsset(result);
    });
  };

  const initiateInvite = () => {
    setIsloading(true);
    let obj = {
      email,
      token,
      app_code: selectedInviteApp?.app_code,
      profile_id: selectedInviteApp?.profile_id,
      sending_currency: selectedInviteAsset?.coinSymbol,
      receiving_currency: selectedInviteAsset?.coinSymbol,
      sending_amount: amount,
      user_email: friendemail,
      user_name: friendname,
      user_phone: phoneno,
      custom_message: message,
      expire_in_days: expiry,
      stats: false,
    };
    inviteWithGift(obj).then((response) => {
      let result = response?.data;
      //   console.log(result, 'result');
      setIsloading(false);
      setInvite(false);
      setInviteStep('step1');
      setAllapps([]);
      setCoinData([]);
      setFriendName('');
      setFriendEmail('');
      setPhoneno('');
      setMessage('');
      setAmount('');
      setExpiry('');
      setSelectedInviteApp([]);
      setIsInviteappselected(false);
      setSelectedInviteAsset([]);
      setIsInviteAssetSelected(false);
    });
  };

  function filterItem(text) {
    let filterItem = allapps?.filter((eachitem) => {
      return eachitem?.app_name?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setinvitefilteredApps(filterItem);
  }

  function filterItemAsset(text) {
    let filterItem = coinData?.filter((eachitem) => {
      return eachitem?.coinSymbol?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setinvitefilteredAsset(filterItem);
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="headerr">
          <div className="topp" style={{ height: '3.5rem' }}>
            <div style={{ width: '80%' }}>
              <p style={{ fontSize: '1.75rem', fontWeight: '650' }}>
                Invite With Gift
              </p>
              <p style={{ fontSize: '0.85rem' }}>
                Add New &rarr;{' '}
                <span
                  style={{
                    textDecorationLine:
                      inviteStep === 'step1' ? 'underline' : '',
                    fontWeight: inviteStep === 'step1' ? '650' : '',
                  }}
                >
                  Invite With Gift
                </span>
                {inviteStep === 'step2' && (
                  <>
                    &nbsp; &rarr;{' '}
                    <span
                      style={{
                        textDecorationLine: 'underline',
                        fontWeight: '650',
                      }}
                    >
                      Select Debiting App
                    </span>
                  </>
                )}
                {inviteStep === 'step3' && (
                  <>
                    &nbsp; &rarr;{' '}
                    <span
                      style={{
                        textDecorationLine: 'underline',
                        fontWeight: '650',
                      }}
                    >
                      Select Gift Asset
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className="closebttn">
              <img
                src={userArr}
                alt=""
                onClick={() => {
                  setInviteStep('step1');
                }}
              />
              <img
                src={userClose}
                alt=""
                onClick={() => {
                  setInvite(false);
                  setInviteStep('step1');
                  setAllapps([]);
                  setCoinData([]);
                  setFriendName('');
                  setFriendEmail('');
                  setPhoneno('');
                  setMessage('');
                  setAmount('');
                  setExpiry('');
                  setSelectedInviteApp([]);
                  setIsInviteappselected(false);
                  setSelectedInviteAsset([]);
                  setIsInviteAssetSelected(false);
                  setinvitefilteredApps([]);
                  setinvitefilteredAsset([]);
                }}
              />
            </div>
          </div>
          {inviteStep === 'step1' ? (
            <div
              style={{
                height: 'calc(100% - 3.5rem)',
                overflowY: 'scroll',
                marginTop: '2rem',
              }}
            >
              <div className="each-field">
                <p>Friends Name</p>
                <div className="inputt-field">
                  <input
                    type="text"
                    placeholder="Name..."
                    value={friendname}
                    onChange={(e) => setFriendName(e.target.value)}
                    style={{ cursor: 'text' }}
                  />
                </div>
              </div>
              <div className="each-field">
                <p>Friends Email</p>
                <div className="inputt-field">
                  <input
                    type="email"
                    placeholder="Email..."
                    value={friendemail}
                    onChange={(e) => setFriendEmail(e.target.value)}
                    style={{ cursor: 'text' }}
                  />
                </div>
              </div>
              <div className="each-field">
                <p>Friends Phone Number</p>
                <div className="inputt-field">
                  <input
                    type="number"
                    placeholder="Phone Number..."
                    value={phoneno}
                    onChange={(e) => setPhoneno(e.target.value)}
                    style={{ cursor: 'text' }}
                  />
                </div>
              </div>
              <div className="each-field">
                <p>Message For Your Friend</p>
                <div className="inputt-field">
                  <input
                    type="text"
                    placeholder="Description...."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ cursor: 'text' }}
                  />
                </div>
              </div>
              <div className="each-field">
                <p>Select Debiting App</p>
                <div
                  className="inputt-field"
                  onClick={() => setInviteStep('step2')}
                >
                  {isInviteappselected ? (
                    <div className="each-appp2">
                      <img
                        src={selectedInviteApp?.app_icon}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {selectedInviteApp?.app_name}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      placeholder="Click Here To Select An App"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <div className="dddiv">
                    <img src={dropdown} alt="" />
                  </div>
                </div>
              </div>
              <div className="each-field">
                <p>Select Gift Asset</p>
                <div
                  className="inputt-field"
                  onClick={() => {
                    assetSelection();
                    setInviteStep('step3');
                  }}
                >
                  {isInviteAssetSelected ? (
                    <div className="each-appp2">
                      <img
                        src={selectedInviteAsset?.coinImage}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {selectedInviteAsset?.coinSymbol}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      placeholder="Click Here To Select The Asset"
                      style={{ cursor: 'pointer' }}
                    />
                  )}

                  <div className="dddiv">
                    <img src={dropdown} alt="" />
                  </div>
                </div>
              </div>
              <div className="each-field">
                <p>Enter Amount</p>
                <div className="inputt-field" style={{ paddingRight: '0' }}>
                  <input
                    type="number"
                    placeholder="0.000"
                    style={{ cursor: 'text', width: '80%' }}
                    className="placeee"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="tbddiv">TBD</div>
                </div>
              </div>
              <div className="each-field">
                <p>Days Until Expiration</p>
                <div className="inputt-field" style={{ paddingRight: '0' }}>
                  <input
                    type="number"
                    placeholder="0"
                    style={{ cursor: 'text', width: '80%' }}
                    className="placeee"
                    value={expiry}
                    onChange={(e) => setExpiry(e.target.value)}
                  />
                  <div className="tbddiv">Days</div>
                </div>
              </div>
            </div>
          ) : inviteStep === 'step2' ? (
            <div
              style={{
                height: 'calc(100% - 3.5rem)',
                overflowY: 'scroll',
                marginTop: '2rem',
              }}
            >
              <div className="each-field" style={{ paddingBottom: '0' }}>
                <p>Select App</p>
                <input
                  type="text"
                  className="inputt-field"
                  placeholder="Search Apps..."
                  onChange={(event) => filterItem(event.target.value)}
                />
              </div>
              <div className="appslist" style={{ height: '75%' }}>
                {invitefilteredApps?.map((e, i) => {
                  return (
                    <div
                      className="each-appp"
                      key={i}
                      onClick={() => {
                        setSelectedInviteApp(e);
                        setIsInviteappselected(true);
                        setInviteStep('step1');
                      }}
                    >
                      <img
                        src={e?.app_icon}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {e?.app_name}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div
              style={{
                height: 'calc(100% - 3.5rem)',
                overflowY: 'scroll',
                marginTop: '2rem',
              }}
            >
              <div className="each-field" style={{ paddingBottom: '0' }}>
                <p>Select Asset</p>
                <input
                  type="text"
                  className="inputt-field"
                  placeholder="Search Asset..."
                  onChange={(event) => filterItemAsset(event.target.value)}
                />
              </div>
              <div className="appslist" style={{ height: '75%' }}>
                {invitefilteredAsset?.map((e, i) => {
                  return (
                    <div
                      className="each-appp1"
                      key={i}
                      onClick={() => {
                        setSelectedInviteAsset(e);
                        setIsInviteAssetSelected(true);
                        setInviteStep('step1');
                      }}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <img
                        src={e?.coinImage}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {e?.coinSymbol}
                        </p>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {e?.coinValue?.toFixed(4)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="footerr" onClick={() => initiateInvite()}>
          <div className="addd" style={{ width: '100%' }}>
            Send Invitation
          </div>
        </div>
      </div>

      {/* {adduserStep === 'step4' && (
        <div
          className="loadingIcon"
          style={{ height: '100%', opacity: '1', padding: '0 2rem' }}
        >
          <div
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              color: '#18191D',
            }}
          >
            {`You Have Successfully Added ${finalresult} To Your Network. You
            Will Automatically Be Redirected To Your NetworkChain Page`}
          </div>
        </div>
      )} */}

      {isloading && (
        <div className="loadingIcon" style={{ height: '100%', opacity: '1' }}>
          <LoadingAnimation icon={loader} width={250} />
          <div
            style={{
              position: 'absolute',
              bottom: '5%',
              color: '#18191D',
              fontSize: '1.3rem',
            }}
          >
            Sending Invitation And Gift To {friendname}
          </div>
        </div>
      )}
    </>
  );
};

export default Invite;
