import React, { useEffect } from 'react';
import Layout from '../../Layout/Layout';
import './malls.scss';
import mall from '../../static/images/malls/mall-icon.svg';
import search from '../../static/images/malls/search.svg';
import img1 from '../../static/images/malls/ham.svg';
import notification from '../../static/images/malls/notification.svg';
import cart from '../../static/images/malls/cart.svg';
import ddown from '../../static/images/malls/ddown.svg';
import home from '../../static/images/malls/home-icon.svg';
import subscription from '../../static/images/malls/subscription.svg';
import offerTag from '../../static/images/malls/offerTag.svg';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Skeleton from 'react-loading-skeleton';
import Subscription from './Subscription';
import { planBContext } from '../../context/PlanBContext';

const ShopComponent = () => {
  const {
    selectedTab,
    setSelectedTab,
    responseData,
    setResponseData,
    shopCompanies,
    setShopCompanies,
    shopBilling,
    setShopBilling,
  } = useAppContextDetails();

  const history = useHistory();
  const { email } = useContext(BankContext);
  const {
    aiIndexOfferings,
    filteredApiData,
    setFilteredApiData,
    apiData,
    setApiData,
    isloading,
    setisloading,
  } = useContext(planBContext);

  if (!email) {
    history.push('/');
  }

  function filterItem(text) {
    let filterProduct = apiData?.filter((eachitem) => {
      return eachitem?.product_name
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilteredApiData(filterProduct);
  }

  return (
    <div className="malls">
      <div className="malls-content">
        <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
          <section className="best-sellers" style={{ padding: '0 0 2rem' }}>
            <div className="bs-products">
              {isloading || filteredApiData?.length < 1
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div
                          className="each-productt"
                          key={index}
                          style={{
                            minWidth: aiIndexOfferings ? '440px' : '0',
                            width: aiIndexOfferings ? '47.5%' : '',
                          }}
                        >
                          <div className="top-sec">
                            <div style={{ width: '40%' }}>
                              <Skeleton
                                width={100}
                                height={100}
                                borderRadius={50}
                              />
                            </div>
                          </div>
                          <div className="mid-sec">
                            <Skeleton width={150} height={35} />
                          </div>
                          <div className="bottom-sec">
                            <div>
                              <div className="amnt">
                                <Skeleton width={50} height={35} />
                              </div>
                              <div className="first">
                                <Skeleton width={100} height={35} />
                              </div>
                            </div>
                            <div>
                              <div className="amnt">
                                <Skeleton width={50} height={35} />
                              </div>
                              <div className="first">
                                <Skeleton width={100} height={35} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                : filteredApiData?.length > 0
                ? filteredApiData?.map((e, i) => {
                    return (
                      <div
                        className="each-productt"
                        key={i}
                        onClick={() => {
                          history.push(`/malls/${e?.product?.product_code}`);
                          localStorage.setItem(
                            'product',
                            JSON.stringify(e?.product)
                          );
                        }}
                        style={{
                          minWidth: aiIndexOfferings ? '440px' : '0',
                          width: aiIndexOfferings ? '47.5%' : '',
                        }}
                      >
                        <div className="top-sec">
                          <div style={{ width: '30%' }}>
                            <img src={e?.product?.product_icon} alt="" />
                          </div>
                        </div>
                        <div className="mid-sec">
                          {e?.product?.product_name}
                        </div>
                        <div className="bottom-sec">
                          <div>
                            <div className="amnt">
                              {e?.product?.pricesWithAppFees[0]?.price?.toFixed(
                                2
                              ) || '0.00'}{' '}
                              &nbsp;
                              {e?.product?.pricesWithAppFees[0]?.coin || ''}
                            </div>
                            <div className="first">First Month</div>
                          </div>
                          <div>
                            <div className="amnt">
                              {e?.product?.pricesWithAppFees[1]?.price?.toFixed(
                                2
                              ) || '0.00'}
                              &nbsp;
                              {e?.product?.pricesWithAppFees[0]?.coin || ''}
                            </div>
                            <div className="first">Monthly</div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ShopComponent;
