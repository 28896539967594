import React, { useState } from 'react';
import Layout from '../../Layout/Layout';
import './subscriptions.scss';
import { useAppContextDetails } from '../../context/AppContext';

//images
import search from '../../static/images/malls/search.svg';
import fulllogo from '../../static/images/subscriptions/fulllogo.svg';
import hamIcon from './hamIcon.svg';
import vv from './vv.svg';
import x3 from './x3.svg';
import token from './token.svg';
import defi from './defi.svg';
import ai from './ai.svg';
import arrow from './rightIcon.svg';
import vv1 from './vv1.svg';
import x31 from './x31.svg';
import token1 from './token1.svg';
import defi1 from './defi1.svg';
import ai1 from './ai1.svg';
import hiw from './hiw.svg';
import vv2 from './vv2.svg';
import x32 from './x32.svg';
import token2 from './token2.svg';
import defi2 from './defi2.svg';
import ai2 from './ai2.svg';

const SubscriptionsPage = () => {
  const { drawerOpen, setDrawerOpen, selectedAppData, setSelectedAppData } =
    useAppContextDetails();
  const [subscriptionsTab, setSubscriptionsTab] = useState('All');
  const [drawermapping, setDrawermapping] = useState([
    {
      name: 'OTC',
      title: 'OTC Affiliate By',
      subhead: 'Earn Money From Fiat To Crypto Conversions',
      icon: x31,
      link: 'https://buy.stripe.com/4gw01X2pPeCXgus28d',
      color: '#3296CA',
    },
    {
      name: 'TokenSwap',
      title: 'TokenSwap',
      subhead: 'Earn Money From Crypto To Crypto Conversions',
      icon: token1,
      link: 'https://buy.stripe.com/aEUaGB8OdcuP1zybIO',
      color: '#6262D9',
    },
    {
      name: 'Viralversity',
      title: 'Viraversity',
      subhead: 'Build your network and start earning',
      icon: vv1,
      link: 'https://buy.stripe.com/fZeeWR8Od0M7a64007',
      color: '#CF1A38',
    },
    {
      name: 'DefiBots',
      title: 'DefiBbots',
      subhead: 'Earn Money From MoneyMarkets & Staking',
      icon: defi1,
      link: 'https://buy.stripe.com/cN2bKF5C1dyTba8eV2',
      color: '#137EFB',
    },
    {
      name: 'Ai Index',
      title: 'Ai Index',
      subhead: 'Earn Money From All Ai Investment Opportunities',
      icon: ai1,
      link: 'https://buy.stripe.com/eVa5mh7K952n6TS14d',
      color: '#59A2DD',
    },
    {
      name: 'ViralPro',
      title: 'ViralPro',
      subhead: 'Get all the core subscriptions in one',
      icon: vv1,
      link: 'https://buy.stripe.com/8wM161c0p52ndigbIJ',
      color: '#CF1A38',
    },
  ]);
  const [drawerDataState, setDrawerDataState] = useState();

  const appData = [
    {
      id: 1,
      applogo: vv,
      appname: 'Viralversity',
      cost: '$10.00',
    },
    {
      id: 2,
      applogo: x3,
      appname: 'OTC',
      cost: '$25.00',
    },
    {
      id: 3,
      applogo: token,
      appname: 'TokenSwap',
      cost: '$10.00',
    },
    {
      id: 4,
      applogo: defi,
      appname: 'DefiBots',
      cost: '$10.00',
    },
    {
      id: 5,
      applogo: ai,
      appname: 'Ai Index',
      cost: '$25.00',
    },
    {
      id: 6,
      applogo: vv,
      appname: 'ViralPro',
      cost: '$50.00',
    },
  ];

  const appBoxes = [
    {
      id: 1,
      icon: vv2,
      name: 'Viralversity',
    },
    {
      id: 2,
      icon: x32,
      name: 'X3',
    },
    {
      id: 3,
      icon: token2,
      name: 'Tokenswap',
    },
    {
      id: 4,
      icon: defi2,
      name: 'DefiBots',
    },
    {
      id: 5,
      icon: ai2,
      name: 'Ai Index',
    },
  ];

  return (
    <Layout active="Subscriptions" className="subscriptionsPage" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="subscriptions-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              gap: '1.5rem',
            }}
          >
            <div style={{ cursor: 'pointer' }}>
              <img src={hamIcon} alt="" />
            </div>
            <div className="subscriptions-icon" style={{ width: '35%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-subscriptions">
              <div className="im">
                <input type="text" placeholder="Search Anything.." />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '100%' }}>
            <div className="optionss-divider">
              <div
                className={`each-option ${
                  subscriptionsTab === 'All' ? 'each-option-selected' : ''
                }`}
                style={{
                  fontWeight: subscriptionsTab === 'All' ? '600' : '',
                }}
              >
                <div>
                  <p>All</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="manage-box">
          <div className="manage-left-div">
            <div style={{ fontSize: '0.9rem' }}>Your Monthly Cost</div>
            <div className="amount-txt">$ 0.00</div>
          </div>
          <div className="manage-btn">Manage Cards</div>
        </div>

        <div className="white-box"></div>

        <div className="subscriptions-content">
          <div className="app-cards-div">
            {appData.map((e, i) => {
              return (
                <div
                  className="each-app-card"
                  key={e.id}
                  onClick={() => {
                    setDrawerOpen(true);
                    let result = drawermapping?.filter(
                      (each) => each.name === e.appname
                    );
                    setDrawerDataState(result[0]);
                  }}
                >
                  <div className="applogo-box">
                    <img src={e.applogo} alt="" />
                  </div>
                  <div className="app-name-txt">{e.appname}</div>
                  <div className="cost-txt">{e.cost}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className="subscription-drawer"
          style={{ right: drawerOpen ? '0' : '-100%' }}
        >
          <div className="drawer-content">
            <div className="header-txt">
              {drawerDataState?.title === 'OTC Affiliate By' ? (
                <>
                  <div>{drawerDataState?.title}</div>
                  <div>
                    <img src={drawerDataState?.icon} alt="" />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <img src={drawerDataState?.icon} alt="" />
                  </div>
                  <div>{drawerDataState?.title}</div>
                </>
              )}
            </div>
            <div className="sub-header-txt">{drawerDataState?.subhead}</div>
            <div className="previous-btn-div">
              <div className="previous-btn">
                <div style={{ fontWeight: '500' }}>Previous Payments</div>
                <div>
                  <img src={arrow} alt="" />
                </div>
              </div>
            </div>
            {drawerDataState?.title === 'ViralPro' && (
              <div className="drawer-mid-area">
                <div className="drawer-hiw-div">
                  <div className="drawer-hiw-left-div">
                    <div className="hiw-textt">How It Works</div>
                    <div style={{ fontSize: '0.8rem', fontWeight: '200' }}>
                      There are certain transactions that happen in every app
                      regardless of the business. The revenue from these
                      transactions are captured by us and we want to share it
                      with you.{' '}
                    </div>
                  </div>
                  <div>
                    <img src={hiw} alt="" />
                  </div>
                </div>
                <div className="app-boxes">
                  {appBoxes.map((e, i) => {
                    return (
                      <div className="each-app-box" key={i}>
                        <div className="app-border">
                          <img src={e.icon} alt="" />
                        </div>
                        <div className="app-namee">{e.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="drawer-footer">
              <div className="yet-text">You Have Yet To Subscribe</div>
              <div
                className="start-btn"
                style={{ background: drawerDataState?.color }}
                onClick={() => {
                  window.open(`${drawerDataState?.link}`);
                }}
              >
                Start Your Subscription
              </div>
            </div>
          </div>
        </div>
        <div
          className="subscription-overlay"
          onClick={() => {
            setDrawerOpen(false);
          }}
          style={{ display: drawerOpen ? 'flex' : 'none' }}
        ></div>
      </div>
    </Layout>
  );
};

export default SubscriptionsPage;
