import React from 'react';
import edit from '../../../static/images/affiliate/edit-icon.svg';
import search from '../../../static/images/affiliate/search-colorful.svg';
import network from '../../../static/images/affiliate/network-people.svg';

const ChainViewHeader = ({
  headerDesc,
  level,
  onEdit,
  showEdit,
  showSearch,
  onSearch,
}) => {
  return (
    <div className="chain-view-header">
      <div className="chain-view-level">DD {level || 0}</div>
      <div className="chian-view-title">{headerDesc}</div>
      {showEdit && (
        <div className="action-item" onClick={onEdit}>
          <img
            src={edit}
            alt=""
            className="action-icon"
          />
        </div>
      )}
      {showSearch && (
        <div className="action-item" onClick={onSearch}>
          <img
            src={search}
            alt=""
            className="action-icon"
          />
        </div>
      )}
      <div className="action-item">
        <img
          src={network}
          alt=""
          className="action-icon"
        />
      </div>
    </div>
  );
};

export default ChainViewHeader;
