import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { BankContext } from '../../context/Context';
import ForexCrpto from './ForexCrypto';
import MoneyMarket from './MoneyMarkets';
import Bonds from './Bonds';

import backshow from './backshow.svg';
import e1 from './e1.svg';
import e2 from './e2.svg';
import e3 from './e3.svg';
import e4 from './e4.svg';
import e5 from './e5.svg';
import forex from './forex.svg';
import crytoassets from './crytoassets.svg';
import exchange from './exchange.svg';
import markert from './markert.svg';
import bond from './bond.svg';
import './Meta.scss';

function AllApp() {
  const { email } = useContext(BankContext);
  const [toggle, settoggle] = useState(false);
  const [infoData, setinfoData] = useState([]);
  const [selectedUser, setselectedUser] = useState([]);
  const [dataLoading, setdataLoading] = useState(false);

  useEffect(() => {
    getMetaBrands();
  }, [email]);

  const getMetaBrands = () => {
    //  setdataLoading(true)
    axios
      .get(
        `https://comms.globalxchange.io/brokerage/com/earnings/get?email=${email}&orderByLevel=true`
      )
      .then((res) => {
        // setmetaData(res.data.apps)
        setinfoData(res?.data?.levelsData);
        setdataLoading(false);
        // console.log("inmzxczxczxdo", res?.data?.levelsData);
      });
  };

  // useEffect(() => {
  //     setDataLoading(true)
  //     axios
  //         .get(
  //             `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?select_type=fund&banker_email=${banker
  //                 ? banker.bankerTag
  //                 : localStorage.getItem("bankerTag")}}`
  //         )
  //         .then((res) => {
  //             console.log("cxcxvxcvxcvasdzxczxczxczxczcxzxczxzxzwerwer", res?.data)
  //             setbankInfo(res?.data?.paths)
  //             setDataLoading(false)

  //         });
  // }, [banker])

  const [Exchangelist, setExchangelist] = useState([]);

  const marketmoney = [
    {
      name: 'Crypto',
      des: 'Liquid Interest Rates On Crypto Assets',
      img: crytoassets,
    },
    {
      name: 'Forex',
      des: 'Liquid Interest Rates On Forex Assets',
      img: forex,
    },
  ];

  const Bond = [
    {
      list: 'Crypto',
      name: 'CryptoBond',
      des: 'See the fees that are deducted from your direct customers on bonds they purchased with crypto assets.',
      img: crytoassets,
    },
    {
      list: 'Forex',
      name: 'ForexBond',
      des: 'See the fees that are deducted from your direct customers on bonds they purchased with forex assets.',
      img: forex,
    },
  ];

  const Exchangefeee = [
    {
      name: 'Forex To Crypto',
      des: 'When Your Customers Trade From Forex To Crypto',
      img: e1,
    },
    {
      name: 'Crypto To Forex',
      des: 'When Your Customers Trade From Crypto To Forex',
      img: e2,
    },
    {
      name: 'Forex To Forex',
      des: 'When Your Customers Trade From Forex To Forex',
      img: e3,
    },
    {
      name: 'Crypto To Crypto',
      des: 'When Your Customers Trade Crypto To Crypto',
      img: e4,
    },
    {
      name: 'Per Customer',
      des: 'Set Custom Exchange Fees For Specific Customers',
      img: e5,
    },
  ];

  const buttonslist = [
    {
      name: 'About',
    },

    {
      name: 'Controller',
    },
    {
      name: 'Analytics',
    },
  ];

  const [listExchange, setlistExchange] = useState('');
  const [ExchangeFeeSubList, setExchangeFeeSubList] = useState('');
  const handlesubmenu = (e) => {
    setselectedUser([e]);
    setlistExchange(e.name);

    //setExchangeFeeSubList(e);
  };
  // console.log("listExchange", listExchange);

  const exchangefeefun = (desktopDataGrid) => {
    switch (listExchange) {
      case 'Forex':
      case 'Crypto':
        return (
          <>
            <MoneyMarket
              selectedUser={selectedUser}
              listExchange={listExchange}
              setlistExchange={setlistExchange}
            />
          </>
        );
      case 'ForexBond':
      case 'CryptoBond':
        return (
          <Bonds
            selectedUser={selectedUser}
            listExchange={listExchange}
            setlistExchange={setlistExchange}
          />
        );

      case 'Forex To Crypto':
        return (
          <ForexCrpto
            selectedUser={selectedUser}
            listExchange={listExchange}
            setlistExchange={setlistExchange}
          />
        );
      case 'Crypto To Forex':
        return (
          <ForexCrpto
            selectedUser={selectedUser}
            listExchange={listExchange}
            setlistExchange={setlistExchange}
          />
        );
      case 'Forex To Forex':
        return (
          <ForexCrpto
            selectedUser={selectedUser}
            listExchange={listExchange}
            setlistExchange={setlistExchange}
          />
        );
      case 'Crypto To Crypto':
        return (
          <ForexCrpto
            selectedUser={selectedUser}
            listExchange={listExchange}
            setlistExchange={setlistExchange}
          />
        );

      case 'Staking':
        return (
          <>
            <div
              style={{
                fontWeight: 700,
                fontSize: '20px',
                // height: window.innerHeight - 175,
                overflowY: 'scroll',
              }}
            >
              {selectedUser?.map((item) => {
                return (
                  <>
                    <div
                      // onClick={() => handledata(item)}

                      className="listDataGrid"
                      style={{
                        gridTemplateColumns: '1.5fr 1fr 1fr 1fr 0.6fr',
                        // background: 'aqua'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          onClick={() => settoggle(false)}
                          src={backshow}
                          alt=""
                          style={{
                            cursor: 'pointer',
                            height: '40px',
                            width: '40px',
                          }}
                          // className={classNames.icon}
                        />
                        <div style={{ paddingLeft: '15px' }}>
                          <div>
                            <div className="customtitle" style={{fontWeight: '600'}}>{item.name}</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Apps:{' '}
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '13px',
                                }}
                              >
                                --
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                                <div >
                                                                    <div className="customtitle">DD Level {item.level}</div>
                                                                    <div className="" style={{
                                                                        fontSize: "11px ", fontWeight: "400",
                                                                        color: "#18191D"
                                                                    }}>Users: <span style={{ fontWeight: "bold", fontSize: "13px" }}>{item?.count}</span></div>
                                                                </div>
                                                            </div> */}

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Customer
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>

                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Volume
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div className="customtitle">--</div>
                        <div
                          className=""
                          style={{
                            fontSize: '11px ',
                            fontWeight: '400',
                            color: '#18191D',
                          }}
                        >
                          My Revenue
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                                textAlign: 'right',
                              }}
                            >
                              Revenue Txn’s
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div
              className="desktopWrapper"
              style={{ height: 'calc(100% - 90px)' }}
            >
              <div style={{ width: '100%' }}>
                {headerSectionSelected('listGrid', desktopDataGrid)}
              </div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  height: '100%',
                  overflowY: 'scroll',
                }}
              >
                {Exchangelist.map((item) => {
                  return (
                    <>
                      {' '}
                      <div
                        onClick={() => handlesubmenu(item)}
                        className="listDataGrid"
                        style={{
                          gridTemplateColumns: '1fr',
                          // background: 'aqua',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={item?.img}
                            alt=""
                            style={{
                              // borderRadius: "50%",
                              height: '30px',
                              width: '30px',
                            }}
                            // className={classNames.icon}
                          />
                          <div style={{ paddingLeft: '15px' }}>
                            <div className="customtitle">{item?.list}</div>
                            <div
                              className=""
                              style={{
                                fontSize: '9px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              {item?.des}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        );

      case 'MoneyMarkets':
        return (
          <>
            <div
              style={{
                fontWeight: 700,
                fontSize: '20px',
                // height: window.innerHeight - 175,
                overflowY: 'scroll',
              }}
            >
              {selectedUser?.map((item) => {
                return (
                  <>
                    <div
                      // onClick={() => handledata(item)}

                      className="listDataGrid"
                      style={{
                        gridTemplateColumns: '1.5fr 1fr 1fr 1fr 0.6fr',
                        // background: 'yellow',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          onClick={() => settoggle(false)}
                          src={backshow}
                          alt=""
                          style={{
                            cursor: 'pointer',
                            height: '40px',
                            width: '40px',
                          }}
                          // className={classNames.icon}
                        />
                        <div style={{ paddingLeft: '15px' }}>
                          <div>
                            <div className="customtitle" style={{fontWeight: '600'}}>{item.name}</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Apps:{' '}
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '13px',
                                }}
                              >
                                --
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                            <div >
                                                                <div className="customtitle">DD Level {item.level}</div>
                                                                <div className="" style={{
                                                                    fontSize: "11px ", fontWeight: "400",
                                                                    color: "#18191D"
                                                                }}>Users: <span style={{ fontWeight: "bold", fontSize: "13px" }}>{item?.count}</span></div>
                                                            </div>
                                                        </div> */}

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Customer
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>

                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Volume
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div className="customtitle">--</div>
                        <div
                          className=""
                          style={{
                            fontSize: '11px ',
                            fontWeight: '400',
                            color: '#18191D',
                          }}
                        >
                          My Revenue
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                                textAlign: 'right',
                              }}
                            >
                              Revenue Txn’s
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div
              className="desktopWrapper"
              style={{ height: 'calc(100% - 90px)' }}
            >
              <div style={{ width: '100%' }}>
                {headerSectionSelected('listGrid', desktopDataGrid)}
              </div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  height: '100%',
                  overflowY: 'scroll',
                }}
              >
                {Exchangelist.map((item) => {
                  return (
                    <>
                      {' '}
                      <div
                        onClick={() => handlesubmenu(item)}
                        className="listDataGrid"
                        style={{
                          gridTemplateColumns: desktopDataGrid,
                          // background: 'orange',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={item?.img}
                            alt=""
                            style={{
                              // borderRadius: "50%",
                              height: '30px',
                              width: '30px',
                            }}
                            // className={classNames.icon}
                          />
                          <div style={{ paddingLeft: '15px' }}>
                            <div className="customtitle">{item?.name}</div>
                            <div
                              className=""
                              style={{
                                fontSize: '9px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              {item?.des}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        );

      case 'Exchange Fees':
        return (
          <>
            <div
              style={{
                fontWeight: 700,
                fontSize: '20px',
                // height: window.innerHeight - 175,
                overflowY: 'scroll',
              }}
            >
              {selectedUser?.map((item) => {
                return (
                  <>
                    <div
                      // onClick={() => handledata(item)}

                      className="listDataGrid"
                      style={{
                        gridTemplateColumns: '2fr 1fr 1fr 1fr 0.6fr',
                        // background: 'red'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          onClick={() => settoggle(false)}
                          src={backshow}
                          alt=""
                          style={{
                            cursor: 'pointer',
                            height: '40px',
                            width: '40px',
                          }}
                          // className={classNames.icon}
                        />
                        <div style={{ paddingLeft: '15px' }}>
                          <div>
                            <div className="customtitle" style={{fontWeight: '600'}}>{item.name}</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Apps:{' '}
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '13px',
                                }}
                              >
                                --
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div >
                                                            <div className="customtitle">DD Level {item.level}</div>
                                                            <div className="" style={{
                                                                fontSize: "11px ", fontWeight: "400",
                                                                color: "#18191D"
                                                            }}>Users: <span style={{ fontWeight: "bold", fontSize: "13px" }}>{item?.count}</span></div>
                                                        </div>
                                                    </div> */}

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Customer
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>

                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              Volume
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div className="customtitle">--</div>
                        <div
                          className=""
                          style={{
                            fontSize: '11px ',
                            fontWeight: '400',
                            color: '#18191D',
                          }}
                        >
                          My Revenue
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <div className="customtitle">--</div>
                            <div
                              className=""
                              style={{
                                fontSize: '11px ',
                                fontWeight: '400',
                                color: '#18191D',
                                textAlign: 'right',
                              }}
                            >
                              Revenue Txn’s
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div
              className="desktopWrapper"
              style={{ height: 'calc(100% - 90px)' }}
            >
              <div style={{ width: '100%' }}>
                {headerSectionSelected('listGrid', desktopDataGrid)}
              </div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  height: '100%',
                  overflowY: 'scroll',
                }}
              >
                {Exchangelist?.map((item) => {
                  return (
                    <>
                      {' '}
                      <div
                        onClick={() => handlesubmenu(item)}
                        className="listDataGrid"
                        style={{
                          gridTemplateColumns: desktopDataGrid,
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={item?.img}
                            alt=""
                            style={{
                              // borderRadius: "50%",
                              height: '30px',
                              width: '30px',
                            }}
                            // className={classNames.icon}
                          />
                          <div style={{ paddingLeft: '15px' }}>
                            <div className="customtitle">{item?.name}</div>
                            <div
                              className=""
                              style={{
                                fontSize: '9px ',
                                fontWeight: '400',
                                color: '#18191D',
                              }}
                            >
                              {item?.des}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        );
      default:
        return <h1></h1>;
    }
  };

  const handledata = (e) => {
    setselectedUser([e]);
    settoggle(true);
    setlistExchange(e.name);
    if (e.name === 'Per Customer') {
      setExchangelist([]);
    } else if (e.name == 'Staking') {
      setExchangelist(Bond);
    } else if (e.name == 'MoneyMarkets') {
      setExchangelist(marketmoney);
    } else {
      setExchangelist(Exchangefeee);
    }
  };
  // console.log(selectedUser, "setselectedUser");

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        {toggle ? (
          <>
            {/* // sdfsdfsdf */}

            {exchangefeefun(desktopDataGrid)}
          </>
        ) : (
          <>
            <div className="desktopWrapper">
              <div style={{ width: '100%' }}>
                {headerSection('listGrid', desktopDataGrid)}
              </div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  height: 'calc(100% - 45px)',
                  overflowY: 'scroll',
                }}
              >
                {!dataLoading ? (
                  datadropdownlist?.length > 0 ? (
                    datadropdownlist.map((item) => {
                      return contentSection(
                        item,
                        'listDataGrid',
                        desktopDataGrid
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '60vh',
                      }}
                    >
                      No Records Found
                    </div>
                  )
                ) : (
                  loadingSection('listDataGrid', desktopDataGrid)
                )}
              </div>
            </div>

            {/* <div className="mobileWrapper">
              <div style={{ overflowY: "scroll", height: "80vh" }}>
                {headerSection("listGridMobile", mobileDataGrid)}

                {!dataLoading ? (
                  data?.length > 0 ? (
                    data?.map((item, index) => {
                      return contentSection(
                        item,
                        "listDataGridMobile",
                        mobileDataGrid
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                      }}
                    >
                      No Records Found
                    </div>
                  )
                ) : (
                  loadingSection("listDataGrid", mobileDataGrid)
                )}
              </div>
            </div> */}
          </>
        )}
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div
        className={gridClass}
        style={{ gridTemplateColumns: gridValues, alignItems: 'center' }}
      >
        <div>Name</div>
      </div>
    );
  };

  const headerSectionSelected = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Type</div>
      </div>
    );
  };

  const maindropdown = [
    {
      name: 'Exchange Fees',
      img: exchange,
    },

    {
      name: 'MoneyMarkets',
      img: markert,
    },
    {
      name: 'Staking',
      img: bond,
    },
    // {
    //   name: "Bond Spread",
    //   img: require("../../../static/images/bond.svg").default,
    // },
    // {
    //   name: "MarketsVerse",
    //   img: require("../../../static/images/meta.svg").default,
    // },
    // {
    //   name: "Retirement Plans",
    //   img: require("../../../static/images/retriment.svg").default,
    // },
  ];
  const [buttonName, setbuttonName] = useState('Controller');

  const buttonhandler = (e) => {
    setbuttonName(e.name);
    if (e.name == 'Controller') {
      setdatadropdownlist([
        {
          name: 'Exchange Fees',
          img: exchange,
        },

        {
          name: 'MoneyMarkets',
          img: markert,
        },
        {
          name: 'Staking',
          img: bond,
        },
      ]);
    } else {
      setdatadropdownlist(maindropdown);
    }
  };
  const [datadropdownlist, setdatadropdownlist] = useState(maindropdown);

  const contentSection = (item, gridClass, gridValues) => {
    return (
      <div
        className={gridClass}
        style={{
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => handledata(item)}
        >
          <img style={{ width: '22px', marginRight: '10px' }} src={item.img} />
          <div>
            <div className="customtitle">{item.name}</div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'flex-end',
            }}
          >
            {buttonslist.map((item) => {
              return (
                <div
                  className="buttonlists"
                  style={{
                    opacity:
                      item.name == buttonName
                        ? '1'
                        : buttonName === ''
                        ? '1'
                        : '0.5',
                  }}
                  //  onClick={() => buttonhandler(item)}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill('')
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: '100%',
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={120} />
              <Skeleton width={80} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        datadropdownlist,
        dataLoading,
        toggle
          ? '2fr 1.1fr 1.3fr 1.5fr 1.5fr  0.6fr'
          : '1.5fr 1.5fr 1.5fr  0.5fr', // Desktop view Grid columns
        '200px 200px 250px 200px ' // Mobile view Grid columns
      )}

      {/* {conditionalResposiveView(
                infoData,
                dataLoading,
                "1.5fr 1.5fr 1.5fr  0.5fr", // Desktop view Grid columns
                "200px 200px 250px 200px " // Mobile view Grid columns
            )} */}

      {/* <BankerSubDashboard

                bankid={bankid}
            /> */}
    </>
  );
}

export default AllApp;
