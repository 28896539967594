import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { BankContext } from '../../context/Context';
import { FormatCurrency } from '../../utils/FunctionTools';
import Skeleton from 'react-loading-skeleton';

function BondMobileTransactions({ contractId }) {
  const { email, defaultPrecission } = useContext(BankContext);
  const [contractEarnings, setContractEarnings] = useState([]);
  const [contractEarningsOverView, setContractEarningsView] = useState({});
  const [txnLoading, setTxnLoading] = useState(true);
  useEffect(() => {
    setTxnLoading(true);
    contractId &&
      Axios.get(
        `https://comms.globalxchange.io/coin/iced/interest/logs/get?email=${email}&contract_id=${contractId}`
      )
        .then((res) => {
          const { data } = res;
          if (data.status) {
            setContractEarningsView({ ...data, interestLogs: undefined });
            setContractEarnings(data.interestLogs);
          }
        })
        .finally(() => {
          setTxnLoading(false);
        });
  }, [contractId]);
  return (
    <>
      {txnLoading
        ? Array(5)
            .fill('')
            .map((txn) => {
              return (
                <div className="txnItem">
                  <div className="label">
                    <Skeleton className="main" width={200} />
                    <Skeleton className="date" width={180} />
                  </div>
                  <Skeleton className="value" width={50} />
                </div>
              );
            })
        : contractEarnings.map((txn) => {
            return (
              <div className="txnItem">
                <div className="label">
                  <div className="main">Interest Payment</div>
                  <div className="date">
                    {moment(txn.timestamp).format(
                      'MMMM Do YYYY [at] h:mm:ss A zz'
                    )}
                  </div>
                </div>
                <span className="value">
                  {FormatCurrency(
                    txn.earned_interest,
                    txn.coin,
                    defaultPrecission
                  )}
                </span>
              </div>
            );
          })}
    </>
  );
}

export default BondMobileTransactions;
