import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const VaultLoadingCards = () => {
  const isBanking = window.location.pathname === '/banking';
  return (
    <div
      className="cryptoData"
      style={{
        // border: '1px solid red',
        marginTop: isBanking ? '50px' : '0',
        padding: isBanking ? '25px' : '0',
        paddingBottom: isBanking ? '80px' : '55px',
      }}
    >
      <div className="eachcard">
        {[1, 2, 3, 4].map(({}, i) => {
          return (
            <div className="cards" key={i}>
              <div className="first-column">
                <Skeleton className="child-skeleton" />
                <div className="child-div">
                  <Skeleton className="cardheading" />
                  <Skeleton className="cardpara" />
                </div>
              </div>
              <div className="second-column">
                <Skeleton className="cardheading" />
                <Skeleton className="cardpara" />
              </div>
              <div className="second-column">
                <Skeleton className="cardheading" />
                <Skeleton className="cardpara" />
              </div>
              <div className="d-flex">
                <Skeleton className="cardheading third-skeleton" />
                <Skeleton className="cardpara third-skeleton" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
