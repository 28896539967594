/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../Layout/Index';
import moneyMarketLogo from '../static/images/moneyMarketLogoPrimaryColor.svg';
import paste from '../static/images/paste.svg';
import search from '../static/images/search.svg';
import { BankContext } from '../context/Context';
import Scrollbars from 'react-custom-scrollbars';

function MoneyMarkets() {
  const { toastShowOn, icedContracts } = useContext(BankContext);
  const [searchStr, setSearch] = useState('');
  const history = useHistory();

  const [contractList, setContractList] = useState([]);
  const [openList, setOpenList] = useState(false);
  useEffect(() => {
    let contractArray = [];
    icedContracts.forEach((contract) => {
      contractArray = [...contractArray, ...contract.contracts];
    });
    setContractList(contractArray);
    console.log('contractArray', contractArray); //_id
  }, [icedContracts]);

  return (
    <Layout active="moneyMarkets" className="moneyMarkets" hideFooter>
      <img src={moneyMarketLogo} className="moneyMarketLogo" alt="" />
      <label
        className="searchWrapper"
        onFocus={() => {
          setOpenList(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            try {
              setOpenList(false);
            } catch (error) {}
          }, 500);
        }}
      >
        <input
          type="text"
          value={searchStr}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Any Asset Hash..."
        />
        <img
          src={paste}
          onClick={() => {
            navigator.clipboard
              .readText()
              .then((clipText) => setSearch(clipText));
          }}
          alt=""
        />
        <img
          src={search}
          alt=""
          onClick={() => {
            if (searchStr) history.push(`/bonds/${searchStr}`);
            else {
              toastShowOn('Enter A Valid Asset Hash');
            }
          }}
        />
        {/* {openList && (
          <Scrollbars
            className="contractList"
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
            renderView={(props) => <div {...props} className="contractView" />}
          >
            {contractList
              .filter((contract) => contract._id.includes(searchStr))
              .map((contract) => (
                <div
                  key={contract._id}
                  className="contractItm"
                  tabIndex={0}
                  onClick={() => {
                    history.push(`/bonds/${contract._id}`);
                  }}
                >
                  {contract._id}
                </div>
              ))}
          </Scrollbars>
        )} */}
      </label>
    </Layout>
  );
}

export default MoneyMarkets;
