import React, { useEffect } from 'react';
import './advertise.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import fulllogo from '../../static/images/advertise/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import sample from '../../static/images/aiindex/sample.svg';
import { planBContext } from '../../context/PlanBContext';
import Skeleton from 'react-loading-skeleton';
import { RxHamburgerMenu } from 'react-icons/rx';
import close from '../CRMPage/close.svg';
import { predefinedToast } from '../../utils/toast';
import axios from 'axios';
import {
  InputDropdown,
  InputDivs,
  ImageUploadDiv,
} from '../../components/BuyBlocks/components/CreatePlanB';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import loadingLogo from '../../static/images/logos/vv.svg';

const Advertise = () => {
  const {
    aitab,
    setAiTab,
    allOpportunities,
    filteredallOpportunities,
    settFilteredallOpportunities,
    setfiltersearch,
    selectedType,
    setSelectedType,
    selectedCustody,
    setSelectedCustody,
    selectedAssetClass,
    setSelectedAssetClass,
    selectedBilling,
    setSelectedBilling,
    selectedComp,
    setSelectedComp,
    selectedLeverage,
    setSelectedLeverage,
    selectedPaymentMethods,
    setSelectedPaymentMethods,
    shopCompanies,
    setShopCompanies,
    shopBilling,
    setShopBilling,
    allOpportunitiesRefresh,
    setAllOpportunitiesRefresh,
    actionClicked,
    setActionClicked,
    listingActionStep,
    setListingActionStep,
    aiIndexOfferingSelected,
    selectedListing,
    setSelectedListing,
  } = useAppContextDetails();
  const {
    aiIndexOfferings,
    setAiIndexOfferings,
    insideplanBCreate,
    setinsideplanBCreate,
    aiIndexDefaultSelected,
    setaiIndexDefaultSelected,
    typeFilter,
    custodyFilter,
    assetClassFilter,
    billingFilter,
    compFilter,
    leverageFilter,
    paymentMethodsFilter,
    shopCompaniesFilter,
    shopBillingFilter,
    adPopularAction,
    setAdPopularAction,
    adPopularActionStep,
    setAdPopularActionStep,

    assetCustodyMap,
    productsTypeMap,
    aidropdownAsset,
    aidropdownCustody,
    setaidropdownAsset,
    setaidropdownCustody,
    //values
    aidropdownProductName,
    setaidropdownProductName,
    productTypeSelected,
    setProductTypeSelected,
    assestCustodySelected,
    setAssestCustodySelected,
    aidropdownDesc,
    setaidropdownDesc,
    aidropdownProdImg,
    setaidropdownProdImg,
    aidropdownMontlyCost,
    setaidropdownMontlyCost,
    aidropdownManaFee,
    setaidropdownManaFee,
    aidropdownROI30,
    setaidropdownROI30,
    aidropdownROI12,
    setaidropdownROI12,
    aidropdownProductLive,
    setaidropdownProductLive,
    aidropdownAvgDrawdown,
    setaidropdownAvgDrawdown,
    aidropdownSubs,
    setaidropdownSubs,
    aidropdownCompanyCreated,
    setaidropdownCompanyCreated,
    aidropdownCompanyWebsite,
    setaidropdownCompanyWebsite,
    aidropdownRegLink,
    setaidropdownRegLink,

    //new fields
    assetClass,
    setAssetClass,
    aidropdownAssetClass,
    setaidropdownAssetClass,
    assetClassMap,
    upfrontCost,
    setUpfrontCost,
    productCharge,
    setProductCharge,
    aidropdownProductCharge,
    setaidropdownProductCharge,
    productChargeMap,
    paymentTypes,
    setPaymentTypes,
    aidropdownPaymentTypes,
    setaidropdownPaymentTypes,
    paymentTypesMap,
    referralProgram,
    setReferralProgram,
    aidropdownReferralProgram,
    setaidropdownReferralProgram,
    referralProgramMap,
    productLeverage,
    setProductLeverage,
    aidropdownProductLeverage,
    setaidropdownProductLeverage,
    productLeverageMap,
  } = useContext(planBContext);
  const { email, token } = useContext(BankContext);
  const history = useHistory();

  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('default');
  const [btnOptionType, setBtnOptionType] = useState('All');
  const [btnOptionAsset, setBtnOptionAsset] = useState('All');
  const [btnOptionCustody, setBtnOptionCustody] = useState('All');
  const [btnOptionBilling, setBtnOptionBilling] = useState('All');
  const [btnOptionInvestment, setBtnOptionInvestment] = useState('$0.00');
  const [btnOptionDrawdown, setBtnOptionDrawdown] = useState('50.00%');
  const [btnOptionManagementFee, setBtnOptionManagementFee] =
    useState('25.00%');
  const [btnOptionCompPlan, setBtnOptionCompPlan] = useState('All');
  const [btnOptionLeverage, setBtnOptionLeverage] = useState('All');
  const [btnOptionPayment, setBtnOptionPayment] = useState('All');

  function listProductCreationFunc() {
    let obj = {
      email: email,
      token: token,
      item_type: productTypeSelected,
      custody: assestCustodySelected,
      item_name: aidropdownProductName,
      company_name: aidropdownCompanyCreated,
      company_website: aidropdownCompanyWebsite,
      product_icon: aidropdownProdImg,
      product_description: aidropdownDesc,
      cost_per_month: aidropdownMontlyCost,
      management_fee: aidropdownManaFee,
      link: aidropdownRegLink,
      last_month_ROI: aidropdownROI30,
      last_12_months_ROI: aidropdownROI12,
      months_running: aidropdownProductLive,
      avg_drawdown: aidropdownAvgDrawdown,
      subscribers: aidropdownSubs,
      asset_class: assetClass,
      billing_type: productCharge,
      comp_plan: referralProgram,
      leverage: productLeverage,
      upfront_cost: upfrontCost,
      accepted_payment_methods: paymentTypes,
    };

    axios
      .post(
        'https://comms.globalxchange.io/gxb/product/aiprowallet/index/item/create',
        obj
      )
      .then((res) => {
        // console.log(res?.data, 'listProductCreationFunc result');
        setinsideplanBCreate(false);
        if (res?.data?.status) {
          predefinedToast('Product listed successfully');
        } else {
          predefinedToast(res?.data?.message);
        }
        setAllOpportunitiesRefresh(!allOpportunitiesRefresh);
      })
      .catch((error) => {
        console.log(error?.message, 'listProductCreationFunc error');
        predefinedToast('Network error try again..');
      });
  }

  const deleteListing = (id) => {
    setIsDeleting(true);
    axios
      .delete(
        `https://comms.globalxchange.io/gxb/product/aiprowallet/index/item/delete?id=${id}`
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'deleteListing result');
        if (result?.status && result?.message === 'Deleted the Index Item') {
          setListingActionStep('step3');
          setIsDeleting(false);
        } else {
          setIsDeleting(false);
          predefinedToast(result?.message);
        }
      })
      .catch((error) => {
        console.log(error, 'deleteListing error');
      });
  };

  return (
    <Layout active="Advertisers" className="advertise" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="advertise-navbar">
          <div
            style={{
              width: '25%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              className="advertise-hamburger"
              onClick={() => {
                setAiIndexOfferings(!aiIndexOfferings);
                if (insideplanBCreate) {
                  setinsideplanBCreate(false);
                }
              }}
            >
              {aiIndexOfferings ? <div>x</div> : <RxHamburgerMenu />}
            </div>
            <div className="advertise-icon">
              <img src={fulllogo} alt="" />
            </div>
          </div>
          <div className="search-advertise">
            <div className="im">
              <input
                type="text"
                placeholder="Search Anything.."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div
            className="advertise-listBtn"
            onClick={() => {
              // setinsideplanBCreate(!insideplanBCreate);
              setAdPopularAction(!adPopularAction);
              if (aiIndexOfferings) {
                setAiIndexOfferings(false);
              }
              if (insideplanBCreate) {
                setinsideplanBCreate(false);
              }
            }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="optionss" style={{ width: '60%' }}>
            <div
              style={{
                fontWeight: aitab === 'My Listings' ? '600' : '',
              }}
              className={`each-option ${
                aitab === 'My Listings' ? 'each-option-selected' : ''
              }`}
              onClick={() => setAiTab('My Listings')}
            >
              <div>
                <p>My Listings</p>
              </div>
            </div>
          </div>
        </div>

        {aitab == 'My Listings' ? (
          <div
            className="advertise-content"
            style={{ flexDirection: aiIndexOfferings ? 'row-reverse' : '' }}
          >
            <div className="left-ai-container">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  overflowY: 'scroll',
                  paddingBottom: '2rem',
                }}
              >
                {filteredallOpportunities
                  ? filteredallOpportunities?.map((eachItem) => {
                      if (eachItem.item_name !== 'sgsg') {
                        return <AIOpportunityCard {...eachItem} />;
                      }
                    })
                  : Array(5)
                      .fill(' ')
                      .map((item, index) => {
                        return (
                          <AIOpportunityCardLoading key={'loading' + index} />
                        );
                      })}
              </div>
            </div>
            <div
              className="right-ai-container"
              style={{
                borderLeft: aiIndexOfferings ? 'none' : '0.5px solid #e7e7e7',
                borderRight: aiIndexOfferings ? '0.5px solid #e7e7e7' : 'none',
                display: aiIndexOfferings
                  ? ''
                  : !insideplanBCreate
                  ? 'none'
                  : '',
              }}
            >
              {selectedFilter === 'default' && (
                <div className="right-ai-items" style={{ padding: '0' }}>
                  <div className="filterItemR">
                    <div>
                      <div>Type</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Type');
                        }}
                      >
                        {btnOptionType}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Asset Class</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Asset Class');
                        }}
                      >
                        {btnOptionAsset}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Custody</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Custody');
                        }}
                      >
                        {btnOptionCustody}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Billing Type</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Billing Type');
                        }}
                      >
                        {btnOptionBilling}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Minimum Investment</div>
                      <div
                        className="btn"
                        // onClick={() => {
                        //   setSelectedFilter('Minimum Investment');
                        // }}
                        style={{ background: 'none', border: 'none' }}
                      >
                        {btnOptionInvestment}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Maximum Drawdown</div>
                      <div
                        className="btn"
                        // onClick={() => {
                        //   setSelectedFilter('Maximum Drawdown');
                        // }}
                        style={{ background: 'none', border: 'none' }}
                      >
                        {btnOptionDrawdown}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Max Management Fee</div>
                      <div
                        className="btn"
                        // onClick={() => {
                        //   setSelectedFilter('Max Management Fee');
                        // }}
                        style={{ background: 'none', border: 'none' }}
                      >
                        {btnOptionManagementFee}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Comp Plan</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Comp Plan');
                        }}
                      >
                        {btnOptionCompPlan}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Leverage</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Leverage');
                        }}
                      >
                        {btnOptionLeverage}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Payment Methods</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Payment Methods');
                        }}
                      >
                        {btnOptionPayment}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedFilter === 'Type' && (
                <FilterItem2
                  title="Type"
                  value="All"
                  selected="true"
                  typeFilter={typeFilter}
                  funcValue={selectedType}
                  func={setSelectedType}
                  setBtn={setBtnOptionType}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Asset Class' && (
                <FilterItem2
                  title="Asset Class"
                  value="All"
                  selected="true"
                  typeFilter={assetClassFilter}
                  funcValue={selectedAssetClass}
                  func={setSelectedAssetClass}
                  setBtn={setBtnOptionAsset}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Custody' && (
                <FilterItem2
                  title="Custody"
                  value="All"
                  selected="true"
                  typeFilter={custodyFilter}
                  funcValue={selectedCustody}
                  func={setSelectedCustody}
                  setBtn={setBtnOptionCustody}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Billing Type' && (
                <FilterItem2
                  title="Billing Type"
                  value="All"
                  selected="true"
                  typeFilter={billingFilter}
                  funcValue={selectedBilling}
                  func={setSelectedBilling}
                  setBtn={setBtnOptionBilling}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Comp Plan' && (
                <FilterItem2
                  title="Comp Plan"
                  value="All"
                  selected="true"
                  typeFilter={compFilter}
                  funcValue={selectedComp}
                  func={setSelectedComp}
                  setBtn={setBtnOptionCompPlan}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Leverage' && (
                <FilterItem2
                  title="Leverage"
                  value="All"
                  selected="true"
                  typeFilter={leverageFilter}
                  funcValue={selectedLeverage}
                  func={setSelectedLeverage}
                  setBtn={setBtnOptionLeverage}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Payment Methods' && (
                <FilterItem2
                  title="Payment Methods"
                  value="All"
                  selected="true"
                  typeFilter={paymentMethodsFilter}
                  funcValue={selectedPaymentMethods}
                  func={setSelectedPaymentMethods}
                  setBtn={setBtnOptionPayment}
                  setgoBack={setSelectedFilter}
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ position: 'relative', width: '100%', height: '88%' }}>
            <div className="malls">
              <div className="malls-content">
                <div
                  style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                >
                  Coming Soon
                </div>
              </div>
            </div>
          </div>
        )}

        {adPopularAction && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setAdPopularAction(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div">
              <div
                className="each-action1"
                onClick={() => {
                  setAdPopularAction(false);
                  setinsideplanBCreate(true);
                }}
              >
                <div>Create New Listing</div>
              </div>
              <div
                className="each-action1"
                onClick={() => {
                  setAdPopularAction(false);
                }}
                style={{ opacity: '0.5' }}
              >
                <div>Create New Listing Marketplace</div>
              </div>
            </div>
          </div>
        )}

        {insideplanBCreate && (
          <div className="popular">
            {adPopularActionStep === 'step1' && (
              <>
                <div className="head-txt">
                  <div>Product Information</div>
                  <div
                    onClick={() => {
                      setinsideplanBCreate(false);
                      setaidropdownProductName('');
                      setProductTypeSelected('');
                      setAssestCustodySelected('');
                      setaidropdownDesc('');
                      setAssetClass('');
                      setaidropdownProdImg('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <InputDivs
                    heading="What is your product name?"
                    placeholderText="Name..."
                    setFunc={setaidropdownProductName}
                    funcValue={aidropdownProductName}
                  />
                  <InputDropdown
                    heading="What type of product is it?"
                    setFunc={setProductTypeSelected}
                    mapValue={typeFilter}
                    funcValue={productTypeSelected}
                    dropdown={aidropdownAsset}
                    dropdownFunc={setaidropdownAsset}
                  />
                  <InputDropdown
                    heading="How are the assets custodialized?"
                    setFunc={setAssestCustodySelected}
                    mapValue={custodyFilter}
                    funcValue={assestCustodySelected}
                    dropdown={aidropdownCustody}
                    dropdownFunc={setaidropdownCustody}
                  />
                  <InputDivs
                    heading="Describe the product"
                    placeholderText="Description..."
                    setFunc={setaidropdownDesc}
                    funcValue={aidropdownDesc}
                  />
                  <InputDropdown
                    heading="What asset class does this product trade in?"
                    setFunc={setAssetClass}
                    mapValue={assetClassFilter}
                    funcValue={assetClass}
                    dropdown={aidropdownAssetClass}
                    dropdownFunc={setaidropdownAssetClass}
                  />

                  <ImageUploadDiv
                    heading="Upload the product icon"
                    setFunc={setaidropdownProdImg}
                    funcValue={aidropdownProdImg}
                  />
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setinsideplanBCreate(false);
                      setAdPopularAction(true);
                      setaidropdownProductName('');
                      setProductTypeSelected('');
                      setAssestCustodySelected('');
                      setaidropdownDesc('');
                      setAssetClass('');
                      setaidropdownProdImg('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        aidropdownProductName &&
                        productTypeSelected &&
                        assestCustodySelected &&
                        aidropdownDesc &&
                        assetClass &&
                        aidropdownProdImg
                          ? '1'
                          : '0.25',
                      cursor:
                        aidropdownProductName &&
                        productTypeSelected &&
                        assestCustodySelected &&
                        aidropdownDesc &&
                        assetClass &&
                        aidropdownProdImg
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        aidropdownProductName &&
                        productTypeSelected &&
                        assestCustodySelected &&
                        aidropdownDesc &&
                        assetClass &&
                        aidropdownProdImg
                      ) {
                        setAdPopularActionStep('step2');
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {adPopularActionStep === 'step2' && (
              <>
                <div className="head-txt">
                  <div>Product Cost</div>
                  <div
                    onClick={() => {
                      setinsideplanBCreate(false);
                      setAdPopularActionStep('step1');
                      setaidropdownProductName('');
                      setProductTypeSelected('');
                      setAssestCustodySelected('');
                      setaidropdownDesc('');
                      setAssetClass('');
                      setaidropdownProdImg('');
                      setProductCharge('');
                      setPaymentTypes('');
                      setUpfrontCost('');
                      setaidropdownMontlyCost('');
                      setaidropdownManaFee('');
                      setReferralProgram('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <InputDropdown
                    heading="How do you charge for this product"
                    setFunc={setProductCharge}
                    mapValue={billingFilter}
                    funcValue={productCharge}
                    dropdown={aidropdownProductCharge}
                    dropdownFunc={setaidropdownProductCharge}
                  />
                  <InputDropdown
                    heading="What payment types do you accept?"
                    setFunc={setPaymentTypes}
                    funcValue={paymentTypes}
                    mapValue={paymentMethodsFilter}
                    dropdown={aidropdownPaymentTypes}
                    dropdownFunc={setaidropdownPaymentTypes}
                  />
                  <InputDivs
                    heading="What is the upfront cost?"
                    placeholderText="$0.00"
                    setFunc={setUpfrontCost}
                    funcValue={upfrontCost}
                  />
                  <InputDivs
                    heading="What is the monthly cost?"
                    placeholderText="$0.00"
                    setFunc={setaidropdownMontlyCost}
                    funcValue={aidropdownMontlyCost}
                  />
                  <InputDivs
                    heading="What is the management fee?"
                    placeholderText="0.00%"
                    setFunc={setaidropdownManaFee}
                    funcValue={aidropdownManaFee}
                  />
                  <InputDropdown
                    heading="Does this product have a referral program"
                    setFunc={setReferralProgram}
                    funcValue={referralProgram}
                    mapValue={compFilter}
                    dropdown={aidropdownReferralProgram}
                    dropdownFunc={setaidropdownReferralProgram}
                  />
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setProductCharge('');
                      setPaymentTypes('');
                      setUpfrontCost('');
                      setaidropdownMontlyCost('');
                      setaidropdownManaFee('');
                      setReferralProgram('');
                      setAdPopularActionStep('step1');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        productCharge &&
                        paymentTypes &&
                        upfrontCost &&
                        aidropdownMontlyCost &&
                        aidropdownManaFee &&
                        referralProgram
                          ? '1'
                          : '0.25',
                      cursor:
                        productCharge &&
                        paymentTypes &&
                        upfrontCost &&
                        aidropdownMontlyCost &&
                        aidropdownManaFee &&
                        referralProgram
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        productCharge &&
                        paymentTypes &&
                        upfrontCost &&
                        aidropdownMontlyCost &&
                        aidropdownManaFee &&
                        referralProgram
                      ) {
                        setAdPopularActionStep('step3');
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {adPopularActionStep === 'step3' && (
              <>
                <div className="head-txt">
                  <div>Product Performance</div>
                  <div
                    onClick={() => {
                      setinsideplanBCreate(false);
                      setAdPopularActionStep('step1');
                      setaidropdownProductName('');
                      setProductTypeSelected('');
                      setAssestCustodySelected('');
                      setaidropdownDesc('');
                      setAssetClass('');
                      setaidropdownProdImg('');
                      setProductCharge('');
                      setPaymentTypes('');
                      setUpfrontCost('');
                      setaidropdownMontlyCost('');
                      setaidropdownManaFee('');
                      setReferralProgram('');
                      setaidropdownROI30('');
                      setaidropdownROI12('');
                      setaidropdownProductLive('');
                      setaidropdownAvgDrawdown('');
                      setaidropdownSubs('');
                      setProductLeverage('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <InputDivs
                    heading="What is the ROI in the last 30 days?"
                    placeholderText="0.00%"
                    setFunc={setaidropdownROI30}
                    funcValue={aidropdownROI30}
                  />
                  <InputDivs
                    heading="What is the ROI in the last 12 months?"
                    placeholderText="0.00%"
                    setFunc={setaidropdownROI12}
                    funcValue={aidropdownROI12}
                  />
                  <InputDivs
                    heading="How many months has this product been live?"
                    placeholderText="0"
                    setFunc={setaidropdownProductLive}
                    funcValue={aidropdownProductLive}
                  />
                  <InputDivs
                    heading="What is the average drawdown?"
                    placeholderText="0.00%"
                    setFunc={setaidropdownAvgDrawdown}
                    funcValue={aidropdownAvgDrawdown}
                  />
                  <InputDivs
                    heading="How many people are subscribed to the product?"
                    placeholderText="0"
                    setFunc={setaidropdownSubs}
                    funcValue={aidropdownSubs}
                  />
                  <InputDropdown
                    heading="Does this product use leverage to trade?"
                    setFunc={setProductLeverage}
                    funcValue={productLeverage}
                    mapValue={leverageFilter}
                    dropdown={aidropdownProductLeverage}
                    dropdownFunc={setaidropdownProductLeverage}
                  />
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setaidropdownROI30('');
                      setaidropdownROI12('');
                      setaidropdownProductLive('');
                      setaidropdownAvgDrawdown('');
                      setaidropdownSubs('');
                      setProductLeverage('');
                      setAdPopularActionStep('step2');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        aidropdownROI30 &&
                        aidropdownROI12 &&
                        aidropdownProductLive &&
                        aidropdownAvgDrawdown &&
                        aidropdownSubs &&
                        productLeverage
                          ? '1'
                          : '0.25',
                      cursor:
                        aidropdownROI30 &&
                        aidropdownROI12 &&
                        aidropdownProductLive &&
                        aidropdownAvgDrawdown &&
                        aidropdownSubs &&
                        productLeverage
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        aidropdownROI30 &&
                        aidropdownROI12 &&
                        aidropdownProductLive &&
                        aidropdownAvgDrawdown &&
                        aidropdownSubs &&
                        productLeverage
                      ) {
                        setAdPopularActionStep('step4');
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {adPopularActionStep === 'step4' && (
              <>
                <div className="head-txt">
                  <div>Type Information</div>
                  <div
                    onClick={() => {
                      setinsideplanBCreate(false);
                      setAdPopularActionStep('step1');
                      setaidropdownProductName('');
                      setProductTypeSelected('');
                      setAssestCustodySelected('');
                      setaidropdownDesc('');
                      setAssetClass('');
                      setaidropdownProdImg('');
                      setProductCharge('');
                      setPaymentTypes('');
                      setUpfrontCost('');
                      setaidropdownMontlyCost('');
                      setaidropdownManaFee('');
                      setReferralProgram('');
                      setaidropdownROI30('');
                      setaidropdownROI12('');
                      setaidropdownProductLive('');
                      setaidropdownAvgDrawdown('');
                      setaidropdownSubs('');
                      setProductLeverage('');
                      setaidropdownCompanyCreated('');
                      setaidropdownCompanyWebsite('');
                      setaidropdownRegLink('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <InputDivs
                    heading="Which company created this product?"
                    placeholderText="Company name"
                    setFunc={setaidropdownCompanyCreated}
                    funcValue={aidropdownCompanyCreated}
                  />
                  <InputDivs
                    heading="What is the company’s website?"
                    placeholderText="Enter URL"
                    setFunc={setaidropdownCompanyWebsite}
                    funcValue={aidropdownCompanyWebsite}
                  />
                  <InputDivs
                    heading="Enter registration link"
                    placeholderText="Enter URL"
                    setFunc={setaidropdownRegLink}
                    funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setaidropdownCompanyCreated('');
                      setaidropdownCompanyWebsite('');
                      setaidropdownRegLink('');
                      setAdPopularActionStep('step3');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        aidropdownCompanyCreated &&
                        aidropdownCompanyWebsite &&
                        aidropdownRegLink
                          ? '1'
                          : '0.25',
                      cursor:
                        aidropdownCompanyCreated &&
                        aidropdownCompanyWebsite &&
                        aidropdownRegLink
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      // setSelectedDropDown('');
                      if (
                        aidropdownCompanyCreated &&
                        aidropdownCompanyWebsite &&
                        aidropdownRegLink
                      ) {
                        listProductCreationFunc();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {actionClicked && (
          <div className="popular">
            {listingActionStep === 'step1' && (
              <>
                <div className="head-txt">
                  <div>Listing Actions</div>
                  <div
                    onClick={() => {
                      setActionClicked(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div className="each-action1" style={{ opacity: '0.5' }}>
                    <div>Edit Listing</div>
                  </div>
                  <div
                    className="each-action1"
                    onClick={() => {
                      setListingActionStep('step2');
                    }}
                  >
                    <div>Delete Listing</div>
                  </div>
                </div>
              </>
            )}

            {listingActionStep === 'step2' && (
              <>
                <div className="head-txt">
                  <div>Listing Actions</div>
                  <div
                    onClick={() => {
                      setActionClicked(false);
                      setListingActionStep('step1');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div
                    className="each-action1"
                    onClick={() => {
                      deleteListing(aiIndexOfferingSelected);
                    }}
                  >
                    <div>Confirm Delete</div>
                  </div>
                  <div
                    className="each-action1"
                    onClick={() => {
                      setListingActionStep('step1');
                    }}
                  >
                    <div>Never Mind</div>
                  </div>
                </div>
              </>
            )}

            {isDeleting && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={loadingLogo} width={200} />
              </div>
            )}

            {listingActionStep === 'step3' && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setActionClicked(false);
                      setListingActionStep('step1');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Deleted {selectedListing}
                  </div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{
                      background: '#CF1A38',
                      width: '100%',
                    }}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    See Updated List
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Advertise;

const AIOpportunityCard = ({
  item_name,
  company_name,
  product_icon,
  product_description,
  cost_per_month,
  link,
  last_month_ROI,
  last_12_months_ROI,
  months_running,
  avg_drawdown,
  subscribers,
  company_website,
  _id,
}) => {
  const {
    aitab,
    aiIndexOfferingSelected,
    setAiIndexOfferingSelected,
    actionClicked,
    setActionClicked,
    selectedListing,
    setSelectedListing,
  } = useAppContextDetails();
  return (
    <div
      className="ai-card"
      onClick={() => {
        setAiIndexOfferingSelected(_id);
        setSelectedListing(item_name);
      }}
      style={{
        boxShadow:
          aiIndexOfferingSelected == _id
            ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
            : '',
        opacity: !actionClicked
          ? '1'
          : actionClicked && aiIndexOfferingSelected == _id
          ? '1'
          : '0.5',
      }}
    >
      <div className="top-data">
        <div className="imgg-div">
          <img
            src={product_icon?.includes('http') ? product_icon : sample}
            alt=""
          />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">{item_name}</div>
              <div>
                By{' '}
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!company_website?.includes('https://' || 'www.')) {
                      window.open('https://' + company_website, '_blank');
                    } else {
                      window.open(company_website, '_blank');
                    }
                  }}
                >
                  {company_name}
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              <div>
                <div className="txtt1">
                  {cost_per_month
                    ? '$' + Number(cost_per_month).toFixed(2)
                    : '$0'}
                </div>
                <div style={{ fontSize: '0.8rem' }}>USD Per Month</div>
              </div>
              <div
                className="gs-btn"
                onClick={() => {
                  setActionClicked(true);
                }}
              >
                Actions
              </div>
              <div className="center-line"></div>
            </div>
          </div>

          <p className="bottom-txt">{product_description}</p>
        </div>
      </div>
      {/* <div className="bottom-data">
        <div>
          <div>Last Month</div>
          <div className="txtt2">
            {last_month_ROI ? Number(last_month_ROI).toFixed(2) + '%' : '0%'}
          </div>
        </div>
        <div>
          <div>Last 12 Months</div>
          <div className="txtt2">
            {last_12_months_ROI
              ? Number(last_12_months_ROI).toFixed(2) + '%'
              : '0%'}
          </div>
        </div>
        <div>
          <div>Months Running</div>
          <div className="txtt3">{months_running} Months</div>
        </div>
        <div>
          <div>Avg Drawdown</div>
          <div className="txtt3">
            {avg_drawdown ? Number(avg_drawdown).toFixed(2) + '%' : ''}
          </div>
        </div>
        <div>
          <div>Subscribers</div>
          <div className="txtt3">{subscribers}</div>
        </div>
      </div> */}
    </div>
  );
};

const AIOpportunityCardLoading = () => {
  return (
    <div className="ai-card">
      <div className="top-data">
        <div className="imgg-div">
          <Skeleton circle />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">
                <Skeleton width={120} />
              </div>
            </div>
            <div>
              <div className="txtt1">
                <Skeleton />
              </div>
              <div>
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  <Skeleton />
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              <div>
                <div className="txtt1">
                  <Skeleton />
                </div>
                <div style={{ fontSize: '0.8rem' }}>
                  <Skeleton />
                </div>
              </div>
              {/* <div className="gs-btn">
                  <Skeleton />
                </div> */}
              <div className="center-line"></div>
            </div>
          </div>

          <p className="bottom-txt">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>
        </div>
      </div>
      {/* <div className="bottom-data">
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

const FilterItem = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);
  return (
    <div className="filterItem">
      <div
        onClick={() => {
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#18191d ',
          }}
        >
          {funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return <div onClick={() => func(eachOption)}>{eachOption}</div>;
            })
          : ''}
        {funcValue && <div onClick={() => func('')}>All</div>}
      </div>
    </div>
  );
};

const FilterItem1 = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [btnOption, setBtnOption] = useState('');

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);

  return (
    <div className="filterItem">
      <div
        onClick={() => {
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#18191d ',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {btnOption ? btnOption : funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return title === 'Companies' ? (
                <div
                  onClick={() => {
                    setBtnOption(eachOption?.name);
                    func(eachOption?.email);
                  }}
                >
                  {eachOption?.profile_img && (
                    <img
                      src={eachOption?.profile_img}
                      alt=""
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <span>{eachOption?.name}</span>
                </div>
              ) : (
                <div
                  onClick={() => {
                    func(eachOption?.billingMethod);
                  }}
                >
                  {eachOption?.billingMethod ? eachOption?.billingMethod : ''}
                </div>
              );
            })
          : ''}
        {funcValue && (
          <div
            onClick={() => {
              setBtnOption('');
              func('');
            }}
          >
            All
          </div>
        )}
      </div>
    </div>
  );
};

const FilterItem2 = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
  setBtn,
  setgoBack,
}) => {
  return (
    <div className="right-ai-items" style={{ padding: '0' }}>
      <div className="filterOption">
        <div className="headerTxt">Select {title}</div>
        <div className="scrollableDiv">
          {typeFilter?.length > 0
            ? typeFilter?.map((e, i) => {
                return (
                  <div
                    className="eachDiv"
                    key={i}
                    onClick={() => {
                      func(e);
                      setBtn(e);
                    }}
                  >
                    <div>{e}</div>
                  </div>
                );
              })
            : ''}
        </div>
        <div className="BtnsDiv">
          <div
            style={{ background: '#18191d' }}
            onClick={() => {
              func('');
              setBtn('All');
            }}
          >
            Clear All Filters
          </div>
          <div
            style={{ background: '#CF1A38' }}
            onClick={() => {
              setgoBack('default');
            }}
          >
            Go Back
          </div>
        </div>
      </div>
    </div>
  );
};

// businesses changed to My Listings
