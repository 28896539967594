import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import './opportunities.scss';
import { BankContext } from '../../context/Context';
import axios from 'axios';
import { getProfileForBanker } from './api';
import Skeleton from 'react-loading-skeleton';
import { LoadingAnimation } from '../../components/LoadingAnimation';

import search from '../../static/images/malls/search.svg';
import fulllogo from '../../static/images/opportunities/fulllogo.svg';
import empty from './empty.svg';
import dots from './dots.svg';
import dummy from './dummy.png';
import close from '../CRMPage/close.svg';
import loadingLogo from '../../static/images/logos/vv.svg';
import {
  InputDivsTextArea1,
  InputDivsWithMT,
  ImageUploadDiv2,
} from '../../components/BuyBlocks/components/CreatePlanB';

const OpportunitiesPage = () => {
  const {
    email,
    token,
    sidebarCollapse,
    contentArea,
    setContentArea,
    layoutOverlay,
    setLayoutOverlay,
    promoteStep,
    setPromoteStep,
  } = useContext(BankContext);

  const [opportunitiesTab, setOpportunitiesTab] = useState('All Brands');
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [bankeremail, setBankerEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [promoteLoading, setPromoteLoading] = useState(false);
  const [promoteStatus, setPromoteStatus] = useState(false);
  const [categoryAction, setCategoryAction] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categorySelected, setCategorySelected] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [deleteCategoryStep, setDeleteCategoryStep] = useState('step1');
  const [deleting, setDeleting] = useState(false);
  const [largestFollowingData, setLargestFollowingData] = useState([]);
  const [loading3, setLoading3] = useState(false);
  const [createCategory, setCreateCategory] = useState(false);
  const [createCategoryStep, setCreateCategoryStep] = useState('step1');
  const [categoryName, setCategoryName] = useState();
  const [categoryTag, setCategoryTag] = useState();
  const [categoryDes, setCategoryDes] = useState();
  const [categoryIcon, setCategoryicon] = useState();
  const [creating, setCreating] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    if (deleteCategory) {
      setDeleteCategory(false);
      setDeleteCategoryStep('step1');
    }
    if (createCategory) {
      setCreateCategory(false);
      setCreateCategoryStep('step1');
    }
    setSelectedCategory('');
    setCategorySelected(false);
    getAllCategories();
  }

  const getAllCategories = () => {
    setLoading(true);
    axios
      .get(
        'https://teller2.apimachine.com/banker/categories?onlyMainCategory=true'
      )
      .then((response) => {
        let result = response?.data?.data;
        setCategories(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, 'opportunities catogery error');
      });
  };

  useEffect(() => {
    setLoading1(true);
    axios
      .get('https://teller2.apimachine.com/admin/allBankers')
      .then((response) => {
        let result = response?.data?.data;
        setBrands(result);
        setLoading1(false);
      })
      .catch((error) => {
        console.log(error, 'opportunities brands error');
      });
    getAllCategories();
  }, []);

  useEffect(() => {
    setLoading3(true);
    axios
      .get('https://teller2.apimachine.com/admin/allBankers?sortFollowers=1')
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'largestFollowingData result');
        setLargestFollowingData(result);
        setLoading3(false);
      })
      .catch((error) => {
        console.log(error, 'largestFollowingData error');
      });
  }, []);

  const getProfileData = (banker) => {
    setLoading2(true);
    getProfileForBanker(banker).then((response) => {
      let result = response?.data?.data;
      setProfileData(result);
      setLoading2(false);
    });
  };

  const followBrand = () => {
    setPromoteLoading(true);
    axios
      .post(
        'https://teller2.apimachine.com/banker/follow',
        {
          appCode: 'broker_app',
          bankerEmail: bankeremail,
        },
        { headers: { email, token } }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'followBrand result');
        if (result?.status) {
          setPromoteStatus(true);
          setPromoteLoading(false);
          setLayoutOverlay(false);
        } else {
          setPromoteStatus(false);
          setPromoteLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in followBrand');
      });
  };

  const deleteCategoryApiCall = () => {
    setDeleting(true);
    let id = selectedCategory?._id;

    // console.log(id, 'selectedCategory?._id');
    // console.log(token, 'token');

    const options = {
      headers: {
        email,
        token,
      },
      data: {
        categoryId: id,
      },
    };

    axios
      .delete('https://teller2.apimachine.com/banker/category/delete', options)
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'deleteCategoryApiCall result');
        if (result?.status) {
          setDeleting(false);
          setDeleteCategoryStep('step2');
          myTimeout();
        } else {
          setDeleting(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in deleteCategoryApiCall');
      });
  };

  const createCategoryApiCall = () => {
    setCreating(true);

    let body = {
      name: categoryName,
      categoryTag: categoryTag,
      icon: categoryIcon,
      description: categoryDes,
    };

    axios
      .post('https://teller2.apimachine.com/banker/newCategory', body, {
        headers: { email, token },
      })
      .then((response) => {
        let result = response?.data;
        console.log(result, 'createCategoryApiCall result');
        if (result?.status) {
          setCreating(false);
          setCreateCategoryStep('step2');
          myTimeout();
        }
      })
      .catch((error) => {
        console.log(error, 'error in createCategoryApiCall');
      });
  };

  return (
    <Layout active="Opportunities" className="opportunities" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="opportunities-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="opportunities-icon" style={{ width: '35%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-opportunities">
              <div className="im">
                <input
                  type="text"
                  placeholder="Im Looking For..."
                  //   onChange={(event) => {
                  //     if (crmTab === 'Staking') {
                  //       filterItem(event.target.value);
                  //     }
                  //   }}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-btn"
            style={{ background: '#18191d' }}
            // onClick={() => {
            //   setPopular(!popular);
            // }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '100%' }}>
            <div className="optionss-divider">
              <div
                className={`each-option ${
                  opportunitiesTab === 'All Brands'
                    ? 'each-option-selected'
                    : ''
                }`}
                style={{
                  fontWeight: opportunitiesTab === 'All Brands' ? '600' : '',
                }}
                onClick={() => {
                  setOpportunitiesTab('All Brands');
                  if (contentArea === 'nextPage') {
                    setContentArea('defaultPage');
                  }
                }}
              >
                <div>
                  <p>All Brands</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {contentArea === 'defaultPage' ? (
          <div className="opportunities-content">
            <div className="topArea">
              <div
                className="txtt"
                style={{ opacity: categorySelected ? '0.25' : '1' }}
              >
                Explore Opportunities
              </div>
              <div className="imgBox">
                {loading
                  ? Array(10)
                      .fill(' ')
                      .map((item, index) => {
                        return (
                          <div className="imgDiv" key={index}>
                            <Skeleton
                              width={'100%'}
                              height={'100%'}
                              borderRadius={'25px'}
                            />
                          </div>
                        );
                      })
                  : categories?.map((e, i) => {
                      return (
                        <div
                          className="imgDiv"
                          key={i}
                          style={{
                            opacity: !categorySelected
                              ? '1'
                              : categorySelected &&
                                selectedCategory?._id === e?._id
                              ? '1'
                              : '0.25',
                          }}
                        >
                          {e?.icon ? (
                            <img src={e?.icon} alt="" />
                          ) : (
                            <img src={dummy} alt="" />
                          )}
                          <div
                            className="imgDots"
                            onClick={() => {
                              setCategoryAction(true);
                              setCategorySelected(true);
                              setSelectedCategory(e);
                            }}
                          >
                            <img src={dots} alt="" />
                          </div>
                          <div className="imgTxt">{e?.name}</div>
                        </div>
                      );
                    })}
              </div>
              <div
                className="bottomArea"
                style={{
                  top: sidebarCollapse ? '90%' : '89%',
                }}
              >
                <div
                  className="bottomArea-content"
                  style={{ opacity: categorySelected ? '0.25' : '1' }}
                >
                  <div>New Brands</div>
                  <div className="sideBorder"></div>
                  <div className="emptyDiv"></div>
                  {loading1
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-Content" key={index}>
                              <div>
                                <Skeleton
                                  width={'40px'}
                                  height={'40px'}
                                  borderRadius={50}
                                />
                              </div>
                              <div style={{ width: 'calc(100% - 55px)' }}>
                                <div className="appName">
                                  <Skeleton width={'100%'} height={25} />
                                </div>
                                <div className="appDesc">
                                  <Skeleton width={'100%'} height={50} />
                                </div>
                              </div>
                            </div>
                          );
                        })
                    : brands?.map((e, i) => {
                        return (
                          <div
                            className="each-Content"
                            key={i}
                            onClick={() => {
                              setContentArea('nextPage');
                              getProfileData(e?.email);
                              setBankerEmail(e?.email);
                            }}
                          >
                            <div>
                              <img src={e?.profilePicURL} alt="" />
                            </div>
                            <div>
                              <div className="appName">{e?.displayName}</div>
                              <div className="appDesc">{e?.description}</div>
                            </div>
                          </div>
                        );
                      })}
                </div>

                <div
                  className="bottomArea-content"
                  style={{ opacity: categorySelected ? '0.25' : '1' }}
                >
                  <div>Highest Payouts</div>
                  <div
                    className="sideBorder"
                    style={{ background: '#57D6A1' }}
                  ></div>
                  <div className="emptyDiv"></div>
                </div>

                <div
                  className="bottomArea-content"
                  style={{
                    borderRight: 'none',
                    opacity: categorySelected ? '0.25' : '1',
                  }}
                >
                  <div>Largest Following</div>
                  <div
                    className="sideBorder"
                    style={{ background: '#6669B0' }}
                  ></div>
                  <div className="emptyDiv"></div>
                  {loading3
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-Content" key={index}>
                              <div>
                                <Skeleton
                                  width={'40px'}
                                  height={'40px'}
                                  borderRadius={50}
                                />
                              </div>
                              <div style={{ width: 'calc(100% - 55px)' }}>
                                <div className="appName">
                                  <Skeleton width={'100%'} height={25} />
                                </div>
                                <div className="appDesc">
                                  <Skeleton width={'100%'} height={50} />
                                </div>
                              </div>
                            </div>
                          );
                        })
                    : largestFollowingData?.map((e, i) => {
                        return (
                          <div
                            className="each-Content"
                            key={i}
                            onClick={() => {
                              setContentArea('nextPage');
                              getProfileData(e?.email);
                              setBankerEmail(e?.email);
                            }}
                          >
                            <div>
                              <img src={e?.profilePicURL} alt="" />
                            </div>
                            <div>
                              <div className="appName">{e?.displayName}</div>
                              <div className="appDesc">{e?.description}</div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="opportunities-content1">
            {loading2
              ? Array(1)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="mapArea" key={index}>
                        <div className="coverPic-section">
                          <Skeleton className="coverPic-section" />
                        </div>
                        <div className="bankerData">
                          <div className="bankerIcon">
                            <Skeleton
                              width={75}
                              height={75}
                              borderRadius={50}
                            />
                          </div>
                          <div className="bakerNameDiv">
                            <div>
                              <div>
                                <Skeleton width={150} height={25} />
                              </div>
                              <div>
                                <Skeleton width={80} height={25} />
                              </div>
                            </div>
                            <div>
                              <Skeleton width={70} height={25} />
                            </div>
                          </div>
                        </div>
                        <div className="bioDiv">
                          <div>
                            <Skeleton width={50} height={25} />
                          </div>
                          <div>
                            <Skeleton width={200} height={25} />
                          </div>
                        </div>
                        <div className="statisticsDiv">
                          <div className="headerrDiv">
                            <div>
                              <Skeleton width={100} height={25} />
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                              <Skeleton width={100} height={25} />
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                              <Skeleton width={100} height={25} />
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                              <Skeleton width={100} height={25} />
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <Skeleton width={100} height={25} />
                            </div>
                          </div>
                          <div className="statisticsData">
                            <div>
                              <Skeleton width={50} height={25} />
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                              <Skeleton width={50} height={25} />
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                              <Skeleton width={50} height={25} />
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                              <Skeleton width={50} height={25} />
                            </div>
                            <div style={{ justifyContent: 'flex-end' }}>
                              <Skeleton width={50} height={25} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
              : profileData.length > 0 &&
                profileData?.map((e, i) => {
                  return (
                    <div className="mapArea" key={i}>
                      <div
                        className="coverPic-section"
                        style={{ background: `${'#' + e?.colorCode}` }}
                      >
                        {/* <img src={e?.coverPicURL} alt="" /> */}
                      </div>
                      <div className="bankerData">
                        <div className="bankerIcon">
                          <img src={e?.profilePicURL} alt="" />
                        </div>
                        <div className="bakerNameDiv">
                          <div>
                            <div
                              style={{ fontSize: '1.3rem', fontWeight: '600' }}
                            >
                              {e?.displayName}
                            </div>
                            <div style={{ fontWeight: '100' }}>
                              {e?.country}
                            </div>
                          </div>
                          <div
                            className="promoteBtn"
                            style={{ background: `${'#' + e?.colorCode}` }}
                            onClick={() => {
                              setLayoutOverlay(true);
                            }}
                          >
                            {promoteStatus ? 'Stop Promoting' : 'Promote'}
                          </div>
                        </div>
                      </div>
                      <div className="bioDiv">
                        <div>Bio</div>
                        <div>{e?.description}</div>
                      </div>
                      <div className="statisticsDiv">
                        <div className="headerrDiv">
                          <div>Affiliates</div>
                          <div style={{ justifyContent: 'center' }}>
                            Influencers
                          </div>
                          <div style={{ justifyContent: 'center' }}>
                            Products
                          </div>
                          <div style={{ justifyContent: 'center' }}>
                            Comp Plans
                          </div>
                          <div style={{ justifyContent: 'flex-end' }}>
                            Paid Out
                          </div>
                        </div>
                        <div className="statisticsData">
                          <div>{e?.bankerFollowersCount}</div>
                          <div style={{ justifyContent: 'center' }}>0</div>
                          <div style={{ justifyContent: 'center' }}>
                            {e?.productsCount ? e?.productsCount : '0'}
                          </div>
                          <div style={{ justifyContent: 'center' }}>0</div>
                          <div style={{ justifyContent: 'flex-end' }}>
                            $0.00
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        )}

        {layoutOverlay && (
          <>
            {promoteStep === 1 ? (
              promoteLoading ? (
                <div className="promoteModal">
                  <div
                    style={{
                      marginBottom: '3rem',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={profileData?.[0]?.profilePicURL}
                      alt=""
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: '1.5rem',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    You Are Now Promoting {profileData?.[0]?.displayName}
                  </div>
                </div>
              ) : (
                <div className="promoteModal">
                  <div className="headerrr">
                    Are you sure you want to promote{' '}
                    {profileData?.[0]?.displayName}? This would mean the
                    following
                  </div>
                  <div style={{ width: '100%', marginBottom: '1rem' }}>
                    1. They will be added to the companies that you are
                    promoting on the public NetworkChain
                  </div>
                  <div style={{ width: '100%', marginBottom: '3rem' }}>
                    2. Even though you are promoting them, you may not be
                    eligible for commissions if they have affiliate licenses.
                  </div>
                  <div className="footerrr">
                    <div
                      style={{ background: '#18191D' }}
                      onClick={() => {
                        followBrand();
                      }}
                    >
                      Confirm
                    </div>
                    <div
                      style={{ background: '#CF1A38' }}
                      onClick={() => {
                        setLayoutOverlay(false);
                      }}
                    >
                      Never Mind
                    </div>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </>
        )}

        {categoryAction && (
          <div className="popularO">
            <div className="head-txt">
              <div>Category Actions</div>
              <div
                onClick={() => {
                  setCategoryAction(false);
                  setCategorySelected(false);
                  setSelectedCategory('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setCategoryAction(false);
                setCreateCategory(true);
              }}
            >
              <div>Create Category</div>
            </div>
            <div className="each-action">
              <div>Edit Category</div>
            </div>
            <div className="each-action">
              <div>Add Sub Category</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setCategoryAction(false);
                setDeleteCategory(true);
              }}
            >
              <div>Delete Category</div>
            </div>
          </div>
        )}

        {deleteCategory && (
          <div className="popularO">
            {deleteCategoryStep === 'step1' && (
              <>
                <div className="head-txt">
                  <div>Delete Category</div>
                  <div
                    onClick={() => {
                      setDeleteCategory(false);
                      setCategorySelected(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div
                  className="each-action"
                  onClick={() => {
                    deleteCategoryApiCall();
                  }}
                >
                  <div>Confirm & Delete</div>
                </div>
                <div
                  className="each-action"
                  onClick={() => {
                    setCategoryAction(true);
                    setDeleteCategory(false);
                  }}
                >
                  <div>Never Mind</div>
                </div>
              </>
            )}

            {deleting && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={loadingLogo} width={200} />
              </div>
            )}

            {deleteCategoryStep === 'step2' && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setDeleteCategory(false);
                      setDeleteCategoryStep('step1');
                      setSelectedCategory('');
                      setCategorySelected(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Deleted {selectedCategory?.name}
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{
                      background: '#CF1A38',
                      width: '100%',
                    }}
                    onClick={() => {
                      setDeleteCategory(false);
                      setDeleteCategoryStep('step1');
                      setSelectedCategory('');
                      setCategorySelected(false);
                      window.location.reload();
                    }}
                  >
                    See Updated List
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {createCategory && (
          <div className="popularO">
            {createCategoryStep === 'step1' && (
              <>
                <div className="head-txt">
                  <div>Create Category</div>
                  <div
                    onClick={() => {
                      setCategoryName('');
                      setCategoryTag('');
                      setCategoryDes('');
                      setCategoryicon('');
                      setCreateCategory(false);
                      setCategorySelected(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 'calc(100% - 6rem)',
                    overflowY: 'scroll',
                  }}
                >
                  <InputDivsWithMT
                    heading="What is the name of the category?"
                    placeholderText="Name..."
                    setFunc={setCategoryName}
                    funcValue={categoryName}
                  />
                  <InputDivsWithMT
                    heading="Create category tag"
                    placeholderText="Category tag..."
                    setFunc={setCategoryTag}
                    funcValue={categoryTag}
                  />
                  <InputDivsTextArea1
                    heading="Create category description"
                    placeholderText="Description..."
                    setFunc={setCategoryDes}
                    funcValue={categoryDes}
                  />
                  <ImageUploadDiv2
                    heading="Upload the product icon"
                    setFunc={setCategoryicon}
                    funcValue={categoryIcon}
                    imgLoading={imgLoading}
                    setImgLoading={setImgLoading}
                  />
                  <div className="stepBtns" style={{ marginTop: '2rem' }}>
                    <div
                      style={{ background: '#CF1A38', height: '4rem' }}
                      onClick={() => {
                        setCategoryName('');
                        setCategoryTag('');
                        setCategoryDes('');
                        setCategoryicon('');
                        setCreateCategory(false);
                        setCategoryAction(true);
                      }}
                    >
                      Go Back
                    </div>
                    <div
                      style={{
                        background: '#18191d',
                        height: '4rem',
                        opacity:
                          categoryName &&
                          categoryTag &&
                          categoryDes &&
                          categoryIcon
                            ? '1'
                            : '0.25',
                        cursor:
                          categoryName &&
                          categoryTag &&
                          categoryDes &&
                          categoryIcon
                            ? 'pointer'
                            : 'not-allowed',
                      }}
                      onClick={() => {
                        if (
                          categoryName &&
                          categoryTag &&
                          categoryDes &&
                          categoryIcon
                        ) {
                          createCategoryApiCall();
                        }
                      }}
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </>
            )}

            {creating && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%', height: '100%' }}
              >
                <LoadingAnimation icon={loadingLogo} width={200} />
              </div>
            )}

            {createCategoryStep === 'step2' && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setCreateCategory(false);
                      setCreateCategoryStep('step1');
                      setSelectedCategory('');
                      setCategorySelected(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Created {categoryName}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OpportunitiesPage;
