import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import './affiliatepage.scss';

import fulllogo from '../../static/images/affiliate/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import AllApp from './AllApp';

const AffiliatePage = () => {
  const [affiliateTab, setAffiliateTab] = useState('Default CompPlans');
  return (
    <Layout active="Affiliate" className="affiliatePage" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="affiliatePage-navbar">
          <div
            style={{
              height: '100%',
              width: '55%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="affiliatePage-icon" style={{ width: '18%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-affiliate" style={{ width: '43%' }}>
              <div className="im">
                <input type="text" placeholder="Search Anything.." />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={upline?.profile_img ? upline?.profile_img : guest}
                alt=""
                style={{ height: '50px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{upline?.name}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '45px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>
                  $
                  {balances?.gxbroker_balance
                    ? balances?.gxbroker_balance?.toFixed(2)
                    : '0.00'}
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-btn"
            style={{ background: '#18191d' }}
            onClick={() => {
              setPopular(!popular);
            }}
          >
            Popular Actions
          </div> */}
        </div>
        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '25%' }}>
            <div
              className={`each-option ${
                affiliateTab === 'Default CompPlans'
                  ? 'each-option-selected'
                  : ''
              }`}
              style={{
                fontWeight: affiliateTab === 'Default CompPlans' ? '600' : '',
              }}
              onClick={() => setAffiliateTab('Default CompPlans')}
            >
              <div>
                <p>Default CompPlans</p>
              </div>
            </div>
            <div
              className={`each-option ${
                affiliateTab === 'Per Brand' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: affiliateTab === 'Per Brand' ? '600' : '',
              }}
            //   onClick={() => setAffiliateTab('Per Brand')}
            >
              <div>
                <p>Per Brand</p>
              </div>
            </div>
          </div>
        </div>
        <div className='affiliatePage-content'>
            <AllApp />
        </div>
      </div>
    </Layout>
  );
};

export default AffiliatePage;
