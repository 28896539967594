import Cookies from 'js-cookie';
import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { uploadImageFunc } from '../../utils/imageUpload';
import { planBContext } from '../../context/PlanBContext';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import {
  addProductFunction,
  statusFilterFunction,
  clearFilterFunction,
  deleteProductFunction,
  addCompPlanFunction,
  getCustomerLicense,
} from './api';

import fulllogo from '../../static/images/mlm/fullLogo.svg';
import search from '../../static/images/malls/search.svg';
import close from '../CRMPage/close.svg';
import close1 from './close.svg';
import hamburger from './hamburger.svg';
import plus from '../CRMPage/plus.svg';
import add from './addcompPlan.svg';
import del from './delete.svg';
import dots from './dots.svg';
import dummy from './dummy.svg';
import editIcon from './editIcon.svg';
import './mlm.scss';
import uploading from '../../static/images/uploading.svg';
import loadingLogo from '../../static/images/logos/vv.svg';
import { FaCheck, FaRegCopy } from 'react-icons/fa';

import avatarImg from '../../static/images/avatar.svg';
import {
  InputDropdown,
  InputDropdownWeek,
} from '../../components/BuyBlocks/components/CreatePlanB';
import { APP_USER_TOKEN } from '../../config';
const MLMPage = () => {
  const { email, mlmTab, setMlmTab, popularAction, setPopularAction } =
    useContext(BankContext);
  const token = Cookies.get(APP_USER_TOKEN);
  const history = useHistory();

  if (!email) {
    history.push('/');
  }

  const [productAction, setProductAction] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [retailerData, setRetailerData] = useState([]);
  const [filteredretailerData, setFilteredretailerData] = useState([]);
  const [productSelected, setProductSelected] = useState(false);
  const [productData, setProductData] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [addProductStep, setAddProductStep] = useState(1);
  const [billingType, setBillingType] = useState('');
  const [isfetching, setIsfetching] = useState(false);
  const [allApps, setAllApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [allCurrency, setAllCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [productLabel, setProductLabel] = useState('');
  const [productTagline, setProductTagline] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [firstMonthPrice, setFirstMonthPrice] = useState();
  const [monthlyPrice, setMonthlyPrice] = useState();
  const [gracePeriod, setGracePeriod] = useState();
  const [secondAttempt, setSecondAttempt] = useState();
  const [thirdAttempt, setThirdAttempt] = useState();
  const [productPrice, setProductPrice] = useState();
  const [adding, setAdding] = useState(false);
  const [hamSelected, setHamSelected] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('default');
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [deleteProductStep, setDeleteProductStep] = useState('step1');
  const [deleting, setDeleting] = useState(false);
  const [addCompPlan, setAddCompPlan] = useState(false);
  const [addCompPlanStep, setAddCompPlanStep] = useState('step1');
  const [userCreatedApps, setUserCreatedApps] = useState([]);
  const [compPlanApp, setCompPlanApp] = useState('');
  const [levels, setLevels] = useState();
  const [addingComp, setAddingComp] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [multiplier, setMultiplier] = useState([]);
  const [gettingLicenseData, setGettingLicenseData] = useState(false);
  const [customerLicenseData, setCustomerLicenseData] = useState([]);
  const [filteredCustomerLicenseData, setFilteredCustomerLicenseData] =
    useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchedEmail, setSearchedEmail] = useState(null);

  const [allSubs, setAllSubs] = useState([])

  const getAllSubsForOneUser = () => {
    console.log(`https://comms.globalxchange.io/client/tree/manifest/order?email=${selectedUser?.email}`, selectedUser, "lwkehfwlhflwef")
    axios.get(`https://comms.globalxchange.io/client/tree/manifest/order?email=${selectedUser?.email}`)
      .then(({ data }) => {
        if (data.status) {
          setAllSubs(data?.data)
        }
      })
  }

  const handleCancelSub = (id) => {
    axios.post(`https://comms.globalxchange.io/client/stripe/deleteSubcription`, {
      email: selectedUser?.email,
      client_app: "manifest",
      subscriptionId: id
    }).then(res => {
      console.log(res, "kjwefkjwbfkwjbf")
      getAllSubsForOneUser()
    })
  }

  useEffect(() => {
    if (selectedUser) {
      getAllSubsForOneUser()
    }
  }, [selectedUser])

  const pills = [
    'Users',
    'Purchases',
    'Ranks',
    // 'Payout'
  ];
  const selectedUserActions = [
    {
      actionName: 'See profile',
    },
    {
      actionName: 'Get referral link',
    },
    {
      actionName: 'Initiate forgot password',
    },
    {
      actionName: 'Admin reset password',
    },
    {
      actionName: 'See direct referrals',
    },
    {
      actionName: 'See purchase history',
    },
    {
      actionName: 'See rank history',
    },
    {
      actionName: 'Invite someone on their behalf',
    }, {
      actionName: "See subscriptions"
    }
  ];

  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [dropdownWeek, setDropdownWeek] = useState(null);

  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    setAddProduct(false);
    setAddProductStep(1);
    setBillingType('');
    setSelectedApp('');
    setSelectedCategory('');
    setProductName('');
    setProductCode('');
    setProductLabel('');
    setProductTagline('');
    setProductDescription('');
    setProductImage('');
    setSelectedCurrency('');
    setProductImage('');
    setFirstMonthPrice();
    setMonthlyPrice();
    setGracePeriod();
    setSecondAttempt();
    setThirdAttempt();
    setProductPrice();
  }

  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageChange1 = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPagePurchase(newPage);
    }
  };

  const getUsers = (page) => {
    setLoading(true);
    setAllUsers([]);
    setFilteredUserData([]);
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/user/get/all?page=${page}`
      )
      .then(({ data }) => {
        if (data.status) {
          setAllUsers(data?.data?.native_manifest_users);
          setFilteredUserData(data?.data?.native_manifest_users);
          setTotalPages(data?.data?.affilate_user_metaData?.totalPages);
          setCurrentPage(data?.data?.affilate_user_metaData?.currentPage);
          setLoading(false);
        }
      });
  };

  const [allRanks, setAllRanks] = useState([]);
  const [totalPagesRank, setTotalPagesRank] = useState(null);
  const [currentPageRank, setCurrentPageRank] = useState(null);
  const getAllRanks = (page) => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/get/all/user/profile?not_temp_rank=notApplicable&page=${page}`
      )
      .then(({ data }) => {
        if (data.status) {
          setAllRanks(data.data);
          // setFilteredPurchaseData(data?.data);
          setTotalPagesRank(data?.metadata?.totalPages);
          setCurrentPageRank(data?.metadata?.currentPage);
          setLoading(false);
        }
      });
  };

  const findSearchedUser = () => {
    setLoading(true);
    setAllUsers([]);
    setFilteredUserData([]);
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/user/get/all?email=${searchedEmail}`
      )
      .then(({ data }) => {
        if (data.status) {
          setAllUsers(data?.data?.native_manifest_users);
          setFilteredUserData(data?.data?.native_manifest_users);
          setTotalPages(data?.data?.affilate_user_metaData?.totalPages);
          setCurrentPage(data?.data?.affilate_user_metaData?.currentPage);
          setLoading(false);
        }
      });
  };

  const findSearchedPurchase = () => {
    setLoading(true);
    setAllPurchases([]);
    setFilteredPurchaseData([]);
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/manifest/all/order?email=${searchedEmail}`
      )
      .then(({ data }) => {
        if (data.status) {
          setAllPurchases(data.data);
          setFilteredPurchaseData(data?.data);
          setTotalPagesPurchase(data?.metadata?.totalPages);
          setCurrentPagePurchase(data?.metadata?.currentPage);
          setLoading(false);
        }
      });
  };
  const findSearchedRank = () => {
    setLoading(true);
    setAllRanks([]);
    // setFilteredPurchaseData([]);
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/get/all/user/profile?not_temp_rank=notApplicable&email=${searchedEmail}`
      )
      .then(({ data }) => {
        if (data.status) {
          setAllRanks(data.data);
          // setFilteredPurchaseData(data?.data);
          setTotalPagesRank(data?.metadata?.totalPages);
          setCurrentPageRank(data?.metadata?.currentPage);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (!searchedEmail) {
      getUsers(currentPage ? currentPage : 1);
    }
  }, [currentPage, searchedEmail]);

  const [filteredUserData, setFilteredUserData] = useState([]);

  function filterItem(text) {
    let filterItem = allUsers?.filter((eachitem) => {
      return eachitem?.name?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setFilteredUserData(filterItem);
  }

  const [allPurchases, setAllPurchases] = useState([]);
  const [filteredPurchaseData, setFilteredPurchaseData] = useState([]);
  const [currentPagePurchase, setCurrentPagePurchase] = useState(1);
  const [totalPagesPurchase, setTotalPagesPurchase] = useState(0);
  const getAllPurchases = (page) => {
    setLoading(true);
    setAllPurchases([]);
    setFilteredPurchaseData([]);
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/manifest/all/order?page=${page}`
      )
      .then(({ data }) => {
        if (data.status) {
          setAllPurchases(data.data);
          setFilteredPurchaseData(data?.data);
          setTotalPagesPurchase(data?.metadata?.totalPages);
          setCurrentPagePurchase(data?.metadata?.currentPage);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (mlmTab === 'Purchases' && !searchedEmail) {
      getAllPurchases(currentPagePurchase ? currentPagePurchase : 1);
    }
  }, [mlmTab, currentPagePurchase, searchedEmail]);

  useEffect(() => {
    if (mlmTab === 'Ranks' && !searchedEmail) {
      getAllRanks(currentPageRank ? currentPageRank : 1);
    }
  }, [mlmTab, currentPageRank, searchedEmail]);

  const styles = {
    opacity: '0.25',
    pointerEvents: 'none',
  };
  const applyStyle = (condition) => (condition ? {} : styles);

  function spreadFunc(value) {
    if (value.length > 0) {
      const result = value.reduce((acc, val) => acc && val);
      // console.log(result, 'resultttt');
      return result;
    }
  }

  const handleLevelChange = (event) => {
    const newLevel = parseInt(event.target.value);
    if (newLevel >= 1) {
      setLevels(newLevel);
      setInputValues(Array(newLevel).fill(''));
      setMultiplier(Array(newLevel).fill(''));
    }
  };

  const handleInputChange = (index, event, funcValue, func) => {
    const newInputValues = [...funcValue];
    newInputValues[index] = event.target.value;
    // console.log(newInputValues, 'newInputValues');
    func(newInputValues);
  };

  const renderLevelInputs = (funcValue, func) => {
    return funcValue.map((value, index) => (
      <div className="each-action1" key={index}>
        <div className="partition">
          <div>{index}</div>
          <input
            type="number"
            value={value}
            onChange={(event) =>
              handleInputChange(index, event, funcValue, func)
            }
            placeholder="0.00%"
          />
        </div>
      </div>
    ));
  };

  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess('Copied!');
      setTimeout(() => {
        setCopySuccess('');
      }, 2000);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  const [adminResetPasswordData, setAdminResetPasswordData] = useState(null);
  useEffect(() => {
    if (selectedUser) {
      axios
        .get(
          `https://gxauth.apimachine.com/gx/disabled/users/get?email=${selectedUser?.email}`
        )
        .then(({ data }) => {
          if (data.status) {
            setAdminResetPasswordData(data?.user);
          }
        });
    }
  }, [selectedUser]);

  const [regStep, setRegStep] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loadingResetpassword, setLoadingResetpassword] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const handleResetPassword = () => {
    if (newPassword === confirmPassword) {
      setLoadingResetpassword(true);

      axios
        .post(`https://gxauth.apimachine.com/gx/admin/set/user/password`, {
          token: token,
          userEmail: selectedUser?.email,
          permanent: true,
          password: confirmPassword,
        })
        .then(({ data }) => {
          if (data.status) {
            setLoadingResetpassword(false);
            setRegStep(null);
            setSelectedAction(null);
            setSelectedUser(null);
          } else {
            setLoadingResetpassword(false);
            setRegStep(1);
            setResetPasswordError(data?.message);
          }
        });
    }
  };

  return (
    <Layout active="MLM" className="retailer" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="retailer-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* <div
              className="retailer-hamburger"
              onClick={() => {
                setHamSelected(!hamSelected);
              }}
            >
              <img src={hamSelected ? close1 : hamburger} alt="" />
            </div> */}
            <div className="retailer-icon" style={{ width: '25%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-retailer">
              <div className="im">
                <input
                  style={{ width: '90%' }}
                  autocomplete="off"
                  type="text"
                  placeholder="Search Anything"
                  onChange={(event) => {
                    if (
                      mlmTab === 'Users' ||
                      mlmTab === 'Purchases' ||
                      mlmTab === 'Ranks'
                    ) {
                      // filterItem(event.target.value);
                      setSearchedEmail(event.target.value);
                    }
                  }}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img
                    src={search}
                    alt=""
                    onClick={(e) => {
                      if (mlmTab === 'Users') {
                        findSearchedUser();
                      } else if (mlmTab === 'Purchases') {
                        findSearchedPurchase();
                      } else if (mlmTab === 'Ranks') {
                        findSearchedRank();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-btn"
            onClick={() => {
              setPopularAction(!popularAction);
            }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '35%' }}>
            {pills?.map((item) => (
              <div
                className={`each-option ${mlmTab === item ? 'each-option-selected' : ''
                  }`}
                style={{
                  fontWeight: mlmTab === item ? '600' : '',
                }}
                onClick={() => setMlmTab(item)}
              >
                <div>
                  <p>{item}</p>
                </div>
              </div>
            ))}
            {(mlmTab === 'Ranks' || mlmTab === 'Payout') && (
              <div className="weekDropdown">
                <InputDropdownWeek
                  setFunc={setSelectedWeek}
                  funcValue={selectedWeek}
                  mapValue={Array(52)
                    .fill('')
                    .map((item, index) => `Week ${index + 1}`)}
                  dropdown={dropdownWeek}
                  dropdownFunc={setDropdownWeek}
                  mlmTab={mlmTab}
                />
              </div>
            )}
          </div>
        </div>

        {mlmTab === 'Users' ? (
          <div className="retailer-content1">
            <div className="license-header">
              <div style={{ width: '30%' }}>User</div>
              <div style={{ width: '15%' }}>Upline</div>
              <div style={{ width: '15%' }}>Placed Under</div>
              <div style={{ width: '20%' }}>Date</div>
              <div style={{ width: '15%' }}>Registration Status</div>
              <div style={{ width: '15%' }}>Tree Status</div>
            </div>
            <div className="license-content">
              {loading ? (
                Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="each-license" key={index}>
                        <div className="user-div">
                          <div>
                            <Skeleton width={75} height={20} />
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                            width: '28%',
                          }}
                        >
                          <div>
                            <Skeleton width={40} height={40} borderRadius={5} />
                          </div>
                          <div>
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                        </div>
                        <div className="ProductName-div">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{ width: '12.5%' }}
                        >
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                        <div className="ProductName-div1">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : filteredUserData ? (
                filteredUserData?.map((e, i) => {
                  return (
                    <div
                      className="each-license"
                      key={i}
                      onClick={() => {
                        console.log(e, "lkwefnlkwenflewf")
                        setSelectedUser(e)
                      }}
                    >
                      <div
                        className="ProductName-div"
                        style={{
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center',
                          width: '30%',
                        }}
                      >
                        <div>
                          {e?.profile_img ? (
                            <img
                              src={e?.profile_img}
                              alt=""
                              style={{
                                borderRadius: '50%',
                                backgroundColor: '#e7e7e7',
                              }}
                            />
                          ) : (
                            <img
                              src={avatarImg}
                              alt=""
                              style={{
                                borderRadius: '50%',
                                backgroundColor: '#e7e7e7',
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <div style={{ fontWeight: '600' }}>{e?.name}</div>
                          <div>{e?.email}</div>
                        </div>
                      </div>

                      <div
                        className="ProductName-div"
                        style={{
                          width: '15%',
                        }}
                      >
                        <div
                          className="ellipsis"
                          style={{ fontWeight: '600' }}
                          onClick={() => copyToClipboard(e?.ref_affiliate)}
                        >
                          {e?.ref_affiliate}
                          &nbsp;
                          {copySuccess === '' ? (
                            <FaRegCopy
                              onClick={() => copyToClipboard(e?.ref_affiliate)}
                            />
                          ) : (
                            <FaCheck />
                          )}
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '15%',
                        }}
                      >
                        <div
                          className="ellipsis"
                          style={{ fontWeight: '600' }}
                          onClick={() => copyToClipboard(e?.direct_affiliate)}
                        >
                          {e?.direct_affiliate}
                          &nbsp;
                          {copySuccess === '' ? (
                            <FaRegCopy
                              onClick={() =>
                                copyToClipboard(e?.direct_affiliate)
                              }
                            />
                          ) : (
                            <FaCheck />
                          )}
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '20%',
                        }}
                      >
                        <div style={{ fontWeight: '600' }}>
                          {new Date(e?.date)?.toLocaleString()}
                        </div>
                      </div>
                      <div className="ProductName-div" style={{ width: '15%' }}>
                        <div style={{ fontWeight: '600' }}>--</div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '15%' }}
                      >
                        <div>
                          {e?.legAssigned === 'true' || 'Placed'
                            ? 'Placed'
                            : 'Not Placed'}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ paddingLeft: '50px', paddingTop: '20px' }}>
                  <div>
                    No User found with email: <b>{searchedEmail}</b>
                  </div>
                </div>
              )}
            </div>
            <div className="buttonWrapper1">
              <div
                onClick={() => handlePageChange(Number(currentPage) - 1)}
                className={
                  Number(currentPage) === 1
                    ? 'buttonStyleDisabled'
                    : 'buttonStyle'
                }
              >
                Previous
              </div>
              <div style={{ fontWeight: '700' }}>
                Page {currentPage}/{totalPages}
              </div>
              <div
                onClick={() => handlePageChange(Number(currentPage) + 1)}
                className={
                  Number(currentPage) === totalPages
                    ? 'buttonStyleDisabled'
                    : 'buttonStyle'
                }
              >
                Next
              </div>
            </div>
          </div>
        ) : mlmTab === 'Purchases' ? (
          <div className="retailer-content1">
            <div className="license-header">
              <div style={{ width: '30%' }}>User</div>
              <div style={{ width: '15%' }}>Week</div>
              <div style={{ width: '20%' }}>Date</div>
              <div style={{ width: '25%' }}>Product</div>
              <div style={{ width: '15%' }}>Amount</div>
              <div style={{ width: '15%' }}>Status</div>
              <div style={{ width: '15%' }}>Invoice</div>
            </div>
            <div className="license-content">
              {loading ? (
                Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="each-license" key={index}>
                        <div className="user-div">
                          <div>
                            <Skeleton width={75} height={20} />
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                            width: '28%',
                          }}
                        >
                          <div>
                            <Skeleton width={40} height={40} borderRadius={5} />
                          </div>
                          <div>
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                        </div>
                        <div className="ProductName-div">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{ width: '12.5%' }}
                        >
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                        <div className="ProductName-div1">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                        <div className="ProductName-div1">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : filteredPurchaseData ? (
                filteredPurchaseData?.map((e, i) => {
                  return (
                    <div
                      className="each-license"
                      key={i}
                    // onClick={() => setSelectedUser(e)}
                    >
                      <div
                        className="ProductName-div"
                        style={{
                          width: '30%',
                        }}
                      >
                        <div>{e?.email ? e?.email : '--'}</div>
                      </div>

                      <div
                        className="ProductName-div"
                        style={{
                          width: '15%',
                          fontWeight: 600,
                        }}
                      >
                        <div>{e?.payment[0]?.week}</div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '20%',
                        }}
                      >
                        {e?.payment[0]?.timestamp ? (
                          <div style={{ fontWeight: '600' }}>
                            {new Date(
                              e?.payment[0]?.timestamp
                            )?.toLocaleString()}
                          </div>
                        ) : (
                          <div>--</div>
                        )}
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '25%',
                        }}
                      >
                        <div
                          className="ellipsis1"
                          style={{ fontWeight: '600' }}
                          onClick={() => copyToClipboard(e?.direct_affiliate)}
                        >
                          {
                            e?.payment[0]?.invoiceObject?.lines?.data[0]
                              ?.description
                          }
                          &nbsp;
                          {/* {copySuccess === '' ? (
                            <FaRegCopy
                              onClick={() =>
                                copyToClipboard(e?.direct_affiliate)
                              }
                            />
                          ) : (
                            <FaCheck />
                          )} */}
                        </div>
                      </div>
                      <div className="ProductName-div" style={{ width: '15%' }}>
                        <div style={{ fontWeight: '600' }}>
                          {e?.payment[0]?.amount}&nbsp;
                          {e?.payment[0]?.currency?.toUpperCase()}
                        </div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '15%' }}
                      >
                        <div>--</div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '15%' }}
                      >
                        {e?.payment[0]?.invoiceObject?.hosted_invoice_url ? (
                          <div>
                            <a
                              style={{
                                color: 'red',
                                textDecoration: 'underline',
                              }}
                              href={
                                e?.payment[0]?.invoiceObject?.hosted_invoice_url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Link
                            </a>
                          </div>
                        ) : (
                          '--'
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ paddingLeft: '50px', paddingTop: '20px' }}>
                  <div>
                    No User found with email: <b>{searchedEmail}</b>
                  </div>
                </div>
              )}
            </div>

            {mlmTab === 'Purchases' && (
              <div className="buttonWrapper1">
                <div
                  onClick={() =>
                    handlePageChange1(Number(currentPagePurchase) - 1)
                  }
                  className={
                    Number(currentPagePurchase) === 1
                      ? 'buttonStyleDisabled'
                      : 'buttonStyle'
                  }
                >
                  Previous
                </div>
                <div style={{ fontWeight: '700' }}>
                  Page {currentPagePurchase}/{totalPagesPurchase}
                </div>
                <div
                  onClick={() =>
                    handlePageChange1(Number(currentPagePurchase) + 1)
                  }
                  className={
                    Number(currentPagePurchase) === totalPagesPurchase
                      ? 'buttonStyleDisabled'
                      : 'buttonStyle'
                  }
                >
                  Next
                </div>
              </div>
            )}
          </div>
        ) : mlmTab === 'Ranks' ? (
          <div className="retailer-content1">
            <div className="license-header">
              <div style={{ width: '30%' }}>User</div>
              <div style={{ width: '15%' }}>Week</div>
              <div style={{ width: '15%' }}>Rank</div>
              <div style={{ width: '20%' }}>Left Leg CV</div>
              <div style={{ width: '20%' }}>Right Leg CV</div>
              <div style={{ width: '20%' }}>Left Leg Directs</div>
              <div style={{ width: '20%' }}>Right Leg Directs</div>
              <div style={{ width: '15%' }}>Activity</div>
            </div>
            <div className="license-content">
              {loading
                ? Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="each-license" key={index}>
                        <div className="user-div">
                          <div>
                            <Skeleton width={75} height={20} />
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                            width: '28%',
                          }}
                        >
                          <div>
                            <Skeleton
                              width={40}
                              height={40}
                              borderRadius={5}
                            />
                          </div>
                          <div>
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                        </div>
                        <div className="ProductName-div">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{ width: '12.5%' }}
                        >
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                        <div className="ProductName-div1">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })
                : allRanks?.map((item, i) => {
                  return (
                    <div
                      className="each-license"
                      key={i}
                      onClick={() => setSelectedUser(item)}
                    >
                      <div
                        className="ProductName-div"
                        style={{
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center',
                          width: '30%',
                        }}
                      >
                        {/* <div>
                            {e?.profile_img ? (
                              <img
                                src={e?.profile_img}
                                alt=""
                                style={{
                                  borderRadius: '50%',
                                  backgroundColor: '#e7e7e7',
                                }}
                              />
                            ) : (
                              <img
                                src={avatarImg}
                                alt=""
                                style={{
                                  borderRadius: '50%',
                                  backgroundColor: '#e7e7e7',
                                }}
                              />
                            )}
                          </div> */}
                        <div>
                          <div style={{ fontWeight: '600' }}>
                            {item?.name}
                          </div>
                          {item?.email?.length > 28 ? (
                            <div>{item?.email?.substring(0, 28)}...</div>
                          ) : (
                            <div>{item?.email}</div>
                          )}
                        </div>
                      </div>

                      <div
                        className="ProductName-div"
                        style={{
                          width: '15%',
                        }}
                      >
                        <div
                          className="ellipsis"
                          style={{ fontWeight: '600' }}
                        // onClick={() => copyToClipboard(e?.ref_affiliate)}
                        >
                          35
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '15%',
                        }}
                      >
                        <div
                          className="ellipsis"
                          style={{ fontWeight: '600' }}
                          onClick={() =>
                            copyToClipboard(item?.direct_affiliate)
                          }
                        >
                          {item?.temp_rank?.charAt(0).toUpperCase() +
                            item?.temp_rank?.slice(1)}
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '20%',
                        }}
                      >
                        <div style={{ fontWeight: '600' }}>
                          {' '}
                          {item?.temp_left_cv_value}
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{ width: '20%' }}
                      >
                        <div style={{ fontWeight: '600' }}>
                          {item?.temp_right_cv_value}
                        </div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '20%' }}
                      >
                        <div>--</div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '20%' }}
                      >
                        <div>--</div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '15%' }}
                      >
                        <div>
                          {item?.status?.charAt(0).toUpperCase() +
                            item?.status?.slice(1)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="buttonWrapper1">
              <div
                onClick={() => handlePageChange(Number(currentPage) - 1)}
                className={
                  Number(currentPageRank) === 1
                    ? 'buttonStyleDisabled'
                    : 'buttonStyle'
                }
              >
                Previous
              </div>
              <div style={{ fontWeight: '700' }}>
                Page {currentPageRank}/{totalPagesRank}
              </div>
              <div
                onClick={() => handlePageChange(Number(currentPageRank) + 1)}
                className={
                  Number(currentPageRank) === totalPagesRank
                    ? 'buttonStyleDisabled'
                    : 'buttonStyle'
                }
              >
                Next
              </div>
            </div>
          </div>
        ) : mlmTab === 'Payout' ? (
          <div className="retailer-content1">
            <div className="license-header">
              <div style={{ width: '30%' }}>User</div>
              <div style={{ width: '15%' }}>Week</div>
              <div style={{ width: '15%' }}>Rank</div>
              <div style={{ width: '20%' }}>Rank Payout</div>
              <div style={{ width: '20%' }}>Fast Start Bonus</div>
              <div style={{ width: '20%' }}>Total Payable</div>
              <div style={{ width: '20%' }}>Status</div>
            </div>
            <div className="license-content">
              {loading
                ? Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="each-license" key={index}>
                        <div className="user-div">
                          <div>
                            <Skeleton width={75} height={20} />
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                            width: '28%',
                          }}
                        >
                          <div>
                            <Skeleton
                              width={40}
                              height={40}
                              borderRadius={5}
                            />
                          </div>
                          <div>
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                        </div>
                        <div className="ProductName-div">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{ width: '12.5%' }}
                        >
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                        <div className="ProductName-div1">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })
                : filteredUserData?.map((e, i) => {
                  return (
                    <div
                      className="each-license"
                      key={i}
                      onClick={() => setSelectedUser(e)}
                    >
                      <div
                        className="ProductName-div"
                        style={{
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center',
                          width: '30%',
                        }}
                      >
                        <div>
                          {e?.profile_img ? (
                            <img
                              src={e?.profile_img}
                              alt=""
                              style={{
                                borderRadius: '50%',
                                backgroundColor: '#e7e7e7',
                              }}
                            />
                          ) : (
                            <img
                              src={avatarImg}
                              alt=""
                              style={{
                                borderRadius: '50%',
                                backgroundColor: '#e7e7e7',
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <div style={{ fontWeight: '600' }}>Name</div>
                          <div>email</div>
                        </div>
                      </div>

                      <div
                        className="ProductName-div"
                        style={{
                          width: '15%',
                        }}
                      >
                        <div
                          className="ellipsis"
                          style={{ fontWeight: '600' }}
                        // onClick={() => copyToClipboard(e?.ref_affiliate)}
                        >
                          35
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '15%',
                        }}
                      >
                        <div
                          className="ellipsis"
                          style={{ fontWeight: '600' }}
                          onClick={() => copyToClipboard(e?.direct_affiliate)}
                        >
                          Bronze
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          width: '20%',
                        }}
                      >
                        <div style={{ fontWeight: '600' }}>--</div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{ width: '20%' }}
                      >
                        <div style={{ fontWeight: '600' }}>--</div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '20%' }}
                      >
                        <div>--</div>
                      </div>
                      <div
                        className="ProductName-div1"
                        style={{ width: '20%' }}
                      >
                        <div>--</div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="buttonWrapper1">
              <div
                onClick={() => handlePageChange(Number(currentPage) - 1)}
                className={
                  Number(currentPage) === 1
                    ? 'buttonStyleDisabled'
                    : 'buttonStyle'
                }
              >
                Previous
              </div>
              <div style={{ fontWeight: '700' }}>
                Page {currentPage}/{totalPages}
              </div>
              <div
                onClick={() => handlePageChange(Number(currentPage) + 1)}
                className={
                  Number(currentPage) === totalPages
                    ? 'buttonStyleDisabled'
                    : 'buttonStyle'
                }
              >
                Next
              </div>
            </div>
          </div>
        ) : (
          <div className="retailer-content1">
            <div className="license-header">
              <div style={{ width: '22%' }}>User</div>
              <div style={{ width: '28%' }}>Product</div>
              <div>License</div>
              <div style={{ width: '12.5%' }}>Marketplace</div>
              <div style={{ width: '12.5%' }}>Status</div>
            </div>
            <div className="license-content">
              {gettingLicenseData
                ? Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="each-license" key={index}>
                        <div className="user-div">
                          <div>
                            <Skeleton width={75} height={20} />
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                            width: '28%',
                          }}
                        >
                          <div>
                            <Skeleton
                              width={40}
                              height={40}
                              borderRadius={5}
                            />
                          </div>
                          <div>
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                        </div>
                        <div className="ProductName-div">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={120} height={20} />
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{ width: '12.5%' }}
                        >
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                        <div className="ProductName-div1">
                          <div>
                            <Skeleton width={75} height={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })
                : filteredCustomerLicenseData?.map((e, i) => {
                  return (
                    <div className="each-license" key={i}>
                      <div className="user-div">
                        <div>
                          <div style={{ fontWeight: '600' }}>{e?.name}</div>
                          <div>{e?.email}</div>
                        </div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center',
                          width: '28%',
                        }}
                      >
                        <div>
                          <img src={e?.product_icon} alt="" />
                        </div>
                        <div>
                          <div style={{ fontWeight: '600' }}>
                            {e?.product_name}
                          </div>
                          <div>{e?.product_id}</div>
                        </div>
                      </div>
                      <div className="ProductName-div">
                        <div style={{ fontWeight: '600' }}>
                          {e?.license_id}
                        </div>
                        <div>{e?.license_code}</div>
                      </div>
                      <div
                        className="ProductName-div"
                        style={{ width: '12.5%' }}
                      >
                        <div style={{ fontWeight: '600' }}>--</div>
                        <div>{e?.client_app}</div>
                      </div>
                      <div className="ProductName-div1">
                        <div>
                          {e?.status === 'true' || 'active'
                            ? 'Active'
                            : 'Inactive'}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {popularAction && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setPopularAction(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setPopularAction(false);
                setAddProduct(true);
              }}
            >
              <div>
                <img src={plus} alt="" />
              </div>
              <div>Add New Product</div>
            </div>
          </div>
        )}
        {selectedUser && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setSelectedUser(null);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div style={{ height: '90vh', overflowY: 'scroll' }}>
              {selectedUserActions?.map((item) => (
                <div
                  className="each-action-user"
                  onClick={() => {
                    setPopularAction(false);
                    setSelectedAction(item?.actionName);
                    //   setAddProduct(true);
                  }}
                >
                  {/* <div>
                    <img src={plus} alt="" />
                  </div> */}
                  <div>{item?.actionName}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        {selectedAction === 'Get referral link' && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setSelectedUser(null);
                  setSelectedAction(null);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div style={{ height: '85vh', overflowY: 'scroll' }}>
              <div className="each-action-user">
                <div className="ellipsis_reflink">{`https://app.manifest.family/register/affiliate/${selectedUser?.username}`}</div>
              </div>
            </div>
            <div className="rowBtn">
              <div className="redBtn">Edit Link</div>
              <div
                className="blackBtn"
                onClick={(e) =>
                  copyToClipboard(
                    `https://app.manifest.family/register/affiliate/${selectedUser?.username}`
                  )
                }
              >
                {copySuccess ? 'Copied..' : 'Copy Link'}
              </div>
            </div>
          </div>
        )}
        {selectedAction === 'Admin reset password' && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setSelectedUser(null);
                  setSelectedAction(null);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div style={{ height: '85vh', overflowY: 'scroll' }}>
              <div style={{ paddingBottom: '20px' }}>
                <div>Username</div>
                <div className="each-action-user">
                  <div className="ellipsis_reflink">
                    {adminResetPasswordData?.Username}
                  </div>
                </div>
              </div>
              <div style={{ paddingBottom: '20px' }}>
                <div>Login status</div>
                <div className="each-action-user">
                  <div className="ellipsis_reflink">
                    {adminResetPasswordData?.Enabled?.toString()}
                  </div>
                </div>
              </div>
              <div style={{ paddingBottom: '20px' }}>
                <div>Email verification status</div>
                <div className="each-action-user">
                  <div className="ellipsis_reflink">
                    {
                      adminResetPasswordData?.UserAttributes?.find(
                        (o) => o.Name === 'email_verified'
                      )?.Value
                    }
                  </div>
                </div>
              </div>
              <div style={{ paddingBottom: '20px' }}>
                <div>Password status</div>
                <div className="each-action-user">
                  <div className="ellipsis_reflink">
                    {adminResetPasswordData?.UserStatus}
                  </div>
                </div>
              </div>
            </div>
            <div className="rowBtn">
              <div
                className="blackBtn"
                style={{ width: '100%' }}
                onClick={(e) => setRegStep(1)}
              >
                Admin reset
              </div>
            </div>
          </div>
        )}
        {selectedAction === 'Admin reset password' && regStep === 1 && (
          <div className="popular">
            <div className="head-txt">
              <div>Actions</div>
              <div
                onClick={() => {
                  setSelectedUser(null);
                  setSelectedAction(null);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div style={{ height: '85vh', overflowY: 'scroll' }}>
              <form autoComplete="off">
                <div style={{ paddingBottom: '20px' }}>
                  <div>Enter new password</div>
                  <div className="each-action-input">
                    <input
                      autoFocus
                      autocomplete="off"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ paddingBottom: '20px' }}>
                  <div>Confirm password</div>
                  <div className="each-action-input">
                    <input
                      autocomplete="off"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
              </form>
              {resetPasswordError && <div>Error: {resetPasswordError}</div>}
            </div>
            <div className="rowBtn">
              <div className="redBtn" onClick={(e) => setRegStep(null)}>
                Go Back
              </div>
              <div
                className="blackBtn"
                style={{
                  opacity:
                    newPassword === confirmPassword && confirmPassword !== ''
                      ? 1
                      : 0.5,
                }}
                onClick={handleResetPassword}
              >
                Submit
              </div>
            </div>
          </div>
        )}

        {selectedAction === 'Admin reset password' &&
          regStep === 1 &&
          loadingResetpassword && (
            <div
              className="popular"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingAnimation icon={loadingLogo} width={200} />
            </div>
          )}

        {selectedAction === 'See subscriptions' && (
          <div className="popular">
            <div className="head-txt">
              <div>Actions</div>
              <div
                onClick={() => {
                  setSelectedUser(null);
                  setSelectedAction(null);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div>All subscriptions</div>
            {allSubs?.map(item => (
              <div className="each-action-user" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div style={{ fontWeight: 700, paddingBottom: '7px' }}>{item?.items?.data[0]?.plan?.amount} {item?.items?.data[0]?.plan?.currency?.toUpperCase()} Per {item?.items?.data[0]?.plan?.interval}</div>
                  <div>Created at {new Date(item?.items?.data[0]?.plan?.created)?.toLocaleDateString()}</div>
                </div>
                <div className='cardBtn' onClick={e => handleCancelSub(item?.id)}>
                  Cancel
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MLMPage;

export const ImageUploadDiv = ({ heading, setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{ width: '100%', justifyContent: 'space-between' }}
    >
      <label htmlFor="profileUpdateImgPlanB" className="uploadFileDiv">
        <input
          className="uploadNewPicPlanB"
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div style={{ fontWeight: '500' }}>
          {planBAccountPicUploading ? 'Uploading...' : 'Upload Profile Image'}
        </div>
      </label>
      <div className="imageDiv" style={{ marginRight: '0' }}>
        <img
          src={funcValue ? funcValue : dummy}
          alt="planBAccountPic"
          className="profileImg"
          htmlFor="profileUpdateImgPlanB"
          style={{ width: '90px', height: '90px' }}
        />
        {planBAccountPicUploading ? (
          <div className="overlayDiv">
            <img src={uploading} alt="uploading" className="uploadingimg" />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
