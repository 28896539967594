import React, { useEffect } from 'react';
import Layout from '../../Layout/Layout';
import './affiliate.scss';
import fulllogo from '../../static/images/crm/fullLogo.svg';
import search from '../../static/images/malls/search.svg';
import ablogo from '../../static/images/affiliate/ablogo.svg';
import close from '../CRMPage/close.svg';
import plus from '../CRMPage/plus.svg';
import heart from '../CRMPage/heart.svg';
import edit from '../CRMPage/edit.svg';
import withdraw from '../CRMPage/withdraw.svg';
import leftarr from '../../static/images/affiliate/leftarr.svg';
import guest from '../../static/images/guest.jpg';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { affiliateBalances, levelBasedData, allUsers } from './api';
import axios from 'axios';
import vvLogo from '../../static/images/logos/vv.svg';
import Withdraw from '../AffiliatePage/Withdraw';
import { JsonWebTokenError } from 'jsonwebtoken';

const Leveldata = () => {
  const { email, username, name, profileImg, linkname, token } =
    useContext(BankContext);
  const {
    affiliateTab,
    setaffiliateTab,
    levelData,
    setLevelData,
    balances,
    setBalances,
    showleveldata,
    setShowLevelData,
    showleveldata1,
    setShowLevelData1,
    leveltab,
    setLevelTab,
    upline,
    popular,
    setPopular,
    iswithdraw,
    setIswithdraw,
    //update user name
    newUserNameSidebar,
    setnewUserNameSidebar,
    invite,
    setInvite,
    setAdduser,
    affiliateid,
    setAllaffiliatedata,
    setuserfilteredAff,
    updatingUsername,
    setupdatingUsername,
    newUserName,
    usernameExist,
    setnewUserName,
  } = useAppContextDetails();
  const history = useHistory();

  const [crmTab, setCrmTab] = useState('Overview');
  const [filterList, setFilterList] = useState([]);

  if (!email) {
    history.push('/');
  }

  useEffect(() => {
    let product = localStorage.getItem('leveldata');
    setShowLevelData(JSON.parse(product));
    setFilterList(JSON.parse(product));

    let level = localStorage.getItem('level');
    setShowLevelData1(JSON.parse(level));

    let mailId = email;

    affiliateBalances(mailId).then((response) => {
      let result = response?.data;
      setBalances(result);
    });
  }, []);

  const handleChange = (event) => {
    setnewUserName(event.target.value);
  };

  const affiliateData = () => {
    let obj = {
      affiliate_id: affiliateid,
    };
    allUsers(obj).then((response) => {
      let result = response?.data;
      setAllaffiliatedata(result);
      setuserfilteredAff(result);
    });
  };

  function usernameUpdate() {
    setupdatingUsername(true);
    axios
      .post('https://comms.globalxchange.io/user/details/update', {
        email: email,
        token: token,
        username: newUserName,
      })
      .then((response) => {
        // console.log(response, 'updated username');
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log('error checking username', error?.message);
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      });
  }

  function filterItem(searchText) {
    let filteredRes = showleveldata?.filter((eachplan) => {
      return (
        eachplan?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        eachplan?.email?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
    });
    setFilterList(filteredRes);
  }

  return (
    <Layout active="affiliate" className="affiliate" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="affiliate-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="affiliate-icon" style={{ width: '18%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-affiliate" style={{ width: '77%' }}>
              <div className="im">
                <input
                  type="text"
                  placeholder="Im Looking For..."
                  onChange={(event) => filterItem(event.target.value)}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={upline?.profile_img ? upline?.profile_img : guest}
                alt=""
                style={{ height: '50px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{upline?.name}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '45px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>
                  $
                  {balances?.gxbroker_balance
                    ? balances?.gxbroker_balance?.toFixed(2)
                    : '0.00'}
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-btn"
            style={{ background: '#18191d' }}
            onClick={() => {
              setPopular(!popular);
            }}
          >
            Popular Actions
          </div>
        </div>
        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '10%' }}>
            <div
              className={`each-option ${
                crmTab === 'Overview' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: crmTab === 'Overview' ? '600' : '',
              }}
              onClick={() => setCrmTab('Overview')}
            >
              <div>
                <p>Overview</p>
              </div>
            </div>
          </div>
        </div>

        <div className="levell">
          <div
            style={{ display: 'flex', flexDirection: 'row' }}
            className="backarrow"
          >
            <img
              src={leftarr}
              alt=""
              style={{ height: '100%' }}
              onClick={() => history.push('/crm')}
            />
          </div>
          <div style={{ width: '20%', alignItems: 'flex-start' }}>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              DDLevel <span>{showleveldata1?.level}</span>
            </p>
            <p style={{ fontSize: '0.8rem' }}>
              Users: <span>{showleveldata1?.count}</span>
            </p>
          </div>
          <div style={{ width: '12%' }}>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              --
            </p>
            <p style={{ fontSize: '0.8rem' }}>Customers</p>
          </div>
          <div style={{ width: '12%' }}>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              --
            </p>
            <p style={{ fontSize: '0.8rem' }}>Affiliates</p>
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              ${showleveldata1?.total_revenue?.toFixed(2)}
            </p>
            <p style={{ fontSize: '0.8rem' }}>Volume</p>
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              ${showleveldata1?.total_earnings?.toFixed(2)}
            </p>
            <p style={{ fontSize: '0.8rem' }}>My Revenue</p>
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              {showleveldata1?.total_count_log}
            </p>
            <p style={{ fontSize: '0.8rem' }}>Revenue Txn's</p>
          </div>
        </div>
        <div className="levell-header">
          <div style={{ width: '22%' }}>Name</div>
          <div style={{ width: '12%' }}>Customer</div>
          <div style={{ width: '12%' }}>Affiliate</div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            Volume
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            My Revenue
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            Revenue Txn’s
          </div>
        </div>
        <div className="overall-lvl">
          {filterList?.map((e, i) => {
            return (
              <div className="levell-data" key={i}>
                <div style={{ display: 'flex', gap: '10px', width: '22%' }}>
                  <img
                    src={e?.profile_img}
                    alt=""
                    style={{
                      height: '50px',
                      width: '50px',
                      borderRadius: '50%',
                    }}
                  />
                  <div>
                    <p
                      style={{
                        fontSize: '1.15rem',
                        fontWeight: '600',
                        color: '#050505',
                      }}
                    >
                      {e?.name}
                    </p>
                    <p>{e?.email}</p>
                  </div>
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#050505',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  --
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#050505',
                  }}
                >
                  {e?.broker_license_active === true ? 'Active' : 'Inactive'}
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#18191D',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  ${e?.comData?.total_revenue?.toFixed(2)}
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#18191D',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  ${e?.comData?.total_earnings?.toFixed(2)}
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#18191D',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {e?.comData?.total_count_log}
                </div>
              </div>
            );
          })}
        </div>
        {popular && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setPopular(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              style={{ marginTop: '2.75rem' }}
              onClick={() => {
                setPopular(false);
                setIswithdraw(true);
              }}
            >
              <div>
                <img src={withdraw} alt="" />
              </div>
              <div>Withdraw Commissions</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setPopular(false);
                setnewUserNameSidebar(true);
              }}
            >
              <div>
                <img src={edit} alt="" />
              </div>
              <div>Update Username</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setPopular(false);
                setAdduser(true);
                if (affiliateid) {
                  affiliateData();
                }
              }}
            >
              <div>
                <img src={plus} alt="" />
              </div>
              <div>Pre Register A Prospect</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setPopular(false);
                setInvite(true);
              }}
            >
              <div>
                <img src={heart} alt="" />
              </div>
              <div>Pre Register With A Gift</div>
            </div>
          </div>
        )}
        {updatingUsername ? (
          <div
            className="editUsername usernameLoading"
            style={{ right: newUserNameSidebar ? '' : '-150%' }}
          >
            <img src={vvLogo} alt="Logo" className="affiliateLogo" />
            <div className="updatingName">
              <span>Updating Username To</span>
              <span>{'  '} </span>
              <span> {newUserName}</span>
            </div>
          </div>
        ) : (
          <div
            className="editUsername"
            style={{ right: newUserNameSidebar ? '' : '-150%' }}
          >
            <div className="heading">
              <span>Update Username</span>
              <div onClick={() => setnewUserNameSidebar(false)}>X</div>
            </div>
            <div className="otherFields">
              <div>Current Username</div>
              <div>
                <input
                  value={linkname ? linkname : ''}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </div>
            <div className="otherFields">
              <div>New Username</div>
              <div>
                <input
                  placeholder="Enter Username"
                  type="text"
                  value={newUserName}
                  onChange={handleChange}
                />
                <div
                  className="usernameIndicator"
                  style={{ background: usernameExist ? '#57D6A1' : '' }}
                ></div>
              </div>
            </div>
            <div
              className="confirmBtn"
              style={{
                pointerEvents: usernameExist ? '' : 'none',
                opacity: usernameExist ? '' : '0.4',
              }}
              onClick={usernameUpdate}
            >
              Confirm Update
            </div>
          </div>
        )}
        <div
          className="adduserSidebar"
          style={{ right: iswithdraw ? '0' : '-100%' }}
        >
          <Withdraw />
        </div>
      </div>
    </Layout>
  );
};

export default Leveldata;
