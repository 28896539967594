import bondDebuntures from '../static/images/assetClasses/bondDebuntures.svg';
import cryptoCurrencies from '../static/images/assetClasses/cryptoCurrencies.svg';
import forexCurrencies from '../static/images/assetClasses/forexCurrencies.svg';
import moneyMarkets from '../static/images/assetClasses/moneyMarkets.svg';
import sharesOffering from '../static/images/assetClasses/sharesOffering.svg';
import bonds from '../static/images/login/bond.svg';
import bondMarkets from '../static/images/assetClasses/bondMarkets.svg';
import stakingVaults from '../static/images/assetClasses/stakingVaults.svg';

import marketsChat from '../static/images/marketsSidebar/marketsChat.svg';
import marketWatchers from '../static/images/marketsSidebar/marketWatchers.svg';
import stream from '../static/images/marketsSidebar/stream.svg';
import trending from '../static/images/marketsSidebar/trending.svg';
import terminals from '../static/images/marketsSidebar/terminals.svg';
import terminalsFull from '../static/images/marketsSidebar/terminalsFull.svg';
import investorSocialFull from '../static/images/marketsSidebar/investorSocialFull.svg';
import marketsChatFull from '../static/images/marketsSidebar/marketsChatFull.svg';
import marketWatchersFull from '../static/images/marketsSidebar/marketWatchersFull.svg';
import tradeStreamFull from '../static/images/marketsSidebar/tradeStreamFull.svg';

export const ASSET_CLASSES = [
  {
    name: 'crypto',
    icon: cryptoCurrencies,
    label: 'Cryprocurrency',
    marketOrder: 0,
    marketEnable: true,
    aboutOrder: 1,
  },
  {
    name: 'fiat',
    icon: forexCurrencies,
    label: 'Fiat Currency',
    marketOrder: 6,
    marketEnable: true,
    aboutOrder: 2,
  },
  {
    name: 'moneyMarkets',
    icon: moneyMarkets,
    label: 'MoneyMarkets',
    marketOrder: 2,
    marketEnable: true,
    aboutOrder: 5,
  },
  {
    name: 'bonds',
    icon: bondDebuntures,
    label: 'Bonds',
    marketOrder: 3,
    marketEnable: true,
    aboutOrder: 6,
  },
  {
    name: 'shares',
    icon: sharesOffering,
    label: 'Shares',
    marketOrder: 1,
    marketEnable: true,
    aboutOrder: 7,
  },
];

export const MARKET_ASSET_CLASSES = [
  // {
  //   name: 'crypto',
  //   icon: cryptoCurrencies,
  //   label: 'Cryprocurrency',
  //   marketOrder: 0,
  //   marketEnable: true,
  //   aboutOrder: 1,
  // },
  // {
  //   name: 'fiat',
  //   icon: forexCurrencies,
  //   label: 'Fiat Currency',
  //   marketOrder: 6,
  //   marketEnable: true,
  //   aboutOrder: 2,
  // },
  {
    name: 'bonds',
    icon: bondMarkets,
    label: 'Bonds',
    marketOrder: 1,
    marketEnable: true,
    aboutOrder: 6,
    newName: 'Staking Contracts'
  },
  {
    name: 'moneyMarkets',
    icon: moneyMarkets,
    label: 'MoneyMarkets',
    marketOrder: 2,
    marketEnable: true,
    aboutOrder: 5,
    newName: 'MoneyMarkets'
  },
  // {
  //   name: 'Defi Markets',
  //   marketOrder: 3,
  //   marketEnable: false,
  //   newName: 'Defi Markets'
  // },
  // {
  //   name: 'Defi Routing',
  //   marketOrder: 4,
  //   marketEnable: false,
  //   newName: 'Defi Routing'
  // },
  {
    name: 'myContracts',
    marketOrder: 3,
    marketEnable: true,
    newName: 'My Contracts'
  },
  {
    name: 'stakingVaults',
    icon: stakingVaults,
    label: 'StakingVaults',
    marketOrder: 4,
    marketEnable: false,
    aboutOrder: 5,
    newName: 'Build Your Bond'
  },
  // {
  //   name: 'shares',
  //   icon: sharesOffering,
  //   label: 'Shares',
  //   marketOrder: 1,
  //   marketEnable: true,
  //   aboutOrder: 7,
  // },
];

export const SWAP_ASSET_CLASSES = [
  {
    name: 'crypto',
    icon: cryptoCurrencies,
    label: 'Cryprocurrency',
    marketOrder: 0,
    marketEnable: true,
    aboutOrder: 1,
  },
  {
    name: 'fiat',
    icon: forexCurrencies,
    label: 'Fiat Currency',
    marketOrder: 1,
    marketEnable: true,
    aboutOrder: 2,
  },
];

export const SIDEBAR_FOOTERS = [
  {
    icon: trending,
    label: 'Trending',
    fullLogo: investorSocialFull,
  },
  {
    icon: marketWatchers,
    label: 'MarketWatchers',
    fullLogo: marketWatchersFull,
  },
  {
    icon: bonds,
    label: 'Bonds',
    fullLogo: bondDebuntures,
  },
  {
    icon: terminals,
    label: 'Terminals',
    fullLogo: terminalsFull,
  },
  {
    icon: stream,
    label: 'Stream',
    fullLogo: tradeStreamFull,
  },
];

export function updateScroll() {
  var element = document.getElementById('preventScroll');
  element.scrollTop = 0;
}
