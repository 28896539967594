import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import arrowUp from '../../static/images/clipIcons/arrowUp.svg';
import arrowDown from '../../static/images/clipIcons/arrowDown.svg';
import { useCoinContextData } from '../../context/CoinContext';
import { useAppContextDetails } from '../../context/AppContext';
import { VaultTypes } from '../VaultsPage/Vaults/VaultTypes';
import { VaultLoadingCards } from '../VaultsPage/Vaults/VaultLoadingCards';
import { BankContext } from '../../context/Context';
import { useLoadContractData } from '../VaultsPage/Vaults/api';

export const ListData = ({
  isLoading,
  searchedValue,
  coinsData,
  moneyMarketData,
  bondData,
  bankingData,
  vaultTypeResponse,
}) => {
  const history = useHistory();
  const { appCode, appCurrencyName } = useAppContextDetails();
  const {
    coinActionEnabled,
    setCoinActionEnabled,
    coinType,
    setCoinType,
    setSelectedCoin,
    selectedCoin,
    setCoinAction,
    setAddActionStep,
    setStep: setWithdrawActionStep,
    setTransferActionStep,
    setTransferMethod,
    setFundingCurrency,
    setPayMethod,
    setOtcDesk,
    setEnterAmount,
    setCurrentStep,
    setSelectedTab,
    setAddApiValue,
    setToCurrencyApiValue,
    setCountryApiValue,
    setPayMethodApiValue,
    setOtcApiValue,
    setEachCardShowValue,
    newWholeValue,
    setNewWholeValue,
    setWithdrawForexEnteredAmount,
    setWithdrawForexStep,
    setWithdrawForexCurrency,
    setWithdrawForexCountry,
    setWithdrawForexPaymentMethod,
    setWithdrawForexOtcDesk,
    setWithdrawForexAccountId,
    setWithdrawForexAccountName,
    setAllApiData,
    setForexQuote,
    setCheckedOneForex,
    setOtpForex,
    setUpdatedForexBalance,
    setAddressTerm,
    setFromTerm,
    setToTerm,
    senbtnClicked,
    setSendBtnClicked,
  } = useCoinContextData();

  const { email, sidebarCollapse, setSidebarCollapse } =
    useContext(BankContext);
  const { data: contractResponse, isLoading: isContractsLoading } =
    useLoadContractData(email);

  const filteredData = (item) =>
    item
      ? Object.values(item)
          ?.join(' ')
          ?.toLowerCase()
          ?.includes(searchedValue.toLowerCase())
      : [];

  const bankingFilter = (data) => {
    const filterValueArray = [
      data?.account_name,
      data?.bank_account_id,
      data?.paymentMethod_data?.name,
      data?.currency,
      data?.instituteData?.institute_name,
      data?.countryData?.name,
    ];
    return filterValueArray.some((item) =>
      item?.toLowerCase()?.includes(searchedValue?.toLowerCase())
    );
  };

  const vaultData = () => {
    switch (coinType) {
      case 'crypto':
      case 'fiat':
        return coinsData;
      case 'bonds':
        return bondData;
      case 'moneyMarkets':
        return moneyMarketData;
    }
  };

  const isCryptoOrFx = coinType === 'crypto' || coinType === 'fiat';
  const isBonds = coinType === 'bonds';
  const isMoneyMarkets = coinType === 'moneyMarkets';
  const isBanking = window.location.pathname === '/banking';
  const isVault =
    window.location.pathname === '/vault' && coinType !== 'portfolio';
  const isPortfolio =
    window.location.pathname === '/vault' && coinType === 'portfolio';

  if (isLoading || isContractsLoading) return <VaultLoadingCards />;

  return (
    <div
      className="cryptoData"
      style={{
        marginTop: isBanking ? '50px' : '0',
        padding: isBanking ? '25px' : '0',
        paddingBottom: isBanking ? '80px' : '55px',
      }}
    >
      <div className="eachcard">
        {isPortfolio && (
          <VaultTypes
            vaultTypeResponse={vaultTypeResponse}
            searchValue={searchedValue?.toLowerCase()}
            contractResponse={contractResponse}
          />
        )}

        {isBanking &&
          bankingData?.filter(bankingFilter)?.map((each, i) => {
            return (
              <div className={isBanking ? '' : 'main-card'} key={i}>
                <div
                  className={
                    isBanking ? 'bankIndividual individual' : 'individual'
                  }
                  key={i}
                >
                  <div
                    className="minidiv"
                    key={i}
                    style={{
                      width: isBanking ? '80vw' : '100%',
                      paddingRight: 0,
                      paddingRight: isBanking ? '10vw' : '',
                    }}
                  >
                    <div
                      className="first-column align-items-center"
                      style={{ width: '30%' }}
                    >
                      <div className="child-div mt-3">
                        <h1 className="cardheading">{each.account_name}</h1>
                        <p className="cardpara">{each.bank_account_id}</p>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{ width: '30%' }}
                    >
                      <div className="d-flex">
                        <img
                          className="coinimage"
                          src={each.paymentMethod_data?.icon}
                          alt="PaymentMethod"
                        />
                        <div className="d-flex flex-column ml-2">
                          <h1 className="cardheading1 pt-2">
                            {each.paymentMethod_data?.name}
                          </h1>
                          <p className="cardpara">{each.currency}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-column justify-content-center mt-3"
                      style={{ width: '40%' }}
                    >
                      <div className="d-flex">
                        <img
                          className="coinimage"
                          src={each.instituteData?.profile_image}
                          alt="InstituteImage"
                        />
                        <div className="d-flex flex-column ml-2">
                          <h1 className="cardheading1">
                            {each.instituteData?.institute_name}
                          </h1>
                          <p className="cardpara">{each.countryData?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="buttondiv">
                    <button
                      className="actionbtn"
                      key={'action' + JSON.stringify(i)}
                      onClick={() => null}
                      style={{
                        background: '#e7e7e7',
                        color: '#343C5B',
                        border: 'none',
                      }}
                    >
                      Actions
                    </button>
                  </div>
                </div>
              </div>
            );
          })}

        {isVault &&
          vaultData()
            ?.filter((coinItem) => filteredData(coinItem))
            ?.map((each, i) => {
              return (
                <div className={isBanking ? '' : 'main-card'} key={i}>
                  <div
                    onClick={() => {
                      setCoinAction(['Menu']);
                      setCoinActionEnabled(false);
                      setFundingCurrency(false);
                      setPayMethod(false);
                      setOtcDesk(false);
                      setEnterAmount(false);
                      setCurrentStep('step1');
                      setSelectedTab('sendingCurrency');
                      setAddApiValue('');
                      setToCurrencyApiValue('');
                      setCountryApiValue('');
                      setPayMethodApiValue('');
                      setOtcApiValue('');
                      setEachCardShowValue('step1');
                      setNewWholeValue({
                        ...newWholeValue,
                        step2: '',
                        step3: '',
                        step4: '',
                      });
                      setSendBtnClicked('');
                    }}
                    className={
                      isBanking ? 'bankIndividual individual' : 'individual'
                    }
                    key={i}
                    style={{
                      opacity: !coinActionEnabled
                        ? 1
                        : coinActionEnabled && selectedCoin._id === each._id
                        ? 1
                        : senbtnClicked === 'CAD' &&
                          selectedCoin._id === each._id
                        ? 1
                        : senbtnClicked === 'BTC' &&
                          selectedCoin._id === each._id
                        ? 1
                        : senbtnClicked === 'ETH' &&
                          selectedCoin._id === each._id
                        ? 1
                        : senbtnClicked === 'USDT' &&
                          selectedCoin._id === each._id
                        ? 1
                        : senbtnClicked === 'USDC' &&
                          selectedCoin._id === each._id
                        ? 1
                        : 0.3,
                    }}
                  >
                    <div className="minidiv" key={i}>
                      <div className="first-column align-items-center">
                        <img
                          className="coinimage"
                          src={each.coinImage}
                          alt="CoinImage"
                        />
                        <div className="child-div mt-3">
                          <h1 className="cardheading">{each.coinName}</h1>
                          <p className="cardpara">{each.coinSymbol}</p>
                        </div>
                      </div>
                      <div
                        className={`d-flex flex-column justify-content-center ${
                          isCryptoOrFx && 'mt-3'
                        } text-left coin-value`}
                      >
                        <h1
                          className={`cardheading ${!isCryptoOrFx && 'pt-2'}`}
                        >
                          {coinType == 'moneyMarkets'
                            ? each.coinValue?.toFixed(6)
                            : each.coinValue?.toFixed(4)}
                        </h1>
                        {isCryptoOrFx && (
                          <p className="cardpara">
                            {appCurrencyName}&nbsp;
                            {each.coinValue_DC?.toFixed(2)}
                          </p>
                        )}
                      </div>

                      {isCryptoOrFx && (
                        <div className="d-flex flex-column justify-content-center mt-3">
                          <div className="d-flex">
                            <img
                              src={each._24hrchange >= 0 ? arrowUp : arrowDown}
                              alt="24Hr Change"
                              className="pr-1"
                            />
                            <h1
                              style={{
                                color: each._24hrchange < 0 ? 'red' : '#0E9347',
                              }}
                              className="cardheading1 pt-2"
                            >
                              {each._24hrchange?.toFixed(2) ?? 0}%
                            </h1>
                          </div>
                          <p className="cardpara ml-5">--</p>
                        </div>
                      )}

                      {isBonds && (
                        <div className="d-flex flex-column justify-content-center coin-value">
                          <div className="d-flex">
                            <h1 className="cardheading1 pt-2 text-left">
                              {appCurrencyName}&nbsp;
                              {each.value_in_displayCurrency?.toFixed(2)}
                            </h1>
                          </div>
                        </div>
                      )}

                      {isMoneyMarkets && (
                        <div className="d-flex flex-column justify-content-center coin-value">
                          <div className="d-flex">
                            <h1 className="cardheading1 pt-2 text-left">
                              {appCurrencyName}&nbsp;
                              {each.coinValue_dc?.toFixed(2)}
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="buttondiv">
                      <button
                        className="actionbtn"
                        key={'transaction' + JSON.stringify(i)}
                        onClick={() => {
                          setSelectedCoin(each);
                          history.push(
                            `vault/${coinType}/${appCode}/${each.coinSymbol}`
                          );
                        }}
                      >
                        Transactions
                      </button>
                      {isBonds || isMoneyMarkets ? (
                        <button
                          className="actionbtn"
                          key={'withdraw' + JSON.stringify(i)}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCoinActionEnabled(true);
                            setSelectedCoin(each);
                            setCoinAction(['Transfer']);
                            setAddActionStep(1);
                            setWithdrawActionStep(1);
                            setTransferActionStep(3);
                            isBonds && setTransferMethod('bonds');
                            isMoneyMarkets && setTransferMethod('moneymarkets');
                            setSidebarCollapse(false);
                          }}
                        >
                          Withdraw
                        </button>
                      ) : (
                        <button
                          className="actionbtn"
                          key={'action' + JSON.stringify(i)}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCoinActionEnabled(true);
                            setSelectedCoin(each);
                            setCoinAction(['Menu']);
                            setAddActionStep(1);
                            setWithdrawActionStep(1);
                            setTransferActionStep(1);
                            setFundingCurrency(false);
                            setPayMethod(false);
                            setOtcDesk(false);
                            setEnterAmount(false);
                            setCurrentStep('step1');
                            setSelectedTab('sendingCurrency');
                            setAddApiValue('');
                            setToCurrencyApiValue('');
                            setCountryApiValue('');
                            setPayMethodApiValue('');
                            setOtcApiValue('');
                            setEachCardShowValue('step1');
                            setNewWholeValue({
                              ...newWholeValue,
                              step2: '',
                              step3: '',
                              step4: '',
                            });
                            setWithdrawForexEnteredAmount('');
                            setWithdrawForexStep('1');
                            setWithdrawForexCurrency('');
                            setWithdrawForexCountry('');
                            setWithdrawForexPaymentMethod('');
                            setWithdrawForexOtcDesk('');
                            setWithdrawForexAccountId('');
                            setWithdrawForexAccountName('');
                            setAllApiData('');
                            setForexQuote('');
                            setCheckedOneForex('');
                            setOtpForex('');
                            setUpdatedForexBalance('');
                            setSidebarCollapse(false);
                            setAddressTerm('');
                            setFromTerm('');
                            setToTerm('');
                          }}
                        >
                          Actions
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};
