import React, { useState, useEffect, useContext } from 'react';
import './ads.scss';
import { getadds, getNews, getRewards } from './api';
import usersinfo from '../../../static/images/usersinfo.svg';
import closemarks from '../../../static/images/closemarks.svg';
import { BankContext } from '../../../context/Context';
// import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import Skeleton from 'react-loading-skeleton';
import ReactPlayer from 'react-player';
export default function Index() {
  const [cardInfo, setcardInfo] = useState([]);
  const [tab, settab] = useState('main');
  const [info, setinfo] = useState({});
  const [newdata, setnewdata] = useState({});
  const [drawerTable, setdrawerTable] = useState('first');
  const [inputvalue, setinputvalue] = useState('');
  const [drawerToggle, setsetdrawerToggle] = useState(false);
  const [sucessData, setsucessData] = useState([]);
  const [loader, setloader] = useState(false);
  const { email, token, tostShowOn, setUserSelectedOnHold } =
    useContext(BankContext);
  useEffect(() => {
    getaddsFunction();
    return () => {};
  }, []);

  const getaddsFunction = async () => {
    setloader(true);
    await getadds().then((response) => {
      let result = response?.data?.users;
      setcardInfo(result);
      setloader(false);
      console.log('zxc', result);
    });
  };
  const handle = (e) => {
    setinfo(e);
    settab('inner');
    getNewsFunction(e?.email);
  };
  const handleFunction = () => {
    setdrawerTable('second');
  };

  const getrewordFunction = async () => {
    let obj = {
      token: token,
      email: email,
      app_code: 'web3today',
      videoCode: inputvalue,
    };
    await getRewards(obj).then((response) => {
      let result = response?.data;
      if (result?.status) {
        setsucessData(result);
        setdrawerTable('sucess');
      }
      // setcardInfo(result);
      console.log('zxc', result);
    });
  };

  const getNewsFunction = async (e) => {
    await getNews(e).then((response) => {
      let result = response?.data?.videoCampaigns?.[0];
      //   setcardInfo(result);
      setnewdata(result);
      console.log('1231e23d', result);
    });
  };
  console.log(info, 'setinfo');

  const drawerFuntion = () => {
    switch (drawerTable) {
      case 'sucess':
        return (
          <>
            <div className="drawerAds">
              <div className="topHeaderDrawerAds">
                <h1>
                  You Have Successfully Claimed {sucessData?.userData?.amount}{' '}
                  {sucessData?.userData?.coin} To Your Web3Today Account
                </h1>
              </div>
              <div className="cardsbottomDrawer">
                <div className="leftcardlistdrawer">
                  <div className="textinside">Previous Balance</div>
                  <div className="textinside">
                    {sucessData?.userData?.current_balance?.toFixed(2)}{' '}
                    {sucessData?.userData?.coin}
                  </div>
                </div>
                <div className="leftcardlistdrawer">
                  <div className="textinside">Updated Balance</div>
                  <div className="textinside">
                    {sucessData?.userData?.updated_balance?.toFixed(2)}{' '}
                    {sucessData?.userData?.coin}
                  </div>
                </div>
                <div className="labelbottom">
                  <label
                    className="submit"
                    onClick={() => setsetdrawerToggle(false)}
                  >
                    Close
                  </label>
                </div>
              </div>
            </div>
          </>
        );

        break;
      case 'second':
        return (
          <>
            <div className="drawerAds">
              <div className="topHeaderDrawerAds">
                <h1> Select Action</h1>
                <img
                  src={closemarks}
                  onClick={() => setsetdrawerToggle(false)}
                />
              </div>
              <div className="cardsbottomDrawer">
                <input
                  value={inputvalue}
                  placeholder="Enter Code"
                  onChange={(e) => setinputvalue(e.target.value)}
                />

                <div className="labelbottom">
                  <label className="submit" onClick={getrewordFunction}>
                    Submit Code
                  </label>
                  <label
                    className="back"
                    onClick={() => setdrawerTable('first')}
                  >
                    Go Back
                  </label>
                </div>
              </div>
            </div>
          </>
        );

      case 'first':
        return (
          <>
            <div className="drawerAds">
              <div className="topHeaderDrawerAds">
                <h1> Select Action</h1>
                <img
                  src={closemarks}
                  onClick={() => setsetdrawerToggle(false)}
                />
              </div>
              <div className="cardsbottomDrawer">
                <div className="leftcardlistdrawer">
                  <div
                    className="textinside"
                    style={{
                      opacity: '0.5',
                    }}
                  >
                    See Campaign
                  </div>
                </div>
                <div className="leftcardlistdrawer" onClick={handleFunction}>
                  <div className="textinside">Claim Direct Reward</div>
                </div>
                <div className="leftcardlistdrawer">
                  <div
                    className="textinside"
                    style={{
                      opacity: '0.5',
                    }}
                  >
                    Claim Social Meida Reward
                  </div>
                </div>
              </div>
            </div>
          </>
        );

        break;

      default:
        break;
    }
  };

  const swtchtabfunction = () => {
    switch (tab) {
      case 'main':
        return (
          <>
            {loader ? (
              <>
                {Array(25)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div class="flip">
                        <Skeleton
                          Width="100%"
                          height="231px"
                          style={{ borderRadius: '0px' }}
                        />
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="ads">
                  {cardInfo?.map((item) => {
                    if (item?.advertiser_profile)
                      return (
                        <div class="flip" onClick={() => handle(item)}>
                          <div class="front">
                            <img src={item?.advertiser_profile?.profile_img} />
                          </div>
                          <div class="back">
                            <img src={item?.advertiser_profile?.profile_img} />
                            <p>{item?.advertiser_profile?.name}</p>
                          </div>
                        </div>
                      );
                  })}
                  <>
                    {Array(25)
                      .fill(' ')
                      .map((item, index) => {
                        return (
                          <div class="flip">
                            <div class="front"></div>
                            <div class="back">
                              <p></p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                </div>
              </>
            )}
          </>
        );
        break;
      case 'inner':
        return (
          <div className="innerAdsDash">
            <div
              className="imageSectionadds"
              style={{
                background: info?.advertiser_profile?.color_code,
              }}
            >
              <img src={info?.advertiser_profile?.whiteinvrersedlogo} />
              <div className="allbrands" onClick={() => settab('main')}>
                <img src={usersinfo} />
                All Brands
              </div>
            </div>
            <div className="headerDash">
              <div className="topheaderadd">
                <div className="cardAdds">
                  <h1>0</h1>
                  <h2>Total Ads</h2>
                </div>
                <div className="cardAdds">
                  <h1>0.00</h1>
                  <h2>Total Payout</h2>
                </div>
                <div className="cardAdds">
                  <h1>0</h1>
                  <h2>Live Ads</h2>
                </div>
                <div className="cardAdds">
                  <h1>0.00</h1>
                  <h2>Avl Payout</h2>
                </div>
                <div className="cardAdds">
                  <label
                    className="signup"
                    style={{
                      background: info?.advertiser_profile?.color_code,
                    }}
                    onClick={() =>
                      window.open(
                        `http://${info?.advertiser_profile?.call_action_link}`,
                        '_blank'
                      )
                    }
                  >
                    Signup
                  </label>
                  <label
                    className="website"
                    onClick={() =>
                      window.open(
                        `http://${info?.advertiser_profile?.website_link}`,
                        '_blank'
                      )
                    }
                  >
                    Website
                  </label>
                </div>
              </div>
            </div>
            <div className="contnetaddtop">
              <div className="cardDash">
                <div className="lefsiteadd">
                  <div className="innerContent">
                    <h4>Fixed Payouts</h4>
                    <h4>0.00</h4>
                  </div>
                  <div className="innerContent">
                    <h5>0 Live Campaigns</h5>
                    <h5>Avl Payout</h5>
                  </div>
                  <div
                   className="borderCard"
                    style={{
                      background: `url(${newdata?.video_thumbnail})`,
                    }}
                  >
                    <ReactPlayer
                        // style={{
                        //   background: `url(${newdata?.video_thumbnail})`,
                        // }}
                      // className="borderCard"
                      width={'100%'}
                      height={"300px"}
                      // className={styles.reactPlayer}
                      light={false}
                      // alt="Thumbnail"
                      url={newdata?.profile_video}
                      controls
                      playing
                    />
                  </div>

                  <h1
                    style={{
                      display: 'block',
                      textAlign: 'start',
                    }}
                  >
                    {newdata?.video_title}
                  </h1>
                  <div className="labelSection">
                    <label
                      className="payout"
                      onClick={() => setsetdrawerToggle(true)}
                    >
                      {newdata?.payout_amount} {newdata?.payOutCoin} Payout
                    </label>
                    <label
                      className="payoutLeft"
                      style={{
                        background: info?.advertiser_profile?.color_code,
                      }}
                    >
                      {newdata?.left_redeem_count} Payouts Left
                    </label>
                  </div>
                  <div className="borderCard"></div>
                </div>
                <div className="rightsiteadd">
                  <div className="innerContent">
                    <h4>Variable Payouts</h4>
                    <h4>0.00</h4>
                  </div>
                  <div className="innerContent">
                    <h5>0 Live Campaigns</h5>
                    <h5>Avl Payout</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <>
      {swtchtabfunction()}

      {drawerToggle ? <>{drawerFuntion()}</> : ''}
    </>
  );
}
