import axios from 'axios';


export const getplayout = async (email) => {
  try {
    const data = await axios.get(
      `https://publications.apimachine.com/publication/638dd769b257b3715a8fbe07`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getPointsData = async (obj) => {
    try {
      const data = await axios.get(
        `https://publications.apimachine.com/articleread/finalpoints?user_id=${obj}&publication_id=638dd769b257b3715a8fbe07`
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  };
  

