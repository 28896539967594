import React, { useContext } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import guest from '../../../static/images/guest.jpg';
import settings from '../../../static/images/sidebar-icons/settingsIcon.svg';
import closeIcon from '../../../static/images/sidebar-icons/closeIcon.svg';

export const DefaultFooter = ({
  isSecondaryMenu,
  toggleSettings,
  openSettings,
  setAdminSidebar,
}) => {
  const { email, username, name, profileImg, sidebarCollapse } =
    useContext(BankContext);
  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
  } = useCoinContextData();

  return (
    <div
      className="d-flex menu-bottom-item"
      onClick={() => setAdminSidebar(true)}
      style={{
        paddingLeft: sidebarCollapse ? '15px' : '30px',
        opacity: isCoinLoadingInAddAction ? 0.5 : 1,
      }}
    >
      <div className="profile-icon">
        <img src={profileImg ? profileImg : guest} alt="Profile Image" />
      </div>

      {!sidebarCollapse && (
        <>
          <div className="col my-auto text-break">
            <h5>{name ?? username}&nbsp;</h5>
            <div className="getStartedBtn">{email}</div>
          </div>
          <img
            src={isSecondaryMenu ? closeIcon : settings}
            className="p-4 mr-3"
            width={isSecondaryMenu ? 70 : 80}
            onClick={(e) => {
              e.stopPropagation();
              if (!isCoinLoadingInAddAction) {
                if (coinActionEnabled) {
                  setCoinActionEnabled(false);
                  setCoinAction(['Menu']);
                } else if (addBankingEnabled) {
                  setAddBankingEnabled(false);
                } else {
                  toggleSettings(!openSettings);
                }
              }
            }}
          />
        </>
      )}
    </div>
  );
};
