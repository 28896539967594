import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import moneyMarketLogo from '../../static/images/moneyMarketLogoPrimaryColor.svg';
import paste from '../../static/images/paste.svg';
import searchIcn from '../../static/images/search.svg';
function BondSearch() {
  const history = useHistory();
  const { toastShowOn } = useContext(BankContext);
  const [search, setSearch] = useState('');

  return (
    <div className="moneyMarkets">
      <img src={moneyMarketLogo} className="moneyMarketLogo" alt="" />
      <label className="searchWrapper">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Any Asset Hash..."
        />
        <img
          src={paste}
          onClick={() => {
            navigator.clipboard
              .readText()
              .then((clipText) => setSearch(clipText));
          }}
          alt=""
        />
        <img
          src={searchIcn}
          alt=""
          onClick={() => {
            if (search) history.push(`/bonds/${search}`);
            else {
              toastShowOn('Enter A Valid Asset Hash');
            }
          }}
        />
      </label>
    </div>
  );
}

export default BondSearch;
