import React, { useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import transferIn from '../../../static/images/transfer-icons/transferIn.svg';
import transferOut from '../../../static/images/transfer-icons/transferOut.svg';
import metaverseApps from '../../../static/images/sidebarIcons/metaverseApps.svg';
import bonds from '../../../static/images/login/bond.svg';
import moneymarket from '../../../static/images/vaults/moneymarket.svg';
import { FromAssetEarnings } from './FromAssetEarnings';
import { TransferSuccess } from './TransferSuccess';

export const TransferAction = () => {
  const {
    transferActionStep,
    setTransferActionStep,
    selectedCoin,
    setCoinAction,
    setTransferMethod,
  } = useCoinContextData();
  const { sidebarCollapse } = useContext(BankContext);
  const { appName } = useAppContextDetails();
  const marginLeftSize = { marginLeft: sidebarCollapse ? '15px' : '35px' };

  const transferActions = [
    {
      id: 1,
      icon: transferIn,
      onClick: () => {
        setTransferActionStep(2);
      },
      text: 'Transfer In',
      subText: `Transferring Into ${appName} ${selectedCoin.coinSymbol} Vault`,
    },
    {
      id: 2,
      icon: transferOut,
      text: 'Transfer Out',
      subText: `Transferring Out Of ${appName} ${selectedCoin.coinSymbol} Vault`,
    },
  ];

  const transferInActions = [
    {
      id: 1,
      icon: metaverseApps,
      text: 'From Another App',
      subText: `Move Liquid ${selectedCoin.coinSymbol} Between Your Vaults`,
    },
    {
      id: 2,
      icon: bonds,
      onClick: () => {
        setTransferActionStep(3);
        setTransferMethod('bonds');
      },
      text: 'From Bond Earnings',
      subText: `Cash Out Your ${selectedCoin.coinSymbol} Bond Interest`,
    },
    {
      id: 3,
      icon: moneymarket,
      onClick: () => {
        setTransferActionStep(3);
        setTransferMethod('moneymarkets');
      },
      text: 'From MoneyMarkets',
      subText: `Cash Out Your ${selectedCoin.coinSymbol} MoneyMarkets Interest`,
    },
  ];

  return (
    <>
      <div className="breadCrumbs">
        {transferActionStep === 1 && (
          <div className="gt">
            <span onClick={() => setCoinAction(['Menu'])}>
              {selectedCoin.coinName} Actions &nbsp;&gt;&nbsp;
            </span>
            <span>Select Direction</span>
          </div>
        )}
        {transferActionStep === 2 && (
          <div className="gt">
            <span onClick={() => setTransferActionStep(1)}>
              Select Direction &nbsp;&gt;&nbsp;
            </span>
            <span>Select Origin</span>
          </div>
        )}
        {transferActionStep === 3 && (
          <div className="gt">
            <span onClick={() => setTransferActionStep(2)}>
              Select Origin &nbsp;&gt;&nbsp;
            </span>
            <span>Amount</span>
          </div>
        )}
      </div>

      {transferActionStep === 1 && (
        <div className="sidebar-menu mt-3">
          {transferActions.map((option) => (
            <>
              <div
                className="d-flex sidebar-container clickable-div"
                onClick={option.onClick}
                style={{
                  opacity: option.onClick ? 1 : 0.25,
                  pointerEvents: !option.onClick && 'none',
                }}
              >
                <img
                  className="sidebar-content-icon"
                  src={option.icon}
                  alt={option.text}
                  width={60}
                  style={marginLeftSize}
                />
                {!sidebarCollapse && (
                  <div className="col my-auto p-0">
                    <h5 className="action-label">{option.text}</h5>
                    <div className="getStartedBtn action-sublabel">
                      {option.subText}
                    </div>
                  </div>
                )}
              </div>
              <div className="sidebar-spacer" />
            </>
          ))}
        </div>
      )}

      {transferActionStep === 2 && (
        <div className="sidebar-menu mt-3">
          {transferInActions.map((option) => (
            <>
              <div
                className="d-flex sidebar-container clickable-div"
                onClick={option.onClick}
                style={{
                  opacity: option.onClick ? 1 : 0.25,
                  pointerEvents: !option.onClick && 'none',
                }}
              >
                <img
                  className="sidebar-content-icon"
                  src={option.icon}
                  alt={option.text}
                  width={60}
                  style={marginLeftSize}
                />
                {!sidebarCollapse && (
                  <div className="col my-auto p-0">
                    <h5 className="action-label">{option.text}</h5>
                    <div className="getStartedBtn action-sublabel">
                      {option.subText}
                    </div>
                  </div>
                )}
              </div>
              <div className="sidebar-spacer" />
            </>
          ))}
        </div>
      )}
      {transferActionStep === 3 && <FromAssetEarnings />}
      {transferActionStep === 4 && <TransferSuccess />}
    </>
  );
};
