import React, { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppContextDetails } from '../../context/AppContext';
import { NetWorthContext } from '../../context/NetWorthContext';
import { BankContext } from '../../context/Context';
import './listComponent.scss';
import searchIcon from '../../static/images/search.svg';
import vault from '../../static/images/vaults/fulllogo.svg';
import ddown from '../../static/images/malls/ddown.svg';
import { useCoinContextData } from '../../context/CoinContext';
import { ListData } from './ListData';
// import Investments from './Investments';
import Toggle from '../../components/Toggle';
import { TrackerContents } from '../VaultsPage/VaultComponent/TrackerContents';
import { VaultPageContext } from '../../context/VaultPageContext';
import { TrackerDetailed } from '../VaultsPage/VaultComponent/TrackerDetailed';

export const ListComponent = ({
  isLoading,
  leftHeaderData,
  leftSubHeader,
  rightHeaderData,
  listOfIcons,
  searchedValue,
  setSearchedValue,
  moneyMarketData,
  bondData,
  coinsData,
  bankingData,
  vaultTypeResponse,
}) => {
  const { appColorCode, balanceToggle, setBalanceToggle, mmEarningsVault } =
    useAppContextDetails();
  const { sidebarCollapse } = useContext(BankContext);
  const { placeholder, setCoinType, coinType } = useCoinContextData();
  const isBanking = window.location.pathname === '/banking';
  // const { trackerData } = useContext(VaultPageContext);
  const { netWorth, liquidCrypto, liquidFiat } = useContext(NetWorthContext);

  return (
    <>
      <div className="list-component">
        {isBanking ? (
          <div
            className="userdata"
            style={{
              backgroundColor: appColorCode,
            }}
          >
            <div
              className="left-data"
              style={{
                width: isLoading ? '20%' : 'auto',
              }}
            >
              {isLoading ? (
                <Skeleton className="data-loader" />
              ) : (
                leftHeaderData
              )}
              {leftSubHeader}
            </div>
            <div
              style={{
                width: isLoading ? '20%' : 'auto',
              }}
              className="right-data"
            >
              {rightHeaderData &&
                (isLoading ? (
                  <Skeleton className="data-loader" />
                ) : (
                  rightHeaderData
                ))}
            </div>
          </div>
        ) : (
          <>
            <div className="vaultss-navbar">
              <div className="vaultss-icon">
                <img src={vault} alt="vault" />
              </div>
              <div className="search-vaultss">
                {/* <div className="all">
                  <p>All Assets</p>
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={ddown} alt="" />
                  </div>
                </div> */}
                <div className="im">
                  <input
                    type="text"
                    placeholder="Ex. Bitcoin..."
                    // onChange={(event) => filterItem(event.target.value)}
                    onChange={(event) => {
                      setSearchedValue(event.target.value);
                    }}
                  />
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div className="right-top">
                <div
                  style={{
                    borderRight: '1px solid #e7e7e7',
                    paddingRight: '3rem',
                  }}
                  className="divv"
                >
                  <div>
                    <p style={{ opacity: '0.25' }}>
                      {coinType === 'crypto'
                        ? 'Liquid Crypto'
                        : coinType === 'tracker'
                        ? 'Deposits'
                        : coinType === 'moneyMarkets'
                        ? 'MM Earnings Vaults'
                        : coinType === 'fiat'
                        ? 'Liquid Forex'
                        : 'Liquid Holdings'}
                    </p>
                    <p style={{ fontWeight: '700' }}>
                      {/* {trackerData?.perAppCode?.length > 0 && coinType === 'tracker'
                    ? trackerData?.perAppCode[0]?.count
                    : coinType === 'tracker'
                    ? '0'
                    : '$0.00 USD'} */}
                      {coinType === 'crypto'
                        ? `$${liquidCrypto ? liquidCrypto : '0.00'} USD`
                        : coinType === 'fiat'
                        ? `$${liquidFiat ? liquidFiat : '0.00'} USD`
                        : coinType === 'moneyMarkets'
                        ? `$${mmEarningsVault ? mmEarningsVault : ''} USD`
                        : '$0.00 USD'}
                    </p>
                  </div>
                </div>
                <div style={{ paddingLeft: '2rem' }} className="divv">
                  <div>
                    <p style={{ opacity: '0.25' }}>
                      {coinType === 'tracker'
                        ? 'Withdrawals'
                        : 'Total Net-Worth'}
                    </p>
                    <p style={{ fontWeight: '700' }}>
                      {coinType === 'tracker'
                        ? '0'
                        : `$${netWorth ? netWorth : '0.00'} USD`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-space">
              <div className="optionss" style={{ width: '100%' }}>
                <div className="optionss-divider">
                  <div
                    className={`each-option ${
                      coinType === 'crypto' ? 'each-option-selected' : ''
                    }`}
                    style={{
                      fontWeight: coinType === 'crypto' ? '650' : '300',
                    }}
                    onClick={() => setCoinType('crypto')}
                  >
                    <div>
                      <p>Crypto</p>
                    </div>
                  </div>
                  <div
                    className={`each-option ${
                      coinType === 'fiat' ? 'each-option-selected' : ''
                    }`}
                    style={{
                      fontWeight: coinType === 'fiat' ? '650' : '300',
                    }}
                    onClick={() => setCoinType('fiat')}
                  >
                    <div>
                      <p>Forex</p>
                    </div>
                  </div>
                  {/* <div
                    className="each-option"
                    style={{
                      fontWeight: coinType === 'bonds' ? '650' : '300',
                    }}
                    onClick={() => setCoinType('bonds')}
                  >
                    <div>
                      <p>Staking Rewards</p>
                    </div>
                  </div> */}
                  <div
                    className={`each-option ${
                      coinType === 'moneyMarkets' ? 'each-option-selected' : ''
                    }`}
                    style={{
                      fontWeight: coinType === 'moneyMarkets' ? '650' : '300',
                    }}
                    onClick={() => setCoinType('moneyMarkets')}
                  >
                    <div>
                      <p>MoneyMarket Earnings</p>
                    </div>
                  </div>
                  {/* <div
                    className="each-option"
                    style={{
                      fontWeight: coinType === 'investments' ? '650' : '300',
                    }}
                    onClick={() => setCoinType('investments')}
                  >
                    <div>
                      <p>Investments</p>
                    </div>
                  </div> */}
                  <div
                    className={`each-option ${
                      coinType === 'tracker' ? 'each-option-selected' : ''
                    }`}
                    style={{
                      fontWeight: coinType === 'tracker' ? '650' : '300',
                    }}
                    onClick={() => setCoinType('tracker')}
                  >
                    <div>
                      <p>Tracker</p>
                    </div>
                  </div>
                </div>
                <div className="last-option">
                  <div className="toggle">
                    <Toggle
                      toggle={balanceToggle}
                      setToggle={setBalanceToggle}
                      coinType={coinType}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* {coinType === 'tracker' && <div>Tracker</div>} */}

        {coinType === 'trackerdetailed' ? (
          <TrackerDetailed />
        ) : coinType === 'tracker' ? (
          <TrackerContents />
        ) : (
          coinType != 'investments' && (
            <div
              className="cryptocard"
              style={{
                // width: '100%',
                width:
                  sidebarCollapse && isBanking
                    ? '80%'
                    : isBanking
                    ? '74%'
                    : '100%',
                left: sidebarCollapse ? '12%' : '24%',
                border: isBanking ? '0.5px solid #e5e5e5' : '0px',
                top: isBanking ? '17rem' : '16rem',
                marginTop: isBanking ? '-6rem' : '',
                position: isBanking ? 'fixed' : '',
              }}
            >
              {isBanking && (
                <div className="cardnav">
                  <div className="d-flex searchbox">
                    <img
                      className="searching"
                      width={30}
                      src={searchIcon}
                      alt="Search Icon"
                    />
                    <input
                      className="searchfield"
                      type="search"
                      placeholder={placeholder}
                      value={searchedValue}
                      onChange={(event) => {
                        setSearchedValue(event.target.value);
                      }}
                    />
                  </div>
                  {listOfIcons}
                </div>
              )}

              <ListData
                isLoading={isLoading}
                moneyMarketData={moneyMarketData}
                bondData={bondData}
                coinsData={coinsData}
                searchedValue={searchedValue}
                bankingData={bankingData}
                vaultTypeResponse={vaultTypeResponse}
              />
            </div>
          )
        )}

        {/* {coinType === 'investments' && <Investments />} */}
      </div>
    </>
  );
};
