import React, { useState } from 'react';
import classNames from './marketAssetDetailFinancials.module.scss';
import angleDown from '../../static/images/angleDown.svg';
import togleFullScreen from '../../static/images/togleFullScreen.svg';
import { useAppContextDetails } from '../../context/AppContext';

const TABS = [
  'Liquidity',
  'Issuances',
  'Balance Sheet',
  'Income Statement',
  'Cash Flow Statement',
  'Capitalization Table',
  'External Audits',
  'External Audits',
  'Earning Calls',
  'Other',
];

function MarketAssetDetailFinancials() {
  const [fullScreen, setFullScreen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const { appCurrencyName } = useAppContextDetails();

  return (
    <div
      className={`${classNames[fullScreen.toString()]} ${
        classNames.marketAssetDetailFinancialsWrap
      }`}
    >
      <div className={classNames.marketAssetDetailFinancials}>
        <div className={classNames.headerTabs}>
          {TABS.map((tab, i) => (
            <div
              className={`${classNames.tabItm} ${
                classNames[(i === 0).toString()]
              }`}
            >
              {tab}
            </div>
          ))}
          <div
            className={classNames.btnFullScreen}
            onClick={() => setFullScreen(!fullScreen)}
          >
            <img src={togleFullScreen} alt="" />
          </div>
        </div>
        <div className={classNames.detailWrap}>
          <div className={classNames.detailTexts}>
            <div className={classNames.title}>Liquidity</div>
            <div className={classNames.desc}>
              Liquidity represents the and demand data for the a particular
              ShareToken by mapping the circulating supply metrics over the data
              from the secondary market orderbook.
            </div>
          </div>
          <div className={classNames.details}>
            <div className={classNames.detail}>
              <div className={classNames.value}>--</div>
              <div className={classNames.label}>Owned By Company</div>
            </div>
            <div className={classNames.detail}>
              <div className={classNames.value}>--</div>
              <div className={classNames.label}>In The Orderbook</div>
            </div>
            <div className={classNames.detail}>
              <div className={classNames.value}>--</div>
              <div className={classNames.label}>Owned By Others</div>
            </div>
          </div>
        </div>
        <div className={classNames.marketTable}>
          <div className={`${classNames.header}`}>
            <div className={classNames.date}>Date</div>
            <div className={classNames.direction}>Direction</div>
            <div className={classNames.status}>Status</div>
            <div className={classNames.amount}>Amount</div>
            <div className={classNames.price}>Price</div>
            <div className={classNames.volume}>Volume</div>
            <div className={classNames.filled}>Filled</div>
            <div className={classNames.pending}>Pending</div>
            <div className={classNames.details}></div>
            <img
              className={`${classNames.btnFilter} ${
                classNames[filterOpen.toString()]
              }`}
              src={angleDown}
              alt=""
              onClick={() => setFilterOpen(!filterOpen)}
            />
          </div>
          {filterOpen && (
            <div className={classNames.filtersWrap}>
              <div className={classNames.filters}>
                <div className={classNames.filter}>
                  <img
                    src={
                      'https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png'
                    }
                    alt=""
                  />
                  <span>{appCurrencyName}</span>
                </div>
                <div className={classNames.filter}>
                  + <span>Add Filter</span>
                </div>
              </div>
            </div>
          )}
          <div className={classNames.liquidList}>
            {Array(10)
              .fill('')
              .map(() => (
                <div className={`${classNames.liquidItem}`}>
                  <div className={classNames.date}>Jan 1st 2022</div>
                  <div className={classNames.direction}>
                    <div className={classNames.btnSell}>Sell</div>
                  </div>
                  <div className={classNames.status}>Open</div>
                  <div className={classNames.amount}>23</div>
                  <div className={classNames.price}>10.00</div>
                  <div className={classNames.volume}>2300.00</div>
                  <div className={classNames.filled}>5</div>
                  <div className={classNames.pending}>5</div>
                  <div className={classNames.details}>
                    <div className={classNames.btnDetails}>
                      Investor Details
                    </div>
                  </div>
                  <img
                    className={`${classNames.btnFilter} ${
                      classNames[filterOpen.toString()]
                    }`}
                    src={angleDown}
                    alt=""
                    onClick={() => setFilterOpen(!filterOpen)}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketAssetDetailFinancials;
