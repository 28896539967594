import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Layout from '../../Layout/Layout';
import '../AffiliatePage/affiliate.scss';
import ChainView from '../AffiliatePage/ChainView';
import { affiliateBalances, uplineData } from '../AffiliatePage/api';

import search from '../../static/images/malls/search.svg';
import fulllogo from '../../static/images/networkChain/fullLogo.svg';
import guest from '../../static/images/affiliate/userlogo.svg';
import ablogo from '../../static/images/affiliate/ablogo.svg';

const NetworkChain = () => {
  const {
    levelData,
    setLevelData,
    balances,
    setBalances,
    showleveldata,
    setShowLevelData,
    adduser,
    setAdduser,
    adduserStep,
    setAdduserStep,
    affiliateid,
    setAffiliateId,
    allaffiliatedata,
    setAllaffiliatedata,
    chainuserData,
    setchainUserData,
    appColorCode,
    affStats,
    setAffStats,
    appName,
    upline,
    setUpline,
    invite,
    setInvite,
    setuserfilteredAff,
    iswithdraw,
    setIswithdraw,
    //update user name
    newUserNameSidebar,
    setnewUserNameSidebar,
    newUserName,
    setnewUserName,
    usernameExist,
    setusernameExist,
  } = useAppContextDetails();

  const {
    email,
    token,
    username,
    name,
    profileImg,
    copyToClipboard,
    linkname,
    setLinkname,
  } = useContext(BankContext);

  if (!email) {
    history.push('/');
  }

  const history = useHistory();
  const [ncTab, setNcTab] = useState('My Chain');
  const [viewingUser, setViewingUser] = useState('');

  useEffect(() => {
    let mailId = email;

    affiliateBalances(mailId).then((response) => {
      let result = response?.data;
      //   console.log(result, 'balance');
      setBalances(result);
    });

    uplineData(mailId).then((response) => {
      let result = response?.data?.uplines?.[0];
      setUpline(result);
      let username = response?.data?.user?.username;
      setLinkname(username);
    });
  }, [email]);

  return (
    <Layout active="Network Chains" className="affiliate" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="affiliate-navbar" style={{ position: 'relative' }}>
          <div
            style={{
              height: '100%',
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
            }}
          >
            <div className="affiliate-icon" style={{ width: '30%' }}>
              <img src={fulllogo} alt="" />
            </div>
          </div>
          <div
            className="search-affiliate"
            style={{
              width: '43%',
              height: 'calc(100% - 2rem)',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '42%',
            }}
          >
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={upline?.profile_img ? upline?.profile_img : guest}
                alt=""
                style={{ height: '50px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{upline?.name}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '45px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>
                  $
                  {balances?.gxbroker_balance
                    ? balances?.gxbroker_balance?.toFixed(2)
                    : '0.00'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-space">
          <div className="optionsss" style={{ width: '10%' }}>
            <div
              className={`each-option ${
                ncTab === 'My Chain' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: ncTab === 'My Chain' ? '600' : '',
              }}
              onClick={() => setNcTab('My Chain')}
            >
              <div>
                <p>My Chain</p>
              </div>
            </div>
          </div>
        </div>
        <ChainView viewingUser={viewingUser} setViewingUser={setViewingUser} />
      </div>
    </Layout>
  );
};

export default NetworkChain;
