import React, { useContext, useEffect, useState } from 'react';
import {
  faAngleRight,
  faCaretUp,
  faChevronRight,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import { BankContext } from '../../context/Context';

function BondMobileStats({ contract }) {
  const { coinListObject, defaultPrecission } = useContext(BankContext);
  const [listDetail, setListDetail] = useState(null);
  const [feesDetail, setFeesDetail] = useState(null);
  const feesItem = () => {
    switch (feesDetail) {
      case 1:
        return (
          <div className="listDetail sub">
            <div className="subHead">Affiliate Fees</div>
            <p>
              Affiliate Fees Are Deducted From You Gross Daily Earnings At A
              Rate Of {FormatNumber(contract && contract.feeRate, 2)}%.
              Therefore If You Are Earning $10.00 USD Today Your Affiliate Fee
              Would Be ${FormatNumber(0.1 * (contract && contract.feeRate), 2)}{' '}
              USD
            </p>
            <p className="nb">All Values In This Bond Is Post Affiliate Fees</p>
            <FontAwesomeIcon
              onClick={() => {
                setFeesDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 2:
        return (
          <div className="listDetail sub">
            <div className="subHead">Issuance Fee</div>
            <p>
              Issuance Fees Are Deducted From The Bond Upon Redemption. Daily
              Earning Power And Term Earning Power Calculations Are Pre-Issuance
              Fee While Net ROI Calculations Are Post Issuance Fees
            </p>
            <div className="item">
              <div className="label">Issuance Fee</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionFee,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract && contract.redemptionFeeUSD,
                    'USD',
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setFeesDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      default:
        return (
          <>
            <div className="listItem sub mt-2" onClick={() => setFeesDetail(1)}>
              Affiliate Fees
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className="listItem sub" onClick={() => setFeesDetail(2)}>
              Issuance Fee
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </>
        );
    }
  };
  const detailList = () => {
    switch (listDetail) {
      case 1:
        return (
          <div className="listDetail">
            <div className="head">Issuance Details</div>
            <div className="date">
              {moment(contract?.timestamp).format(
                '[Date: ] MMMM Do YYYY [At] hh:mm A z'
              )}
            </div>
            <div className="item">
              <div className="label">Bond Currency</div>
              <div className="value">
                <div className="primary">
                  {coinListObject &&
                    contract &&
                    coinListObject[contract?.asset].coinName}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Paid For With</div>
              <div className="value">
                <div className="primary">
                  {coinListObject &&
                    contract &&
                    coinListObject[contract?.coin].coinName}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Purchased Where</div>
              <div className="value">
                <div className="primary">{contract?.origin_app_code}</div>
              </div>
            </div>
            <div className="item">
              <div className="label">
                <div className="primary">Cost Per Bond</div>
                <div className="secondary">
                  Today’s USD Value or{' '}
                  {FormatCurrency(
                    contract && contract.contract_amount,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  Initial USD Value For{' '}
                  {FormatCurrency(
                    contract && contract.contract_amount,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
              </div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.contract_amount,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract.contract_amount *
                      ((coinListObject &&
                        coinListObject[contract.asset] &&
                        coinListObject[contract.asset].price.USD) ||
                        1),
                    'USD',
                    defaultPrecission
                  )}
                </div>
                <div className="secondary">
                  {/* $
                    {FormatCurrency(
                      contract.contract_amount *
                        ((coinListObject &&
                          coinListObject[contract.asset] &&
                          coinListObject[contract.asset].price.USD) ||
                          1),
                      'USD'
                    )} */}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Quantity</div>
              <div className="value">
                <div className="primary">
                  {contract && contract.num_of_bonds} Bond
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">
                <div className="primary">Total Cost</div>
                <div className="secondary">
                  Today’s USD Value For{' '}
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  Initial USD Value For{' '}
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
              </div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract.investment *
                      ((coinListObject &&
                        coinListObject[contract.asset] &&
                        coinListObject[contract.asset].price.USD) ||
                        1),
                    'USD',
                    defaultPrecission
                  )}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract && contract.investment_usd,
                    'USD',
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 2:
        return (
          <div className="listDetail">
            <div className="head">Redemption Details</div>
            <div className="date">
              {moment(contract && contract.redemption_timestamp).format(
                '[Date: ] MMMM Do YYYY [At] hh:mm A z'
              )}
            </div>
            <div className="item">
              <div className="label">Redemption Value</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract &&
                      contract.redemptionFee + contract.redemptionAmount,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                {/* <div className="secondary">
                    $
                    {FormatCurrency(
                      contract.redemptionFeeUSD + contract.redemptionAmountUSD,
                      'USD'
                    )}
                  </div> */}
              </div>
            </div>
            <div className="item">
              <div className="label">Redemption Fee</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionFee,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                {/* <div className="secondary">
                    $
                    {FormatCurrency(contract && contract.redemptionFeeUSD, 'USD')}
                  </div> */}
              </div>
            </div>
            <div className="item">
              <div className="label">Gross Redemption Value</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionAmount,
                    contract && contract.asset,
                    defaultPrecission
                  )}{' '}
                  {contract && contract.asset}
                </div>
                {/* <div className="secondary">
                    $
                    {FormatCurrency(
                      contract && contract.redemptionAmountUSD,
                      'USD'
                    )}
                  </div> */}
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 3:
        return (
          <div className="listDetail">
            <div className="head">Daily Earning Power</div>
            <div className="date">{`${contract.days} Payments`}</div>
            <div className="item">
              <div className="label">Daily Interest Rate</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(contract && contract.interest_rate, 3)}%
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Daily Interest</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract &&
                      (contract.investment * contract.interest_rate) / 100,
                    5
                  )}{' '}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract &&
                      (contract.investment_usd * contract.interest_rate) / 100,
                    'USD',
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 4:
        return (
          <div className="listDetail">
            <div className="head">Term Earning Power</div>
            <div className="date">{`${contract.days} Payments`}</div>
            <div className="item">
              <div className="label">Term Interest Rate</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract && contract.interest_rate * contract.days,
                    3
                  )}
                  %
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Term Interest</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract &&
                      (contract.investment *
                        contract.interest_rate *
                        contract.days) /
                        100,
                    5
                  )}{' '}
                  {contract && contract.asset}
                </div>
                <div className="secondary">
                  $
                  {FormatCurrency(
                    contract &&
                      (contract.investment_usd *
                        contract.interest_rate *
                        contract.days) /
                        100,
                    'USD',
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 5:
        return (
          <div className="listDetail">
            <div className="head">Interest Rate Mechanics</div>
            <div className="date">
              Daily Rate =&nbsp;
              {FormatNumber(contract && contract.interest_rate, 3)}%
            </div>
            <div className="item">
              <div className="label">Base Compression Rate</div>
              <div className="value">
                <div className="primary">
                  {FormatNumber(
                    contract && contract.interest_factors.base_rate_lower,
                    3
                  )}
                  %
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Interest Rate Velocity</div>
              <div className="value">
                <div className="primary up">
                  {FormatNumber(
                    contract && contract.interest_factors.base_velocity,
                    2
                  )}
                  %
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Velocity Acceleration Factor</div>
              <div className="value">
                <div className="primary down">
                  {FormatNumber(
                    contract && -contract.interest_factors.acceleration,
                    2
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 6:
        return (
          <div className="listDetail">
            <div className="head">Fees</div>
            <div className="date">2 Fee Structures</div>
            {feesItem()}
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      case 7:
        return (
          <div className="listDetail">
            <div className="head">Net ROI</div>
            <div className="item">
              <div className="label">Total Investment</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.investment,
                    contract && contract.asset,
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Term Earnings</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.initial_earning_power,
                    contract && contract.asset,
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Issuance Fees</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract && contract.redemptionFee,
                    contract && contract.asset,
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Net Term Earnings</div>
              <div className="value">
                <div className="primary">
                  {FormatCurrency(
                    contract &&
                      contract.initial_earning_power - contract.redemptionFee,
                    contract && contract.asset,
                    defaultPrecission
                  )}
                </div>
              </div>
            </div>
            <div className="item big">
              <div className="label">Net ROI</div>
              <div className="value">
                <div className="primary up">
                  {FormatNumber(
                    contract &&
                      (contract.initial_earning_power / contract.investment) *
                        100,
                    2
                  )}
                  %
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setListDetail(null);
              }}
              className="close"
              icon={faCaretUp}
            />
          </div>
        );
      default:
        return (
          <>
            <div className="listItem" onClick={() => setListDetail(1)}>
              Issuance Details
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className="listItem" onClick={() => setListDetail(2)}>
              Redemption Details
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className="listItem" onClick={() => setListDetail(3)}>
              Daily Earning Power
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className="listItem" onClick={() => setListDetail(4)}>
              Term Earning Power
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className="listItem" onClick={() => setListDetail(5)}>
              Interest Rate Mechanics
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className="listItem" onClick={() => setListDetail(6)}>
              Fees
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className="listItem" onClick={() => setListDetail(7)}>
              Net ROI
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </>
        );
    }
  };
  return <>{detailList()}</>;
}

export default BondMobileStats;
