import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import {
  useLoadBondVaults,
  useLoadMoneyMarketVault,
  useLoadVaultData,
  useLoadVaultTypes,
} from './api';
import bitcoin from '../../../static/images/vaults/bitcoin.svg';
import bonds from '../../../static/images/login/bond.svg';
import forex from '../../../static/images/vaults/forex.svg';
import staking from '../../../static/images/vaults/staking.svg';
import portfolioIcon from '../../../static/images/login/portfolioai.svg';
import moneymarket from '../../../static/images/vaults/moneymarket.svg';
import './vaults.scss';
import Toggle from '../../Toggle';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useCoinContextData } from '../../../context/CoinContext';
import { ListComponent } from '../../ListComponent';

export const Vaults = () => {
  const history = useHistory();
  // const [searchedValue, setSearchedValue] = useState('');
  // const [balanceToggle, setBalanceToggle] = useState(false);

  const { email } = useContext(BankContext);
  const {
    appCode,
    appCurrencyName,
    appLogo,
    appCurrencySymbol,
    searchedValue,
    setSearchedValue,
    balanceToggle,
    setBalanceToggle,
  } = useAppContextDetails();
  const {
    coinType,
    setCoinType,
    updateVaultData,
    setUpdateVaultData,
    setPlaceholder,
    updateBondVaultData,
    setUpdateBondVaultData,
    updateMMVaultData,
    setUpdateMMVaultData,
  } = useCoinContextData();
  const vaultData = useLoadVaultData(appCode);

  const { data: vaultTypeResponse, isLoading: isVaultTypeLoading } =
    useLoadVaultTypes(email, appCurrencySymbol);

  useEffect(() => {
    switch (coinType) {
      case 'portfolio':
        return setPlaceholder('Search Vault Types');
      case 'crypto':
        return setPlaceholder('Search Crypto Vaults');
      case 'fiat':
        return setPlaceholder('Search Forex Vaults');
      case 'moneyMarkets':
        return setPlaceholder('Search Money Market Vaults');
      case 'bonds':
        return setPlaceholder('Search Bond Vaults');
    }
  }, [coinType]);

  const {
    data: moneyMarketVaultData,
    isLoading: isMoneyMarketVaultDataLoading,
  } = useLoadMoneyMarketVault(
    email,
    appCurrencyName,
    appCode,
    balanceToggle,
    true,
    coinType,
    updateMMVaultData,
    setUpdateMMVaultData
  );

  const moneyMarketData = useMemo(
    () =>
      moneyMarketVaultData?.data?.result?.[0]?.balances?.[0]?.liquid_balances,
    [moneyMarketVaultData]
  );

  const { data: bondVaultData, isLoading: isBondVaultLoading } =
    useLoadBondVaults(
      email,
      balanceToggle,
      appCurrencyName,
      true,
      coinType,
      updateBondVaultData,
      setUpdateBondVaultData
    );
  const bondData = useMemo(
    () => bondVaultData?.data?.result?.[0]?.balances,
    [bondVaultData]
  );

  useEffect(() => {
    if (coinType === 'crypto' || coinType === 'fiat')
      vaultData.mutate({
        app_code: appCode,
        email,
        displayCurrency: appCurrencyName,
        with_balances: balanceToggle,
        type: coinType,
      });
    setUpdateVaultData(false);
  }, [appCode, balanceToggle, coinType, updateVaultData]);

  // useEffect(() => {
  //   if (activeTab === 'liquid') {
  //     setCoinType('crypto');
  //   } else if (activeTab === 'defi') {
  //     setCoinType('bonds');
  //   }
  // }, [activeTab]);

  const userVaultData = vaultData.data?.data;
  const isLoading =
    vaultData?.isLoading ||
    isBondVaultLoading ||
    isMoneyMarketVaultDataLoading ||
    isVaultTypeLoading;

  const cachedVaultData = useMemo(
    () => ({
      userName: userVaultData?.username,
      totalVaultValue: userVaultData?.totalVaultsValue_DC,
      coinsData: userVaultData?.coins_data,
    }),
    [vaultData]
  );

  const { userName, totalVaultValue, coinsData } = cachedVaultData;
  const bondVaultTotalBalance = bondVaultData?.data?.result?.[0]?.totalValue;
  const moneyMarketTotalBalance = moneyMarketVaultData?.data?.totalBalance_dc;
  const portfolioTotalBalance = vaultTypeResponse?.data?.networth_dc;

  const totalBalance = () => {
    switch (coinType) {
      case 'crypto':
      case 'fiat':
        return totalVaultValue;
      case 'bonds':
        return bondVaultTotalBalance;
      case 'moneyMarkets':
        return moneyMarketTotalBalance;
      case 'portfolio':
        return portfolioTotalBalance;
      default:
        return 0;
    }
  };
  const formattedTotalValue = totalBalance()?.toFixed(2) ?? '0.00';

  if (!vaultData) return <LoadingAnimation icon={appLogo} width={200} />;

  return (
    <ListComponent
      isLoading={isLoading}
      leftHeaderData={<h6>Welcome Back {userName}</h6>}
      leftSubHeader={
        <div className="refer" onClick={() => history.push('/affiliate')}>
          <h6>Refer A Friend</h6>
        </div>
      }
      rightHeaderData={
        <>
          <h6 className="value">
            {appCurrencyName} {formattedTotalValue}
          </h6>
          <h6 className="value-label">Total Balance</h6>
        </>
      }
      listOfIcons={
        <div className="icons">
          {coinType !== 'portfolio' && (
            <div className="toggle">
              <Toggle
                toggle={balanceToggle}
                setToggle={setBalanceToggle}
                coinType={coinType}
              />
            </div>
          )}

          <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'crypto' ? 1 : '',
                }}
                src={bitcoin}
                alt="Bitcoin"
                key="img1"
                onClick={() => setCoinType('crypto')}
              />
            </div>
          </div>

          <div className="iconbox" style={{ borderTopRightRadius: '20px' }}>
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{ opacity: coinType === 'fiat' ? 1 : '' }}
                src={forex}
                alt="Forex"
                key="img2"
                onClick={() => setCoinType('fiat')}
              />
            </div>
          </div>

          <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'bonds' ? 1 : '',
                }}
                src={staking}
                alt="Staking"
                key="img4"
                onClick={() => setCoinType('bonds')}
              />
            </div>
          </div>

          <div className="iconbox" style={{ borderTopRightRadius: '20px' }}>
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'moneyMarkets' ? 1 : '',
                }}
                src={moneymarket}
                alt="MoneyMarket"
                key="img3"
                onClick={() => setCoinType('moneyMarkets')}
              />
            </div>
          </div>
        </div>
      }
      searchedValue={searchedValue}
      setSearchedValue={setSearchedValue}
      moneyMarketData={moneyMarketData}
      bondData={bondData}
      coinsData={coinsData}
      vaultTypeResponse={vaultTypeResponse}
    />
  );
};
