import React, { useState } from 'react';

import fulllogo from '../../static/images/player.svg';
import searchIcon from '../../static/images/search.svg';
import Layout from '../../Layout/Layout';
import Points from './Points';
import './Player.scss';
import Ads from './Ads'
export default function Index() {

  const [tabName,settabName]=useState("Points")
  return (
    <Layout active="Play" className="player" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="player-navbar">
          {/* <div>
            <img src={img1} alt="" />
          </div> */}
          <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z" fill="currentColor"></path></svg>
          <div className='player-inner' style={{marginLeft:"20px"}}>
          
          <div className="academy-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-academy">
            {/* <div className="all">
              <p>All Videos</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
          </div>
          {/* <div className='spendDetail'>Spend</div> */}
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '20%' }}>
            <div
              className={`each-option
              
              
              ${tabName=='Points'&&'each-option-selected'}`}
                onClick={() => settabName('Points')}
            >
              <div>
                <p>Score</p>
              </div>
            </div>
             <div
                className={`each-option
              
              
                ${tabName=='Ads'&&'each-option-selected'}`}
              onClick={() => settabName('Ads')}
              // onClick={() => setAcademytab('affiliates')}
            >
              <div>
                <p>Ads</p>
              </div>
            </div>
            {/* <div
              className={`each-option`}

              // onClick={() => setAcademytab('paid')}
            >
              <div>
                <p>Airdrops</p>
              </div>
            </div> */}
          </div>
          {/* <div
              className={`each-option`}

              // onClick={() => setAcademytab('paid')}
            >
              <div>
                <p> Global Rankings</p>
              </div>
            </div> */}
         
         
        </div>
        {tabName=="Points"&& <Points />}
        {/* */}
        {tabName=="Ads"&& <Ads/>}
        
      </div>
     </Layout>
  );
}
