import React, { useContext, useState } from 'react';
import { MOBILE_SIZE } from '../config';
import useWindowDimensions from '../utilsold/WindowSize';

function MainLayout({ children }) {
  const { width } = useWindowDimensions();
  return (
    <div
      className="wholePage"
      style={{
        // marginTop: width < MOBILE_SIZE ? '79.2px' : '103px',
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
}

export default MainLayout;
