import React from 'react';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';

const StackingRewardsControls = () => {
  return (
    <div
      className={classNames.stakingControls}
      style={{ opacity: '0.5', pointerEvents: 'none' }}
    >
      <div>+ &nbsp; Date Range </div>
      <div>+ &nbsp; Single Day </div>
      <div>+ &nbsp; Multi Day</div>
      {/* <div>+ &nbsp; Purchased App</div> */}
      <div>+ &nbsp; Destination App</div>
      <div>+ &nbsp; Direction</div>
      <div className={classNames.borderLine}></div>
    </div>
  );
};

export default StackingRewardsControls;
