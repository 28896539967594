import React, { useState, useEffect, useRef } from 'react';

import './points.scss';
import { getplayout, getPointsData } from './api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import { BankContext } from '../../context/Context';
export default function Index() {
  const [payOut, setpayOut] = useState({});
  const [points, setpoints] = useState({});
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [loader, setloader] = useState(false);
  //   const { email } = useContext(BankContext);
  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);
  useEffect(() => {
    PointsData();
    palyoutFunction();
  }, []);

  const palyoutFunction = async () => {
    setloader(true);
    await getplayout().then((response) => {
      let result = response?.data?.data;

      setpayOut(result);
      setloader(false);
      console.log('zxc', result);
    });
  };

  const PointsData = async () => {
    let obj = localStorage.getItem('web3UserId');
    // let obj=localStorage.getItem('loginData')
    // let pop=obj[0]?.user
    console.log('xzczxczxc', obj);
    await getPointsData(obj).then((response) => {
      let result = response?.data;
      setpoints(result);
    });
  };
  console.log('width', width);
  return (
    <div className="points-dashboard">
      <div className="top-inner">
        <div className="top-left" ref={elementRef}>
          <div>
            <h1>Rank Up</h1>
            <h2>To Multiply The Amount Of Points You Earn</h2>
          </div>

          <div className="topCardInner">
            <div
              className="innerCardTop"
              style={{
                background: 'rgba(255, 255, 255, 0.38)',
                border: '0px ',
              }}
            >
              <h3>Bronze</h3>
              <h4>1x</h4>
              <div
                className="requrement"
                style={{
                  fontWeight: '700',
                  fontSize: '12px',
                  background: '#fff',
                  color: '#4B2A91',
                }}
              >
                Current
              </div>
            </div>

            <div className="innerCardTop">
              <h3>Silver</h3>
              <h4>2x</h4>
              <div className="requrement">Rank Up</div>
            </div>

            <div className="innerCardTop">
              <h3>Gold</h3>
              <h4>3x</h4>
              <div className="requrement">Rank Up</div>
            </div>

            <div className="innerCardTop">
              <h3>Diamond</h3>
              <h4>4x</h4>
              <div className="requrement">Rank Up</div>
            </div>
          </div>
        </div>
        <div className="top-right">
          <div className="header">
            <div className="topheader">
              <div>
                <h1>Your Stats</h1>
                <p>Rank: Bronze</p>
              </div>
              <div>
                <h1>52</h1>
                <p>Global Standing</p>
              </div>
            </div>
            <div>
              <h2>Lifetime Earnings</h2>
              <div className="calculate">{points?.totalPoints?.toFixed(2)}</div>
            </div>
            <div>
              <h2>Total Spent</h2>
              <div className="calculate">0.00</div>
            </div>
            {/* <div className="cashout">Cash Out</div> */}
          </div>
        </div>
      </div>
      <div className="pointBottomCards">
        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#4B2A91',
            }}
          >
            {payOut?.rewardPoints} W3T Per Minute
          </div>
          <div className="numberCard">
            {loader ? (
              <Skeleton width="8rem" height="2.1rem" style={{ borderRadius: '0px' }} />
            ) : (
              <h1> {points?.totalPerMinuteArticlePoints?.toFixed(2)}</h1>
            )}
            <h2>Reading</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div className="analytis"> Analytics</div>
          </div>
        </div>
        {/*  */}
        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#17C5CD',
              background: 'rgba(23, 197, 205, 0.1)',
            }}
          >
            2 W3T Per Minute
          </div>
          <div className="numberCard">
          {loader ? (
              <Skeleton width="8rem" height="2.1rem" style={{ borderRadius: '0px' }} />
            ) : (
              <h1>{points?.totalPerMinuteVideoPoints?.toFixed(2)}</h1>
            )}
          
            <h2>Watching</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#17C5CD',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>

        {/*  */}

        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#18191d',
              background: 'rgba(8, 21, 45, 0.1)',
            }}
          >
            __ W3T Per Referral
          </div>
          <div className="numberCard">
            <h1>--</h1>
            <h2>Referrals</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#18191d',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>

        {/*  */}

        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#FFBA32',
              background: 'rgba(255, 186, 50, 0.1)',
            }}
          >
            {payOut?.articleRead} W3T Per Article
          </div>
          <div className="numberCard">

          {loader ? (
              <Skeleton width="8rem" height="2.1rem" style={{ borderRadius: '0px' }} />
            ) : (
              <h1>{points?.totalArticlePoints?.toFixed(2)}</h1>
            )}
          
 
            <h2>Article Completion</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#FFBA32',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>
        {/*  */}

        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#17C5CD',
              background: 'rgba(23, 197, 205, 0.1)',
            }}
          >
            __W3T Per Video
          </div>
          <div className="numberCard">
            <h1>--</h1>
            <h2>Video Completion</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#17C5CD',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>
        {/*  */}

        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#8D9CA8',
              background: 'rgba(141, 156, 168, 0.1)',
            }}
          >
            
            {payOut?.fiveArticleRead} W3T On Completion
          </div>
          <div className="numberCard">
          {loader ? (
              <Skeleton width="8rem" height="2.1rem" style={{ borderRadius: '0px' }} />
            ) : (
              <h1>{points?.totalBonusPoints?.toFixed(2)}</h1>
            )}
    
            <h2>Five Article Bonus</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#8D9CA8',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>
        {/*  */}

        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#E14248',
              background: 'rgba(225, 66, 72, 0.1)',
            }}
          >
            __ W3T On Completion
          </div>
          <div className="numberCard">
            <h1>--</h1>
            <h2>Five Video Bonus</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#E14248',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>

        {/*  */}

        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#C18678',
              background: 'rgba(193, 134, 120, 0.1)',
            }}
          >
            __ W3T Per Question
          </div>
          <div className="numberCard">
            <h1>--</h1>
            <h2>Questions</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#C18678',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>
        {/*  */}
        <div
          className="pointsCards"
          style={{
            width: `calc(${width}px / 3 - 1.4vw)`,
          }}
        >
          <div
            className="time-points"
            style={{
              color: '#63AD86',
              background: 'rgba(99, 173, 134, 0.1)',
            }}
          >
            {payOut?.dailyLogin} W3T Per Login
          </div>
          <div className="numberCard">
          {loader ? (
              <Skeleton width="8rem" height="2.1rem" style={{ borderRadius: '0px' }} />
            ) : (
              <h1>{points?.totalDailyPoints?.toFixed(2)}</h1>
            )}

            <h2>Daily Login</h2>
          </div>
          <div className="sectionBottomPoits">
            <div className="botteMpoinumberCardntDetial">
              <h3>Last 24HR:</h3>
              <h4>--</h4>
            </div>
            <div className="botteMpoinumberCardntDetial">
              <h3>% Of Total:</h3>
              <h4>--</h4>
            </div>
            <div
              className="analytis"
              style={{
                background: '#63AD86',
              }}
            >
              {' '}
              Analytics
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
