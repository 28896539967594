import React, { useState, useEffect, useContext } from 'react';
import { commissionForStaking } from './api';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import moment from 'moment';
import uparrow from '../../static/images/affiliate/uparrow.svg';
import Skeleton from 'react-loading-skeleton';

const StakingCommission = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [minDate, setMinDate] = useState(getDefaultMinDate());
  const [maxDate, setMaxDate] = useState(getDefaultMaxDate());
  const { email } = useContext(BankContext);
  const {
    stakingData,
    setStakingData,
    filteredStakingData,
    setFilteredStakingdata,
  } = useAppContextDetails();

  function fetchData() {
    setIsLoading(true);
    commissionForStaking(email)
      .then((response) => {
        let result = response?.data?.logsData?.logs;
        // console.log(result, 'result');
        if (result?.length > 0) {
          setStakingData(result);
          setFilteredStakingdata(result);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error, 'error in fetchData for Staking Commission');
        setIsLoading(false);
      });
  }

  function handleScroll(event) {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && !isLoading) {
      setMinDate(getPreviousMonth(minDate));
    }
  }

  function getPreviousMonth(date) {
    const d = new Date(date);
    d.setMonth(d.getMonth() - 1);
    return formatDate(d);
  }

  function getDefaultMinDate() {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return formatDate(d);
  }

  function getDefaultMaxDate() {
    return formatDate(new Date());
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  }

  // useEffect(() => {
  //   fetchData();
  // }, [minDate, maxDate]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      onScroll={handleScroll}
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      {filteredStakingData &&
        filteredStakingData?.map((e) => (
          <div key={e.id} className="ind-detailss">
            <div className="type1" style={{ width: '20%' }}>
              <div className="detailss">
                <p style={{ fontSize: '0.9rem', fontWeight: '600' }}>
                  {e?.txn?.email} ({e?.commissions?.ps})
                </p>
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                  {moment(e?.txn?.date).format('MMMM Do YYYY, h:mm a')}
                </p>
              </div>
            </div>
            <div className="type1" style={{ width: '20%' }}>
              <div className="detailss">
                <p style={{ fontSize: '0.9rem', fontWeight: '600' }}>
                  {e?.txn?.coin} Contract
                </p>
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                  {e?.txn?.contract_id}
                </p>
              </div>
            </div>
            <div className="dd1" style={{ width: '15%' }}>
              <div style={{ fontSize: '0.9rem', fontWeight: '600' }}>
                ${e?.txn?.commission_value?.toFixed(4)}
              </div>
              <div style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                ${e?.txn?.amount?.toFixed(4)} - $
                {e?.txn?.amount_credited?.toFixed(4)}
              </div>
            </div>

            <div
              className="direct1"
              style={{ fontSize: '0.9rem', fontWeight: '600' }}
            >
              {e?.commissions?.dt_commission?.toFixed(7)}
            </div>
            <div
              className="direct1"
              style={{ fontSize: '0.9rem', fontWeight: '600' }}
            >
              {e?.commissions?.it_commission?.toFixed(7)}
            </div>
            <div
              className="baln1"
              style={{ width: '15%', fontSize: '0.9rem', fontWeight: '600' }}
            >
              {e?.commissions?.bd_commission?.toFixed(4)}
            </div>
          </div>
        ))}
      {isLoading &&
        Array(10)
          .fill(' ')
          .map((item, index) => {
            return (
              <div className="ind-detailss" key={index}>
                <div className="type1" style={{ width: '20%' }}>
                  <div className="detailss">
                    <Skeleton width={75} height={15} />
                    <p>
                      <Skeleton width={100} height={15} />
                    </p>
                  </div>
                </div>
                <div className="type1" style={{ width: '20%' }}>
                  <div className="detailss">
                    <Skeleton width={100} height={15} />
                    <p>
                      <Skeleton width={100} height={15} />
                    </p>
                  </div>
                </div>
                <div className="dd1" style={{ width: '15%' }}>
                  <Skeleton width={60} height={25} />
                </div>
                <div className="direct1">
                  <Skeleton width={60} height={25} />
                </div>
                <div className="direct1">
                  <Skeleton width={60} height={25} />
                </div>
                <div className="baln1" style={{ width: '15%' }}>
                  <Skeleton width={60} height={25} />
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default StakingCommission;
