import React from 'react';
import Lottie from 'react-lottie';
import * as animationDataDark from '../../../static/animations/loading.json';
import * as animationData from '../../../static/animations/loading-white.json';

const LoadingAnimation = ({ size = 90, dark = true, style = {} }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dark ? animationDataDark.default : animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div style={style}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  );
};

export default LoadingAnimation;
