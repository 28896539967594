import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DashSvg } from '../../static/images/mobile-footer-icon/dash.svg';
import { ReactComponent as CoinInvestSvg } from '../../static/images/mobile-footer-icon/coinvest.svg';
import { ReactComponent as MortageSvg } from '../../static/images/mobile-footer-icon/mortage.svg';
import { ReactComponent as ProfileSvg } from '../../static/images/search.svg';
import { ReactComponent as IceSvg } from '../../static/images/logo.svg';
import { useAppContextDetails } from '../../context/AppContext';

function BondOverviewMobileFooter({ menuSelected, setMenuSelected }) {
  const history = useHistory();
  const { appName } = useAppContextDetails();
  return (
    <div className="mobile-footer">
      <div
        className={`footer-itm ${menuSelected === 'Stats'}`}
        onClick={() => {
          try {
            setMenuSelected('Stats');
          } catch (error) {}
        }}
      >
        <DashSvg />
        <div className="tab-name">Stats</div>
      </div>
      <div
        className={`footer-itm ${menuSelected === 'Transactions'}`}
        onClick={() => {
          try {
            setMenuSelected('Transactions');
          } catch (error) {}
        }}
      >
        <MortageSvg />
        <div className="tab-name">Transactions</div>
      </div>
      <div
        className={`footer-itm`}
        onClick={() => {
          history.push('/app');
        }}
      >
        <IceSvg />
        <div className="tab-name">{appName}</div>
      </div>
      <div
        className={`footer-itm ${menuSelected === 'Actions'}`}
        onClick={() => {
          try {
            setMenuSelected('Actions');
          } catch (error) {}
        }}
      >
        <CoinInvestSvg />
        <div className="tab-name">Actions</div>
      </div>
      <div
        className={`footer-itm ${menuSelected === 'Search'}`}
        onClick={() => {
          try {
            setMenuSelected('Search');
          } catch (error) {}
        }}
      >
        <ProfileSvg />
        <div className="tab-name">Search</div>
      </div>
    </div>
  );
}

export default BondOverviewMobileFooter;
