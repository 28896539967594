import React, { useState } from 'react';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContextDetails } from '../../context/AppContext';

function CopyBondAPIModal({ onClose, api }) {
  const [copied, setCopied] = useState(false);
  const { appLogo } = useAppContextDetails();
  return (
    <div className="moreActionsWrapper">
      <div className="overlayClose" onClick={() => onClose()}></div>
      <div className="contentCard">
        <div className="head">
          <img src={appLogo} alt="" />
          Bond Actions
        </div>
        <div className="content copyContent">
          <div className="labelEndpoint">Endpoint</div>
          <div
            className="valueCpy"
            onClick={() => {
              navigator.clipboard.writeText(api).then(() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              });
            }}
          >
            <div className="value">
              {copied ? 'Copied To Clipboard...' : api}
            </div>
            <FontAwesomeIcon icon={faCopy} className="cpy" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CopyBondAPIModal;
