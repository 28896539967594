import React from 'react';
import { useHistory } from 'react-router-dom';
import copy from '../../../static/images/sidebar-icons/copy.svg';
import { useCoinContextData } from '../../../context/CoinContext';
import { useAppContextDetails } from '../../../context/AppContext';
import { updateScroll } from '../../../config/constants';
import { SubmitButton } from './helpers';
import quote from '../../../static/images/actionSidebar/qoute.svg';

export const AddActionFooter = ({ setToast, alternateFooterComponent }) => {
  const history = useHistory();

  const {
    enterAmount,
    setCoinAction,
    selectedCoin,
    coinType,
    selectedTab,
    setEnterAmount,
    setSelectedTab,
    setCurrentStep,
    otcDesk,
    setOtcDesk,
    payMethod,
    setPayMethod,
    fundingCurrency,
    setFundingCurrency,
    setAddActionStep,
    addActionStep,
    hashText,
    setSubmitHashEnabled,
    setConfirmAmount,
    addForexCurrencyValue,
    setAddApiValue,
    setAddApiValueCoin,
    setToCurrencyApiValue,
    setCountryApiValue,
    setPayMethodApiValue,
    setAddForexCurrencyValue,
    isAddingForexLoading,
  } = useCoinContextData();

  const { appColorCode, appCode } = useAppContextDetails();

  const nextPage = () => {
    if (addForexCurrencyValue) {
      setConfirmAmount(true);
      setCurrentStep('step6');
      setSelectedTab('confirmQuote');
    }
    updateScroll();
  };

  const isEthCoin = selectedCoin.eth_token || selectedCoin.coinSymbol === 'ETH';
  const isTrxOrXrp =
    selectedCoin.coinSymbol === 'TRX' || selectedCoin.coinSymbol === 'XRP';
  const isSolCoin = selectedCoin.coinSymbol === 'SOL';

  const handleCloseButton = () => {
    setCurrentStep('step1');
    setSelectedTab('sendingCurrency');
    setCoinAction(['Menu']);
    setAddApiValue('');
    setAddApiValueCoin('');
    setToCurrencyApiValue('');
    setCountryApiValue('');
    setPayMethodApiValue('');
    setFundingCurrency(false);
    setPayMethod(false);
    setOtcDesk(false);
    setAddForexCurrencyValue('');
    updateScroll();
  };

  if (coinType === 'crypto')
    return (
      <>
        {addActionStep === 1 && (
          <>
            {selectedCoin.coin_address ? (
              <div className="d-flex w-100 share-copy-wrapper">
                <div className="share-copy-div w-50">
                  <SubmitButton
                    isEthCoin={isEthCoin}
                    isTrxOrXrp={isTrxOrXrp}
                    isSolCoin={isSolCoin}
                  />
                </div>
                <div
                  className="share-copy-div w-50"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(selectedCoin.coin_address)
                      .then(() => setToast(true))
                  }
                >
                  <div className="d-flex clickable-div">
                    <img className="mr-2" src={copy} alt="copy" width={20} />
                    <h5 className="mt-2">Copy</h5>
                  </div>
                </div>
              </div>
            ) : (
              alternateFooterComponent
            )}
          </>
        )}

        {addActionStep === 2 && (
          <>
            <div className="withdraw-footer">
              <div
                className="back1 w-100"
                key="back1"
                onClick={() => {
                  setAddActionStep(1);
                  updateScroll();
                }}
              >
                <h6>Back</h6>
              </div>
            </div>
          </>
        )}

        {addActionStep === 3 && (
          <>
            <div className="withdraw-footer">
              <div
                className="back1"
                key="back1"
                onClick={() => {
                  setAddActionStep(2);
                  updateScroll();
                }}
              >
                <h6>Back</h6>
              </div>
              <div
                className={hashText !== '' ? 'nextbtn' : 'nextbtn1'}
                key="nextbtn"
                style={{
                  backgroundColor: appColorCode,
                  opacity: hashText !== '' ? 1 : 0.25,
                  color: hashText !== '' ? 'white' : '',
                  cursor: hashText !== '' ? 'pointer' : '',
                }}
                onClick={() => {
                  setSubmitHashEnabled(true);
                  updateScroll();
                }}
              >
                <h6>Submit</h6>
              </div>
            </div>
          </>
        )}

        {addActionStep === 4 && (
          <>
            <div className="withdraw-footer">
              <div
                className="back1"
                key="back1"
                onClick={() => {
                  setCoinAction(['Menu']);
                  setAddActionStep(1);
                  updateScroll();
                }}
              >
                <h6>Close</h6>
              </div>
              <div
                className="nextbtn"
                key="nextbtn"
                style={{
                  backgroundColor: appColorCode,
                  opacity: 1,
                  color: 'white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push(
                    `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
                  )
                }
              >
                <h6>{selectedCoin?.coinSymbol} Vault</h6>
              </div>
            </div>
          </>
        )}
      </>
    );

  return (
    <>
      {selectedTab === 'enterAmount' && (
        <div
          className="withdraw-footer"
          style={{
            position: 'absolute',
            bottom: '12rem',
            width: '22%',
            zIndex: '5',
            borderTop: 'none',
            padding: '0 1.5rem',
            height: '3rem',
          }}
        >
          <div
            className="add-nextbtn"
            key="add-nextbtn"
            style={{
              backgroundColor: '#78D0B1',
              opacity: addForexCurrencyValue ? 1 : 0.25,
              color: addForexCurrencyValue ? 'white' : '',
              cursor: addForexCurrencyValue ? 'pointer' : '',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              alignItems: 'baseline',
              borderRadius: '25px',
              height: '100%',
            }}
            onClick={() => nextPage()}
          >
            <div className="hoverrr">
              <h6
                className="footText2"
                style={{ marginBottom: '0', fontWeight: '700' }}
              >
                Get Quote
              </h6>
              <img src={quote} alt="" />
            </div>
          </div>
        </div>
      )}
      {selectedTab === 'confirmQuote' && (
        <div
          className="withdraw-footer"
          style={{
            position: 'absolute',
            bottom: '12rem',
            width: '22%',
            zIndex: '5',
            borderTop: 'none',
            padding: '0 1.5rem',
            height: '3rem',
          }}
        >
          <div
            className="add-nextbtn"
            key="add-nextbtn"
            style={{
              backgroundColor: '#78D0B1',
              opacity: isAddingForexLoading ? 0.25 : 1,
              cursor: 'pointer',
              pointerEvents: isAddingForexLoading && 'none',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              alignItems: 'baseline',
              borderRadius: '25px',
              height: '100%',
            }}
            onClick={() => {
              setCurrentStep('step7');
              setSelectedTab('addSuccess');
              updateScroll();
            }}
          >
            <div className="hoverrr">
              <h6 className="footText2" style={{ marginBottom: '0' }}>
                Initiate Transaction
              </h6>
              <img src={quote} alt="" />
            </div>
          </div>
        </div>
      )}

      {/* {selectedTab === 'addSuccess' && (
        <div className="withdraw-footer">
          <div
            className="back1"
            onClick={handleCloseButton}
            style={{
              backgroundColor: appColorCode,
              opacity: isAddingForexLoading ? 0.25 : 1,
              cursor: 'pointer',
              pointerEvents: isAddingForexLoading && 'none',
              width: '100%',
            }}
          >
            <p className="footText" style={{ color: 'white' }}>
              Close Control Panel
            </p>
          </div>
        </div>
      )} */}

      {/* {selectedTab === 'accountDetails' && (
        <div className="withdraw-footer">
          <div className="back1" onClick={() => setSelectedTab('addSuccess')}>
            <p className="footText">Back</p>
          </div>
          <div
            className="add-nextbtn"
            key="add-nextbtn"
            style={{
              backgroundColor: appColorCode,
              opacity: 1,
              cursor: 'pointer',
            }}
            onClick={handleCloseButton}
          >
            <h6 className="footText2">Close</h6>
          </div>
        </div>
      )} */}
    </>
  );
};
