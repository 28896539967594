/* eslint-disable jsx-a11y/no-autofocus */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ProfileAvatar from './ProfileAvatar';
import { onPaste } from '../../../utils/FunctionTools';
import { useAppContextDetails } from '../../../context/AppContext';
import './search-layout.scss';
import black from '../../../static/images/affiliate/back-solid-button.svg';
import pasteicon from '../../../static/images/affiliate/paste-icon.svg';
import searchicon from '../../../static/images/affiliate/search-icon.svg';

const SearchLayout = ({
  value,
  setValue,
  onBack,
  onSubmit,
  placeholder = '',
  list,
  selectedFilter,
}) => {
  const [usersList, setUsersList] = useState();
  const [filteredList, setFilteredList] = useState();
  const {
    affiliateid,
  } = useAppContextDetails();

  useEffect(() => {
    if (list) {
      setUsersList(list);
    } else {
      (async () => {
        const affId = affiliateid;

        axios
          .post('https://comms.globalxchange.io/get_broker_names', { affiliate_id: affId })
          .then((resp) => {
            const { data } = resp;
            // console.log('Users List', data);
            setUsersList(data || []);
          })
          .catch((error) => {
            console.log('Error on getting AppList', error);
          });
      })();
    }
  }, [list]);

  useEffect(() => {
    const searchQuery = value.trim().toLowerCase();

    if (usersList) {
      if (selectedFilter) {
        const filterList = usersList.filter((item) => {
          let found = false;
          const searchData = item[selectedFilter.paramKey];

          if (searchData) {
            found = searchData?.toLowerCase().includes(searchQuery);
          }

          return found;
        });
        setFilteredList(filterList);
      } else {
        const filterList = usersList.filter(
          (item) =>
            item?.email?.toLowerCase()?.includes(searchQuery) ||
            item?.name?.toLowerCase()?.includes(searchQuery),
        );

        setFilteredList(filterList);
      }
    }
  }, [value, usersList, selectedFilter]);

  const onUserSelected = (selectedUser) => {
    onSubmit(selectedUser?.email, selectedUser);
  };

  return (
    <div className="search-layout-container">
      <div className="search-input-container">
        <img
          src={black}
          alt=""
          className="back-button"
          onClick={onBack}
        />
        <input
          type="text"
          className="search-input"
          placeholder={placeholder}
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="action-container">
          <img
            src={pasteicon}
            alt=""
            className="action-icon"
            onClick={() => onPaste(setValue)}
          />
        </div>
        <div className="action-container">
          <img
            src={searchicon}
            alt=""
            className="action-icon"
          />
        </div>
      </div>
      <div className="list-container">
        {filteredList?.map((item, index) => (
          <div
            key={item._id || item.email || index}
            className="list-item"
            onClick={() => onUserSelected(item)}
          >
            <ProfileAvatar
              name={item?.name}
              avatar={item?.profile_img}
              size={45}
            />
            <div className="item-details">
              <div className="item-name">{item?.name}</div>
              <div className="item-email">{item?.email}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchLayout;
