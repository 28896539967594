import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { uploadImageFunc } from '../../utils/imageUpload';
import { planBContext } from '../../context/PlanBContext';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import {
  addProductFunction,
  statusFilterFunction,
  clearFilterFunction,
  deleteProductFunction,
  addCompPlanFunction,
  getCustomerLicense,
} from './api';

import fulllogo from '../../static/images/retailer/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import close from '../CRMPage/close.svg';
import close1 from './close.svg';
import hamburger from './hamburger.svg';
import plus from '../CRMPage/plus.svg';
import add from './addcompPlan.svg';
import del from './delete.svg';
import dots from './dots.svg';
import dummy from './dummy.svg';
import editIcon from './editIcon.svg';
import './retailer.scss';
import uploading from '../../static/images/uploading.svg';
import loadingLogo from '../../static/images/logos/vv.svg';

const RetailerPage = () => {
  const {
    email,
    token,
    retailerTab,
    setRetailerTab,
    popularAction,
    setPopularAction,
  } = useContext(BankContext);

  const history = useHistory();

  if (!email) {
    history.push('/');
  }

  const [productAction, setProductAction] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [retailerData, setRetailerData] = useState([]);
  const [filteredretailerData, setFilteredretailerData] = useState([]);
  const [productSelected, setProductSelected] = useState(false);
  const [productData, setProductData] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [addProductStep, setAddProductStep] = useState(1);
  const [billingType, setBillingType] = useState('');
  const [isfetching, setIsfetching] = useState(false);
  const [allApps, setAllApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [allCurrency, setAllCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [productLabel, setProductLabel] = useState('');
  const [productTagline, setProductTagline] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [firstMonthPrice, setFirstMonthPrice] = useState();
  const [monthlyPrice, setMonthlyPrice] = useState();
  const [gracePeriod, setGracePeriod] = useState();
  const [secondAttempt, setSecondAttempt] = useState();
  const [thirdAttempt, setThirdAttempt] = useState();
  const [productPrice, setProductPrice] = useState();
  const [adding, setAdding] = useState(false);
  const [hamSelected, setHamSelected] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('default');
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [deleteProductStep, setDeleteProductStep] = useState('step1');
  const [deleting, setDeleting] = useState(false);
  const [addCompPlan, setAddCompPlan] = useState(false);
  const [addCompPlanStep, setAddCompPlanStep] = useState('step1');
  const [userCreatedApps, setUserCreatedApps] = useState([]);
  const [compPlanApp, setCompPlanApp] = useState('');
  const [levels, setLevels] = useState();
  const [addingComp, setAddingComp] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [multiplier, setMultiplier] = useState([]);
  const [gettingLicenseData, setGettingLicenseData] = useState(false);
  const [customerLicenseData, setCustomerLicenseData] = useState([]);
  const [filteredCustomerLicenseData, setFilteredCustomerLicenseData] =
    useState([]);

  function getProductData() {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/product/get?product_created_by=${email}&status=active`
      )
      .then((response) => {
        if (response?.data?.products?.length > 0) {
          let result = response?.data?.products;
          // console.log(response?.data, 'retailer products');
          setRetailerData(result);
          setFilteredretailerData(result);
          setIsloading(false);
        } else {
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log(error, 'retailer products error');
      });
  }

  useEffect(() => {
    getProductData();
  }, []);

  const getApps = () => {
    setIsfetching(true);
    axios
      .get('https://comms.globalxchange.io/gxb/apps/get')
      .then((response) => {
        let result = response?.data?.apps;
        // console.log(result, 'all apps');
        setAllApps(result);
        setIsfetching(false);
      })
      .catch((error) => {
        console.log(error, 'error in getting all apps');
        setIsfetching(false);
      });
  };

  const getAppsforUser = () => {
    setIsfetching(true);
    axios
      .get('https://comms.globalxchange.io/gxb/apps/get')
      .then((response) => {
        let result = response?.data?.apps;
        // console.log(result, 'getAppsforUser result');
        setUserCreatedApps(result);
        setIsfetching(false);
      })
      .catch((error) => {
        console.log(error, 'getAppsforUser error');
      });
  };

  const getCategories = () => {
    setIsfetching(true);
    axios
      .get('https://comms.globalxchange.io/gxb/product/category/get')
      .then((response) => {
        let result = response?.data?.categories;
        // console.log(result, 'all categories');
        setAllCategories(result);
        setIsfetching(false);
      })
      .catch((error) => {
        console.log(error, 'error in getting all categories');
      });
  };

  const getCurrency = () => {
    setIsfetching(true);
    axios
      .get('https://comms.globalxchange.io/coin/vault/get/all/coins')
      .then((response) => {
        let result = response?.data?.coins;
        // console.log(result, 'all coins');
        setAllCurrency(result);
        setIsfetching(false);
      })
      .catch((error) => {
        console.log(error, 'error in getting all coins');
      });
  };

  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    setAddProduct(false);
    setAddProductStep(1);
    setBillingType('');
    setSelectedApp('');
    setSelectedCategory('');
    setProductName('');
    setProductCode('');
    setProductLabel('');
    setProductTagline('');
    setProductDescription('');
    setProductImage('');
    setSelectedCurrency('');
    setProductImage('');
    setFirstMonthPrice();
    setMonthlyPrice();
    setGracePeriod();
    setSecondAttempt();
    setThirdAttempt();
    setProductPrice();
    getProductData();
  }

  const myTimeout1 = () => {
    setTimeout(reload1, 3000);
  };

  function reload1() {
    setAddCompPlan(false);
    setAddCompPlanStep('step1');
    setUserCreatedApps([]);
    setCompPlanApp('');
    setLevels();
    setInputValues([]);
    setMultiplier([]);
    history.push('/engagement');
  }

  const myTimeout2 = () => {
    setTimeout(reload2, 3000);
  };

  function reload2() {
    setDeleteProduct(false);
    setDeleteProductStep('step1');
    setProductSelected(false);
    getProductData();
  }

  const addProductMonthly = () => {
    setAdding(true);
    let obj = {
      email,
      token,
      product_code: productCode,
      product_name: productName,
      product_icon: productImage,
      revenue_account: email,
      client_app: selectedApp,
      product_category_code: selectedCategory,
      sub_category_code: '',
      custom_product_label: productLabel,
      points_creation: false,
      sub_text: productTagline,
      full_description: productDescription,
      first_purchase: {
        price: firstMonthPrice,
        coin: selectedCurrency,
      },
      billing_cycle: {
        monthly: {
          price: monthlyPrice,
          coin: selectedCurrency,
        },
      },
      grace_period: gracePeriod,
      first_retry: secondAttempt,
      second_retry: thirdAttempt,
      staking_allowed: false,
      staking_details: {},
    };

    addProductFunction(obj)
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'product added successfully monthly');
        if (result?.status && result?.product_id) {
          setAdding(false);
          setAddProductStep(7);
          myTimeout();
        } else {
          setAdding(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in adding product monthly');
      });
  };

  const addProductOnce = () => {
    setAdding(true);
    let obj = {
      email,
      token,
      product_code: productCode,
      product_name: productName,
      product_icon: productImage,
      revenue_account: email,
      client_app: selectedApp,
      product_category_code: selectedCategory,
      sub_category_code: '',
      custom_product_label: productLabel,
      points_creation: false,
      sub_text: productTagline,
      full_description: productDescription,
      first_purchase: {
        price: productPrice,
        coin: selectedCurrency,
      },
      billing_cycle: {
        lifetime: {
          price: productPrice,
          coin: selectedCurrency,
        },
      },
      grace_period: 0,
      first_retry: 0,
      second_retry: 0,
      staking_allowed: false,
      staking_details: {},
    };

    addProductFunction(obj)
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'product added successfully lifetime');
        if (result?.status && result?.product_id) {
          setAdding(false);
          setAddProductStep(7);
          myTimeout();
        } else {
          setAdding(false);
        }
      })
      .catch((error) => {
        console.log(error, 'error in adding product lifetime');
      });
  };

  const deleteproduct = () => {
    setDeleting(true);
    let obj = {
      email,
      token,
      product_id: productData?.product_id,
    };
    deleteProductFunction(obj).then((response) => {
      let result = response?.data;
      // console.log(result, 'deleteproduct result');
      if (result?.status) {
        setDeleting(false);
        setDeleteProductStep('step2');
        myTimeout2();
      } else {
        setDeleting(false);
      }
    });
  };

  const addComplan = () => {
    setAddingComp(true);

    let fixedPayouts = inputValues.map((e, i) => {
      return {
        level: i,
        percentage: e,
      };
    });
    // console.log(fixedPayouts, 'fixedPayouts');

    let numValues = multiplier.map((e, i) => {
      return {
        level: i,
        numerator: e,
      };
    });
    // console.log(numValues, 'numValues');

    let obj = {
      email,
      token,
      app_code: compPlanApp,
      product_id: productData?.product_id,
      comp_plan_id: 'comp4',
      fixed_payouts: fixedPayouts,
      numeratorValues: numValues,
    };
    // console.log(obj, 'object');

    addCompPlanFunction(obj).then((response) => {
      let result = response?.data;
      console.log(result);
      if (result?.status) {
        setAddingComp(false);
        setAddCompPlanStep('step6');
        myTimeout1();
      } else {
        setAddingComp(false);
      }
    });
  };

  const statusFilter = (status) => {
    setIsloading(true);
    statusFilterFunction(email, status).then((response) => {
      let result = response?.data?.products;
      // console.log(result, 'statusFilter result');
      setRetailerData(result);
      setFilteredretailerData(result);
      setIsloading(false);
    });
  };

  const clearFilter = () => {
    setIsloading(true);
    clearFilterFunction(email).then((response) => {
      let result = response?.data?.products;
      setRetailerData(result);
      setFilteredretailerData(result);
      setIsloading(false);
    });
  };

  function filterItem(text) {
    let filterItem = retailerData?.filter((eachitem) => {
      return eachitem?.product_name
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilteredretailerData(filterItem);
  }

  const styles = {
    opacity: '0.25',
    pointerEvents: 'none',
  };
  const applyStyle = (condition) => (condition ? {} : styles);

  function spreadFunc(value) {
    if (value.length > 0) {
      const result = value.reduce((acc, val) => acc && val);
      // console.log(result, 'resultttt');
      return result;
    }
  }

  const handleLevelChange = (event) => {
    const newLevel = parseInt(event.target.value);
    if (newLevel >= 1) {
      setLevels(newLevel);
      setInputValues(Array(newLevel).fill(''));
      setMultiplier(Array(newLevel).fill(''));
    }
  };

  const handleInputChange = (index, event, funcValue, func) => {
    const newInputValues = [...funcValue];
    newInputValues[index] = event.target.value;
    // console.log(newInputValues, 'newInputValues');
    func(newInputValues);
  };

  const renderLevelInputs = (funcValue, func) => {
    return funcValue.map((value, index) => (
      <div className="each-action1" key={index}>
        <div className="partition">
          <div>{index}</div>
          <input
            type="number"
            value={value}
            onChange={(event) =>
              handleInputChange(index, event, funcValue, func)
            }
            placeholder="0.00%"
          />
        </div>
      </div>
    ));
  };

  useEffect(() => {
    setGettingLicenseData(true);
    getCustomerLicense(email).then((response) => {
      let result = response?.data?.licenses;
      // console.log(result, 'getLicenseData result');
      setCustomerLicenseData(result);
      setFilteredCustomerLicenseData(result);
      setGettingLicenseData(false);
    });
  }, []);

  return (
    <Layout active="Retailer" className="retailer" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="retailer-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              className="retailer-hamburger"
              onClick={() => {
                setHamSelected(!hamSelected);
              }}
            >
              <img src={hamSelected ? close1 : hamburger} alt="" />
            </div>
            <div className="retailer-icon" style={{ width: '25%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-retailer">
              <div className="im">
                <input
                  type="text"
                  placeholder="Search Anything"
                  onChange={(event) => {
                    if (retailerTab === 'Products') {
                      filterItem(event.target.value);
                    }
                  }}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-btn"
            onClick={() => {
              setPopularAction(!popularAction);
            }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '35%' }}>
            <div
              className={`each-option ${
                retailerTab === 'Products' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: retailerTab === 'Products' ? '600' : '',
              }}
              onClick={() => setRetailerTab('Products')}
            >
              <div>
                <p>Products</p>
              </div>
            </div>
            <div
              className={`each-option ${
                retailerTab === 'Customers' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: retailerTab === 'Customers' ? '600' : '',
              }}
              // onClick={() => setRetailerTab('Customers')}
            >
              <div>
                <p>Customers</p>
              </div>
            </div>
            <div
              className={`each-option ${
                retailerTab === 'Customer Licenses'
                  ? 'each-option-selected'
                  : ''
              }`}
              style={{
                fontWeight: retailerTab === 'Customer Licenses' ? '600' : '',
              }}
              onClick={() => setRetailerTab('Customer Licenses')}
            >
              <div>
                <p>Customer Licenses</p>
              </div>
            </div>
          </div>
        </div>

        {retailerTab === 'Products' ? (
          <div className="retailer-content">
            <div
              style={{
                width: hamSelected ? '70%' : '100%',
                height: '100%',
                overflowY: 'scroll',
                padding: '2% 4% 0',
              }}
            >
              <div className="bs-products">
                {isloading
                  ? Array(10)
                      .fill(' ')
                      .map((item, index) => {
                        return (
                          <div
                            className="each-productt"
                            key={index}
                            style={{ width: hamSelected ? '48%' : '31.5%' }}
                          >
                            <div className="top-sec">
                              <div style={{ width: '50%' }}>
                                <Skeleton
                                  width={100}
                                  height={100}
                                  borderRadius={50}
                                />
                              </div>
                              <Skeleton width={100} height={35} />
                            </div>
                            <div className="mid-sec">
                              <Skeleton width={150} height={35} />
                            </div>
                            <div className="bottom-sec">
                              <div>
                                <div className="amnt">
                                  <Skeleton width={50} height={35} />
                                </div>
                                <div className="first">
                                  <Skeleton width={100} height={35} />
                                </div>
                              </div>
                              <div>
                                <div className="amnt">
                                  <Skeleton width={50} height={35} />
                                </div>
                                <div className="first">
                                  <Skeleton width={100} height={35} />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  : retailerTab === 'Products' &&
                    filteredretailerData?.length > 0
                  ? filteredretailerData?.map((e, i) => {
                      return (
                        <div
                          className="each-productt"
                          key={i}
                          onClick={() => {
                            history.push(`/retailer/${e?.product_code}`);
                            localStorage.setItem(
                              'retailerProduct',
                              JSON.stringify(e)
                            );
                          }}
                          style={{
                            opacity: !productSelected
                              ? '1'
                              : productSelected && productData?._id === e?._id
                              ? '1'
                              : '0.25',
                            width: hamSelected ? '48%' : '31.5%',
                          }}
                        >
                          <div className="top-sec">
                            <div style={{ width: '50%' }}>
                              <img src={e?.product_icon} alt="" />
                            </div>
                            <button
                              onClick={(event) => {
                                event.stopPropagation();
                                setProductAction(!productAction);
                                setProductData(e);
                                setProductSelected(true);
                              }}
                            >
                              <img src={dots} alt="" />
                            </button>
                          </div>
                          <div className="mid-sec">{e?.product_name}</div>
                          <div className="bottom-sec">
                            <div>
                              <div className="amnt">
                                {e?.first_purchase?.price
                                  ? Number(e?.first_purchase?.price)?.toFixed(2)
                                  : '0.00'}{' '}
                                &nbsp;
                                {e?.first_purchase?.coin
                                  ? e?.first_purchase?.coin
                                  : ''}
                              </div>
                              <div className="first">First Month</div>
                            </div>
                            <div>
                              <div className="amnt">
                                {e?.monthly && e?.billing_cycle?.monthly?.price
                                  ? Number(
                                      e?.billing_cycle?.monthly?.price
                                    )?.toFixed(2)
                                  : '0.00'}{' '}
                                &nbsp;
                                {e?.monthly && e?.billing_cycle?.monthly?.coin
                                  ? e?.billing_cycle?.monthly?.coin
                                  : e?.billing_cycle?.lifetime?.coin}
                              </div>
                              <div className="first">Monthly</div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </div>
            </div>
            {hamSelected && (
              <div className="right-ai-container">
                {selectedFilter === 'default' && (
                  <div className="right-ai-items">
                    <div className="filterItemR">
                      <div>
                        <div>App</div>
                        <div
                          className="btn"
                          // onClick={() => {
                          //   setSelectedFilter('App');
                          // }}
                        >
                          All
                        </div>
                      </div>
                    </div>
                    <div className="filterItemR">
                      <div>
                        <div>Status</div>
                        <div
                          className="btn"
                          onClick={() => {
                            setSelectedFilter('Status');
                          }}
                        >
                          Active
                        </div>
                      </div>
                    </div>
                    <div className="filterItemR">
                      <div>
                        <div>Category</div>
                        <div
                          className="btn"
                          // onClick={() => {
                          //   setSelectedFilter('Category');
                          // }}
                        >
                          All
                        </div>
                      </div>
                    </div>
                    <div className="filterItemR">
                      <div>
                        <div>Billing Method</div>
                        <div
                          className="btn"
                          // onClick={() => {
                          //   setSelectedFilter('Billing Method');
                          // }}
                        >
                          All
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedFilter === 'Status' && (
                  <div className="filterOption">
                    <div className="headerTxt">Select Status</div>
                    <div className="scrollableDiv">
                      <div
                        className="eachDiv"
                        onClick={() => {
                          statusFilter('active');
                        }}
                      >
                        Active
                      </div>
                      <div
                        className="eachDiv"
                        onClick={() => {
                          statusFilter('inactive');
                        }}
                      >
                        Inactive
                      </div>
                    </div>
                    <div className="BtnsDiv">
                      <div
                        style={{ background: '#18191d' }}
                        onClick={() => {
                          clearFilter();
                        }}
                      >
                        Clear All Filters
                      </div>
                      <div
                        style={{ background: '#CF1A38' }}
                        onClick={() => {
                          setSelectedFilter('default');
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                  </div>
                )}

                {selectedFilter === 'App' && (
                  <div className="filterOption">
                    <div className="headerTxt">Select App</div>
                    <div className="scrollableDiv">
                      <div className="eachDiv">App 1</div>
                      <div className="eachDiv">App 2</div>
                      <div className="eachDiv">App 3</div>
                      <div className="eachDiv">App 4</div>
                      <div className="eachDiv">App 5</div>
                    </div>
                    <div className="BtnsDiv">
                      <div
                        style={{ background: '#18191d' }}
                        // onClick={() => {
                        //   clearFilter();
                        // }}
                      >
                        Clear All Filters
                      </div>
                      <div
                        style={{ background: '#CF1A38' }}
                        onClick={() => {
                          setSelectedFilter('default');
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                  </div>
                )}

                {selectedFilter === 'Category' && (
                  <div className="filterOption">
                    <div className="headerTxt">Select Category</div>
                    <div className="scrollableDiv">
                      <div className="eachDiv">Category 1</div>
                      <div className="eachDiv">Category 2</div>
                      <div className="eachDiv">Category 3</div>
                      <div className="eachDiv">Category 4</div>
                      <div className="eachDiv">Category 5</div>
                    </div>
                    <div className="BtnsDiv">
                      <div
                        style={{ background: '#18191d' }}
                        // onClick={() => {
                        //   clearFilter();
                        // }}
                      >
                        Clear All Filters
                      </div>
                      <div
                        style={{ background: '#CF1A38' }}
                        onClick={() => {
                          setSelectedFilter('default');
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                  </div>
                )}

                {selectedFilter === 'Billing Method' && (
                  <div className="filterOption">
                    <div className="headerTxt">Select Billing Type</div>
                    <div className="scrollableDiv">
                      <div className="eachDiv">Monthly</div>
                      <div className="eachDiv">Annual</div>
                      <div className="eachDiv">One Time</div>
                      <div className="eachDiv">Staking</div>
                    </div>
                    <div className="BtnsDiv">
                      <div
                        style={{ background: '#18191d' }}
                        // onClick={() => {
                        //   clearFilter();
                        // }}
                      >
                        Clear All Filters
                      </div>
                      <div
                        style={{ background: '#CF1A38' }}
                        onClick={() => {
                          setSelectedFilter('default');
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="retailer-content1">
            <div className="license-header">
              <div style={{ width: '22%' }}>User</div>
              <div style={{ width: '28%' }}>Product</div>
              <div>License</div>
              <div style={{ width: '12.5%' }}>Marketplace</div>
              <div style={{ width: '12.5%' }}>Status</div>
            </div>
            <div className="license-content">
              {gettingLicenseData
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="each-license" key={index}>
                          <div className="user-div">
                            <div>
                              <Skeleton width={75} height={20} />
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                          <div
                            className="ProductName-div"
                            style={{
                              flexDirection: 'row',
                              gap: '5px',
                              alignItems: 'center',
                              width: '28%',
                            }}
                          >
                            <div>
                              <Skeleton
                                width={40}
                                height={40}
                                borderRadius={5}
                              />
                            </div>
                            <div>
                              <div>
                                <Skeleton width={75} height={20} />
                              </div>
                              <div>
                                <Skeleton width={120} height={20} />
                              </div>
                            </div>
                          </div>
                          <div className="ProductName-div">
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                          <div
                            className="ProductName-div"
                            style={{ width: '12.5%' }}
                          >
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                          </div>
                          <div className="ProductName-div1">
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                : filteredCustomerLicenseData?.map((e, i) => {
                    return (
                      <div className="each-license" key={i}>
                        <div className="user-div">
                          <div>
                            <div style={{ fontWeight: '600' }}>{e?.name}</div>
                            <div>{e?.email}</div>
                          </div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                            width: '28%',
                          }}
                        >
                          <div>
                            <img src={e?.product_icon} alt="" />
                          </div>
                          <div>
                            <div style={{ fontWeight: '600' }}>
                              {e?.product_name}
                            </div>
                            <div>{e?.product_id}</div>
                          </div>
                        </div>
                        <div className="ProductName-div">
                          <div style={{ fontWeight: '600' }}>
                            {e?.license_id}
                          </div>
                          <div>{e?.license_code}</div>
                        </div>
                        <div
                          className="ProductName-div"
                          style={{ width: '12.5%' }}
                        >
                          <div style={{ fontWeight: '600' }}>--</div>
                          <div>{e?.client_app}</div>
                        </div>
                        <div className="ProductName-div1">
                          <div>
                            {e?.status === 'true' || 'active'
                              ? 'Active'
                              : 'Inactive'}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        )}

        {popularAction && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setPopularAction(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setPopularAction(false);
                setAddProduct(true);
              }}
            >
              <div>
                <img src={plus} alt="" />
              </div>
              <div>Add New Product</div>
            </div>
          </div>
        )}

        {addProduct && (
          <div className="popular">
            {addProductStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Select Billing Type</div>
                  <div
                    onClick={() => {
                      setAddProduct(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div
                    className="each-action1"
                    onClick={() => {
                      setBillingType('monthly');
                      setAddProductStep(2);
                      getApps();
                    }}
                  >
                    <div>Monthly Subscription</div>
                  </div>
                  <div
                    className="each-action1"
                    onClick={() => {
                      setBillingType('once');
                      setAddProductStep(2);
                      getApps();
                    }}
                  >
                    <div>One Time</div>
                  </div>
                  <div
                    className="each-action1"
                    // onClick={() => {
                    //   setAddProductStep(2);
                    // }}
                    style={{ opacity: '0.5', cursor: 'not-allowed' }}
                  >
                    <div>Staking</div>
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddProduct(false);
                      setPopularAction(true);
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: '0.25',
                      cursor: 'not-allowed',
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addProductStep === 2 && (
              <>
                <div className="head-txt">
                  <div>Which Marketplace Do You Want To Sell On?</div>
                  <div
                    onClick={() => {
                      setAddProduct(false);
                      setAddProductStep(1);
                      setBillingType('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  {isfetching
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-action1" key={index}>
                              <Skeleton width={150} height={30} />
                            </div>
                          );
                        })
                    : allApps &&
                      allApps?.map((e, i) => {
                        return (
                          <div
                            className="each-action1"
                            onClick={() => {
                              setAddProductStep(3);
                              setSelectedApp(e?.app_code);
                              getCategories();
                            }}
                          >
                            <div>
                              <img src={e?.app_icon} alt="" />
                            </div>
                            <div>{e?.app_name}</div>
                          </div>
                        );
                      })}
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddProductStep(1);
                      setBillingType('');
                      setSelectedApp('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: '0.25',
                      cursor: 'not-allowed',
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addProductStep === 3 && (
              <>
                <div className="head-txt">
                  <div>How Would You Categorize This Product?</div>
                  <div
                    onClick={() => {
                      setAddProduct(false);
                      setAddProductStep(1);
                      setBillingType('');
                      setSelectedApp('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  {isfetching
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-action1" key={index}>
                              <Skeleton width={150} height={30} />
                            </div>
                          );
                        })
                    : allCategories &&
                      allCategories?.map((e, i) => {
                        return (
                          <div
                            className="each-action1"
                            onClick={() => {
                              setAddProductStep(4);
                              setSelectedCategory(e?.code);
                            }}
                          >
                            <div>{e?.name}</div>
                          </div>
                        );
                      })}
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddProductStep(2);
                      setSelectedApp('');
                      setSelectedCategory('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: '0.25',
                      cursor: 'not-allowed',
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addProductStep === 4 && (
              <>
                <div className="head-txt">
                  <div>Product Information</div>
                  <div
                    onClick={() => {
                      setAddProduct(false);
                      setAddProductStep(1);
                      setBillingType('');
                      setSelectedApp('');
                      setSelectedCategory('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="upload">
                    <ImageUploadDiv
                      setFunc={setProductImage}
                      funcValue={productImage}
                    />
                  </div>
                  <div className="each-action1">
                    <input
                      type="text"
                      placeholder="Product Name"
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                  <div className="each-action1">
                    <input
                      type="text"
                      placeholder="Product Code"
                      onChange={(e) => setProductCode(e.target.value)}
                    />
                  </div>
                  <div className="each-action1">
                    <input
                      type="text"
                      placeholder="Product Label"
                      onChange={(e) => setProductLabel(e.target.value)}
                    />
                  </div>
                  <div className="each-action1">
                    <input
                      type="text"
                      placeholder="Product Tagline"
                      onChange={(e) => setProductTagline(e.target.value)}
                    />
                  </div>
                  <div className="each-action1" style={{ minHeight: '15rem' }}>
                    <input
                      type="text"
                      placeholder="Product Description"
                      onChange={(e) => setProductDescription(e.target.value)}
                    />
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddProductStep(3);
                      setSelectedCategory('');
                      setProductName('');
                      setProductCode('');
                      setProductLabel('');
                      setProductTagline('');
                      setProductDescription('');
                      setProductImage('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        productName.length > 1 &&
                        productCode.length > 1 &&
                        productLabel.length > 1 &&
                        productTagline.length > 1 &&
                        productDescription.length > 1 &&
                        productImage
                          ? '1'
                          : '0.25',
                      cursor:
                        productName.length > 1 &&
                        productCode.length > 1 &&
                        productLabel.length > 1 &&
                        productTagline.length > 1 &&
                        productDescription.length > 1 &&
                        productImage
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        productName.length > 1 &&
                        productCode.length > 1 &&
                        productLabel.length > 1 &&
                        productTagline.length > 1 &&
                        productDescription.length > 1 &&
                        productImage
                      ) {
                        setAddProductStep(5);
                        getCurrency();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addProductStep === 5 && (
              <>
                <div className="head-txt">
                  <div>What Currency Is The Price In?</div>
                  <div
                    onClick={() => {
                      setAddProduct(false);
                      setAddProductStep(1);
                      setBillingType('');
                      setSelectedApp('');
                      setSelectedCategory('');
                      setProductName('');
                      setProductCode('');
                      setProductLabel('');
                      setProductTagline('');
                      setProductDescription('');
                      setProductImage('');
                      setProductImage('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  {isfetching
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-action1" key={index}>
                              <Skeleton width={150} height={30} />
                            </div>
                          );
                        })
                    : allCurrency &&
                      allCurrency?.map((e, i) => {
                        return (
                          <div
                            className="each-action1"
                            onClick={() => {
                              setAddProductStep(6);
                              setSelectedCurrency(e?.coinSymbol);
                            }}
                          >
                            <div>
                              <img src={e?.coinImage} alt="" />
                            </div>
                            <div>{e?.coinName}</div>
                          </div>
                        );
                      })}
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddProductStep(4);
                      setSelectedCurrency('');
                      setProductName('');
                      setProductCode('');
                      setProductLabel('');
                      setProductTagline('');
                      setProductDescription('');
                      setProductImage('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: '0.25',
                      cursor: 'not-allowed',
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addProductStep === 6 && (
              <>
                <div className="head-txt">
                  <div>Pricing Information</div>
                  <div
                    onClick={() => {
                      setAddProduct(false);
                      setAddProductStep(1);
                      setBillingType('');
                      setSelectedApp('');
                      setSelectedCategory('');
                      setProductName('');
                      setProductCode('');
                      setProductLabel('');
                      setProductTagline('');
                      setProductDescription('');
                      setProductImage('');
                      setSelectedCurrency('');
                      setProductImage('');
                      setFirstMonthPrice();
                      setMonthlyPrice();
                      setGracePeriod();
                      setSecondAttempt();
                      setThirdAttempt();
                      setProductPrice();
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  {billingType === 'monthly' ? (
                    <>
                      <div className="each-action2">
                        <input
                          type="number"
                          placeholder="First Months Price"
                          onChange={(e) => setFirstMonthPrice(e.target.value)}
                        />
                        <div className="currencyDiv">{selectedCurrency}</div>
                      </div>

                      <div className="each-action2">
                        <input
                          type="number"
                          placeholder="Monthly Price"
                          onChange={(e) => setMonthlyPrice(e.target.value)}
                        />
                        <div className="currencyDiv">{selectedCurrency}</div>
                      </div>

                      <div className="each-action2">
                        <input
                          type="number"
                          placeholder="Grace Period"
                          onChange={(e) => setGracePeriod(e.target.value)}
                        />
                        <div className="currencyDiv">Days</div>
                      </div>

                      <div className="each-action2">
                        <input
                          type="number"
                          placeholder="Second Charge Attempt"
                          onChange={(e) => setSecondAttempt(e.target.value)}
                        />
                        <div className="currencyDiv">Days</div>
                      </div>

                      <div className="each-action2">
                        <input
                          type="number"
                          placeholder="Third Charge Attempt"
                          onChange={(e) => setThirdAttempt(e.target.value)}
                        />
                        <div className="currencyDiv">Days</div>
                      </div>
                    </>
                  ) : (
                    <div className="each-action2">
                      <input
                        type="number"
                        placeholder="Product Price"
                        onChange={(e) => setProductPrice(e.target.value)}
                      />
                      <div className="currencyDiv">{selectedCurrency}</div>{' '}
                    </div>
                  )}
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setSelectedCurrency('');
                      setFirstMonthPrice('');
                      setMonthlyPrice('');
                      setGracePeriod('');
                      setSecondAttempt('');
                      setThirdAttempt('');
                      setProductPrice('');
                      setAddProductStep(5);
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        billingType === 'monthly' &&
                        firstMonthPrice &&
                        monthlyPrice &&
                        gracePeriod &&
                        secondAttempt &&
                        thirdAttempt
                          ? '1'
                          : billingType === 'once' && productPrice
                          ? '1'
                          : '0.25',
                      cursor:
                        billingType === 'monthly' &&
                        firstMonthPrice &&
                        monthlyPrice &&
                        gracePeriod &&
                        secondAttempt &&
                        thirdAttempt
                          ? 'pointer'
                          : billingType === 'once' && productPrice
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        billingType === 'monthly' &&
                        firstMonthPrice &&
                        monthlyPrice &&
                        gracePeriod &&
                        secondAttempt &&
                        thirdAttempt
                      ) {
                        addProductMonthly();
                      } else if (billingType === 'once' && productPrice) {
                        addProductOnce();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {adding && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={loadingLogo} width={200} />
              </div>
            )}

            {addProductStep === 7 && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setAddProduct(false);
                      setAddProductStep(1);
                      setBillingType('');
                      setSelectedApp('');
                      setSelectedCategory('');
                      setProductName('');
                      setProductCode('');
                      setProductLabel('');
                      setProductTagline('');
                      setProductDescription('');
                      setProductImage('');
                      setSelectedCurrency('');
                      setProductImage('');
                      setFirstMonthPrice();
                      setMonthlyPrice();
                      setGracePeriod();
                      setSecondAttempt();
                      setThirdAttempt();
                      setProductPrice();
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Created A New Product
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{
                      background: '#CF1A38',
                      width: '100%',
                    }}
                    // onClick={() => {
                    // setSelectedCurrency('');
                    // setFirstMonthPrice('');
                    // setMonthlyPrice('');
                    // setGracePeriod('');
                    // setSecondAttempt('');
                    // setThirdAttempt('');
                    // setProductPrice('');
                    // setAddProductStep(5);
                    // }}
                  >
                    See Product Profile
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {productAction && (
          <div className="popular">
            <div className="head-txt">
              <div>Product Actions</div>
              <div
                onClick={() => {
                  setProductAction(false);
                  setProductSelected(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              // onClick={() => {
              //   setProductAction(false);
              // }}
            >
              <div>
                <img src={plus} alt="" />
              </div>
              <div>Edit Product</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setProductAction(false);
                setAddCompPlan(true);
              }}
            >
              <div>
                <img src={add} alt="" />
              </div>
              <div>Add CompPlan</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                if (retailerTab === 'Products') {
                  setProductAction(false);
                  setDeleteProduct(true);
                }
              }}
            >
              <div>
                <img src={del} alt="" />
              </div>
              <div>Delete Product</div>
            </div>
          </div>
        )}

        {addCompPlan && (
          <div className="popular">
            {addCompPlanStep === 'step1' && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div
                    className="each-action1"
                    onClick={() => {
                      setAddCompPlanStep('step2');
                      getAppsforUser();
                    }}
                  >
                    <div>Unilevel Standard</div>
                  </div>

                  <div
                    className="each-action1"
                    style={{ opacity: '0.5', cursor: 'not-allowed' }}
                  >
                    <div>Binary Standard</div>
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setProductAction(true);
                      setAddCompPlan(false);
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: '0.25',
                      cursor: 'not-allowed',
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === 'step2' && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep('step1');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="subbTxt">
                    Which Marketplace Are You Listing On?
                  </div>
                  {isfetching
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-action1" key={index}>
                              <Skeleton width={150} height={30} />
                            </div>
                          );
                        })
                    : userCreatedApps &&
                      userCreatedApps?.map((e, i) => {
                        return (
                          <div
                            className="each-action1"
                            onClick={() => {
                              setAddCompPlanStep('step3');
                              setCompPlanApp(e?.app_code);
                            }}
                          >
                            <div>
                              <img src={e?.app_icon} alt="" />
                            </div>
                            <div>{e?.app_name}</div>
                          </div>
                        );
                      })}
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddCompPlanStep('step1');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: '0.25',
                      cursor: 'not-allowed',
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === 'step3' && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep('step1');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="subbTxt">
                    How Many Levels Will Be Paid Out?
                  </div>
                  <div className="each-action1">
                    <input type="number" onChange={handleLevelChange} />
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddCompPlanStep('step2');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: levels > 0 ? '1' : '0.25',
                      cursor: levels > 0 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() => {
                      if (levels > 0) {
                        setAddCompPlanStep('step4');
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === 'step4' && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep('step1');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="subbTxt">
                    Enter Payout Percentage Per Level
                  </div>
                  {renderLevelInputs(inputValues, setInputValues)}
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddCompPlanStep('step3');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      ...applyStyle(spreadFunc(inputValues)),
                      background: '#18191d',
                    }}
                    onClick={() => {
                      if (levels == inputValues.length) {
                        setAddCompPlanStep('step5');
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === 'step5' && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep('step1');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="subbTxt">Enter Multiplier Per Level</div>
                  {renderLevelInputs(multiplier, setMultiplier)}
                </div>

                <div className="stepBtns">
                  <div
                    style={{ background: '#CF1A38' }}
                    onClick={() => {
                      setAddCompPlanStep('step4');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      ...applyStyle(spreadFunc(multiplier)),
                    }}
                    onClick={() => {
                      addComplan();
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addingComp && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={loadingLogo} width={200} />
              </div>
            )}

            {addCompPlanStep === 'step6' && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep('step1');
                      setProductData('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Added A CompPlan To{' '}
                    {productData?.product_name}
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{
                      background: '#CF1A38',
                      width: '100%',
                    }}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    See Updated List
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {deleteProduct && (
          <div className="popular">
            {deleteProductStep === 'step1' && (
              <>
                <div className="head-txt">
                  <div>Delete Products</div>
                  <div
                    onClick={() => {
                      setDeleteProduct(false);
                      setProductSelected(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div
                  className="each-action"
                  onClick={() => {
                    deleteproduct();
                  }}
                >
                  <div>Confirm & Delete</div>
                </div>
                <div
                  className="each-action"
                  onClick={() => {
                    setProductAction(true);
                    setDeleteProduct(false);
                  }}
                >
                  <div>Never Mind</div>
                </div>
              </>
            )}

            {deleting && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={loadingLogo} width={200} />
              </div>
            )}

            {deleteProductStep === 'step2' && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setDeleteProduct(false);
                      setDeleteProductStep('step1');
                      setProductData('');
                      setProductSelected(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Deleted {productData?.product_name}
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{
                      background: '#CF1A38',
                      width: '100%',
                    }}
                    onClick={() => {
                      setDeleteProduct(false);
                      setDeleteProductStep('step1');
                      setProductData('');
                      setProductSelected(false);
                      window.location.reload();
                    }}
                  >
                    See Updated List
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default RetailerPage;

export const ImageUploadDiv = ({ heading, setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{ width: '100%', justifyContent: 'space-between' }}
    >
      <label htmlFor="profileUpdateImgPlanB" className="uploadFileDiv">
        <input
          className="uploadNewPicPlanB"
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div style={{ fontWeight: '500' }}>
          {planBAccountPicUploading ? 'Uploading...' : 'Upload Profile Image'}
        </div>
      </label>
      <div className="imageDiv" style={{ marginRight: '0' }}>
        <img
          src={funcValue ? funcValue : dummy}
          alt="planBAccountPic"
          className="profileImg"
          htmlFor="profileUpdateImgPlanB"
          style={{ width: '90px', height: '90px' }}
        />
        {planBAccountPicUploading ? (
          <div className="overlayDiv">
            <img src={uploading} alt="uploading" className="uploadingimg" />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
