import React, { useEffect, useState } from 'react';
import ChainViewHeader from './ChainViewHeader';
import LevelUsers from './LevelUsers';

const ChainViewItem = ({
  level,
  userList,
  onUserSelected,
  isLoading,
  headerDesc,
  setLastSelectedUser,
  emptyMessage,
  clearToIndex,
  onTreeClick,
  onPopupOpen,
  onSearchOpen,
  searchedUser,
  isMoneyViewOpen,
}) => {
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    setSelectedUser();
  }, [userList]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchedUser && userList) {
        setSelectedUser(searchedUser);

        const index = userList.findIndex(
          user => user.email === searchedUser.email,
        );

        if (index >= 0) {
          // carouselRef.current?.snapToItem(index);
        }
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchedUser, userList]);

  return (
    <div className="chain-view-item">
      <ChainViewHeader
        headerDesc={
          userList
            ? selectedUser
              ? `You Have Selected ${selectedUser.name}`
              : headerDesc
            : ''
        }
        selectedUser={selectedUser}
        level={level}
        onEdit={() => {
          setSelectedUser();
          clearToIndex(level);
        }}
        showEdit={!!selectedUser}
        showSearch={!selectedUser && userList}
        onSearch={() => onSearchOpen(userList, level)}
      />
      <LevelUsers
        userList={userList}
        emptyMessage={emptyMessage}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        isLoading={isLoading}
        setLastSelectedUser={setLastSelectedUser}
        onUserSelected={onUserSelected}
        level={level}
        isMoneyViewOpen={isMoneyViewOpen}
        onPopupOpen={onPopupOpen}
      />
    </div>
  );
};

export default ChainViewItem;
