import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import { useCoinContextData } from '../../context/CoinContext';
import { SIDEBAR_CONTENTS } from './constants';
import { SIDEBAR_MENU } from './constants';
import { useLocation } from 'react-router-dom';

//images
import settings from '../../static/images/icons/settings.svg';
import close from '../../static/images/icons/close.svg';
import plus from '../../static/images/clipIcons/plusBlack.svg';
import ddown from '../../static/images/clipIcons/ddown.svg';
import ddown1 from '../../static/images/clipIcons/dup.svg';
import guest from '../../static/images/guest.jpg';

import digifinex from '../../static/images/icons/digifinex.svg';
import coinMarketCap from '../../static/images/icons/coinMarketCap.svg';
import etherScan from '../../static/images/icons/etherScan.svg';
import dgp from '../../static/images/icons/dgp.svg';
import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';
import { openAppRetiredApp } from '../../assets/Functions/miniFunctions';

export const DefaultSideBarContent = ({
  active,
  sidebarCollapse,
  chatOn,
  setChatOn,
  appColorCode,
  openSettings,
  isSecondaryMenu,
  toggleSettings,
}) => {
  const {
    email,
    setSidebarCollapse,
    setSelectedTopItemReact,
    username,
    name,
    profileImg,
  } = useContext(BankContext);

  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
    isClose,
    setIsClose,
    setSelectedCoin,
    setCoinType,
    senbtnClicked,
    setSendBtnClicked,
  } = useCoinContextData();
  const {
    hideEveryone,
    setHideEveryone,
    hideAffiliate,
    setHideAffiliate,
    hideBrands,
    setHideBrands,
    hideInfluencers,
    setHideInfluencers,
    hideContentCreators,
    setHideContentCreators,
  } = useAppContextDetails();

  const { pathname } = useLocation();
  const history = useHistory();
  // const sideBarBorder = { borderLeft: '7px solid #CF1A38' };
  const sideBarBorder = {
    background: '#FFFFFF',
    border: ' 0.5px solid #E5E5E5',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    height: 'max-content',
    aspectRatio: '1/1',
    margin: '2rem 1rem',
  };
  const applyStyle = (condition) => (condition ? sideBarBorder : {});
  const closeChatScreen = () => (chatOn ? setChatOn(false) : null);
  const [hoverItem, setHoverItem] = useState('');

  function hoverFunctionOpportunities() {
    setHoverItem('Opportunities');
  }

  function hoverFunctionConnection1() {
    setHoverItem('Connection1');
  }

  function hoverFunctionPublishing() {
    setHoverItem('Publishing');
  }

  function hoverFunctionProduction() {
    setHoverItem('Production');
  }

  function hoverFunctionInfluenceMarket() {
    setHoverItem('InfluenceMarket');
  }

  function hoverFunctionVaults() {
    setHoverItem('vaults');
  }

  function hoverFunctionPlay() {
    setHoverItem('Play');
  }

  function hoverFunctionAffiliate() {
    setHoverItem('Affiliate');
  }
  function hoverFunctionMlm() {
    setHoverItem('mlm');
  }

  function hoverFunctionCRM() {
    setHoverItem('CRM');
  }

  function hoverFunctionBrands() {
    setHoverItem('Brands');
  }

  function hoverFunctionAffiliateBank() {
    setHoverItem('AffiliateBank');
  }

  function hoverFunctionCRM1() {
    setHoverItem('Network Chains');
  }

  function hoverFunctionBrands1() {
    setHoverItem('Deals');
  }

  function hoverFunctionEngagement() {
    setHoverItem('Engagement');
  }

  function hoverFunctionSubscriptions() {
    setHoverItem('Subscriptions');
  }

  function hoverFunctionCommercial() {
    setHoverItem('Commercials');
  }

  function hoverFunctionMalls() {
    setHoverItem('Retailer');
  }

  function hoverFunctionAdvertisers() {
    setHoverItem('Advertisers');
  }

  function hoverFunctionEndorsement() {
    setHoverItem('Endorsement');
  }

  function hoverFunctionLinked() {
    setHoverItem('Linked');
  }

  function hoverLeaveFunc() {
    setHoverItem('');
  }

  useEffect(() => {
    if (
      pathname.includes('malls') ||
      pathname.includes('opportunities') ||
      pathname.includes('subscriptions')
    ) {
      setHideEveryone(false);
      setHideAffiliate(true);
      setHideBrands(true);
      setHideContentCreators(true);
      setHideInfluencers(true);
    }
    if (
      pathname.includes('crm') ||
      pathname.includes('affiliatebank') ||
      pathname.includes('networkChain') ||
      pathname.includes('levels') ||
      pathname.includes('affiliate')
    ) {
      setHideEveryone(true);
      setHideAffiliate(false);
      setHideBrands(true);
      setHideContentCreators(true);
      setHideInfluencers(true);
    }
    if (
      pathname.includes('vault') ||
      pathname.includes('mlm') ||
      pathname.includes('advertise') ||
      pathname.includes('retailer') ||
      pathname.includes('engagement') ||
      pathname.includes('commercial')
    ) {
      setHideEveryone(true);
      setHideAffiliate(true);
      setHideBrands(false);
      setHideContentCreators(true);
      setHideInfluencers(true);
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: sidebarCollapse ? '92%' : '100%',
        display: isClose ? 'none' : 'flex',
        flexWrap: sidebarCollapse ? 'nowrap' : 'wrap',
        border: sidebarCollapse ? 'none' : '0.5px solid #EBEBEB',
        borderRight: 'none',
        flexFlow: sidebarCollapse ? 'column' : 'wrap',
        alignContent: sidebarCollapse ? '' : 'flex-start',
        borderLeft: 'none',
        borderBottom: 'none',
        justifyContent: sidebarCollapse ? 'center' : '',
        marginTop: sidebarCollapse ? '-32px' : '0',
      }}
    >
      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
        }}
        onClick={() => {
          setHideEveryone(!hideEveryone);
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideEveryone ? ddown : ddown1} alt="" />
          </div>
          <div>For Everyone</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'Opportunities' && !chatOn && sidebarCollapse
          ),
          display: hideEveryone ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/opportunities');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionOpportunities}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[0].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Opportunities' || active === 'Opportunities'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Opportunities' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[0].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Opportunities' && active != 'Opportunities'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[0].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'InfluenceMarket' && !chatOn && sidebarCollapse
          ),
          display: hideEveryone ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            setSelectedTopItemReact('InfluenceMarket');
            history.push('/malls');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionInfluenceMarket}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[1].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'InfluenceMarket' || active === 'InfluenceMarket'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'InfluenceMarket' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[1].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'InfluenceMarket' && active != 'InfluenceMarket'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[1].name}
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          borderRight: 'none',
          ...applyStyle(
            active === 'Subscriptions' && !chatOn && sidebarCollapse
          ),
          display: hideEveryone ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/subscriptions');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionSubscriptions}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[20].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Subscriptions' || active === 'Subscriptions'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Subscriptions' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[20].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Subscriptions' && active != 'Subscriptions'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[20].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'Play' && !chatOn && sidebarCollapse),
          display: hideEveryone ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        onClick={() => {
          if (email) {
            history.push('/play/ads');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionPlay}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[18].icon}
          alt="VaultIcon"
          style={{
            height: hoverItem === 'Play' || active === 'Play' ? '50px' : '40px',
            // opacity: '0.25',
            // cursor: 'not-allowed',
          }}
        />
        {!sidebarCollapse && active === 'Play' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[18].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display: hoverItem === 'Play' && active != 'Play' ? 'flex' : 'none',
            // opacity: '0.25',
            // cursor: 'not-allowed',
          }}
        >
          {SIDEBAR_MENU[18].name}
        </div>
      </div> */}
      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
          borderTop: !hideEveryone ? '0.5px solid #e7e7e7' : '',
        }}
        onClick={() => {
          setHideAffiliate(!hideAffiliate);
          if (!hideEveryone) {
            setHideEveryone(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideAffiliate ? ddown : ddown1} alt="" />
          </div>
          <div>For Affiliates</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'Affiliate' && !chatOn && sidebarCollapse),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/affiliate');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionAffiliate}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[3].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Affiliate' || active === 'Affiliate'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Affiliate' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[3].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Affiliate' && active != 'Affiliate'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[3].name}
        </div>
      </div> */}

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'CRM' && !chatOn && sidebarCollapse),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/crm');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionCRM}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[4].icon}
          alt="VaultIcon"
          style={{
            height: hoverItem === 'CRM' || active === 'CRM' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'CRM' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[4].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display: hoverItem === 'CRM' && active != 'CRM' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[4].name}
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          borderRight: 'none',
          ...applyStyle(active === 'Deals' && !chatOn && sidebarCollapse),
          display: hideAffiliate ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        // onClick={() => {
        //   if (email) {
        //     setSelectedTopItemReact('Resources');
        //     history.push('/academy');
        //   } else {
        //     history.push('/');
        //   }
        //   closeChatScreen();
        // }}
        onMouseEnter={hoverFunctionBrands1}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[5].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Deals' || active === 'Deals' ? '50px' : '40px',
            opacity: '0.25',
          }}
        />
        {!sidebarCollapse && active === 'Deals' && (
          <div className="sidebar-icon-label" style={{ opacity: '0.25' }}>
            {SIDEBAR_MENU[5].name}
          </div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Deals' && active != 'Deals' ? 'flex' : 'none',
            opacity: '0.25',
          }}
        >
          {SIDEBAR_MENU[5].name}
        </div>
      </div> */}

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'AffiliateBank' && !chatOn && sidebarCollapse
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/affiliatebank');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionAffiliateBank}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[6].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'AffiliateBank' || active === 'AffiliateBank'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'AffiliateBank' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[6].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'AffiliateBank' && active != 'AffiliateBank'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[6].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'Network Chains' && !chatOn && sidebarCollapse
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/networkChain');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionCRM1}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[7].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Network Chains' || active === 'Network Chains'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Network Chains' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[7].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Network Chains' && active != 'Network Chains'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[7].name}
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          borderRight: 'none',
          ...applyStyle(active === 'Brands' && !chatOn && sidebarCollapse),
          display: hideAffiliate ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        // onClick={() => {
        //   if (email) {
        //     setSelectedTopItemReact('Resources');
        //     history.push('/academy');
        //   } else {
        //     history.push('/');
        //   }
        //   closeChatScreen();
        // }}
        onMouseEnter={hoverFunctionBrands}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[8].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Brands' || active === 'Brands' ? '50px' : '40px',
            opacity: '0.25',
          }}
        />
        {!sidebarCollapse && active === 'Brands' && (
          <div className="sidebar-icon-label" style={{ opacity: '0.25' }}>
            {SIDEBAR_MENU[8].name}
          </div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Brands' && active != 'Brands' ? 'flex' : 'none',
            opacity: '0.25',
          }}
        >
          {SIDEBAR_MENU[8].name}
        </div>
      </div> */}

      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
        }}
        onClick={() => {
          setHideBrands(!hideBrands);
          if (!hideEveryone) {
            setHideEveryone(true);
          }
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideBrands ? ddown : ddown1} alt="" />
          </div>
          <div>For Brands</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'Retailer' && !chatOn && sidebarCollapse),
          display: hideBrands ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/retailer');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionMalls}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[11].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Retailer' || active === 'Retailer'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Retailer' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[11].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Retailer' && active != 'Retailer'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[11].name}
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'Advertisers' && !chatOn && sidebarCollapse),
          display: hideBrands ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/advertise');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionAdvertisers}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[14].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Advertisers' || active === 'Advertisers'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Advertisers' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[14].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Advertisers' && active != 'Advertisers'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[14].name}
        </div>
      </div> */}

      <div
        className="menu-itm active clickable"
        style={{
          borderRight: 'none',
          ...applyStyle(active === 'Engagement' && !chatOn && sidebarCollapse),
          display: hideBrands ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/engagement');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionEngagement}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[9].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Engagement' || active === 'Engagement'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Engagement' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[9].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Engagement' && active != 'Engagement'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[9].name}
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          cursor: 'default',
        }}
      >
      </div> */}

      <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'Commercials' && !chatOn && sidebarCollapse),
          display: hideBrands ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/commercial');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionCommercial}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[19].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Commercials' || active === 'Commercials'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Commercials' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[19].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Commercials' && active != 'Commercials'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[19].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'mlm' && !chatOn && sidebarCollapse),
          display: hideBrands ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/mlm');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionMlm}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[21]?.icon}
          alt="VaultIcon"
          style={{
            height: hoverItem === 'mlm' || active === 'mlm' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'MLM' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[21]?.name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display: hoverItem === 'mlm' && active != 'MLM' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[21]?.name}
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'vaults' && !chatOn && sidebarCollapse),
          display: hideBrands ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/vault');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionVaults}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[2].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'vaults' || active === 'vaults' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'vaults' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[2].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'vaults' && active != 'vaults' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[2].name}
        </div>
      </div> */}
      {/* <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'vaults' && !chatOn && sidebarCollapse),
          display: hideBrands ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/vault');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionVaults}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[2].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'vaults' || active === 'vaults' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'vaults' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[2].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'vaults' && active != 'vaults' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[2].name}
        </div>
      </div> */}

      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
          borderTop: sidebarCollapse
            ? 'none'
            : hideBrands
            ? 'none'
            : '0.5px solid #e7e7e7',
        }}
        onClick={() => {
          setHideInfluencers(!hideInfluencers);
          if (!hideEveryone) {
            setHideEveryone(true);
          }
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideInfluencers ? ddown : ddown1} alt="" />
          </div>
          <div>For Influencers</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'Endorsement' && !chatOn && sidebarCollapse),
          display: hideInfluencers ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        // onClick={() => {
        //   if (email) {
        //     setSelectedTopItemReact('Resources');
        //     history.push('/academy');
        //   } else {
        //     history.push('/');
        //   }
        //   closeChatScreen();
        // }}
        onMouseEnter={hoverFunctionEndorsement}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[12].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Endorsement' || active === 'Endorsement'
                ? '50px'
                : '40px',
            opacity: '0.25',
          }}
        />
        {!sidebarCollapse && active === 'Endorsement' && (
          <div className="sidebar-icon-label" style={{ opacity: '0.25' }}>
            {SIDEBAR_MENU[12].name}
          </div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Endorsement' && active != 'Endorsement'
                ? 'flex'
                : 'none',
            opacity: '0.25',
          }}
        >
          {SIDEBAR_MENU[12].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'Linked' && !chatOn && sidebarCollapse),
          display: hideInfluencers ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        // onClick={() => {
        //   if (email) {
        //     setSelectedTopItemReact('Resources');
        //     history.push('/academy');
        //   } else {
        //     history.push('/');
        //   }
        //   closeChatScreen();
        // }}
        onMouseEnter={hoverFunctionLinked}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[13].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Linked' || active === 'Linked' ? '50px' : '40px',
            opacity: '0.25',
          }}
        />
        {!sidebarCollapse && active === 'Linked' && (
          <div className="sidebar-icon-label" style={{ opacity: '0.25' }}>
            {SIDEBAR_MENU[13].name}
          </div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Linked' && active != 'Linked' ? 'flex' : 'none',
            opacity: '0.25',
          }}
        >
          {SIDEBAR_MENU[13].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          borderRight: 'none',
          ...applyStyle(active === 'Connection1' && !chatOn && sidebarCollapse),
          display: hideInfluencers ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        onMouseEnter={hoverFunctionConnection1}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[15].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Connection1' || active === 'Connection1'
                ? '50px'
                : '40px',
            opacity: '0.25',
          }}
        />
        {!sidebarCollapse && active === 'Connection1' && (
          <div className="sidebar-icon-label" style={{ opacity: '0.25' }}>
            {SIDEBAR_MENU[15].name}
          </div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Connection1' && active != 'Connection1'
                ? 'flex'
                : 'none',
            opacity: '0.25',
          }}
        >
          {SIDEBAR_MENU[15].name}
        </div>
      </div>

      <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
        }}
        onClick={() => {
          setHideContentCreators(!hideContentCreators);
          if (!hideEveryone) {
            setHideEveryone(true);
          }
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideContentCreators ? ddown : ddown1} alt="" />
          </div>
          <div>For Content Creators</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'Publishing' && !chatOn && sidebarCollapse),
          display: hideContentCreators ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        // onClick={() => {
        //   if (email) {
        //     setSelectedTopItemReact('Resources');
        //     history.push('/academy');
        //   } else {
        //     history.push('/');
        //   }
        //   closeChatScreen();
        // }}
        onMouseEnter={hoverFunctionPublishing}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[16].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Publishing' || active === 'Publishing'
                ? '50px'
                : '40px',
            opacity: '0.25',
          }}
        />
        {!sidebarCollapse && active === 'Publishing' && (
          <div className="sidebar-icon-label" style={{ opacity: '0.25' }}>
            {SIDEBAR_MENU[16].name}
          </div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Publishing' && active != 'Publishing'
                ? 'flex'
                : 'none',
            opacity: '0.25',
          }}
        >
          {SIDEBAR_MENU[16].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'Production' && !chatOn && sidebarCollapse),
          display: hideContentCreators ? 'none' : 'flex',
          cursor: 'not-allowed',
        }}
        onMouseEnter={hoverFunctionProduction}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[17].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Production' || active === 'Production'
                ? '50px'
                : '40px',
            opacity: '0.25',
          }}
        />
        {!sidebarCollapse && active === 'Production' && (
          <div className="sidebar-icon-label" style={{ opacity: '0.25' }}>
            {SIDEBAR_MENU[17].name}
          </div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Production' && active != 'Production'
                ? 'flex'
                : 'none',
            opacity: '0.25',
          }}
        >
          {SIDEBAR_MENU[17].name}
        </div>
      </div>
    </div>
  );
};
