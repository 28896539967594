import portfolioIcon from '../../static/images/sidebar-icons/portfolio.svg';

import Fund from '../../static/images/sidebar-icons/fund.svg';

import vaultsIcon from '../../static/images/sidebar-icons/vaults.svg';
import defiIcon from '../../static/images/sidebar-icons/defiIcon.svg';
import affiliateIcon from '../../static/images/sidebar-icons/affiliateIcon.svg';
import taxesIcon from '../../static/images/sidebarIcons/taxes.svg';
import supportIcon from '../../static/images/sidebar-icons/supportIcon.svg';
import supportNewIcon from '../../static/images/sidebar-icons/supportNew.svg';
import sharesToken from '../../static/images/login/sharestoken.svg';
import products from '../../static/images/sidebar-icons/malls.svg';
import trainingIcon from '../../static/images/sidebar-icons/trainingIcon.svg';
import swapIcon from '../../static/images/sidebar-icons/swapIcon.svg';
import aiindexIcon from '../../static/images/sidebar-icons/aiindexIcon.svg';
import tellersIcon from '../../static/images/sidebar-icons/tellersIcon.svg';

import displayIcon from '../../static/images/settings/displayCurrencyIcon.svg';
import themeIcon from '../../static/images/settings/themeIcon.svg';
import securityIcon from '../../static/images/settings/securityIcon.svg';
import settingsIcon from '../../static/images/settings/settingsIcon.svg';
import kycIcon from '../../static/images/settings/kycIcon.svg';
import bankingIcon from '../../static/images/settings/banking.svg';
import logoutIcon from '../../static/images/settings/logoutIcon.svg';

import qrAddress from '../../static/images/actionSidebar/qrAddress.svg';
import connectIcon from '../../static/images/vaultCpanelIcons/connect.svg';
import withdraw from '../../static/images/vaultCpanelIcons/withdraw.svg';
import arrowUp from '../../static/images/clipIcons/arrowUp.svg';
import arrowDown from '../../static/images/clipIcons/arrowDown.svg';
import plus from '../../static/images/actionSidebar/plus.svg';
import retiredLogo from '../../static/images/retiredLogo.svg';

import icon1 from '../../static/images/new-menu/1.svg';
import connectionIcon from '../../static/images/new-menu/connection.svg';
import icon2 from '../../static/images/new-menu/2.svg';
import icon3 from '../../static/images/new-menu/3.svg';
import icon4 from '../../static/images/new-menu/4.svg';
import icon5 from '../../static/images/new-menu/5.svg';
import icon6 from '../../static/images/new-menu/6.svg';
import icon7 from '../../static/images/new-menu/7.svg';
import icon8 from '../../static/images/new-menu/8.svg';
import networkChainIcon from '../../static/images/new-menu/networkchain.svg';
import icon9 from '../../static/images/new-menu/14.svg';
import funnelIcon from '../../static/images/new-menu/funnel.svg';
import icon10 from '../../static/images/new-menu/9.svg';
import engagementIcon from '../../static/images/new-menu/engagement.svg';
import icon11 from '../../static/images/new-menu/10.svg';
import prstudioIcon from '../../static/images/new-menu/prstudio.svg';
import icon12 from '../../static/images/new-menu/11.svg';
import advertisersIcon from '../../static/images/new-menu/advertisers.svg';
import icon13 from '../../static/images/new-menu/12.svg';
import endorsementIcon from '../../static/images/new-menu/endorsement.svg';
import icon14 from '../../static/images/new-menu/13.svg';
import linkedIcon from '../../static/images/new-menu/linked.svg';
import publishingIcon from '../../static/images/new-menu/publishing.svg';
import productionIcon from '../../static/images/new-menu/production.svg';
import commercialIcon from '../../static/images/new-menu/commercial.svg';
import subscriptionsIcon from '../../static/images/new-menu/subscriptions.svg';
import playImage from '../../static/images/playImage.svg';
import mlm from '../../static/images/new-menu/mlm.svg';

import { useCoinContextData } from '../../context/CoinContext';

export const SIDEBAR_CONTENTS = [
  {
    id: 1,
    name: 'Portfolio.ai',
    icon: portfolioIcon,
  },
  {
    id: 2,
    name: 'Vaults',
    icon: vaultsIcon,
  },
  {
    id: 3,
    name: 'Defi',
    icon: defiIcon,
  },
  {
    id: 4,
    name: 'Affiliate',
    icon: affiliateIcon,
  },
  {
    id: 8,
    name: 'Products',
    icon: products,
  },
  {
    id: 9,
    name: 'Training',
    icon: trainingIcon,
  },
  {
    id: 10,
    name: 'Swap',
    icon: swapIcon,
  },
  {
    id: 11,
    name: 'AI Index',
    icon: aiindexIcon,
  },
  {
    id: 12,
    name: 'Funds',
    icon: Fund,
  },
  {
    id: 13,
    name: 'Retired',
    icon: retiredLogo,
  },
];

export const SIDEBAR_MENU = [
  {
    id: 1,
    icon: connectionIcon,
    name: 'Opportunities',
  },
  {
    id: 2,
    icon: icon2,
    name: 'Shop',
  },
  {
    id: 3,
    icon: icon3,
    name: 'Vaults',
  },
  {
    id: 4,
    icon: icon4,
    name: 'Affiliate',
  },
  {
    id: 5,
    icon: icon5,
    name: 'CRM',
  },
  {
    id: 6,
    icon: funnelIcon,
    name: 'Funnels',
  },
  {
    id: 7,
    icon: icon7,
    name: 'AffiliateBank',
  },
  {
    id: 8,
    icon: networkChainIcon,
    name: 'NetworkChains',
  },
  {
    id: 9,
    icon: icon9,
    name: 'Deals',
  },
  {
    id: 10,
    icon: engagementIcon,
    name: 'Engagement',
  },
  {
    id: 11,
    icon: prstudioIcon,
    name: 'PR Studio',
  },
  {
    id: 12,
    icon: icon12,
    name: 'Retailer',
  },
  {
    id: 13,
    icon: endorsementIcon,
    name: 'Endorsement',
  },
  {
    id: 14,
    icon: linkedIcon,
    name: 'Linked',
  },
  {
    id: 15,
    icon: advertisersIcon,
    name: 'Advertise',
  },
  {
    id: 16,
    icon: icon1,
    name: 'Connection',
  },
  {
    id: 17,
    icon: publishingIcon,
    name: 'Publishing',
  },
  {
    id: 18,
    icon: productionIcon,
    name: 'Production',
  },
  {
    id: 19,
    icon: playImage,
    name: 'Play',
  },
  {
    id: 20,
    icon: commercialIcon,
    name: 'Commercials',
  },
  {
    id: 21,
    icon: subscriptionsIcon,
    name: 'Subscriptions',
  },
  {
    id: 22,
    icon: mlm,
    name: 'MLM',
  },
];

export const forEveryone = [
  {
    id: 1,
    icon: connectionIcon,
    name: 'Opportunities',
    link: '/opportunities',
  },
  {
    id: 2,
    icon: icon2,
    name: 'Shop',
    link: '/play/ads',
  },
];

export const forAffiliates = [
  {
    id: 1,
    icon: icon5,
    name: 'CRM',
  },
  {
    id: 1,
    icon: icon7,
    name: 'AffiliateBank',
  },
  {
    id: 1,
    icon: networkChainIcon,
    name: 'NetworkChains',
  },
];
export const forBrands = [
  {
    id: 1,
    icon: icon12,
    name: 'Retailer',
  },
  {
    id: 2,
    icon: engagementIcon,
    name: 'Engagement',
  },
  {
    id: 3,
    icon: commercialIcon,
    name: 'Commercials',
  },
];

export const forInfluencers = [
  {
    id: 1,
    icon: endorsementIcon,
    name: 'Endorsement',
  },
  {
    id: 2,
    icon: linkedIcon,
    name: 'Linked',
  },
  {
    id: 3,
    icon: icon1,
    name: 'Connection',
  },
];
export const forContentCreators = [
  {
    id: 1,
    icon: publishingIcon,
    name: 'Publishing',
  },
  {
    id: 2,
    icon: productionIcon,
    name: 'Production',
  },
];

export const SETTINGS_MENU = (
  onLogoutClick,
  onSupportClick,
  onShareTokenClick,
  onTaxesClick,
  onSettingsClick,
  onHoldClick
) => [
  {
    id: 1,
    icon: settingsIcon,
    text: 'Settings',
    subText: 'Manage Your Settings',
    onClick: onSettingsClick,
  },
  // {
  //   id: 2,
  //   text: 'Support',
  //   icon: supportNewIcon,
  //   subText: 'Need Help? Click Here',
  //   onClick: onSupportClick,
  // },
  {
    id: 2,
    text: 'ShareToken',
    icon: sharesToken,
    subText: 'Want To Own ViralVerse? Click Here',
    onClick: onShareTokenClick,
  },
  // {
  //   id: 4,
  //   text: 'Taxes',
  //   icon: taxesIcon,
  //   subText: 'Connect With An Accountant Today',
  //   onClick: onTaxesClick,
  // },
  {
    id: 3,
    text: 'Play',
    icon: playImage,
    subText: 'Connect With An Accountant Today',
    onClick: onTaxesClick,
  },
  {
    id: 4,
    text: 'Subscriptions',
    icon: subscriptionsIcon,
    subText: 'Connect With An Accountant Today',
    onClick: onTaxesClick,
  },
  {
    id: 5,
    text: 'Commercials',
    icon: commercialIcon,
    subText: 'Connect With An Accountant Today',
    onClick: onTaxesClick,
  },

  // {
  //   id: 6,
  //   text: 'Commercials',
  //   icon: commercialIcon,
  //   subText: 'Connect With An Accountant Today',
  //   onClick: onTaxesClick,
  // },
  {
    id: 7,
    text: 'Affiliate',
    icon: affiliateIcon,
    subText: 'Connect With An Accountant Today',
    onClick: onTaxesClick,
  },
  {
    id: 8,
    text: 'Deals',
    icon: icon5,
    subText: 'Connect With An Accountant Today',
    onClick: onTaxesClick,
  },
  {
    icon: supportIcon,
    text: 'OnHold',
    subText: 'Help Your customers',
    onClick: onHoldClick,
  },
  {
    id: 10,
    icon: logoutIcon,
    text: 'Logout',
    subText: 'Hope To See You Again Soon',
    onClick: onLogoutClick,
  },
];

export const COIN_ACTIONS = (coinName, setCoinAction, isMarketsPage) => {
  const { coinType, networkData } = useCoinContextData();
  const showBuyAndSellOnly = [
    {
      id: 5,
      icon: arrowUp,
      text: 'Buy',
      subText: `Buy ${coinName} With Another Currency`,
      action: () => setCoinAction(['Buy']),
    },
    {
      id: 6,
      icon: arrowDown,
      text: 'Sell',
      subText: `Buy ${coinName} For Another Currency`,
      action: () => setCoinAction(['Sell']),
    },
  ];

  const enableActionsByCondition = isMarketsPage
    ? showBuyAndSellOnly
    : coinType === 'crypto'
    ? [
        // {
        //   id: 1,
        //   icon: qrAddress,
        //   text: 'Add',
        //   subText: `Add ${coinName} From External Wallet`,
        //   action: () => setCoinAction(['Add']),
        // },
        // {
        //   id: 2,
        //   icon: vaultsIcon,
        //   text: 'Transfer',
        //   subText: `Transfer ${coinName} Between Your Vaults`,
        //   action: () => setCoinAction(['Transfer']),
        // },
        // {
        //   id: 3,
        //   icon: connectIcon,
        //   text: 'Connect',
        //   subText: `Send ${coinName} To Your Friends`,
        // },
        // {
        //   id: 4,
        //   icon: withdraw,
        //   text: 'Withdraw',
        //   subText: `Send ${coinName} To An External Wallet`,
        //   action: () => setCoinAction(['Withdraw']),
        // },
        // ...showBuyAndSellOnly,
        {
          id: 5,
          text: 'Deposit',
          subText: `Receive ${coinName} From An External Wallet`,
          action:
            networkData?.length < 1 ? '' : () => setCoinAction(['AddCrypto']),
        },
        {
          id: 6,
          text: 'Withdraw',
          subText: `Send ${coinName} To An External Wallet`,
          action: () => setCoinAction(['Withdraw']),
        },
      ]
    : [
        {
          id: 1,
          icon: plus,
          text: 'Add',
          subText: `Send ${coinName} From Your Bank Account`,
          action: () => setCoinAction(['Add']),
        },
        {
          id: 2,
          icon: vaultsIcon,
          text: 'Transfer',
          subText: `Transfer ${coinName} Between Your Vaults`,
          action: () => setCoinAction(['Transfer']),
        },
        {
          id: 3,
          icon: connectIcon,
          text: 'Connect',
          subText: `Send ${coinName} To Your Friends`,
        },
        {
          id: 4,
          icon: withdraw,
          text: 'Withdraw',
          subText: `Send ${coinName} To Your Bank Account`,
          action: () => setCoinAction(['Withdraw']),
        },
      ];
  return enableActionsByCondition;
};
