import React, { useState } from 'react';
import styles from './education.module.scss';
import searchIcon from '../../static/images/search.svg';
import tempImage from './tempimage.svg';
import testProfile from './shoruptest.svg';
import { useContext } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import { BankContext } from '../../context/Context';

const EducationContainer = () => {
  const {
    email,
    setSidebarCollapse,
    setSelectedTopItemReact,
    username,
    name,
    profileImg,
  } = useContext(BankContext);
  const [allVideos, setAllVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All Videos');
  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    if (selectedCategory === 'All Videos') {
      setFilteredVideos(allVideos);
    } else {
      setFilteredVideos(
        allVideos.filter(
          (video) => video.categoryDetail[0].title === selectedCategory
        )
      );
    }
  }, [selectedCategory, allVideos]);

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    axios
      .get(
        `https://publications.apimachine.com/video/publication/63789c1dfd970d0dc2885715`
        // https://publications.apimachine.com/video/publication/638dd769b257b3715a8fbe0763789c1dfd970d0dc2885715
      )
      .then(({ data }) => {
        console.log(data, 'data');
        if (data.status) {
          setAllVideos(data.data);
          setLoading(false);
        } else {
          // history.push('/404');
          setLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://publications.apimachine.com/category/publication/63789c1dfd970d0dc2885715`
      )
      .then(({ data }) => {
        setAllCategories(data.data);
        // setSelectedSearchItem(data.data[0].title);
      });
  }, []);

  const calculateTimeDifference = (timestamp) => {
    var currentTime = new Date();

    var timestampDate = new Date(timestamp);

    var timeDifference = currentTime - timestampDate;

    var timeDifferenceInHours = timeDifference / 1000 / 60 / 60;
    if (timeDifferenceInHours >= 24) {
      return (
        Math.floor(timeDifferenceInHours / 24) +
        ' day and ' +
        Math.floor(timeDifferenceInHours % 24) +
        ' hours ago'
      );
    } else {
      return Math.floor(timeDifferenceInHours) + ' hours ago';
    }

    // return Math.floor(timeDifferenceInHours);
  };

  // const scrollHandler = (_) => {
  //   // setShowPopup(false);
  //   const catDiv = document.getElementById('catDiv');

  //   if (
  //     catDiv?.getBoundingClientRect()?.bottom < 277 ||
  //     catDiv?.getBoundingClientRect()?.top < 1
  //   ) {
  //     catDiv?.classList?.replace(styles.categorybg, styles.categorybgNew);
  //   } else {
  //     catDiv?.classList?.replace(styles.categorybg, styles.categorybgNew);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', scrollHandler, scrollTo ? false : true);
  //   return () => {
  //     window.removeEventListener('scroll', scrollHandler, true);
  //   };
  // }, [allCategories, scrollTo]);

  return (
    // <div className={classNames.educationContainer}>
    //   <div className={classNames.leftContainer}>
    //     <div>
    //       <div>Playlists</div>
    //       {/* <img src={searchIcon} alt="searchIcon" /> */}
    //     </div>
    //     {/* <div className={classNames.selectionBtns}>
    //       <div
    //         className={` ${
    //           educationLeftSelected == 'Youtube'
    //             ? classNames.selectedBtn
    //             : classNames.notselectedBtn
    //         }`}
    //         onClick={(event) =>
    //           seteducationLeftSelected(event.target.innerText)
    //         }
    //       >
    //         Youtube
    //       </div>
    //       <div
    //         className={` ${
    //           educationLeftSelected == 'Exclusive'
    //             ? classNames.selectedBtn
    //             : classNames.notselectedBtn
    //         }`}
    //         onClick={(event) =>
    //           seteducationLeftSelected(event.target.innerText)
    //         }
    //       >
    //         Exclusive
    //       </div>
    //     </div> */}
    //     <div>
    //       {allPlaylistAvailable &&
    //         allPlaylistAvailable.map((eachPlaylist) => {
    //           return <VideoContainer eachPlaylist={eachPlaylist} />;
    //         })}
    //     </div>
    //   </div>
    //   <div className={classNames.rightContainer}>
    //     <ExclusiveDiv selectedPlaylistData={selectedPlaylistData} />
    //   </div>
    // </div>
    <>
      <div id="catDiv" className={styles.categorybg}>
        <div>
          <div className={styles.welcomeMsg}>Welcome {name}</div>
          <div className={styles.welcomeMsg1}>To The AI Training Center</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className={styles.allVideos}
            onClick={(e) => {
              setSelectedCategory('All Videos');
            }}
          >
            All Videos
          </div>
          {allCategories.map((item) => {
            return (
              <div
                className={styles.videoCategory}
                onClick={(e) => {
                  setSelectedCategory(item.title);
                }}
              >
                {item?.title}
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          overflowY: 'scroll',
          height: window.innerHeight - 300,
          marginTop: '-120px',
          position: 'relative',
        }}
      >
        {filteredVideos.length > 0 && (
          <>
            <div className={styles.heroGrid}>
              <div className={styles.leftSide} style={{ borderRadius: '20px' }}>
                <ReactPlayer
                  width={'auto'}
                  className={styles.reactPlayer}
                  light={true}
                  url={filteredVideos[0]?.video}
                  controls
                  playing
                />
                {/* <img
              src={allVideos[0]?.image}
              alt=""
              className={styles.imageStyle}
            /> */}
              </div>
              <div className={styles.rightSide}>
                <div>
                  <div className={styles.heroTitle}>
                    {filteredVideos[0]?.title}
                  </div>
                  <div className={styles.heroSubTitle}>
                    {filteredVideos[0]?.email} |{' '}
                    {calculateTimeDifference(filteredVideos[0]?.createdAt)}
                  </div>
                </div>
                <div className={styles.heroDesc}>{filteredVideos[0]?.desc}</div>
              </div>
            </div>
            <div className={styles.nonHeroGrid}>
              {filteredVideos?.map((item, index) => {
                if (index > 0) {
                  return (
                    <div className={styles.nonHeroItem}>
                      <ReactPlayer
                        width={'auto'}
                        height={'250px'}
                        className={styles.reactPlayer1}
                        light={true}
                        url={item?.video}
                        controls
                        playing
                      />
                      {/* <img src={item?.image} alt="" className={styles.imageStyle} /> */}
                      <div className={styles.nonHeroTitle}>
                        {item?.title.length > 50
                          ? item?.title.substring(0, 50) + '...'
                          : item?.title}
                      </div>
                      <div className={styles.nonHeroSubTitle}>
                        {item?.email}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </>
        )}
      </div>
      {/* <Modal isOpen={isOpen} onRequestClose={onClose} style={modelStyle}>
        <ReactPlayer
          className={styles.reactPlayer}
          light={true}
          // width="100%"
          // height="100%"
          url={selectedVideo?.video}
          playing
          controls
        />
      </Modal> */}
    </>
  );
};

export default EducationContainer;

const modelStyle = {
  overlay: {
    zIndex: '9999999999',
    backgroundColor: 'rgba(103, 103, 103, 0.75)',
  },
  content: {
    // width: '50%',
    // height: '50%',
    // background: 'white',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    // border: 'none',
    // background: 'none',
    // overflow: 'auto',
    // borderRadius: 'none',
    // height: 'auto',
    // display: 'flex',
    // justifyContent: 'center',
    // maxWidth: '80%',
    // paddingTop: '5rem',
    // paddingBottom: '5rem',
    // paddingLeft: '0',
    // paddingRight: '0',
  },
};
