import React from 'react';
import { useCoinContextData } from '../../context/CoinContext';

const ActionFooter = () => {
  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
    setNetworkData,
  } = useCoinContextData();
  return (
    <div
      onClick={() => {
        if (coinActionEnabled) {
          setCoinActionEnabled(false);
          setCoinAction(['Menu']);
          setNetworkData('');
        }
      }}
      className="actionMenu-btmItm"
    >
      <div className="closee-btn">Close</div>
    </div>
  );
};

export default ActionFooter;
