import React, { useState, useContext, useEffect } from 'react';

import Layout from '../Layout/Index';
import useWindowDimensions from '../utils/WindowSize';
import IndexContextProvider from '../context/IndexContext';
import iceLogo from '../static/images/logo.svg';
import backDouble from '../static/images/backDouble.svg';
import IceSidebar from '../components/IceSidebar/IceSidebar';
import { BankContext } from '../context/Context';
import Indices from '../components/Indices/Indices';
import { useAppContextDetails } from '../context/AppContext';

function Options({ activeTab }) {
  const { iceSidebarOpen, setIceSidebarOpen } = useContext(BankContext);
  const [menuSelected, setMenuSelected] = useState('Assets');
  const { width } = useWindowDimensions();
  const { appName } = useAppContextDetails();

  function getPageContent(tab) {
    switch (tab) {
      default:
        return (
          <>
            <Indices tabSelected={menuSelected} />
          </>
        );
    }
  }
  useEffect(() => {
    if (activeTab === 'bonds') setMenuSelected('bonds-tab');
    if (activeTab === 'globalPayments') setMenuSelected('payouts');
  }, [activeTab]);

  const details = [
    'Indicies Will Allow You To Create Your Own Index Funds In A Matter Of Seconds. Tap Into The Biggest Trends In The World Without The Risk Of Choosing The Right Asset. ',
    `${appName} Has Created The Ice Protocol Which Enables Your To Digitalize Any Asset. Once Your Have Turned Your Assets Into Iced Assets, They Can Now Be Used As An Instrument On The Platform `,
    'The IceMachine Is The Interface To The IcedProtocol. It Allows Your To Interact WIth The Smart Contract & Lay The Abstraction Parameters For You Newly Created Iced Asset',
  ];

  return (
    <IndexContextProvider>
      <Layout
        active="Terminal"
        className="Options"
        menuSelected={menuSelected}
        setMenuSelected={setMenuSelected}
        hideFooter={true}
        footerMain={true}
      >
        <div className="icedContainer">
          <div className={`mainContent ${iceSidebarOpen}`}>
            {width > 768 ? (
              <div className="tab">
                <div
                  className={`tab-itm ${menuSelected === 'Assets'}`}
                  onClick={() => {
                    setMenuSelected('Assets');
                  }}
                >
                  Assets
                </div>
                <div
                  className={`tab-itm ${menuSelected === 'Indicies'}`}
                  onClick={() => {
                    // setMenuSelected('Indicies');
                  }}
                >
                  Indicies
                </div>
                <div
                  className={`tab-itm ${menuSelected === 'Bonds'}`}
                  onClick={() => {
                    // setMenuSelected('Bonds');
                  }}
                >
                  Bonds
                </div>
                <div
                  className={`tab-itm ${menuSelected === 'Funds'}`}
                  onClick={() => {
                    // setMenuSelected('Funds');
                  }}
                  // onMouseEnter={() => setDetailIndex(1)}
                  // onMouseLeave={() => setDetailIndex(null)}
                >
                  Funds
                </div>
                <div
                  onClick={() => setIceSidebarOpen(!iceSidebarOpen)}
                  className={`tab-itm ice ${menuSelected === 'collateral'}`}
                >
                  <img src={iceSidebarOpen ? backDouble : iceLogo} alt="" />
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              className={`content-section ${menuSelected} ${
                iceSidebarOpen ? 'sideOpen' : ''
              }`}
            >
              {getPageContent(menuSelected)}
            </div>
          </div>
          {iceSidebarOpen ? <IceSidebar /> : ''}
        </div>
      </Layout>
    </IndexContextProvider>
  );
}

export default Options;
