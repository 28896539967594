import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';

const SwapBreadcrumb = ({ data, handleBreadCrumb }) => {
  const {
    swapActionStep,
    setSwapActionStep,
    selectedCoin,
    setCoinAction,
    selectedSwapAction,
    setSelectedSwapAction,
    setEnteredSwapValue,
  } = useCoinContextData();

  function switchBreadCrumb(event) {
    let selectedText = event.target.textContent;
    if (selectedText.includes('Direction')) {
      setSwapActionStep('step1');
    } else if (selectedText.includes('Buy Asset')) {
      setSwapActionStep('step2');
    } else if (selectedText.includes('Sell Asset')) {
      setSwapActionStep('step2');
    } else if (selectedText.includes('Amount')) {
      setSwapActionStep('step3');
      setEnteredSwapValue('');
    }
  }

  return (
    <div
      className="newBreadcrumb"
      style={{
        borderTop: '0.5px solid #e5e5e5',
        borderBottom: '0.5px solid #e5e5e5',
        paddingLeft: '1.85rem',
      }}
    >
      {data.map((d, i) => {
        return (
          <p
            key={i}
            style={{
              marginTop: '0px',
              fontWeight: data.length - 1 == i ? '700' : '',
              fontSize: '11px',
              cursor: 'pointer',
              background: data.length - 1 == i ? '#9FD6DF' : '',
              color: data.length - 1 == i ? '#ffffff' : '#18191d',
              opacity: data.length - 1 == i ? '1' : '0.5',
              marginBottom: '0px',
            }}
            id={d.clickable}
            onClick={(e) => switchBreadCrumb(e)}
          >
            <span id={d.clickable}>{d.value}</span>
          </p>
        );
      })}
    </div>
  );
};

export default SwapBreadcrumb;
