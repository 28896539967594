import React, { useEffect, useState, useContext } from 'react';
import userArr from '../../static/images/affiliate/userArr.svg';
import userClose from '../../static/images/affiliate/userClose.svg';
import dropdown from '../../static/images/affiliate/dropdown.svg';
import loader from '../../static/images/affiliate/loader1.svg';

import imagupload from '../../static/images/Funds/imagupload.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import './AddnewFund.scss';
import { LoadingAnimation } from '../../components/LoadingAnimation';
// import {
//   affiliateId,
//   emailCheck,
//   getApps,
//   placeUnderMe,
//   preRegistration,
// } from './api';

const AddUser = () => {
  const {
    setFundAddlabel,
    FundAddlabel,
    appLogo,
    adduser,
    setAdduser,
    adduserStep,
    setAdduserStep,
    affiliateid,
    setAffiliateId,
    setAllaffiliatedata,
    allaffiliatedata,
    selectedApp,
    setSelectedApp,
    selectedAffiliate,
    setSelectedAffiliate,
    isaffiliateselected,
    setIsaffiliateselected,
    isappselected,
    setIsappselected,
    mail,
    setMail,
    userName,
    setUsername,
    userfilteredApps,
    setuserfilteredApps,
    userfilteredAff,
    setuserfilteredAff,
    setToggleAddFunds,
    ToggleAddFunds,
  } = useAppContextDetails();
  const { email } = useContext(BankContext);
  const [allapps, setAllapps] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [emailResult, setEmailResult] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [finalresult, setFinalresult] = useState([]);
  const [isValid, setIsValid] = useState(false);

  //   useEffect(() => {
  //     getApps().then((response) => {
  //       let result = response?.data?.apps;
  //       setAllapps(result);
  //       setuserfilteredApps(result);
  //     });
  //   }, []);

  const validateEmail = (email) => {
    const regex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(email);
  };

  const handleChange = (event) => {
    setMail(event.target.value);
    setIsValid(validateEmail(event.target.value));
  };

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const fetchData = debounce(async () => {
    const response = await fetch(
      `https://comms.globalxchange.io/user/profile/data/get?email=${mail}`
    );
    const data = await response.json();
    setEmailResult(data?.count);
  }, 200);

  useEffect(() => {
    fetchData();
  }, [mail]);

  const userfetchData = debounce(async () => {
    const response = await fetch(
      `https://comms.globalxchange.io/user/profile/data/get?username=${userName}` //url changed
    );
    const data = await response.json();
    setUserResult(data?.count);
  }, 200);

  useEffect(() => {
    userfetchData();
  }, [userName]);

  const registration = () => {
    // setIsloading(true);
    // let obj = {
    //   username: userName,
    //   email: mail,
    //   ref_affiliate: affiliateid,
    //   app_code: selectedApp?.app_code,
    // };
    // preRegistration(obj).then((response) => {
    //   let result = response?.data?.email;
    //   setFinalresult(result);
    //   setIsloading(false);
    //   setAdduserStep('step4');
    // });
  };

  const placementunderme = () => {
    // placeUnderMe(email).then((response) => {
    //   let result = response?.data?.usersData?.[0]?.hardCoded?.[0]?.data;
    //   // console.log(result, 'result');
    //   setSelectedAffiliate(result);
    //   setIsaffiliateselected(true);
    //   setAdduserStep('step1');
    // });
  };

  function filterItem(text) {
    let filterItem = allapps?.filter((eachitem) => {
      return eachitem?.app_name?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setuserfilteredApps(filterItem);
  }

  function filterItemplacement(text) {
    let filterItem = allaffiliatedata?.filter((eachitem) => {
      return eachitem?.name?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setuserfilteredAff(filterItem);
  }

  const addFunction = () => {
    switch (FundAddlabel) {
      case 'dropdown':
        return (
          <>
            <div
              className="each-field"
              style={{ marginTop: '2rem', paddingBottom: '0' }}
            >
              <p>Select App</p>
              <input
                type="text"
                className="inputt-field"
                placeholder="Search Apps..."
                onChange={(event) => filterItem(event.target.value)}
              />
            </div>
            <div className="appslist">
              {userfilteredApps?.map((e, i) => {
                return (
                  <div
                    className="each-appp"
                    key={i}
                    onClick={() => {
                      setSelectedApp(e);
                      setIsappselected(true);
                      setAdduserStep('step1');
                    }}
                  >
                    <img
                      src={e?.app_icon}
                      alt=""
                      style={{ width: '40px', height: '40px' }}
                    />
                    <div>
                      <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                        {e?.app_name}
                      </p>
                      <p>{e?.app_code}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        );

      case 'EnternalFund':
        return (
          <>
         EaDrawer.scssEaDrawer.scss
          </>
        );

      case 'addfund':
        return (
          <>
            <div className="scrollerFunds ">
              <div className="innerFundsAdding">
                <div className="each-field">
                  <p>Select Issuance Asset</p>
                  <div
                    className="inputt-field"
                    // onClick={() => setFundAddlabel('dropdown')}
                  >
                    {isappselected ? (
                      <div className="each-appp2">
                        <img
                          src="{selectedApp?.app_icon}"
                          alt=""
                          style={{ width: '40px', height: '40px' }}
                        />
                        <div>
                          <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                            {/* {selectedApp?.app_name} */}
                          </p>
                          <p
                            style={{
                              marginTop: '-0.2rem',
                              fontSize: '0.95rem',
                            }}
                          >
                            {/* {selectedApp?.app_code} */}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <input
                        type="email"
                        placeholder="Click To Select"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <div className="dddiv">
                      <img src={dropdown} alt="" />
                    </div>
                  </div>
                </div>

                <div className="each-field">
                  <p>Select Issuance Country</p>
                  <div
                    className="inputt-field"
                    // onClick={() => setFundAddlabel('dropdown')}
                  >
                    {isappselected ? (
                      <div className="each-appp2">
                        <img
                          src="{selectedApp?.app_icon}"
                          alt=""
                          style={{ width: '40px', height: '40px' }}
                        />
                        <div>
                          <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                            {/* {selectedApp?.app_name} */}
                          </p>
                          <p
                            style={{
                              marginTop: '-0.2rem',
                              fontSize: '0.95rem',
                            }}
                          >
                            {/* {selectedApp?.app_code} */}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <input
                        type="email"
                        placeholder="Click To Select"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <div className="dddiv">
                      <img src={dropdown} alt="" />
                    </div>
                  </div>
                </div>

                <div className="each-field">
                  <p>Which Apps Do You Want List On?</p>
                  <div
                    className="inputt-field"
                    // onClick={() => setFundAddlabel('dropdown')}
                  >
                    {isappselected ? (
                      <div className="each-appp2">
                        <img
                          src="{selectedApp?.app_icon}"
                          alt=""
                          style={{ width: '40px', height: '40px' }}
                        />
                        <div>
                          <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                            {/* {selectedApp?.app_name} */}
                          </p>
                          <p
                            style={{
                              marginTop: '-0.2rem',
                              fontSize: '0.95rem',
                            }}
                          >
                            {/* {selectedApp?.app_code} */}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <input
                        type="email"
                        placeholder="Click To Select"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <div className="dddiv">
                      <img src={dropdown} alt="" />
                    </div>
                  </div>
                </div>

                <div className="each-field">
                  <p>Create Your FundCoin Ticker Symbol</p>
                  <div
                    className="inputt-field"
                    style={{ paddingRight: '0rem' }}
                  >
                    <input
                      type=""
                      placeholder="Enter Up To Four Characters"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                    <div className="chekfund">Check</div>
                  </div>
                </div>

                <div className="each-field">
                  <p>Create Your FundCoin Name</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Enter Fund Full Name"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>

                <div className="each-field">
                  <p>Create Your FundCoin Name</p>

                  <div class="upload-options">
                    <label className="label">
                      <img src={imagupload} />
                      <input
                        type="file"
                        class="image-upload"
                        accept="image/*"
                      />
                    </label>
                  </div>
                  <div className="imgTitle">Coloured Icon</div>
                </div>

                <div className="each-field">
                  <p>Enter Primary FundCoin Colour Code</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Enter Primary Colour Code"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>

                <div className="each-field">
                  <p>Enter Secondary FundCoin Colour Code</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Enter Secondary Colour Code"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>
                <div className="each-field">
                  <p>Enter Initial FundCoin Supply</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Enter Supply"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>

                <div className="each-field">
                  <p>Enter Initial FundCoin Price</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Enter Price"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>

                <div className="each-field">
                  <p>Set Your Performance Fee</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Enter Percentage Of Profits"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>

                <div className="each-field">
                  <p>Set A Purchase Fee</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Enter A Fixed Amount"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>

                <div className="each-field">
                  <p>Set Withdrawal Waiting Period</p>
                  <div className="inputt-field">
                    <input
                      type=""
                      placeholder="Amount Of Days"
                      //   value={mail}
                      //   onChange={handleChange}
                      style={{ cursor: 'text' }}
                    />
                  </div>
                </div>
              </div>
              <div className="footerr">
                <div
                  className="goback"
                  onClick={() => {
                    setToggleAddFunds(false);
                  }}
                >
                  Go Back
                </div>
                <div
                  className="addd"
                  onClick={() => setFundAddlabel('EnternalFund')}

                  // onClick={() => {
                  //   if (
                  //     mail &&
                  //     userName &&
                  //     affiliateid &&
                  //     selectedApp?.app_code &&
                  //     (selectedAffiliate?.name || selectedAffiliate?.username)
                  //   ) {
                  //     registration();
                  //   }
                  // }}
                >
                  Next Step
                </div>
              </div>
            </div>
          </>
        );
        break;

      default:
        break;
    }
  };

  const headerpart = () => {
    switch (FundAddlabel) {
      case 'addfund':
        return (
          <div className="topp">
            <div style={{ width: '80%' }}>
              <p style={{ fontSize: '1.75rem', fontWeight: '650' }}>New Fund</p>
            </div>
            <div className="closebttn">
              <img
                src={userArr}
                alt=""
                // onClick={() => {
                //   setAdduserStep('step1');
                // }}
              />
              <img
                src={userClose}
                alt=""
                onClick={() => {
                  setToggleAddFunds(false);
                }}
                // onClick={() => {
                //   setAdduser(false);
                //   setAdduserStep('step1');
                //   setSelectedAffiliate([]);
                //   setSelectedApp([]);
                //   setIsappselected(false);
                //   setIsaffiliateselected(false);
                //   setMail('');
                //   setUsername('');
                //   setAllapps([]);
                //   setuserfilteredApps([]);
                //   setAllaffiliatedata([]);
                //   setuserfilteredAff([]);
                // }}
              />
            </div>
          </div>
        );
      case 'EnternalFund':
        return (
          <div className="topp">
            <div style={{ width: '80%' }}>
              <p style={{ fontSize: '1.75rem', fontWeight: '650' }}>
                New Enternal Fund
              </p>
              <p style={{ fontSize: '0.85rem' }}>
                Add New &rarr; FundCoins &rarr;{' '}
                <span
                  style={{
                    textDecorationLine:
                      adduserStep === 'step1' ? 'underline' : '',
                    fontWeight: adduserStep === 'step1' ? '650' : '',
                  }}
                >
                  Eternal
                </span>
              </p>
            </div>
            <div className="closebttn">
              <img
                src={userArr}
                alt=""
                onClick={() => {
                  setFundAddlabel('addfund');
                }}
              />
              <img
                src={userClose}
                alt=""
                onClick={() => {
                  setToggleAddFunds(false);
                }}
                // onClick={() => {
                //   setAdduser(false);
                //   setAdduserStep('step1');
                //   setSelectedAffiliate([]);
                //   setSelectedApp([]);
                //   setIsappselected(false);
                //   setIsaffiliateselected(false);
                //   setMail('');
                //   setUsername('');
                //   setAllapps([]);
                //   setuserfilteredApps([]);
                //   setAllaffiliatedata([]);
                //   setuserfilteredAff([]);
                // }}
              />
            </div>
          </div>
        );
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="AddFund">
        <div style={{ width: '100%', height: '100%' }}>
          <div className="headerr">
            {/*  */}
            {headerpart()}
            {addFunction()}

            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
