import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

export const addActionForex = async (object) => {
  try {
    const response = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
      { params: object }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPathId = async (body) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?`,
      { params: body }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useGetPathId = (
  params,
  setSuccessFlag = () => null,
  enableQuery = true
) => {
  const query = useQuery(
    ['getPathId', { params }],
    () => getPathId(params),
    {
      onSuccess: () => {
        setSuccessFlag(true);
      },
    },
    { enabled: Boolean(params) && enableQuery }
  );
  return query;
};

const getQuote = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadQuote = () => {
  const mutation = useMutation(getQuote);
  return mutation;
};

export const initiateAdd = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useInitiateAdd = (
  setUpdateVaultData,
  setUpdateCoinBalance,
  setLoadPathData,
  isBankAccountIdSet
) => {
  const mutation = useMutation(isBankAccountIdSet ? () => null : initiateAdd, {
    onSuccess: () => {
      !isBankAccountIdSet && setUpdateVaultData(true);
      setUpdateCoinBalance(true);
      setLoadPathData(true);
    },
  });
  return mutation;
};

export const getPathDataForId = async (pathId) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?`,
      {
        params: {
          path_id: pathId,
        },
      }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadPathDataForId = (pathId, loadPathData) => {
  const query = useQuery(
    ['loadPathDataForId', { pathId }],
    () => getPathDataForId(pathId),
    { enabled: Boolean(pathId && loadPathData) }
  );
  return query;
};

export const getBankAccountDetails = async (bankAccountId) => {
  try {
    const data = await axios.get(
      'https://comms.globalxchange.io/coin/user/bank/account/get?',
      { params: { bank_account_id: bankAccountId } }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadBankAccountDetails = (bankAccountId) => {
  const query = useQuery(
    ['loadBankAccountDetails', { bankAccountId }],
    () => getBankAccountDetails(bankAccountId),
    { enabled: Boolean(bankAccountId) }
  );
  return query;
};
