import React, { createContext, useContext, useEffect, useState } from 'react';

export const MarketContext = createContext({});

export const MarketContextProvider = ({ children }) => {
  const [streamOpen, setStreamOpen] = useState(false);
  const [defiTab, setDefiTab] = useState('Staking Contracts');
  const [defiDropdown, setDefiDropdown] = useState(false);
  const [defiSelectedDropdown, setDefiSelectedDropdown] = useState('Asset');
  const [coinSelected, setCoinSelected] = useState('');
  const [buyContract, setBuyContract] = useState(false);
  const [buyContractStep, setBuyContractStep] = useState('step1');
  const [selectedAsset, setSelectedAsset] = useState([]);
  const [contractId, setContractId] = useState('');
  const [coinBalance, setCoinBalance] = useState([]);
  const [contractCost, setContractCost] = useState();

  useEffect(() => {
    setDefiDropdown(false);
  }, [defiSelectedDropdown]);

  return (
    <MarketContext.Provider
      value={{
        streamOpen,
        setStreamOpen,
        defiTab,
        setDefiTab,
        defiDropdown,
        setDefiDropdown,
        defiSelectedDropdown,
        setDefiSelectedDropdown,
        coinSelected,
        setCoinSelected,
        buyContract,
        setBuyContract,
        buyContractStep,
        setBuyContractStep,
        selectedAsset,
        setSelectedAsset,
        contractId,
        setContractId,
        coinBalance,
        setCoinBalance,
        contractCost,
        setContractCost,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export const useMarketContext = () => useContext(MarketContext);
