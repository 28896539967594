import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory, useParams } from 'react-router-dom';
import BondMobileStats from '../components/BondOverviewMobile/BondMobileStats';
import BondMobileTransactions from '../components/BondOverviewMobile/BondMobileTransactions';
import BondSearch from '../components/BondOverviewMobile/BondSearch';

import BondOverviewMobileFooter from '../components/BondOverviewMobileFooter/BondOverviewMobileFooter';
import LionBond from '../components/SVGComponents/LionBond';
import { BankContext } from '../context/Context';
import Layout from '../Layout/Index';
import { FormatCurrency } from '../utils/FunctionTools';

function BondOverviewMobile() {
  const { tostShowOn, coinListObject, defaultPrecission, coinList } =
    useContext(BankContext);
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { contractId } = useParams();

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `https://comms.globalxchange.io/coin/iced/contract/get?_id=${contractId}`
    )
      .then((res) => {
        const { data } = res;
        if (
          data.status &&
          data.icedContracts &&
          data.icedContracts[0] &&
          data.icedContracts[0].contracts &&
          data.icedContracts[0].contracts[0]
        )
          setContract(data.icedContracts[0].contracts[0]);
        else {
          tostShowOn(data.message || 'Something Went Wrong');
          history.push('/moneyMarkets');
        }
      })
      .finally(() => {
        setLoading(false);
      }); // eslint-disable-next-line
  }, [contractId]);

  const [bondTab, setBondTab] = useState('Stats');
  const [openSelectCoin, setOpenSelectCoin] = useState(false);
  const [selectCoin, setSelectCoin] = useState('');
  useEffect(() => {
    setSelectCoin(contract.asset);
  }, [contract]);

  const [rate, setRate] = useState(1);
  useEffect(() => {
    setRate(coinListObject && coinListObject[selectCoin]?.price?.USD);
    console.log(
      'coinListObject && coinListObject[selectCoin]?.price?.USD',
      coinListObject && coinListObject[selectCoin]?.price?.USD
    );
  }, [selectCoin, coinListObject]);

  function getTabContent() {
    switch (true) {
      case openSelectCoin:
        return (
          <>
            <div className="coinTitle">Choose Display Currency</div>
            <div className="bondCurrency">
              Bond Currency:{' '}
              {coinListObject &&
                coinListObject[contract.asset] &&
                coinListObject[contract.asset].coinName}
            </div>
            {coinList.map((coin) => (
              <div
                className="coinItem"
                key={coin.coinSymbol}
                onClick={() => {
                  setSelectCoin(coin.coinSymbol);
                  setOpenSelectCoin(false);
                }}
              >
                <img src={coin.coinImage} alt="" className="coinIcn" />
                <span>{coin.coinName}</span>
              </div>
            ))}
          </>
        );
      case bondTab === 'Stats':
        return <BondMobileStats contract={contract} />;
      case bondTab === 'Transactions':
        return <BondMobileTransactions contractId={contractId} />;
      case bondTab === 'Search':
        return <BondSearch />;
      default:
        return '';
    }
  }

  return (
    <Layout
      active="moneyMarkets"
      className="bondOverviewMobile"
      mobileFooter={
        <BondOverviewMobileFooter
          menuSelected={bondTab}
          setMenuSelected={setBondTab}
        />
      }
    >
      {bondTab !== 'Search' && (
        <>
          <LionBond
            text={`${
              coinListObject &&
              coinListObject[contract.asset] &&
              coinListObject[contract.asset].coinName &&
              coinListObject[contract.asset].coinName.toUpperCase()
            } BOND`}
          />
          <div className={`bondId ${openSelectCoin}`}>
            <div className="bond">Bond</div>
            <div className="idVal">
              <span>{contractId}</span>
            </div>
            <div
              className="defCoin"
              onClick={() => setOpenSelectCoin(!openSelectCoin)}
            >
              {selectCoin}
            </div>
          </div>
          <div className={`valueOfBond ${openSelectCoin}`}>
            <span>Value Of Bond</span>
            <span className="value">
              <span className="def">
                {contract &&
                  coinListObject &&
                  FormatCurrency(
                    (contract.current_voc *
                      coinListObject[contract.asset]?.price?.USD) /
                      rate,
                    selectCoin,
                    defaultPrecission
                  )}
                {selectCoin}
              </span>
              <span className="hov">
                $
                {contract &&
                  FormatCurrency(
                    contract.current_voc *
                      ((coinListObject &&
                        coinListObject[contract.asset] &&
                        coinListObject[contract.asset].price.USD) ||
                        1),
                    'USD',
                    defaultPrecission
                  )}
              </span>
            </span>
          </div>
        </>
      )}
      <Scrollbars
        autoHide
        className="bondDetailScroll"
        renderView={(props) => <div {...props} className="view" />}
        renderThumbHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
      >
        {getTabContent()}
      </Scrollbars>
    </Layout>
  );
}

export default BondOverviewMobile;
