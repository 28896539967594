import axios from 'axios';

export const becomeAdvertiserFunc = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/promo/farm/user/upgrade/advertiser',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const createProductLineFunc = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/promo/farm/product/line/create',
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};
