import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import LoadingAnimation from './LoadingAnimation';
import { usdValueFormatter } from '../../../utils/FunctionTools';

const EarningsModal = ({ isOpen, onClose, selectedCustomer, userEmail }) => {
  const [selectedTab, setSelectedTab] = useState();
  const [earningsData, setEarningsData] = useState();
  const [selectedEarnings, setSelectedEarnings] = useState();

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab();
      setEarningsData();
      setSelectedEarnings();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCustomer) {
      const earnings = selectedCustomer?.comData;

      const parsedData = [
        {
          type: 'total',
          revenue: earnings?.total_revenue || 0,
          earnings: earnings?.total_earnings || 0,
          direct: earnings?.direct || 0,
          indirect: earnings?.indirect || 0,
        },
        ...(earnings?.data || []),
      ];

      setEarningsData(parsedData);
      setSelectedTab(parsedData[0]);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (earningsData) {
      const earning = earningsData.find(x => x?.type === selectedTab?.type);
      setSelectedEarnings(earning);
    } else {
      setSelectedEarnings();
    }
  }, [selectedTab, earningsData]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modelStyle}>
      <div className="modal-popup-container earnings-modal">
        <div className="earning-modal-header">
          <div className="header-item">Earnings</div>
          <div className="header-item alt">Equity</div>
        </div>
        <div className="tab-container">
          {earningsData?.map(item => (
            <div
              key={item.title}
              onClick={() => setSelectedTab(item)}
              className={`tab-item ${
                selectedTab?.type === item.type ? 'active' : ''
              }`}
            >
              {item.type}
            </div>
          ))}
        </div>
        <div className="view-container">
          {earningsData ? (
            <div className="earnings">
              <div className="earning-item">
                <div className="earning-title">
                  {usdValueFormatter.format(selectedEarnings?.revenue || 0)}
                </div>
                <div className="earning-label">{selectedTab?.type} Revenue</div>
              </div>
              <div className="earning-item">
                <div className="earning-title">
                  {usdValueFormatter.format(selectedEarnings?.earnings || 0)}
                </div>
                <div className="earning-label">
                  {selectedTab?.type} Earnings
                </div>
              </div>
              <div className="earning-item">
                <div className="earning-title">
                  {usdValueFormatter.format(selectedEarnings?.direct || 0)}
                </div>
                <div className="earning-label">Direct Earnings</div>
              </div>
              <div className="earning-item">
                <div className="earning-title">
                  {usdValueFormatter.format(selectedEarnings?.indirect || 0)}
                </div>
                <div className="earning-label">Indirect Earnings</div>
              </div>
            </div>
          ) : (
            <div className="flex-fill d-flex flex-column justify-content-center">
              <LoadingAnimation />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EarningsModal;

const modelStyle = {
  overlay: {
    zIndex: '9999999999',
    backgroundColor: 'rgba(103, 103, 103, 0.75)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'none',
    overflow: 'auto',
    borderRadius: 'none',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '5rem',
    paddingBottom: '5rem',
    paddingLeft: '0',
    paddingRight: '0',
  },
};
