import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import { useHistory } from 'react-router-dom';
import CoinComponent from '../../components/MallProduct/CoinComponent';
import Step4 from './Step4';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';

import fulllogo from '../../static/images/retailer/fulllogo.svg';
import fulllogo1 from '../../static/images/engagement/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import close from '../CRMPage/close.svg';
import plus from '../CRMPage/plus.svg';
import add from './addcompPlan.svg';
import del from './delete.svg';
import './retailer.scss';

const RetailerProduct = () => {
  const {
    email,
    retailerTab,
    setRetailerTab,
    popularAction,
    setPopularAction,
    product,
    setProduct,
  } = useContext(BankContext);
  const { buy, setBuy, mallCoindata, setfilteredcoins } =
    useAppContextDetails();

  const history = useHistory();
  const { pathname } = useLocation();

  if (!email) {
    history.push('/');
  }

  useEffect(() => {
    let product = localStorage.getItem('retailerProduct');
    setProduct(JSON.parse(product));
  }, []);

  function filterItem(text) {
    let filterItem = mallCoindata?.filter((eachitem) => {
      return eachitem?.coinSymbol?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setfilteredcoins(filterItem);
  }

  return (
    <Layout
      active={pathname.includes('retailer') ? 'Retailer' : 'Engagement'}
      className="retailer"
      hideFooter
    >
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="retailer-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="retailer-icon" style={{ width: pathname.includes('retailer') ? '25%' : '35%' }}>
              <img
                src={pathname.includes('retailer') ? fulllogo : fulllogo1}
                alt=""
              />
            </div>
            <div className="search-retailer" style={{ width: '43%' }}>
              <div className="im">
                <input type="text" placeholder="Search Anything" />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-btn"
            // onClick={() => {
            //   setPopularAction(!popularAction);
            // }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss">
            <div className="hamburger">
              <p
                onClick={() => {
                  setBuy('step1');
                  if (pathname.includes('retailer')) {
                    history.push('/retailer');
                  } else {
                    history.push('/engagement');
                  }
                }}
                className="hov-animate"
              >
                Home{' '}
              </p>{' '}
              &nbsp; &gt;
              <p> &nbsp; {product?.product_name}</p>
            </div>
          </div>
        </div>

        <div className="retailer-content">
          <div className="content-area">
            <div className="left-divv">
              <div className="productt-det">
                <img
                  src={product?.product_icon}
                  alt=""
                  style={{ width: '20%', height: '100%' }}
                />
                <div className="pro-name">
                  <p
                    style={{
                      fontSize: '3rem',
                      fontWeight: '700',
                      color: '#18191d',
                    }}
                  >
                    {product?.product_name}
                  </p>
                  <p style={{ color: '#757883' }}>{product?.sub_text}</p>
                </div>
              </div>

              <div className="price-section">
                <div className="about-section">
                  <p
                    style={{
                      fontSize: '1.5rem',
                      fontWeight: '700',
                      color: '#18191d',
                    }}
                  >
                    About {product?.product_name}
                  </p>
                  <div>{product?.full_description}</div>
                </div>
                <div className="price-divv">
                  <p
                    style={{
                      fontSize: '1.5rem',
                      fontWeight: '700',
                      color: '#18191d',
                    }}
                  >
                    Pricing Details
                  </p>
                  <div className="pricing">
                    <div className="billing-txt">Billing Type:</div>
                    <div className="pricing-btns">
                      <button
                        style={{
                          background: product?.lifetime
                            ? 'rgba(229, 229, 229, 0.37)'
                            : '#FFFFFF',
                          border: '0.5px solid #e5e5e5',
                        }}
                        className="onetym-btn"
                      >
                        One Time
                      </button>
                      <button
                        style={{
                          background: product?.lifetime
                            ? '#FFFFFF'
                            : 'rgba(229, 229, 229, 0.37)',
                          border: '0.5px solid #e5e5e5',
                        }}
                        className="onetym-btn"
                      >
                        Recurring
                      </button>
                    </div>
                  </div>
                  <div className="pricing">
                    <div className="billing-txt">Frequency:</div>
                    <div className="pricing-btns">
                      <button
                        style={{
                          background: product?.monthly
                            ? 'rgba(229, 229, 229, 0.37)'
                            : '#FFFFFF',
                          border: '0.5px solid #e5e5e5',
                        }}
                        className="onetym-btn"
                      >
                        Monthly
                      </button>
                      <button
                        style={{
                          background: product?.monthly
                            ? '#FFFFFF'
                            : 'rgba(229, 229, 229, 0.37)',
                          border: '0.5px solid #e5e5e5',
                        }}
                        className="onetym-btn"
                      >
                        Anually
                      </button>
                    </div>
                  </div>
                  <div className="pricing">
                    <div className="billing-txt">Staking:</div>
                    <div className="pricing-btns">
                      <button
                        style={{
                          background: product?.staking_allowed
                            ? 'rgba(229, 229, 229, 0.37)'
                            : '#FFFFFF',
                          border: '0.5px solid #e5e5e5',
                        }}
                        className="onetym-btn"
                      >
                        Allowed
                      </button>
                      <button
                        style={{
                          background: product?.staking_allowed
                            ? '#FFFFFF'
                            : 'rgba(229, 229, 229, 0.37)',
                          border: '0.5px solid #e5e5e5',
                        }}
                        className="onetym-btn"
                      >
                        Not Allowed
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="price-divv"
                  style={{
                    paddingTop: '2rem',
                    height: '23rem',
                    borderBottom: 'none',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1.5rem',
                      fontWeight: '700',
                      color: '#18191d',
                    }}
                  >
                    Other Data
                  </p>
                  <div className="pricing">
                    <div className="billing-txt">Affiliate Points:</div>
                    <div className="pricing-btns">
                      <button
                        style={{
                          background: product?.points_creation
                            ? 'rgba(229, 229, 229, 0.37)'
                            : '#FFFFFF',
                        }}
                        className="onetym-btn"
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          background: product?.points_creation
                            ? '#FFFFFF'
                            : 'rgba(229, 229, 229, 0.37)',
                          border: '0.5px solid #e5e5e5',
                        }}
                        className="onetym-btn"
                      >
                        No
                      </button>
                    </div>
                  </div>
                  <div className="pricing">
                    <div className="billing-txt">Grace Period:</div>
                    <div
                      className="pricing-btns"
                      style={{
                        justifyContent: 'flex-end',
                        color: '#18191d',
                        fontWeight: '700',
                      }}
                    >
                      {product?.grace_period} Days
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-divv">
              {buy === 'step1' ? (
                <>
                  <div className="amount-details">
                    {retailerTab === 'With CompPlan' ? (
                      <>
                        <div
                          className="left-amnt"
                          style={{ borderRight: '1px solid #E7E7E7' }}
                        >
                          <p className="amnt-font">
                            {product?.pricesWithAppFees?.length > 0 &&
                            product?.pricesWithAppFees[0]?.price
                              ? product?.pricesWithAppFees[0]?.price?.toFixed(2)
                              : '0.00'}
                            &nbsp;
                            {product?.pricesWithAppFees?.length > 0 &&
                            product?.pricesWithAppFees[0]?.coin
                              ? product?.pricesWithAppFees[0]?.coin
                              : ''}
                          </p>
                          <p className="text-font">
                            {product?.pricesWithAppFees?.length > 0 &&
                            product?.pricesWithAppFees[0]?.billing_method
                              ? product?.pricesWithAppFees[0]?.billing_method
                              : ''}
                          </p>
                        </div>
                        <div className="left-amnt1">
                          <p className="amnt-font">
                            {product?.pricesWithAppFees?.length > 0 &&
                            product?.pricesWithAppFees[1]?.price
                              ? product?.pricesWithAppFees[1]?.price?.toFixed(2)
                              : '0.00'}
                            &nbsp;
                            {product?.pricesWithAppFees?.length > 0 &&
                            product?.pricesWithAppFees[1]
                              ? product?.pricesWithAppFees[1]?.coin
                              : ''}
                          </p>
                          <p className="text-font">
                            {product?.pricesWithAppFees?.length > 0 &&
                            product?.pricesWithAppFees[1]?.billing_method
                              ? product?.pricesWithAppFees[1]?.billing_method
                              : ''}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="left-amnt"
                          style={{ borderRight: '1px solid #E7E7E7' }}
                        >
                          <p className="amnt-font">
                            {product?.first_purchase?.price
                              ? Number(product?.first_purchase?.price)?.toFixed(
                                  2
                                )
                              : '0.00'}
                            &nbsp;
                            {product?.first_purchase?.coin
                              ? product?.first_purchase?.coin
                              : ''}
                          </p>
                          <p className="text-font">First Purchase</p>
                        </div>
                        <div className="left-amnt1">
                          <p className="amnt-font">
                            {product?.monthly &&
                            product?.billing_cycle?.monthly?.price
                              ? Number(
                                  product?.billing_cycle?.monthly?.price
                                )?.toFixed(2)
                              : '0.00'}
                            &nbsp;
                            {product?.monthly &&
                            product?.billing_cycle?.monthly?.coin
                              ? product?.billing_cycle?.monthly?.coin
                              : product?.billing_cycle?.lifetime?.coin}
                          </p>
                          <p className="text-font">Monthly</p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="billing">
                    <div>Billing Currency:</div>
                    <div>{product?.billing_cycle?.monthly?.coin}</div>
                  </div>
                  <div className="buttonss">
                    <button
                      className="buy-btn"
                      onClick={() => {
                        setBuy('step2');
                      }}
                    >
                      Buy Now
                    </button>
                    <button className="share-btn">Share</button>
                  </div>
                </>
              ) : buy === 'step2' ? (
                <div className="buy-step1">
                  <div
                    style={{
                      fontSize: '1.25rem',
                      fontWeight: '700',
                      color: '#18191d',
                    }}
                  >
                    Select Currency To Pay With?
                  </div>
                  <div className="searchh">
                    <input
                      type="text"
                      placeholder="Search DGP Vaults.."
                      onChange={(event) => filterItem(event.target.value)}
                    />
                  </div>
                  <div className="coin-options">
                    <CoinComponent />
                  </div>
                </div>
              ) : buy === 'step3' ? (
                <div className="buy-step1">
                  <div
                    style={{
                      fontSize: '1.25rem',
                      fontWeight: '700',
                      color: '#18191d',
                    }}
                  >
                    Are You Sure You Want To Subscribe To{' '}
                    {product?.product_name}?
                  </div>
                  <div className="boxx" onClick={() => setBuy('step4')}>
                    Confirm Subscription
                  </div>
                  <div
                    className="boxx"
                    style={{
                      marginTop: '1.5rem',
                    }}
                    onClick={() => setBuy('step2')}
                  >
                    Go Back
                  </div>
                  <div
                    className="boxx"
                    style={{
                      marginTop: '1.5rem',
                    }}
                  >
                    Cancel Order
                  </div>
                </div>
              ) : (
                <div className="buy-step1">
                  <Step4 />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RetailerProduct;
