import React, { useCallback, useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from '../marketsPage.module.scss';
import angleDown from '../../../static/images/angleDown.svg';
import { useBankerList, useCustomBondsList } from '../../../queryHooks';
import { BankContext } from '../../../context/Context';
import { FormatCurrency, FormatNumber } from '../../../utils/FunctionTools';
import SelectBondAssetModal from '../../../components/SelectBondAssetModal';
import doubleTik from '../../../static/images/clipIcons/doubleTik.svg';
import BondDetailModal from '../../../components/BondDetailModal';
import SelectBondIssuerModal from '../../../components/SelectBondIssuerModal';
import { useAppContextDetails } from '../../../context/AppContext';
import { useMarketContext } from '../../../context/MarketContext';
import axios from 'axios';

function MarketTableBonds({ streamOpen, active, search }) {
  const { data: bankerList = [] } = useBankerList();
  const { email, coinListObject } = useContext(BankContext);
  const { ownerEmail, appCurrencySymbol } = useAppContextDetails();
  const {
    defiSelectedDropdown,
    buyContract,
    setBuyContract,
    selectedAsset,
    setSelectedAsset,
    coinBalance,
    setCoinBalance,
    contractCost,
    setContractCost,
  } = useMarketContext();

  const getBankerDetail = useCallback(
    (bankerTag) =>
      bankerList.filter((banker) => banker?.bankerTag === bankerTag)[0],
    [bankerList]
  );
  const [bondAsset, setBondAsset] = useState({
    name: 'All Bond Assets ',
    coin: undefined,
    icon: doubleTik,
  });
  const [assetIssuer, setAssetIssuer] = useState({
    displayName: 'All Issuers',
    bankerTag: undefined,
    profilePicURL: doubleTik,
  });
  const { data: bondsData, isLoading } = useCustomBondsList(email, ownerEmail);

  const [assetModal, setAssetModal] = useState(false);
  const [issuerModalOpen, setIssuerModalOpen] = useState(false);
  const [coinSelected, setCoinSelected] = useState();
  const [filterOpen, setFilterOpen] = useState(false);

  const getDataForOneBondTemplateID = (templateId) => {
    axios
      .post('https://comms.globalxchange.io/coin/iced/banker/custom/bond/get', {
        bond_template_id: templateId,
      })
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'result');
        setSelectedAsset(result);
        setContractCost(result?.custom_bonds?.[0]?.coinsData?.[0]?.bondCost);
      })
      .catch((error) => {
        console.log(error, 'templateId error');
      });
  };

  const getBalance = (coin) => {
    let obj = {
      app_code: 'broker_app',
      email,
      include_coins: [coin],
    };
    axios
      .post('https://comms.globalxchange.io/coin/vault/service/coins/get', obj)
      .then((response) => {
        let result = response?.data?.coins_data?.[0];
        // console.log(result, 'balance result');
        setCoinBalance(result);
      })
      .catch((error) => {
        console.log(error, 'getBalance error');
      });
  };

  return (
    <div className={classNames.marketTable}>
      <div
        className={`${classNames.header} ${classNames[!streamOpen]} ${
          classNames[active ? 'active' : '']
        }`}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 1fr)',
          width: '180%',
        }}
      >
        <div className={classNames.assets}>Issuer</div>
        <div className={classNames.assets}>Asset</div>
        <div className={classNames.price}>Contract Cost</div>
        <div className={classNames.marCap}>Length</div>
        <div className={classNames.supply}>Daily Rewards</div>
        <div className={classNames.supply}>Monthly Rewards</div>
        <div className={classNames.supply}>Annual Rewards</div>
        <div className={classNames.supply}>Term Rewards</div>
        <img
          className={`${classNames.btnFilter} ${
            classNames[filterOpen.toString()]
          }`}
          src={angleDown}
          alt=""
          onClick={() => setFilterOpen(!filterOpen)}
        />
      </div>
      {filterOpen && (
        <div className={classNames.filtersWrap} style={{ width: '180%' }}>
          <div className={classNames.filters}>
            <div
              className={classNames.filter}
              onClick={() => setIssuerModalOpen(true)}
            >
              <div className={classNames.label}>Issuer</div>
              <div className={classNames.value}>
                <img src={assetIssuer.profilePicURL} alt="" />
                <span>{assetIssuer.displayName}</span>
              </div>
            </div>
            <div className={classNames.filter}>
              <div className={classNames.label}>Display Currency</div>
              <div className={classNames.value}>
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/rupee.png"
                  alt=""
                />
                <span>{appCurrencySymbol}</span>
              </div>
            </div>
            <div
              className={classNames.filter}
              onClick={() => setAssetModal(true)}
            >
              <div className={classNames.label}>Bond Asset</div>
              <div className={classNames.value}>
                <img src={bondAsset.icon} alt="" />
                <span>{bondAsset.name}</span>
              </div>
            </div>
            <div className={classNames.filter} style={{ opacity: 0.3 }}>
              + <span>Add Filter</span>
            </div>
          </div>
        </div>
      )}
      <div className={classNames.marketsList}>
        {isLoading
          ? Array(8)
              .fill('')
              .map((_, i) => (
                <div
                  className={`${classNames.marketItemBond}  ${
                    classNames[!streamOpen]
                  }`}
                  key={i}
                >
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                </div>
              ))
          : defiSelectedDropdown == 'Asset' && bondsData?.bondsListData
          ? bondsData?.bondsListData
              ?.filter(
                (coin) =>
                  // coin?.bankerData?.displayName
                  //   ?.toLowerCase()
                  //   .includes(search.toLowerCase()) ||
                  coin?.coinsData?.[0]?.coin_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  coin?.coinsData?.[0]?.coin
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              ?.map((coin, i) => (
                <div
                  className={`${classNames.marketItemBond}  ${
                    classNames[!streamOpen]
                  }`}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(8, 1fr)',
                    width: '180%',
                  }}
                  key={coin._id}
                  onClick={() => {
                    setBuyContract(true);
                    setCoinSelected({
                      coin,
                      coinOb: coin?.coinsData?.[0]?.coin,
                      banker: getBankerDetail(coin?.bankerData?.bankerTag),
                    });
                    getDataForOneBondTemplateID(coin?.bond_template_id);
                    getBalance(coin?.coinsData?.[0]?.coin);
                  }}
                >
                  {/* Banker */}
                  <div className={classNames.assets}>
                    <img src={coin?.bankerData?.profilePicURL} alt="" />
                    <div className={classNames.names}>
                      <div className={classNames.name}>
                        {coin?.bankerData?.displayName}
                      </div>
                      <span>
                        {getBankerDetail(coin?.bankerData?.bankerTag)?.country}
                      </span>
                    </div>
                  </div>
                  {/* Asset */}
                  <div className={classNames.assets}>
                    <img src={coin?.coinsData?.[0]?.coin_icon} alt="" />
                    <div className={classNames.names}>
                      <div className={classNames.name}>
                        {coin?.coinsData?.[0]?.coin_name}
                      </div>
                      <span>{coin?.coinsData?.[0]?.coin}</span>
                    </div>
                  </div>
                  {/* Unit Cost */}
                  <div className={classNames.price}>
                    {FormatCurrency(
                      coin?.coinsData[0]?.bondCost,
                      coin?.coinsData[0]?.coin
                    )}
                    <span>
                      ({appCurrencySymbol}&nbsp;
                      {FormatCurrency(
                        coin?.coinsData[0]?.bondCost *
                          (coin?.coinsData[0]?.coin === appCurrencySymbol
                            ? 1
                            : coinListObject &&
                              coinListObject[coin?.coinsData[0]?.coin]?.price?.[
                                appCurrencySymbol
                              ]),
                        appCurrencySymbol
                      )}
                      )
                    </span>
                  </div>
                  {/* Length */}
                  <div className={classNames.marCap}>
                    {FormatNumber(coin?.days)} Days
                    <span>{FormatNumber(coin?.months, 0)} Months</span>
                  </div>
                  {/* Daily Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.daily_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.perDayEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                  {/* Monthly Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.monthly_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.monthlyEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                  {/* Annual Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.annual_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.annualEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                  {/* Term Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.term_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.termEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                </div>
              ))
          : bondsData?.bondsListData
              ?.filter((coin) =>
                coin?.bankerData?.displayName
                  ?.toLowerCase()
                  .includes(search.toLowerCase())
              )
              ?.map((coin, i) => (
                <div
                  className={`${classNames.marketItemBond}  ${
                    classNames[!streamOpen]
                  }`}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(8, 1fr)',
                    width: '180%',
                  }}
                  key={coin._id}
                  onClick={() => {
                    setCoinSelected({
                      coin,
                      coinOb: coin?.coinsData?.[0]?.coin,
                      banker: getBankerDetail(coin?.bankerData?.bankerTag),
                    });
                  }}
                >
                  {/* Banker */}
                  <div className={classNames.assets}>
                    <img src={coin?.bankerData?.profilePicURL} alt="" />
                    <div className={classNames.names}>
                      <div className={classNames.name}>
                        {coin?.bankerData?.displayName}
                      </div>
                      <span>
                        {getBankerDetail(coin?.bankerData?.bankerTag)?.country}
                      </span>
                    </div>
                  </div>
                  {/* Asset */}
                  <div className={classNames.assets}>
                    <img src={coin?.coinsData?.[0]?.coin_icon} alt="" />
                    <div className={classNames.names}>
                      <div className={classNames.name}>
                        {coin?.coinsData?.[0]?.coin_name}
                      </div>
                      <span>{coin?.coinsData?.[0]?.coin}</span>
                    </div>
                  </div>
                  {/* Unit Cost */}
                  <div className={classNames.price}>
                    {FormatCurrency(
                      coin?.coinsData[0]?.bondCost,
                      coin?.coinsData[0]?.coin
                    )}
                    <span>
                      ({appCurrencySymbol}&nbsp;
                      {FormatCurrency(
                        coin?.coinsData[0]?.bondCost *
                          (coin?.coinsData[0]?.coin === appCurrencySymbol
                            ? 1
                            : coinListObject &&
                              coinListObject[coin?.coinsData[0]?.coin]?.price?.[
                                appCurrencySymbol
                              ]),
                        appCurrencySymbol
                      )}
                      )
                    </span>
                  </div>
                  {/* Length */}
                  <div className={classNames.marCap}>
                    {FormatNumber(coin?.days)} Days
                    <span>{FormatNumber(coin?.months, 0)} Months</span>
                  </div>
                  {/* Daily Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.daily_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.perDayEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                  {/* Monthly Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.monthly_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.monthlyEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                  {/* Annual Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.annual_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.annualEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                  {/* Term Interest */}
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.term_interest_rate, 2)}%
                    <span>
                      (
                      {FormatCurrency(
                        coin?.coinsData[0]?.termEarnings,
                        coin?.coinsData[0]?.coin
                      )}
                      &nbsp;
                      {coin?.coinsData[0]?.coin})
                    </span>
                  </div>
                </div>
              ))}
      </div>
      {assetModal && (
        <SelectBondAssetModal
          onClose={() => setAssetModal(false)}
          onSuccess={() => setAssetModal(false)}
          bondAsset={bondAsset}
          setBondAsset={setBondAsset}
        />
      )}
      {issuerModalOpen && (
        <SelectBondIssuerModal
          onClose={() => setIssuerModalOpen(false)}
          onSuccess={() => setIssuerModalOpen(false)}
          assetIssuer={assetIssuer}
          setAssetIssuer={setAssetIssuer}
        />
      )}
      {/* {coinSelected && (
        <BondDetailModal
          onClose={() => setCoinSelected(false)}
          data={coinSelected}
        />
      )} */}
    </div>
  );
}

export default MarketTableBonds;
