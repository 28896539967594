import React, { useState, useEffect, useContext } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import SwapBreadcrumb from './SwapBreadcrumb';
import up from '../../../static/images/icons/upArrow.svg';
import down from '../../../static/images/icons/downArrow.svg';
import { getAssetsBuy, getAssetsSell, getPathId, initiateTrade } from './api';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../../config';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import Skeleton from 'react-loading-skeleton';
import { LoadingAnimation } from '../../../components/LoadingAnimation';
import { useHistory } from 'react-router-dom';

const Breadcrumbs = () => {
  const { swapActionStep, selectedSwapAction } = useCoinContextData();

  switch (swapActionStep) {
    case 'step1':
      var data = [{ value: 'Direction', clickable: 'step1' }];
      return <SwapBreadcrumb data={data} />;
    case 'step2':
      var data = [
        { value: 'Direction', clickable: 'step2' },
        {
          value:
            selectedSwapAction === 'Buy'
              ? 'Buy Asset'
              : selectedSwapAction === 'Sell'
              ? 'Sell Asset'
              : '',
          clickable: 'step2',
        },
      ];
      return <SwapBreadcrumb data={data} />;
    case 'step3':
      var data = [
        {
          value:
            selectedSwapAction === 'Buy'
              ? 'Buy Asset'
              : selectedSwapAction === 'Sell'
              ? 'Sell Asset'
              : '',
          clickable: 'step3',
        },
        { value: 'Amount', clickable: 'step3' },
      ];
      return <SwapBreadcrumb data={data} />;
    case 'step4':
      var data = [
        { value: 'Amount', clickable: 'step4' },
        { value: 'Quote', clickable: 'step4' },
      ];
      return <SwapBreadcrumb data={data} />;
    case 'step5':
      return (
        <div
          style={{
            fontWeight: '450',
            borderTop: '0.5px solid #e5e5e5',
            borderBottom: '0.5px solid #e5e5e5',
            padding: '0.4rem 1.85rem',
          }}
        >
          Trade Completed
        </div>
      );
    default:
      return null;
  }
};

export const SwapAction = () => {
  const {
    swapActionStep,
    setSwapActionStep,
    selectedCoin,
    selectedSwapAction,
    setSelectedSwapAction,
    setSwapMenu,
    setCoinActionEnabled,
    assetName,
    setAssetName,
    enteredSwapValue,
    setEnteredSwapValue,
    buyResult,
    setBuyResult,
    sellResult,
    setSellResult,
    buyQuote,
    setBuyQuote,
    sellQuote,
    setSellQuote,
  } = useCoinContextData();
  const { appCode, appLogo } = useAppContextDetails();
  const { email, profileId } = useContext(BankContext);
  const userIdToken = Cookies.get(APP_USER_TOKEN);

  const [assetsList, setAssetsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [assetData, setAssetData] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [pathIdBuy, setPathIdBuy] = useState('');
  const [pathIdSell, setPathIdSell] = useState('');
  const [toast, setToast] = useState('');
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const handleAssetData = () => {
    if (selectedSwapAction === 'Buy') {
      getAssetsBuy(selectedCoin?.coinSymbol, appCode).then((response) => {
        let result = response?.data;
        // console.log(result, 'assetlist buy');
        if (result?.status) {
          setAssetsList(result?.pathData?.from_currency);
          setIsLoading(false);
        }
      });
    } else {
      getAssetsSell(selectedCoin?.coinSymbol, appCode).then((response) => {
        let result = response?.data;
        // console.log(result, 'assetlist sell');
        if (result?.status) {
          setAssetsList(result?.pathData?.to_currency);
          setIsLoading(false);
        }
      });
    }
  };

  const handleSelect = (each) => {
    setAssetName(each?.coin_metadata?.coinSymbol);
    setAssetData(each);
    if (selectedSwapAction === 'Buy') {
      setFrom(each?.coin_metadata?.coinSymbol);
      getPathId(
        each?.coin_metadata?.coinSymbol,
        selectedCoin?.coinSymbol,
        appCode
      ).then((response) => {
        let result = response?.data?.pathData?.paymentPaths?.[0]?.path_ids?.[0];
        // console.log(result, 'pathId buy');
        setPathIdBuy(result);
      });
    } else {
      setTo(each?.coin_metadata);
      getPathId(
        selectedCoin?.coinSymbol,
        each?.coin_metadata?.coinSymbol,
        appCode
      ).then((response) => {
        let result = response?.data?.pathData?.paymentPaths?.[0]?.path_ids?.[0];
        // console.log(result, 'pathId sell');
        setPathIdSell(result);
      });
    }
  };

  const getQuote = (data) => {
    setLoader(true);
    if (selectedSwapAction === 'Buy') {
      let obj = {
        token: userIdToken,
        email,
        app_code: appCode,
        profile_id: profileId,
        from_amount: enteredSwapValue,
        stats: data,
        identifier: `Exchange ${from} FOR ${selectedCoin?.coinSymbol}`,
        path_id: pathIdBuy,
      };
      initiateTrade(obj).then((response) => {
        let result = response?.data;
        // console.log(result, 'buy quote');
        if (data === true) {
          setBuyQuote(result);
          setLoader(false);
        } else {
          // console.log(result, 'buy result');
          if (result?.status) {
            setBuyResult(result);
            setLoader(false);
            setSwapActionStep('step5');
            history.push(
              `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
            );
          } else if (
            result?.status === false &&
            result?.message === 'The User has Insufficeint Balance!'
          ) {
            setLoader(false);
            setToast('Insufficient Balance');
          } else {
            setLoader(false);
            setToast(result?.message);
          }
        }
      });
    } else {
      let obj = {
        token: userIdToken,
        email,
        app_code: appCode,
        profile_id: profileId,
        from_amount: enteredSwapValue,
        stats: data,
        identifier: `Exchange ${selectedCoin?.coinSymbol} FOR ${to?.coinSymbol}`,
        path_id: pathIdSell,
      };
      initiateTrade(obj).then((response) => {
        let result = response?.data;
        if (data === true) {
          // console.log(result, 'sell quote');
          setSellQuote(result);
          setLoader(false);
        } else {
          // console.log(result, 'sell result');
          if (result?.status) {
            setSellResult(result);
            setLoader(false);
            setSwapActionStep('step5');
            history.push(
              `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
            );
          } else if (
            result?.status === false &&
            result?.message === 'The User has Insufficeint Balance!'
          ) {
            setLoader(false);
            setToast('Insufficient Balance');
          } else {
            setLoader(false);
            setToast(result?.message);
          }
        }
      });
    }
  };

  useEffect(() => {
    handleAssetData();
  }, [selectedSwapAction]);

  const onBlur = (e) => {
    const float = parseFloat(e.target.value);
    setEnteredSwapValue(float.toFixed(2));
  };

  return (
    <>
      <Breadcrumbs />
      <div
        style={{
          width: '100%',
          height:
            swapActionStep === 'step3'
              ? 'calc(97.7% - 15rem)'
              : 'calc(97.7% - 10rem)',
        }}
      >
        {toast && <ToastMessage textOne={toast} onClose={() => setToast('')} />}
        {swapActionStep === 'step1' && (
          <>
            <div className="selectt1" style={{ fontSize: '0.9rem' }}>
              Select An Action
            </div>
            <div
              className="selectt"
              style={{ gap: '10px', fontWeight: '450', cursor: 'pointer' }}
              onClick={() => {
                setSelectedSwapAction('Buy');
                setSwapActionStep('step2');
              }}
            >
              <img src={up} />
              <div>Buy {selectedCoin?.coinName}</div>
            </div>
            <div
              className="selectt"
              style={{ gap: '10px', fontWeight: '450', cursor: 'pointer' }}
              onClick={() => {
                setSelectedSwapAction('Sell');
                setSwapActionStep('step2');
              }}
            >
              <img src={down} />
              <div>Sell {selectedCoin?.coinName}</div>
            </div>
          </>
        )}
        {swapActionStep === 'step2' && (
          <>
            <div className="selectt1" style={{ fontSize: '0.9rem' }}>
              {selectedSwapAction === 'Buy'
                ? 'Which Asset Are You Selling?'
                : 'Which Asset Are You Buying?'}
            </div>
            <div className="assets-div">
              {isLoading
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="each-asset" key={index}>
                          <Skeleton borderRadius={50} width={25} height={25} />
                          <Skeleton width={75} height={25} />
                        </div>
                      );
                    })
                : assetsList.map((each) => {
                    return (
                      <div
                        className="each-asset"
                        key={each._id}
                        onClick={() => {
                          handleSelect(each);
                          setSwapActionStep('step3');
                        }}
                      >
                        <img
                          className="asset-Image"
                          src={each.coin_metadata.coinImage}
                          alt=""
                        />
                        <p className="asset-Title">
                          {each.coin_metadata.coinName}
                        </p>
                      </div>
                    );
                  })}
            </div>
          </>
        )}
        {swapActionStep === 'step3' && (
          <>
            <div className="selectt1" style={{ fontSize: '0.9rem' }}>
              {selectedSwapAction === 'Buy'
                ? `How much ${assetName} Are You Selling?`
                : `How much ${assetName} Are You Buying?`}
            </div>
            <div className="enter-div">
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <img
                  src={
                    selectedSwapAction === 'Buy'
                      ? assetData?.coin_metadata?.coinImage
                      : selectedCoin?.coinImage
                  }
                  alt=""
                />
                <div style={{ fontSize: '1.1rem', fontWeight: '550' }}>
                  {selectedSwapAction === 'Buy'
                    ? assetName
                    : selectedCoin?.coinSymbol}
                </div>
              </div>
              <input
                type="number"
                placeholder="0.00"
                style={{ textAlign: 'end', fontSize: '1.1rem' }}
                value={enteredSwapValue}
                onChange={(e) => {
                  setEnteredSwapValue(e.target.value);
                }}
                onBlur={onBlur}
              />
            </div>
          </>
        )}
        {swapActionStep === 'step4' && (
          <>
            <div className="selectt1" style={{ fontSize: '0.9rem' }}>
              How much{' '}
              {selectedSwapAction === 'Buy'
                ? selectedCoin?.coinSymbol
                : to?.coinSymbol}{' '}
              You Will Receive?
            </div>
            <div className="enter-div">
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <img
                  src={
                    selectedSwapAction === 'Buy'
                      ? selectedCoin?.coinImage
                      : to?.coinImage
                  }
                  alt=""
                />
                <div style={{ fontSize: '1.1rem', fontWeight: '550' }}>
                  {selectedSwapAction === 'Buy'
                    ? selectedCoin?.coinSymbol
                    : to?.coinSymbol}
                </div>
              </div>
              <input
                type="number"
                placeholder="0.00"
                style={{ textAlign: 'end', fontSize: '1.1rem' }}
                value={
                  selectedSwapAction === 'Buy'
                    ? buyQuote?.finalToAmount
                    : sellQuote?.finalToAmount
                }
              />
            </div>
            <div className="fee-details">
              <div className="fee-txt">
                <div>
                  Fees In{' '}
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.fees_in_coin
                    : sellQuote?.fees_in_coin}
                </div>
                <div>
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.gx_fee?.toFixed(4)
                    : sellQuote?.gx_fee?.toFixed(4)}{' '}
                  (
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.percentage_fee_by_volume?.fees_with_broker?.toFixed(
                        2
                      )
                    : sellQuote?.percentage_fee_by_volume?.fees_with_broker?.toFixed(
                        2
                      )}
                  %)
                </div>
              </div>
              <div className="fee-percentage">
                <div>Affiliate</div>
                <div>
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.brokerData?.fee?.toFixed(2)
                    : sellQuote?.brokerData?.fee?.toFixed(2)}{' '}
                  (
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.brokerData?.broker_fee_percentage?.toFixed(2)
                    : sellQuote?.brokerData?.broker_fee_percentage?.toFixed(2)}
                  %)
                </div>
              </div>
              <div className="fee-percentage" style={{ paddingTop: '0' }}>
                <div>AiProWallet</div>
                <div>
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.appData?.app_fee?.toFixed(2)
                    : sellQuote?.appData?.app_fee?.toFixed(2)}{' '}
                  (
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.appData?.app_fee_percentage?.toFixed(2)
                    : sellQuote?.appData?.app_fee_percentage?.toFixed(2)}
                  %)
                </div>
              </div>
              <div className="fee-percentage" style={{ paddingTop: '0' }}>
                <div>Exchange</div>
                <div>
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.bankerData?.trade_fee_native?.toFixed(2)
                    : sellQuote?.bankerData?.trade_fee_native?.toFixed(2)}{' '}
                  (
                  {selectedSwapAction === 'Buy'
                    ? buyQuote?.bankerData?.banker_fee_percentage?.toFixed(2)
                    : sellQuote?.bankerData?.banker_fee_percentage?.toFixed(2)}
                  %)
                </div>
              </div>
            </div>
          </>
        )}
        {swapActionStep === 'step5' && (
          <>
            <div className="congts">
              Congratulations. You Have Successully Exchanged{' '}
              {selectedSwapAction === 'Buy'
                ? `${enteredSwapValue} ${assetName} for ${buyQuote?.finalToAmount} ${selectedCoin?.coinName}`
                : `${sellQuote?.finalToAmount} ${selectedCoin?.coinSymbol}} for ${enteredSwapValue} ${to?.coinSymbol}`}
            </div>
            <div className="new-txt">
              New{' '}
              {selectedSwapAction === 'Buy' || buyResult
                ? buyResult?.userResult?.debit_obj?.coin
                : sellResult?.userResult?.debit_obj?.coin}{' '}
              Balance
            </div>
            <div className="new-bal">
              <div className="inp">
                {selectedSwapAction === 'Buy' || buyResult
                  ? buyResult?.userResult?.debit_obj?.updated_balance
                  : sellResult?.userResult?.debit_obj?.updated_balance}
              </div>
            </div>
            <div className="new-txt">
              New{' '}
              {selectedSwapAction === 'Buy' || buyResult
                ? buyResult?.bankerResult?.credit_obj?.coin
                : sellResult?.bankerResult?.credit_obj?.coin}{' '}
              Balance
            </div>
            <div className="new-bal">
              <div className="inp">
                {selectedSwapAction === 'Buy' || buyResult
                  ? buyResult?.bankerResult?.credit_obj?.updated_balance
                  : sellResult?.bankerResult?.credit_obj?.updated_balance}
              </div>
            </div>
          </>
        )}
      </div>
      {swapActionStep === 'step1' && (
        <div className="newbtns-div1">
          <div
            className="top-btn1"
            style={{
              opacity: '0.25',
              cursor: 'not-allowed',
            }}
          >
            Back
          </div>
          <div
            className="bottom-btn1"
            onClick={() => {
              setSwapMenu(false);
              setCoinActionEnabled(false);
            }}
          >
            Close
          </div>
        </div>
      )}
      {swapActionStep === 'step2' && (
        <div className="newbtns-div1">
          <div
            className="top-btn1"
            onClick={() => {
              setSwapActionStep('step1');
            }}
          >
            Back
          </div>
          <div
            className="bottom-btn1"
            onClick={() => {
              setSwapMenu(false);
              setCoinActionEnabled(false);
              setSwapActionStep('step1');
            }}
          >
            Close
          </div>
        </div>
      )}
      {swapActionStep === 'step3' && (
        <>
          <div className="newbtns-div1" style={{ height: '15rem' }}>
            <div
              className="top-btn1"
              style={{
                background: '#38AF79',
                opacity: enteredSwapValue ? '1' : '0.25',
                cursor: enteredSwapValue ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (enteredSwapValue) {
                  setSwapActionStep('step4');
                  getQuote(true);
                }
              }}
            >
              Confirm
            </div>
            <div
              className="top-btn1"
              onClick={() => {
                setSwapActionStep('step2');
                setAssetName('');
              }}
            >
              Back
            </div>
            <div
              className="bottom-btn1"
              onClick={() => {
                setSwapMenu(false);
                setCoinActionEnabled(false);
                setSwapActionStep('step1');
              }}
            >
              Close
            </div>
          </div>
        </>
      )}
      {swapActionStep === 'step4' && (
        <div className="newbtns-div1">
          <div
            className="top-btn1"
            onClick={() => {
              setEnteredSwapValue('');
              setSwapActionStep('step3');
            }}
          >
            Go Back
          </div>
          <div
            className="bottom-btn1"
            style={{ background: '#38AF79' }}
            onClick={() => {
              getQuote(false);
            }}
          >
            Confirm
          </div>
        </div>
      )}
      {swapActionStep === 'step5' && (
        <div className="newbtns-div1">
          <div
            className="top-btn1"
            style={{ background: '#9FD6DF' }}
            // onClick={() => {
            //   setSwapActionStep('step3');
            // }}
          >
            Trade Again
          </div>
          <div
            className="bottom-btn1"
            style={{ background: '#18191d' }}
            onClick={() => {
              setSwapActionStep('step1');
              setEnteredSwapValue('');
              setSwapMenu(false);
              setCoinActionEnabled(false);
            }}
          >
            Close
          </div>
        </div>
      )}
      {loader && (
        <div className="loadingIcon" style={{ height: '100%' }}>
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};
