import React, { useState, useRef } from 'react';
import './affiliate.scss';
import { useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Exchange from './Exchange';
import {
  commissionForExchangeFees,
  commissionForMM,
  brandsUserFollows,
  getCommission,
} from './api';
import MoneyMarket from './MoneyMarket';
import Skeleton from 'react-loading-skeleton';
import StakingCommission from './StakingCommission';
import moment from 'moment';

//images
import ProductsAffiliateBank from './ProductsAffiliateBank';
import plus from '../../static/images/affiliate/plus1.svg';
import comp from '../../static/images/affiliate/comp.svg';
import filter from '../../static/images/affiliate/filter1.svg';
import products1 from '../../static/images/affiliate/products.svg';
import mm from '../../static/images/affiliate/mm.svg';
import ef from '../../static/images/affiliate/ef.svg';
import rb from '../../static/images/affiliate/rb.svg';
import ai from '../../static/images/affiliate/ai.svg';
import staking1 from '../../static/images/affiliate/staking.svg';
import classroom from '../../static/images/affiliate/classroom.svg';
import funds from '../../static/images/affiliate/funds.svg';
import withdraw from '../../static/images/affiliate/withdraw.svg';
import close from '../../static/images/affiliate/dropdown/close.svg';
import axios from 'axios';

const AffiliateBank = () => {
  const { email, username, name, profileImg } = useContext(BankContext);
  const {
    balances,
    setBalances,
    iswithdraw,
    setIswithdraw,
    exchangeData,
    setExchangeData,
    MMData,
    setMMData,
    ddselected,
    setDdSelected,
    filteredExchangedata,
    setFilteredExchangeData,
    filteredMMData,
    setFilteredMMData,
    filteredProductsABData,
    setFilteredProductsABData,
  } = useAppContextDetails();
  const [dropdown, setDropDown] = useState(false);
  const [isloading1, setIsloading1] = useState(true);
  const [isloading2, setIsloading2] = useState(true);
  const [ddIcon, setDdIcon] = useState(staking1);
  const [collapse, setCollapse] = useState(false);
  const [hidden, setHidden] = useState(false);
  const scrollableDivRef = useRef(null);
  const [gettingBrands, setGettingBrands] = useState(true);
  const [brandsData, setBrandsData] = useState([]);
  const [followdropDown, setFollowDropdown] = useState(false);
  const [followddIcon, setFollowddIcon] = useState();
  const [followddselected, setFollowddselected] = useState();
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [gettingCommission, setGettingCommission] = useState(true);

  useEffect(() => {
    setIsloading1(true);
    setIsloading2(true);

    commissionForExchangeFees(email).then((response) => {
      let result = response?.data?.logs;
      // console.log(result, 'EF');
      setExchangeData(result);
      setFilteredExchangeData(result);
      setIsloading1(false);
    });

    commissionForMM(email).then((response) => {
      let result = response?.data?.logsData?.logs;
      // console.log(result, 'MM');
      setMMData(result);
      setFilteredMMData(result);
      setIsloading2(false);
    });
  }, []);

  // function updateScroll() {
  //   var element = document.getElementById('moveToTop');
  //   console.log(element, 'element');
  //   // element.scrollTop = 0;
  // }

  // const updateScroll = () => {
  //   // scrollableDivRef.current.scrollTop = 0;
  //   console.log(scrollableDivRef, 'scrollableDivRef');
  // };

  const getAllBrands = () => {
    brandsUserFollows(email).then((response) => {
      let result = response?.data?.data?.bankers;
      // console.log(result, 'getAllBrands result');
      setBrandsData(result);
      setGettingBrands(false);
    });
  };

  const brandCommission = (creator) => {
    setGettingCommission(true);
    getCommission(email, creator).then((response) => {
      let result = response?.data?.logs;
      console.log(result, 'brandCommission result');
      if (result?.length > 0) {
        setSelectedBrand(result);
        setGettingCommission(false);
      } else {
        setSelectedBrand([]);
        setGettingCommission(false);
      }
    });
  };

  const loading = isloading1 && isloading2;

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div className="affiliate-content1">
        <div
          className="topp-section"
          style={{ display: hidden ? 'none' : 'flex' }}
        >
          <div className="left-balances">
            <div className="each-balances each-balances1">
              <div className="heading">Balance</div>
              <div className="amount">
                $
                {balances?.gxbroker_balance
                  ? balances?.gxbroker_balance?.toFixed(2)
                  : '0.00'}
              </div>
            </div>
            <div className="each-balances each-balances2">
              <div className="heading">Withdrawable Balance</div>
              <div className="amount">
                $
                {balances?._2weekDelay_balance
                  ? balances?._2weekDelay_balance?.toFixed(2)
                  : '0.00'}
              </div>
            </div>
          </div>
        </div>
        <div
          className="filter-opts"
          style={{ borderTop: hidden ? '0.5px solid #e5e5e5' : '' }}
        >
          <div className="filters">
            {ddselected === 'Products' ? (
              <>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Product Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Company Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Date Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Customer Filter
                </div>
              </>
            ) : (
              <>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Directional Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Categorical Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Date Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Vault Filter
                </div>
              </>
            )}
          </div>
          <div
            className="compplan"
            onClick={() => {
              setDropDown(!dropdown);
              if (collapse) {
                setCollapse(false);
              }
            }}
          >
            <div>
              <img src={ddIcon} alt="compPlan" />
              {ddselected}
            </div>
          </div>
          <div
            className="compplan1"
            style={{ width: '15%' }}
            onClick={() => {
              getAllBrands();
              setFollowDropdown(!followdropDown);
              if (collapse) {
                setCollapse(false);
              }
            }}
          >
            <div>
              {followddselected ? (
                <div>
                  <img
                    src={followddIcon}
                    style={{ width: '25px', height: '25px' }}
                  />
                  {followddselected}
                </div>
              ) : (
                'Brands I Follow'
              )}
            </div>
          </div>
          <div
            className="filterIcon"
            onClick={() => {
              setCollapse(!collapse);
              if (dropdown) {
                setDropDown(false);
              }
              if (followdropDown) {
                setFollowDropdown(false);
              }
            }}
          >
            <img src={collapse ? close : filter} alt="" />
          </div>
        </div>
        {!dropdown && !followdropDown && !collapse && followddselected ? (
          <>
            <div className="headd-section">
              <div className="type" style={{ width: '20%' }}>
                User
              </div>
              <div className="user" style={{ width: '20%' }}>
                Product
              </div>
              <div className="dd" style={{ width: '15%' }}>
                Comp Value
              </div>
              <div className="direct">Direct Earnings</div>
              <div className="direct">Indirect Earnings</div>
              <div className="baln" style={{ width: '15%' }}>
                Prime Affiliate Earnings
              </div>
            </div>
            <div
              className="balance-data"
              style={{
                height: hidden ? 'calc(100% - 7rem)' : 'calc(100% - 17rem)',
              }}
              id="moveToTop"
              ref={scrollableDivRef}
            >
              <div
                style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
              >
                {gettingCommission
                  ? Array(10)
                      .fill(' ')
                      .map((item, index) => {
                        return (
                          <div className="ind-detailss" key={index}>
                            <div className="type1" style={{ width: '20%' }}>
                              <div className="detailss">
                                <Skeleton width={75} height={15} />
                                <p>
                                  <Skeleton width={100} height={15} />
                                </p>
                              </div>
                            </div>
                            <div className="type1" style={{ width: '20%' }}>
                              <div className="detailss">
                                <Skeleton width={100} height={15} />
                                <p>
                                  <Skeleton width={100} height={15} />
                                </p>
                              </div>
                            </div>
                            <div className="dd1" style={{ width: '15%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                            <div className="direct1">
                              <Skeleton width={60} height={25} />
                            </div>
                            <div className="direct1">
                              <Skeleton width={60} height={25} />
                            </div>
                            <div className="baln1" style={{ width: '15%' }}>
                              <Skeleton width={60} height={25} />
                            </div>
                          </div>
                        );
                      })
                  : selectedBrand.length > 0 && selectedBrand?.map((e, i) => {
                      return (
                        <div key={i} className="ind-detailss">
                          <div className="type1" style={{ width: '20%' }}>
                            <div className="detailss">
                              <p
                                style={{
                                  fontSize: '0.9rem',
                                  fontWeight: '600',
                                }}
                              >
                                {e?.txn?.email} ({e?.commissions?.ps})
                              </p>
                              <p
                                style={{
                                  fontSize: '0.8rem',
                                  fontWeight: '400',
                                }}
                              >
                                {moment(e?.txn?.date).format(
                                  'MMMM Do YYYY, h:mm a'
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="type1" style={{ width: '20%' }}>
                            <div className="detailss">
                              <p
                                style={{
                                  fontSize: '0.9rem',
                                  fontWeight: '600',
                                }}
                              >
                                {e?.productDetail?.product_code}
                              </p>
                              <p
                                style={{
                                  fontSize: '0.8rem',
                                  fontWeight: '400',
                                }}
                              >
                                Billed {e?.txn?.billing_method}
                              </p>
                            </div>
                          </div>
                          <div className="dd1" style={{ width: '15%' }}>
                            <div
                              style={{ fontSize: '0.9rem', fontWeight: '600' }}
                            >
                              {e?.txn?.totalComValue +
                                '/' +
                                e?.txn?.amount?.toFixed(2)}{' '}
                              {e?.txn?.amount_coin}
                            </div>
                          </div>

                          <div
                            className="direct1"
                            style={{ fontSize: '0.9rem', fontWeight: '600' }}
                          >
                            {e?.commissions?.dt_commission?.toFixed(4)}
                          </div>
                          <div
                            className="direct1"
                            style={{ fontSize: '0.9rem', fontWeight: '600' }}
                          >
                            {e?.commissions?.it_commission?.toFixed(4)}
                          </div>
                          <div
                            className="baln1"
                            style={{
                              width: '15%',
                              fontSize: '0.9rem',
                              fontWeight: '600',
                            }}
                          >
                            {e?.commissions?.bd_commission?.toFixed(4)}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </>
        ) : !dropdown &&
          !followdropDown &&
          !collapse &&
          ddselected === 'Products' ? (
          <div
            className="products-container"
            style={{
              height: hidden ? 'calc(100% - 4rem)' : 'calc(100% - 14rem)',
            }}
          >
            <div className="head-section-products">
              <div>Product</div>
              <div>Customer</div>
              <div>Cost</div>
              <div>Customer Debited</div>
              <div>Direct Earnings</div>
              <div>Indirect Earnings</div>
              <div>Prime Affiliate Earnings</div>
            </div>
            <div className="balance-data-products">
              {loading ? (
                Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div
                        className="ind-detailss products-details"
                        key={index}
                      >
                        <div className="type1">
                          <div className="detailss">
                            <Skeleton width={75} height={15} />
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="type1">
                          {/* <div>
                          <img
                            src={profileImg ? profileImg : guest}
                            alt=""
                            style={{ borderRadius: '50%', height: '50px' }}
                          />
                      </div> */}
                          <div className="detailss">
                            <p>
                              <Skeleton width={100} height={15} />
                            </p>
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="dd1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div className="baln1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div className="baln1">
                          <Skeleton width={60} height={25} />
                        </div>
                      </div>
                    );
                  })
              ) : (
                <ProductsAffiliateBank />
              )}
            </div>
          </div>
        ) : !dropdown &&
          !followdropDown &&
          !collapse &&
          ddselected === 'Staking' ? (
          <>
            <div className="headd-section">
              <div className="type" style={{ width: '20%' }}>
                User
              </div>
              <div className="user" style={{ width: '20%' }}>
                Txn Details
              </div>
              <div className="dd" style={{ width: '15%' }}>
                Fees From Txn
              </div>
              <div className="direct">Direct Earnings</div>
              <div className="direct">Indirect Earnings</div>
              <div className="baln" style={{ width: '15%' }}>
                Prime Affiliate Earnings
              </div>
            </div>
            <div
              className="balance-data"
              style={{
                height: hidden ? 'calc(100% - 7rem)' : 'calc(100% - 17rem)',
              }}
              id="moveToTop"
              ref={scrollableDivRef}
            >
              <StakingCommission />
            </div>
          </>
        ) : (
          ''
        )}

        {dropdown ? (
          <>
            <div
              className="headd-section"
              style={{ justifyContent: 'space-between' }}
            >
              <div
                style={{ fontSize: '0.85rem', fontWeight: '600', width: '91%' }}
              >
                Name
              </div>
            </div>
            <div
              className="dd-options"
              style={{
                height: hidden ? 'calc(100% - 7rem)' : 'calc(100% - 17rem)',
              }}
            >

              <div
                className="each-dd"
                onClick={() => {
                  setDdSelected('Staking');
                  setDdIcon(staking1);
                  setDropDown(false);
                  if(followddselected) {
                    setFollowddselected();
                  }
                }}
              >
                <div className="wid">
                  <img
                    src={staking1}
                    alt="stakingIcon"
                    className="exchange-icon"
                  />
                  <span>Staking</span>
                </div>
              </div>

              <div
                className="each-dd"
                onClick={() => {
                  setDdSelected('Exchange Fees');
                  setDdIcon(ef);
                  setDropDown(false);
                  if(followddselected) {
                    setFollowddselected();
                  }
                }}
              >
                <div className="wid">
                  <img
                    src={ef}
                    alt="exchangeFeesIcon"
                    className="exchange-icon"
                  />
                  <span>Exchange Fees</span>
                </div>
              </div>

              <div
                className="each-dd"
                onClick={() => {
                  setDdSelected('Money Markets');
                  setDdIcon(mm);
                  setDropDown(false);
                  if(followddselected) {
                    setFollowddselected();
                  }
                }}
              >
                <div className="wid">
                  <img src={mm} alt="moneyMarket" className="exchange-icon" />
                  <span>Money Markets</span>
                </div>
              </div>
            </div>
          </>
        ) : followdropDown ? (
          <>
            <div
              className="headd-section"
              style={{ justifyContent: 'space-between' }}
            >
              <div
                style={{ fontSize: '0.85rem', fontWeight: '600', width: '91%' }}
              >
                Name
              </div>
            </div>
            <div
              className="dd-options"
              style={{
                height: hidden ? 'calc(100% - 7rem)' : 'calc(100% - 17rem)',
              }}
            >
              {gettingBrands
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="each-dd" key={index}>
                          <div className="wid">
                            <Skeleton
                              width={30}
                              height={30}
                              borderRadius={50}
                            />
                            <span>
                              <Skeleton width={150} height={25} />
                            </span>
                          </div>
                        </div>
                      );
                    })
                : brandsData?.map((e, i) => {
                    return (
                      <div
                        className="each-dd"
                        onClick={() => {
                          setFollowddselected(e?.bankerDetails?.displayName);
                          setFollowddIcon(e?.bankerDetails?.profilePicURL);
                          setFollowDropdown(false);
                          brandCommission(e?.bankerEmail);
                        }}
                        key={i}
                      >
                        <div className="wid">
                          <img
                            src={e?.bankerDetails?.profilePicURL}
                            alt="Icon"
                            className="exchange-icon"
                          />
                          <span>{e?.bankerDetails?.displayName}</span>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </>
        ) : !dropdown &&
          !followdropDown &&
          !collapse &&
          ddselected !== 'Products' &&
          ddselected !== 'Staking' ? (
          <>
            <div className="headd-section">
              <div className="type">Type</div>
              <div className="user">User</div>
              <div className="dd">DDLevel</div>
              <div className="direct">Direct</div>
              <div className="direct">Indirect</div>
              <div className="baln">Balance</div>
            </div>

            <div
              className="balance-data"
              style={{
                height: hidden ? 'calc(100% - 7rem)' : 'calc(100% - 17rem)',
              }}
              id="moveToTop"
              ref={scrollableDivRef}
            >
              {loading ? (
                Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="ind-detailss" key={index}>
                        <div className="type1">
                          <div className="detailss">
                            <Skeleton width={75} height={15} />
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="type1">
                          <div className="detailss">
                            <Skeleton width={100} height={15} />
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="dd1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div className="baln1">
                          <Skeleton width={60} height={25} />
                        </div>
                      </div>
                    );
                  })
              ) : ddselected === 'Exchange Fees' ? (
                <Exchange />
              ) : (
                <MoneyMarket />
              )}
            </div>
          </>
        ) : !dropdown && !followdropDown && collapse ? (
          <>
            <div
              className="headd-section"
              style={{ justifyContent: 'flex-start', padding: '1rem 3rem' }}
            >
              <div
                style={{ fontSize: '0.85rem', fontWeight: '600', width: '91%' }}
              >
                Option
              </div>
            </div>
            <div className="dd-options">
              <div
                className="each-dd"
                onClick={() => {
                  setCollapse(!collapse);
                  setHidden(!hidden);
                }}
              >
                <div className="wid">{hidden ? 'Collapse' : 'Expand'}</div>
              </div>
              <div className="each-dd">
                <div className="wid" style={{ opacity: '0.25' }}>
                  Download As CSV
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default AffiliateBank;
